import styled from 'styled-components'

interface Props {
	marginTop?: string
}

export const FieldSet = styled.fieldset<Props>`
	padding: 10px;
	margin-top: ${(props) => props.marginTop};
	border: 1px solid #ddd;
`
