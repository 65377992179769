import { ContextMenuWrapper } from '@/components/ContextMenu/ContextMenuWrapper'
import {
	EditIcon,
	TabHead,
	TabHeadClose,
	TabHeadText,
} from '@/pages/User/pages/Home/components/TabsDetailView/styles'
import { EntityTypeIcon } from '@/pages/User/pages/Home/components/EntityTypeIcon'
import { faCircle, faTimes, faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TabHeadingProps } from '../types'
import { getDpDetailEditMode } from '@/pages/User/pages/Home/components/TabsDetailView/utils'
import { useAppStore } from '@/hooks'

const handleMouseDown = (event: React.MouseEvent) => {
	if (event.nativeEvent.detail > 1) {
		event.stopPropagation()
		event.preventDefault()
	}
}

export const TabHeading = ({
	contextMenu,
	node,
	isSelected,
	isTemporary,
	isVertical,
	handleClick,
	handleDblClick,
	handleMouseUp,
	tab,
	handleCloseClick,
}: TabHeadingProps) => {
	const openedTabs = useAppStore((state) => state.tab.openedTabs)
	const selectedTab = useAppStore((state) => state.tab.selectedTab)

	const currentTab =
		selectedTab !== undefined ? openedTabs[selectedTab] : undefined

	if (tab.releaseTabProps?.isReleaseTab) {
		return (
			<TabHead
				role="tab"
				aria-selected={isSelected}
				$isSelected={isSelected}
				$isTemporary={isTemporary}
				$isVertical={isVertical}
				onClick={handleClick}
				onDoubleClick={handleDblClick}
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
			>
				<FontAwesomeIcon icon={faRocket} color="green" />
				<TabHeadText>
					{tab.releaseTabProps.name}
					{getDpDetailEditMode(openedTabs, currentTab) && (
						<EditIcon icon={faCircle} />
					)}
				</TabHeadText>
				<TabHeadClose role="button" onClick={handleCloseClick}>
					<FontAwesomeIcon icon={faTimes} color="#999" />
				</TabHeadClose>
			</TabHead>
		)
	}

	if (node) {
		return (
			<ContextMenuWrapper menu={contextMenu}>
				<TabHead
					role="tab"
					aria-selected={isSelected}
					$isSelected={isSelected}
					$isTemporary={isTemporary}
					$isVertical={isVertical}
					key={`${node.id}`}
					onClick={handleClick}
					onDoubleClick={handleDblClick}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					title={node.name}
				>
					<EntityTypeIcon type={node.type} />
					<TabHeadText>
						{node.name || '(unnamed)'}
						{tab.editMode && <EditIcon icon={faCircle} />}
					</TabHeadText>
					<TabHeadClose role="button" onClick={handleCloseClick}>
						<FontAwesomeIcon icon={faTimes} color="#999" />
					</TabHeadClose>
				</TabHead>
			</ContextMenuWrapper>
		)
	}

	return (
		<TabHead
			role="tab"
			$isSelected={isSelected}
			$isTemporary={isTemporary}
			$isVertical={isVertical}
		>
			...
		</TabHead>
	)
}
