import { SystemData, SystemMapping } from '@/endpoints/schemas'
import { GenerateCodeEnum } from '@/types'

export interface OpenedSystemData {
	dirty: boolean
	form: SystemDataForm
	original: SystemDataForm
	parsedEditMode: boolean
	selectedReleaseId?: number
	systemTechnologyName?: string
	tab: SystemTab
	workflowTechnologyName?: string
}

export type SystemDataForm = Omit<
	Partial<SystemData>,
	'columns' | 'constants' | 'namingConfig'
> & {
	constants: SystemMapping[]
	generateCode?: GenerateCodeEnum
	generateTableColumnCode?: GenerateCodeEnum
	letterCaseToggle?: boolean
	lookups: SystemMapping[]
}

export enum SystemTab {
	Constants = 'Constants',
	Dependencies = 'Dependencies',
	Environments = 'Environments',
	Lookups = 'Lookups',
	Overview = 'Overview',
	Permissions = 'Permissions',
	Releases = 'Releases',
	Users = 'Users',
	Validation = 'Validation',
}
