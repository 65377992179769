import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFolder,
	faProjectDiagram,
	faTable,
	faDatabase,
	faFolderOpen,
	faWindowMaximize,
	faWifi,
	faLayerGroup,
	faRetweet,
	faTasks,
} from '@fortawesome/free-solid-svg-icons'

import { StructureObjectDto } from '@/endpoints/models'
import entityColors from '@/styles/entityColors'

const typeToIcon = {
	[StructureObjectDto.TypeEnum.ROOT]: faFolder,
	[StructureObjectDto.TypeEnum.SYSTEM]: faDatabase,
	[StructureObjectDto.TypeEnum.FOLDER]: faFolder,
	[StructureObjectDto.TypeEnum.FOLDER_WORKFLOW]: faFolder,
	[StructureObjectDto.TypeEnum.TABLE]: faTable,
	[StructureObjectDto.TypeEnum.TABLE_COLUMN]: faTable,
	[StructureObjectDto.TypeEnum.VIEW]: faWindowMaximize,
	[StructureObjectDto.TypeEnum.MAPPING]: faRetweet,
	[StructureObjectDto.TypeEnum.MAPPING_DEFAULT]: faRetweet,
	[StructureObjectDto.TypeEnum.WORKFLOW]: faProjectDiagram,
	[StructureObjectDto.TypeEnum.API_NODE]: faWifi,
	[StructureObjectDto.TypeEnum.MAPPING_READ]: faRetweet,
	[StructureObjectDto.TypeEnum.API_COLLECTION]: faLayerGroup,
	[StructureObjectDto.TypeEnum.QUEUE]: faTasks,
	[StructureObjectDto.TypeEnum.MAPPING_WRITE]: faRetweet,
	[StructureObjectDto.TypeEnum.CONSTANT_DOMAIN]: faRetweet,
}

const typeToExpandedIcon = {
	[StructureObjectDto.TypeEnum.FOLDER]: faFolderOpen,
	[StructureObjectDto.TypeEnum.FOLDER_WORKFLOW]: faFolderOpen,
}

export const EntityTypeIcon = ({
	type,
	expanded,
}: {
	type: StructureObjectDto.TypeEnum
	expanded?: boolean
}) => (
	<FontAwesomeIcon
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		icon={(expanded && (typeToExpandedIcon as any)[type]) || typeToIcon[type]}
		color={entityColors[type]}
		fixedWidth
	/>
)
