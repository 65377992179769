import styled, { css } from 'styled-components'

interface LegendProps {
	$isHighlighted?: boolean
}

export const Legend = styled.legend<LegendProps>`
	text-transform: uppercase;
	letter-spacing: 0.5px;
	${(props: LegendProps) =>
		props.$isHighlighted &&
		css`
			background: ${(props) => props.theme.colors.warn.light};
		`}
`
