import styled from 'styled-components'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { StructureObjectDto } from '@/endpoints/models'
import { SourceItem } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { OpenedMappingData } from '@/store/modules/mapping/types'
import { MainContentWrap } from '@/styles'

import { Source } from '../../../../components/Source/Source'

interface SourcesValues {
	criteria?: string
	criteriaDescription?: string
	sourceItems: SourceItem[]
	sourceItemsLastId: number
	sources?: string
	sourcesDescription?: string
	targetCriteria: string
	targetCriteriaDescription: string
}

interface Props {
	data: OpenedMappingData
	editMode: boolean
	node: StructureObjectDto
	onChange: (v: Partial<SourcesValues>) => void
	systemNodeId: number
}

export const Sources = ({ onChange, data, editMode }: Props) => {
	const { t } = useAppContext()

	return (
		<StyledContainer>
			<Form<SourcesValues>
				onChange={(values) =>
					onChange({
						...values,
						sourceItems: data.form.sourceItems,
						sourceItemsLastId: data.form.sourceItemsLastId,
					})
				}
				defaultValues={data.form}
				disabled={!editMode}
				enableFieldHighlight
			>
				<Wrapper>
					<Source data={data} onChange={onChange} />

					<TextAreaFormField
						name="sourcesDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_SOURCES')])}
						initialValue={data.form.sourcesDescription}
					/>
					<AceEditorField
						name="criteria"
						title={t('MAPPING_CRITERIA')}
						initialValue={data.form.criteria}
					/>
					<TextAreaFormField
						name="criteriaDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [t('MAPPING_CRITERIA')])}
						initialValue={data.form.criteriaDescription}
					/>
					<AceEditorField
						name="targetCriteria"
						title={t('MAPPING_TARGET_CRITERIA')}
						initialValue={data.form.targetCriteria}
					/>
					<TextAreaFormField
						name="targetCriteriaDescription"
						title={t('MAPPING_FIELD_DESCRIPTION', [
							t('MAPPING_TARGET_CRITERIA'),
						])}
						initialValue={data.form.targetCriteriaDescription}
					/>
				</Wrapper>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(MainContentWrap)`
	textarea {
		border-radius: 0;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`
