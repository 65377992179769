import React from 'react'
import styled from 'styled-components'

import { Tabs, TabProps } from '@/components/Tabs/Tabs'
import {
	WriteMappingTab,
	OpenedWriteMappingData,
	WriteMappingDataForm,
} from '@/store/modules/writeMapping/types'
import { Overview } from '../pages/Overview/Overview'
import { StructureObjectDto } from '@/endpoints/models'
import { Columns } from '../pages/Columns/Columns'
import { Sources } from '../pages/Sources/Sources'
import { Others } from '../pages/Others/Others'
import { useAppContext } from '@/hooks'
import { Deployments } from '../../../components/Deployments/Deployments'
import { Preview } from '../../../components/Preview/Preview'
import { Validation } from '../../../components/Validation/Validation'
import { UpdateDeepPartial } from '@/store/utils'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

type Props = {
	node: StructureObjectDto
	data: OpenedWriteMappingData
	editMode: boolean
	systemNodeId: number
	onTabChange: (tab: TabProps) => void
	onChange: (v: UpdateDeepPartial<WriteMappingDataForm>) => void
}

export const WriteMappingTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	onTabChange,
	onChange,
}: Props) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: WriteMappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={WriteMappingTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
							node={node}
						/>
					),
				},
				{
					id: WriteMappingTab.Columns,
					title: t('TAB_COLUMN_MAPPINGS'),
					content: (
						<Columns
							key={WriteMappingTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: WriteMappingTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={WriteMappingTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: WriteMappingTab.Others,
					title: t('TAB_OTHERS'),
					content: (
						<Others
							key={WriteMappingTab.Others}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: WriteMappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: <Preview key={WriteMappingTab.Preview} node={node} />,
				},
				{
					id: WriteMappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={WriteMappingTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: WriteMappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={WriteMappingTab.Validation} node={node} />,
				},
				{
					id: WriteMappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={WriteMappingTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
