import { BusinessDomainDto } from '@/endpoints/models'
import { Actions } from './actions'
import { BUSINESS_DOMAIN_LOAD } from './constants'

type State = Readonly<typeof initialState>

const initialState = {
	list: [] as BusinessDomainDto[],
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case BUSINESS_DOMAIN_LOAD: {
			return {
				...state,
				list: action.payload,
			}
		}

		default:
			return state
	}
}
