import { ReadMappingData, ReadMappingColumn } from '@/endpoints/schemas'
import { ensureValidData } from '../helpers'
import { v4 } from 'uuid'

export const defaultReadMappingData: Readonly<ReadMappingData> = {
	sourceItems: [],
	sourceItemsLastId: 0,
}

export const getInitialReadMappingData = (name: string, code = '') =>
	ensureValidData(
		{
			name,
			code,
			description: '',
			columns: [],
			columnsLastId: 0,
		},
		defaultReadMappingData,
	)

export const createEmptyReadMappingColumn = (
	columns: ReadMappingColumn[],
): ReadMappingColumn => ({
	id: -1,
	uuid: v4(),
	order: Math.max(0, ...columns.map((c) => c.order || 0)) + 1,
	columnCode: '',
	keyFlag: false,
	mapping: '',
	mappingDescription: '',
	mappingComment: '',
	updateFlag: false,
})
