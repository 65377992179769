import EnhancedPureComponent from '@/components/EnhancedComponents/EnhancedPureComponent'
import { FormWrap } from '@/components/UberForm/styles'
import { FormValue } from '@/types'
import { nextFrame } from '@/utils/async'
import { NativeMap } from '@/utils/collections'
import { setQsValues } from '@/utils/querystring'

import { FormContext } from './FormContext'
import { FormlessFormField } from './FormField'
import { FormFieldInfo, FormProps } from './types'

export default class Form<T> extends EnhancedPureComponent<
	FormProps<T>,
	FormContext<T>
> {
	// used for loading and editMode
	static getDerivedStateFromProps(props: FormProps<any>, state: FormContext) {
		const newState: Partial<FormContext> = {}

		if (state.loading !== props.isLoading) {
			newState.loading = props.isLoading
		}

		if (state.disabled !== props.disabled) {
			newState.disabled = props.disabled
		}

		return newState
	}

	/** Used to generate unique ID for every form. */
	private static idCounter = 1

	/** List of registered fields in the form. */
	private fields: FormFieldInfo<T>[] = []

	/** List of values. */
	private values!: T

	/** Was onLoad fired */
	private loadFired = false

	/** List of fields that were already revalidated */
	private revalidating = {} as NativeMap<boolean>

	/** Used to prevent state changes on unmounted components */
	private mounted = true

	/** Used to prevent form calling onChange if user did not change the input */
	private isUserInput: boolean

	constructor(props: Readonly<FormProps<T>>) {
		super(props)

		this.state = {
			getValues: this.getValues,
			id: `form-${Form.idCounter++}`,
			onFieldChange: this.handleFieldChange,
			onFieldValidated: this.handleFieldValidated,
			onFieldValidating: () => this.updateComputedValues(),
			register: this.registerField,
			reset: this.reset,
			submitting: false,
			unregister: this.unregisterField,
			valid: true,
			validating: false,
			loading: !!props.isLoading,
			isHorizontal: !!props.isHorizontal,
			withQueryString: !!props.withQueryString,
			disabled: !!props.disabled,
			enableFieldHighlight: props.enableFieldHighlight,
		}

		this.isUserInput = true

		this.reset(props.initialValues, true)
	}

	async componentDidMount() {
		this.mounted = true

		if (this.props.onLoad) {
			await nextFrame()

			// Note that this.props.onLoad could have changed
			if (!this.props.isLoading && this.props.onLoad) {
				this.props.onLoad(this.values)
				this.loadFired = true
			}
		} else {
			this.loadFired = true
		}
	}

	componentDidUpdate(prevProps: FormProps<T>) {
		const { isLoading, onLoad, initialValues } = this.props

		if (!this.loadFired && !isLoading) {
			if (onLoad) {
				onLoad(this.values)
			}

			// "Reset" to default values - this fixes some issues with selects and their selected values
			this.reset(initialValues, true)

			this.loadFired = true
		}

		if (this.props.invalid !== prevProps.invalid) {
			this.updateComputedValues()
		}
	}

	componentWillUnmount() {
		this.mounted = false
	}

	handleFieldValidated = async (name: string) => {
		// Keep track of fields that were already validated to stop circular revalidation
		this.revalidating[name] = true

		// Revalidate dependent fields if any
		const field = this.fields.find((field) => field.field.props.name === name)

		if (field && field.field.props.dependentFields) {
			await Promise.all(
				field.field.props.dependentFields
					.filter((dependentName) => !this.revalidating[dependentName])
					.map((dependentName) =>
						this.fields.find(
							(field) => field.field.props.name === dependentName,
						),
					)
					.filter((f) => !!f?.field)
					.map((field) => field?.field.validate()),
			)
		}

		delete this.revalidating[name]

		this.updateComputedValues()
	}

	render() {
		const { children, isHorizontal, style, className, enableAutocomplete } =
			this.props

		return (
			<FormContext.Provider value={this.state as any}>
				<FormWrap
					className={className}
					$isHorizontal={!!isHorizontal}
					onSubmit={this.handleSubmit}
					style={style}
					autoComplete={enableAutocomplete ? 'on' : 'off'}
				>
					{children}
				</FormWrap>
			</FormContext.Provider>
		)
	}

	/**
	 * Sets specified values.
	 *
	 * @param values values to be set
	 * @param revalidate should the form revalidate once the values are set
	 * @returns promise resolved when everything is set and revalidated
	 */
	setValues = async (
		values: Partial<T>,
		revalidate = true,
		disableOnChangeCallback = false,
	) => {
		const { onChange } = this.props

		this.values = {
			...this.values,
			...values,
		}

		await Promise.all(
			(Object.keys(values) as (keyof T)[]).map((key) => {
				const field = this.fields.find((f) => f.field.props.name === key)

				if (!field) {
					// tslint:disable-next-line: no-console
					console.warn(
						`Forms: ${
							key as string
						} field doesn't exists. Existing fields: ${this.fields
							.map((f) => f.field.props.name)
							.join(', ')}`,
					)

					return Promise.resolve()
				}

				// Puts execution to background - the browser wont get stuck
				return nextFrame().then(() => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					let value = values[key] as any

					if (value && field.field.props.arrayName) {
						value = value[field.field.props.arrayIndex as number]
							? value[field.field.props.arrayIndex as number][
									field.field.props.arrayName
								]
							: null
					}

					field.field.setValue(value === undefined ? null : value, false)
				})
			}),
		)

		if (revalidate) {
			await this.revalidate()
		}

		if (disableOnChangeCallback) {
			return
		}

		if (onChange) {
			onChange(this.values)
		}
	}

	/**
	 * Resets values to default values.
	 *
	 * @param values values to reset to
	 * @param internal internal call - don't try to set values to inputs
	 */
	reset = (values?: Partial<T>, internal = false) => {
		this.values = (values ? values : this.props.defaultValues || {}) as T

		if (!internal) {
			// First replace all values, then revalidate all fields
			return this.setAllValues()
				.then(() => this.revalidate())
				.then(() => {
					if (this.props.onReset) {
						this.props.onReset(this.values)
					}
				})
		} else {
			return this.revalidate()
		}
	}

	getValues = () => {
		return this.values
	}

	recap = () => {
		return this.fields
			.filter((subField) => subField.field.shouldRecap())
			.map((subField) => subField.field.recap())
	}

	private setAllValues = () => {
		this.isUserInput = false

		return Promise.all(
			this.fields.map((subField) => {
				let value = this.values[subField.field.props.name] as any

				if (value && subField.field.props.arrayName) {
					value = value[subField.field.props.arrayIndex as number]
						? value[subField.field.props.arrayIndex as number][
								subField.field.props.arrayName
							]
						: null
				}

				return subField.field.setValue(value === undefined ? null : value)
			}),
		).then(() => {
			this.isUserInput = true
		})
	}

	private revalidate = (submitting = false) => {
		return Promise.all(
			this.fields.map((subField) =>
				nextFrame().then(() => subField.field.validate(submitting)),
			),
		)
	}

	private handleFieldChange = (
		field: FormlessFormField<T>,
		value: FormValue,
	) => {
		const { onChange, mutable } = this.props
		const { name, arrayName, arrayIndex } = field.props

		if (arrayName !== undefined) {
			const values = mutable ? this.values : ({ ...this.values } as any)
			const index = arrayIndex as number

			if (!Array.isArray(values[name])) {
				values[name] = []
			}

			if (values[name][index] === undefined) {
				values[name][index] = {}
			}

			values[name][index][arrayName] = value

			this.values = values
		} else {
			// Save updated values
			if (mutable) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.values[name] = value as any
			} else {
				this.values = {
					...this.values,
					[name]: value,
				}
			}
		}

		// Revalidate fields that use this field for validations
		this.fields.forEach((subField) => {
			if (
				subField.field.props.uses &&
				subField.field.props.uses.indexOf(name) >= 0
			) {
				subField.field.validate()
			}
		})

		if (this.isUserInput && onChange) {
			// Only calling onChange if the field change was caused by user input
			onChange(this.values, field)
		}
	}

	private updateComputedValues = async () => {
		if (this.mounted) {
			await this.setState({
				valid: this.props.invalid
					? false
					: !this.fields.some((formField) => !!formField.field.state.error),
				validating: this.fields.some(
					(formField) => formField.field.state.validating,
				),
			})
		}

		if (this.props.onValid) {
			this.props.onValid(this.state.valid)
		}
	}

	private registerField = async (field: FormlessFormField<T>) => {
		this.fields.push({ field })

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let preset = this.values[field.props.name] as any

		if (field.props.arrayName) {
			const { defaultValues, initialValues } = this.props
			const presetValues = defaultValues || initialValues

			if (!preset && presetValues) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				preset = presetValues[field.props.name] as any
			}

			if (preset) {
				const index = field.props.arrayIndex as number
				preset = preset[index] ? preset[index][field.props.arrayName] : null
			}
		}

		if (preset !== undefined) {
			await field.setValue(preset, false, true)
		}
	}

	private unregisterField = (field: FormlessFormField<T>) => {
		this.fields.splice(
			this.fields.findIndex((item) => item.field === field),
			1,
		)
	}

	private handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		e.stopPropagation()

		if (this.state.submitting) {
			return
		}

		await this.revalidate(true)

		const { onSubmit, withQueryString } = this.props
		const { valid, validating } = this.state

		if (valid && !validating) {
			if (withQueryString) {
				setQsValues(this.values as any)
			}

			if (onSubmit) {
				if (this.mounted) {
					await this.setState({ submitting: true })
				}

				try {
					await onSubmit(this.values)
				} finally {
					if (this.mounted) {
						await this.setState({ submitting: false })
					}
				}
			}
		}
	}
}
