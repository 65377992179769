import React from 'react'
import { BatchEditContextProvider } from './context/BatchEditContext'
import { BatchEditSelectType } from './BatchEditSelectType'
import { useAppStore } from '@/hooks'
import { StructureObjectDto } from '@/endpoints/models'

type Props = {
	onClose: () => void
	node: StructureObjectDto
}

export const BatchEdit = ({ onClose, node }: Props) => {
	const selectedNodes = useAppStore((state) => state.node.selectedNodes)

	return (
		<BatchEditContextProvider
			initialState={{
				formChanged: {},
				onClose,
				locking: false,
				submitting: false,
				errors: {},
				selectedNodes,
				node,
			}}
		>
			<BatchEditSelectType />
		</BatchEditContextProvider>
	)
}
