import { useAppContext } from '@/hooks'
import { useMemo } from 'react'

export const useNamesNormalisations = () => {
	const { t } = useAppContext()

	const namesNormalisations = useMemo(
		() => [
			{
				label: t('IMPORT_NAMES_NORMALISATION_NONE'),
				value: 'NONE',
			},
			{
				label: t('IMPORT_NAMES_NORMALISATION_UPPERCASE_FIRST_CHAR'),
				value: 'UPPERCASE_FIRST_CHARS',
			},
			{
				label: t('IMPORT_NAMES_NORMALISATION_UPPERCASE_ALL'),
				value: 'UPPERCASE_ALL',
			},
		],
		[t],
	)

	return namesNormalisations
}
