import { useState } from 'react'
import {
	createObjectSettings,
	getStereotypeTypesForObjectSettings,
	updateObjectSettings,
} from 'src/endpoints'

import { CodeInput } from '@/components'
import { ModalForm } from '@/components/Modal/ModalForm'
import {
	CheckBoxFormField,
	FormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	ObjectSettingsCreateDto,
	ObjectSettingsDto,
	ObjectSettingsUpdateDto,
} from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { FormValue } from '@/types'

import { AuditForm } from '../../../components/AuditForm'
import { auditFieldsAdd, auditFieldsRemove } from '../../../utils'

type ObjectSettingsModalProps = {
	objectSettings?: Partial<ObjectSettingsDto>
	onClose: () => void
	onSave: () => void
	textAreaHeight?: string
}

export const ObjectSettingsModal = ({
	objectSettings,
	onSave,
	onClose,
	textAreaHeight,
}: ObjectSettingsModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const elements = useApi(getStereotypeTypesForObjectSettings())

	const [objectSettingsName, setObjectSettingsName] = useState(
		objectSettings?.name || '',
	)

	const handleSubmit = async (form: Partial<ObjectSettingsDto>) => {
		auditFieldsRemove(form)
		form.code = form.name
		const action =
			objectSettings && objectSettings.id
				? updateObjectSettings(
						objectSettings.id,
						form as ObjectSettingsUpdateDto,
					)
				: createObjectSettings(form as ObjectSettingsCreateDto)

		const result = await request(action)

		if (result) {
			onSave()
			onClose()
		}
	}

	const onNameChange = (v: FormValue) => {
		setObjectSettingsName(v as string)
	}

	return (
		<>
			<ModalForm<ObjectSettingsDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: '550px',
				}}
				initialValues={{
					id: objectSettings?.id,
					name: objectSettings?.name,
					code: objectSettings?.code,
					structureType: objectSettings?.structureType,
					description: objectSettings?.description,
					comment: objectSettings?.comment,
					...auditFieldsAdd(objectSettings),
				}}
				header={
					objectSettings
						? t('UPDATE_OBJECT_SETTINGS')
						: t('CREATE_OBJECT_SETTINGS')
				}
				onSubmit={handleSubmit}
			>
				<TextFormField
					title={t('NAME')}
					name="name"
					required
					onChange={(v) => onNameChange(v)}
				/>
				<FormField name="code" title={t('CODE')}>
					<CodeInput value={objectSettingsName} readonly />
				</FormField>
				<SelectFormField
					title={t('ELEMENT')}
					name="structureType"
					options={elements.data || []}
					fieldIsLoading={elements.loading}
					valueKey="code"
					labelKey="code"
					clearable={false}
					required
				/>
				<TextAreaFormField
					textAreaHeight={textAreaHeight}
					title={t('DESCRIPTION')}
					name="description"
				/>
				<TextAreaFormField
					textAreaHeight={textAreaHeight}
					title={t('COMMENT')}
					name="comment"
				/>
				<CheckBoxFormField
					title={t('DEFAULT')}
					name="defaultFlag"
					formless
					disabled
					initialValue={objectSettings?.defaultFlag}
				/>
				<AuditForm $isUpdating={!!objectSettings} />
			</ModalForm>
		</>
	)
}
