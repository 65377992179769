import {
	getSystemUsers,
	getExistingStructuresForSourceMapping,
	getAvailableStructureForSourceObject,
} from 'src/endpoints'
import { useApi } from '@/endpoints/hooks'
import { SourceEnums, SourceItem } from '@/endpoints/schemas'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab/context/DetailTabContext'
import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { getAvailableStructures } from './getAvailableStructures'
import { useSourceOptions } from './useSourceOptions'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'
import { OptionProps, components } from 'react-select'
const { Option } = components

export const useSourceItemProperties = (): TableColumnProps<SourceItem>[] => {
	const { t } = useAppContext()

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const systemTables = useApi(
		editMode
			? getAvailableStructureForSourceObject(node.id, 'SYSTEM')
			: getExistingStructuresForSourceMapping(node.id, 'SYSTEM'),
	)

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId),
	)

	const { sourceObjectTypes, sourceStereotypes } = useSourceOptions()

	// Custom Option component that shows the name in bold and the full path in regular text with a tooltip
	const CustomOption = (props: OptionProps<any>) => {
		return (
			<Option {...props}>
				<div className="flex flex-col">
					<div className="font-bold">{props.data.name}</div>
					<div title={props.data.fullPath} className="text-sm">
						{props.data.fullPath}
					</div>
				</div>
			</Option>
		)
	}

	const sourceItemProperties = useMemo<TableColumnProps<SourceItem>[]>(
		() => [
			{
				field: 'stereotype',
				label: t('MAPPING_SOURCES_STEREOTYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1.5,
				width: 150,
				optionValue: 'value',
				optionLabel: 'label',
				options: sourceStereotypes || [],
				isNumeric: true,
			},
			{
				field: 'objectType',
				label: t('MAPPING_SOURCES_OBJECT_TYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				width: 150,
				optionValue: 'value',
				optionLabel: 'label',
				options: sourceObjectTypes || [],
				isNumeric: true,
			},
			{
				field: 'objectCode',
				label: t('MAPPING_SOURCES_OBJECT'),
				autoWidth: true,
				minWidth: 150,
				type: ItemPropertyType.OPTION,
				options: (column) => getAvailableStructures(systemTables.data, column),
				optionValue: 'code',
				optionLabel: 'fullPath',
				isNumeric: true,
				isLoading: systemTables.loading,
				flex: 4,
				disabled: (column) =>
					column?.objectType === SourceEnums.ObjectTypeEnum.SUB_SELECT,
				customOption: CustomOption as any,
			},
			{
				field: 'ownerId',
				label: t('NODE_OWNER'),
				type: ItemPropertyType.OPTION,
				flex: 1.5,
				optionValue: 'id',
				optionLabel: 'name',
				options: systemUsers || [],
				isLoading: isSystemUserLoading,
				isNumeric: true,
				minWidth: 150,
				autoWidth: true,
			},
			{
				field: 'objectCustom',
				label: t('SOURCE_SUBSELECT'),
				type: ItemPropertyType.ACE_EDITOR,
				flex: 2,
				disabled: (column) =>
					column.objectType != SourceEnums.ObjectTypeEnum.SUB_SELECT,
				width: 200,
			},
			{
				field: 'alias',
				label: t('MAPPING_SOURCES_ALIAS'),
				type: ItemPropertyType.TEXT,
				flex: 2,
				width: 200,
			},
			{
				field: 'expression',
				label: t('MAPPING_SOURCES_EXPRESSION'),
				type: ItemPropertyType.ACE_EDITOR,
				flex: 2,
				width: 200,
			},
			{
				field: 'sourceContext',
				label: t('MAPPING_SOURCES_SOURCE_CONTEXT'),
				type: ItemPropertyType.TEXTAREA,
				flex: 2,
				width: 200,
			},
		],
		[
			t,
			sourceStereotypes,
			sourceObjectTypes,
			systemUsers,
			isSystemUserLoading,
			systemTables.loading,
			systemTables.data,
		],
	)

	return sourceItemProperties
}
