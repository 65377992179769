import React, { useEffect, useMemo, useState } from 'react'
import { Table } from '@/components'
import {
	ReleaseDto,
	StructureDto,
	StructureObjectDto,
} from '@/endpoints/models'
import { deleteReleaseStructure } from '@/endpoints'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { DeleteModal } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/components'
import { openTab } from '@/store/modules/tab/actions'
import { DpObjectContent } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/types'
import { Column } from '@tanstack/react-table'

interface DpStructureTableProps {
	columns: Column<any>[]
	sortedObjectsData: DpObjectContent[]
	hasLastRowEdit: boolean
	editMode: boolean
	node: StructureDto
	reloadObjects: () => void
	deploymentPackage: ReleaseDto
}

export const DpStructureTable = ({
	columns,
	sortedObjectsData,
	editMode,
	node,
	reloadObjects,
	deploymentPackage,
}: DpStructureTableProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isEditOpen, setIsEditOpen] = useState(false)
	const [objectRowRecord, setObjectRowRecord] = useState<StructureObjectDto>()
	const ENV_TABLE_WIDTH = 1200

	const newNodeObject = useAppStore(
		(state) => state.node.nodes[objectRowRecord?.structureId as number],
	)

	const deleteRequest = useMemo(() => {
		return deleteReleaseStructure(
			node.id,
			deploymentPackage.id!,
			newNodeObject?.id as number,
		)
	}, [node.id, deploymentPackage.id, newNodeObject?.id])

	const onDelete = (object: StructureObjectDto) => {
		setIsDeleteOpen(true)
		setObjectRowRecord(object)
	}

	const useOnEdit = (object: StructureObjectDto) => {
		setObjectRowRecord(object)
		setIsEditOpen(true)
	}

	useEffect(() => {
		if (isEditOpen && newNodeObject) {
			dispatch(openTab(newNodeObject, false))
		}
	}, [isEditOpen, newNodeObject])

	return (
		<>
			<Table
				columns={columns}
				data={sortedObjectsData}
				maxWidth={ENV_TABLE_WIDTH}
				isEditMode={editMode}
				onDelete={onDelete}
				onEdit={useOnEdit}
				hasLastRowEdit
				hasLeftAction={false}
				hasDnd={false}
			/>

			{isDeleteOpen && (
				<DeleteModal
					object={deploymentPackage}
					deleteRequest={deleteRequest}
					onClose={() => {
						reloadObjects()
						setIsDeleteOpen(false)
					}}
					saveTitle={t('REMOVE_ELEMENT_FROM_RELEASE')}
					description={t('REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION', [
						deploymentPackage.name ?? '',
					])}
				/>
			)}
		</>
	)
}
