import { ApiCollectionData } from '@/endpoints/schemas/api-collection'
import { Loader } from '@/components'
import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { useTabContext } from '@/context/TabContext/TabContext'
import {
	initApiCollection,
	saveApiCollection,
	updateApiCollection,
} from '@/store/modules/apiCollection/actions'
import { ApiCollectionTab } from '@/store/modules/apiCollection/types'
import { selectTabInTab } from '@/store/modules/tab/actions'
import { UpdateDeepPartial } from '@/store/utils'
import { useDeletedDomains } from '@/utils/domain'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
} from '@/hooks'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Dependencies } from '../../components/Dependencies/Dependencies'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { Validation } from '../../components/Validation/Validation'
import { useNodeInit } from '../../hooks/useNodeInit'
import { Columns } from './pages/Columns/Columns'
import { Overview } from './pages/Overview/Overview'

const ApiCollectionComponent = () => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const dispatch = useAppDispatch()

	const {
		state: { node, systemNodeId, editMode, selectedTab },
	} = useDetailTabContext()

	const collections = useAppStore((state) => state.apiCollection.items)
	const collection = collections[node.id]
	const domains = useDeletedDomains(systemNodeId, collection)
	useNodeInit()

	const handleSave = useDebounceCallback(async () => {
		if (!collection || !editMode) {
			return
		}

		try {
			await dispatch(saveApiCollection(node))
		} catch (e) {
			onSaveError(e)
		}
	}, 1000)

	const handleEdit = async () => {
		await dispatch(initApiCollection({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initApiCollection({ nodeId: node.id, editMode: false }))
	}

	const handleChange = useCallback(
		(data: UpdateDeepPartial<ApiCollectionData>) => {
			if (!editMode) {
				return
			}

			dispatch(updateApiCollection(node, data))
			handleSave()
		},
		[editMode, dispatch, node, handleSave],
	)

	const handleTabChange = (tab: TabProps) => {
		dispatch(selectTabInTab(node.id, tab.id as ApiCollectionTab))
	}

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = collection || {}

		if (!form || !collection) {
			return []
		}

		return [
			{
				id: ApiCollectionTab.General,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={collection}
						editMode={editMode}
						onChange={handleChange}
						key="overview"
					/>
				),
			},
			{
				id: ApiCollectionTab.Columns,
				title: t('TAB_COLUMNS'),
				content: (
					<Columns
						key="columns"
						data={collection}
						node={node}
						systemNodeId={systemNodeId}
						editMode={editMode}
						domains={domains || []}
						onChange={handleChange}
					/>
				),
			},
			{
				id: ApiCollectionTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={ApiCollectionTab.Validation} node={node} />,
			},
			{
				id: ApiCollectionTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={ApiCollectionTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
		]
	}, [collection, t, node, editMode, handleChange, systemNodeId, domains])

	if (!collection) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<RightButtons>
						<EditableNodeActions
							node={node}
							editMode={editMode}
							dirty={collection.dirty}
							onEdit={handleEdit}
							onCancel={handleCancel}
						/>
					</RightButtons>
				}
			/>

			<Tabs
				tabs={tabs}
				onChange={handleTabChange}
				selectedTabId={selectedTab || ApiCollectionTab.General}
			/>
		</>
	)
}

const RightButtons = styled.div`
	display: flex;
	height: 100%;
	position: relative;
`

export const ApiCollection = React.memo(ApiCollectionComponent)
