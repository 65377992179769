import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
	padding: 0;
	height: 100%;
	display: flex;
	overflow: hidden;
	flex-direction: column;
`

export const TabContent = styled.div`
	z-index: 1;
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
	overflow: auto;
	background-color: ${(props) =>
		props.theme.colors.layout.tabContentBackgroundColor};
`
export const TabsList = styled.div`
	display: flex;
	z-index: 0;
	flex: 1;
	position: relative;
`
export const TabsNavigationWrap = styled.div`
	position: relative;
	padding-right: 75px;
	border-bottom: 1px solid ${(props) => props.theme.colors.layout.borderColor};
`

export const TabsControls = styled.div`
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	height: 30px;
	background: #f0f0f0;
	border-left: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	border-top: 1px solid ${(props) => props.theme.colors.layout.borderColor};
`

export const TabsControl = styled.div`
	cursor: pointer;
	padding: 0 8px;
	display: flex;
	align-items: center;
	color: #999;
`

export const TabsMenu = styled.div`
	border: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	box-sizing: border-box;
	overflow: auto;
	max-height: inherit;
	min-width: 220px;
	box-shadow: ${(props) => props.theme.styles.rightMenusShadows};
`

export const TabHeadClose = styled.div`
	margin-left: 2px;
	transition: 0.2s opacity;
`

export const EditIcon = styled(FontAwesomeIcon)`
	color: ${(props) => props.theme.colors.button.success.background};
	margin-left: 5px;
	margin-bottom: 1px;
	font-size: 75%;
`

export const TabHead = styled.div<{
	$isSelected: boolean
	$isTemporary: boolean
	$isVertical: boolean
}>`
	padding: 8px 10px;
	border-radius: 0;
	cursor: pointer;
	overflow: visible;
	position: relative;
	z-index: ${(props) => (props.$isSelected ? 2 : 1)};
	background-color: ${(props) => (props.$isSelected ? '#fff' : '#f5f5f5')};
	display: flex;
	align-items: center;
	flex-grow: 0;
	transition: 0.2s background-color;
	min-height: 0;

	${(props) =>
		!props.$isVertical &&
		css`
			margin-bottom: -1px;
			border-right: 1px solid ${props.theme.colors.layout.borderColor};
			border-top: 1px solid ${props.theme.colors.layout.borderColor};
			border-bottom: 1px solid
				${props.$isSelected ? '#fff' : props.theme.colors.layout.borderColor};
		`}

	${(props) =>
		props.$isSelected &&
		css`
			box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.075);
		`}

	${(props) =>
		props.$isTemporary &&
		css`
			font-style: italic;
		`}

		${TabHeadClose} {
		opacity: ${(props) => (props.$isSelected ? 0.75 : 0.5)};
	}

	&:hover {
		background-color: ${(props) => (props.$isSelected ? '#fff' : '#f8f8f8')};

		${TabHeadClose} {
			opacity: 1;
		}
	}
`

export const TabHeadText = styled.span`
	padding-left: 5px;
	padding-right: 5px;
	user-select: none;
	white-space: nowrap;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
`
