import { useState, useEffect, useCallback, useMemo } from 'react'
import { NativeMap } from '@/utils/collections'
import { useAppDispatch, useAppStore } from '@/hooks'
import { LRUCache } from '@/utils/cache'
import { setApiError, ErrorRedux } from '@/store/modules/apiError/actions'
import { ApiError, ApiErrorJson } from './utils'

export type ApiHookCallback<T> = ((auth: string | null) => Promise<T>) & {
	__key: string
}

type ApiHookOptions = {
	/** Validity of cache entry, in ms */
	validity?: number
	/** Run in suspense mode */
	suspense?: boolean
}

export type ApiHookResult<T> = [T | undefined, boolean, boolean, any] & {
	/** Data, undefined before the first request passes */
	data: T | undefined
	/** Are data being loaded for the first time */
	loading: boolean
	/** Are data being reloaded on background */
	reloading: boolean
	/** Last error */
	error: any | undefined
	/** Forces data reload */
	invalidate: () => void
}

type ApiHookRef = {
	/** Forces data reload */
	invalidate: () => void
}

type CacheEntry = {
	valid: number
	data: any
	error?: any
}

type ApiHandle = {
	id: number
	/** Forces data reload */
	invalidate: () => void
}

/**
 * This global counter is used to easier reference handles
 */
let RESPONSE_CACHE_HANDLE_ID = 1

/**
 * Cache of responses - currently in memory and pernament
 * TODO: Clear old items automatically
 */
const RESPONSE_CACHE = {
	cache: new LRUCache<CacheEntry>(100),
	listeners: {} as NativeMap<NativeMap<ApiHandle>>,

	/**
	 * Subscribe to cache entry invalidation
	 * @param key
	 * @param handle
	 */
	subscribe(key: string, handle: ApiHandle) {
		let map = this.listeners[key]

		if (!map) {
			map = this.listeners[key] = {}
		}

		map[handle.id] = handle
	},

	/**
	 * Stop listening to cache entry invalidation
	 * @param key
	 * @param handle
	 */
	unsubscribe(key: string, handle: ApiHandle) {
		const map = this.listeners[key]

		if (map && map[handle.id]) {
			delete map[handle.id]
		}
	},

	/**
	 * Remove entry from cache and notify all listeners about it
	 */
	invalidate(key: string) {
		this.unset(key)

		const map = this.listeners[key]

		if (map) {
			Object.values(map).forEach((item) => item?.invalidate())
		}
	},

	get(key: string) {
		return this.cache.get(key)
	},

	set(key: string, value: CacheEntry) {
		return this.cache.set(key, value)
	},

	/**
	 * Remove entry from cache, but don't notify anyone
	 */
	unset(key: string) {
		return this.cache.unset(key)
	},
}

/**
 * List of currently ongoing requests - this prevents duplicate
 * requests when more components with same dependency render at same
 * time.
 */
const ONGOING_REQUESTS = {} as NativeMap<Promise<any>>

/**
 * Fetches specified endpoint and caches response.
 * Cache is refetched every mount unless proper validity value is provided.
 * This is same as useApi, but runs in suspense mode, which can also be
 * achieved by using options.suspense
 *
 * @param callback callback from api definition
 * @param options
 * @returns [data, isLoading, error]
 */

/**
 * Fetches specified endpoint and caches response.
 * Cache is refetched every mount unless proper validity value is provided.
 *
 * @param callback callback from api definition
 * @param dependencies
 * @param options
 */
export const useApi = <T>(
	callback: ApiHookCallback<T>,
	options?: ApiHookOptions,
): ApiHookResult<T> => {
	// We use redux to store user token
	const token = useAppStore((state) => state.auth.token)
	const [mounted, setMounted] = useState(false)

	// Try to load previous result
	const cached = RESPONSE_CACHE.get(callback.__key)

	// Is the same request already running?
	let running = ONGOING_REQUESTS[callback.__key]

	// Saved to state to trigger rerender on change
	const [data, setData] = useState(cached?.data)
	const [error, setError] = useState(cached?.error)

	// Refresh when dependencies change
	useEffect(() => {
		// Start listening to changes to new callback
		const handle = {
			id: RESPONSE_CACHE_HANDLE_ID++,
			invalidate: () => {
				setMounted(false)
			},
		}

		RESPONSE_CACHE.subscribe(callback.__key, handle)

		// Force reload (if needed)
		setMounted(false)
		setData(cached?.data)
		setError(cached?.error)

		return () => {
			// Stop listening to changes of previous callback
			RESPONSE_CACHE.unsubscribe(callback.__key, handle)
		}
	}, [callback.__key])

	// Initial render - start loading if needed
	if (!mounted) {
		if (!cached || new Date().getTime() > cached.valid) {
			// If the request is not running yet, trigger it
			if (!running) {
				running = ONGOING_REQUESTS[callback.__key] = callback(token)
					.then((res) => {
						// Clear from ongoing requests
						delete ONGOING_REQUESTS[callback.__key]

						// Save to cache
						RESPONSE_CACHE.set(callback.__key, {
							valid: new Date().getTime() + (options?.validity ?? 100),
							data: res,
						})

						return res
					})
					.catch((err) => {
						// Clear from ongoing requests
						delete ONGOING_REQUESTS[callback.__key]

						// Save to cache
						RESPONSE_CACHE.set(callback.__key, {
							valid: new Date().getTime() + (options?.validity ?? 100),
							error: err,
							data: undefined,
						})

						throw err
					})
			}

			// Hook to running request
			running
				.then((res) => {
					// Trigger rerender
					setData(res)
					setError(undefined)

					return res
				})
				.catch((err) => {
					// Trigger rerender
					setError(err)
				})
		}

		setMounted(true)
	}

	// Array with props - allows both object and array usage
	const result = useMemo(() => {
		const updated = [data, !cached, !!running, error] as ApiHookResult<T>
		updated.data = data
		updated.loading = !cached
		updated.reloading = !!running
		updated.error = error

		updated.invalidate = () => {
			RESPONSE_CACHE.invalidate(callback.__key)
		}

		return updated
	}, [data, cached, running, error, setMounted])

	if (options?.suspense) {
		running = ONGOING_REQUESTS[callback.__key]

		if (!cached && running) {
			throw running
		}
	}

	return result
}

type Options = { disableError?: boolean }

/**
 * Returns method performing specified request.
 *
 * @param callback
 * @returns
 */
export const useApiRequest = (): (<T>(
	callback: ApiHookCallback<T>,
	opts?: Options,
) => Promise<{ data: T | null; error?: ErrorRedux }>) => {
	const dispatch = useAppDispatch()
	const token = useAppStore((state) => state.auth.token)

	const doRequest = useCallback(
		async <T>(callback: ApiHookCallback<T>, opts?: Options) => {
			try {
				const data = await callback(token)

				return { data, error: undefined }
			} catch (e) {
				if (e instanceof ApiError) {
					const body = (await e.response.json()) as ApiErrorJson

					const error = {
						title: body.errorCode,
						description: body.errorDescription,
						message: body.errorMessage,
					}

					if (!opts?.disableError) {
						dispatch(setApiError(error))
					}

					return { data: null, error }
				}

				return {
					data: null,
					error: {
						title: 'Unknown error',
						description: 'Unknown error',
						message: 'Unknown error',
					},
				}
			}
		},

		[token, dispatch],
	)

	return doRequest
}

/**
 * Returns reference to api call, allowing to invalidate its response
 * and force all hooks using this call to reload
 */
export const getApiRef = <T>(callback: ApiHookCallback<T>): ApiHookRef => ({
	invalidate() {
		RESPONSE_CACHE.invalidate(callback.__key)
	},
})
