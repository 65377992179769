import React from 'react'
import { useAppContext } from '@/hooks'
import { ValidationMessageDto } from '@/endpoints/models'
import styled from 'styled-components'
import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import { Col, Row } from '@/components/Layout'
import debounce from 'debounce'
import { FormlessFormField } from '@/components/UberForm/FormField'

type Props = {
	onChange: (values: FilterValues) => void
}

export type FilterValues = {
	severity?: ValidationMessageDto.SeverityEnum
	fulltext?: string
}

const severityOptions = [
	{ value: ValidationMessageDto.SeverityEnum.ERROR },
	{ value: ValidationMessageDto.SeverityEnum.WARN },
	{ value: ValidationMessageDto.SeverityEnum.INFO },
]

export const Filter = ({ onChange }: Props) => {
	const { t } = useAppContext()

	const debouncedOnChange = debounce(onChange, 200)

	const handleChange = (values: FilterValues, source?: FormlessFormField) =>
		source?.props.name === 'fulltext'
			? debouncedOnChange(values)
			: onChange(values)

	return (
		<FilterContainer>
			<Form onChange={handleChange}>
				<Row>
					<Col size="medium">
						<SelectFormField
							title={t('VALIDATION_SEVERITY')}
							options={severityOptions}
							name="severity"
							labelKey="value"
							allowEmpty
							clearable
						/>
					</Col>
					<Col size="medium">
						<TextFormField title={t('VALIDATION_FULLTEXT')} name="fulltext" />
					</Col>
				</Row>
			</Form>
		</FilterContainer>
	)
}

const FilterContainer = styled.div`
	margin-bottom: 10px;
`
