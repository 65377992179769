import { ValidationMessageDto } from '@/endpoints/models'
import { FilterValues } from './Filter'

export const filterValidationData = (
	data: ValidationMessageDto[],
	filter: FilterValues,
) => {
	const fulltext = filter.fulltext?.toLowerCase()

	return data.filter(
		(x) =>
			(!filter.severity || x.severity === filter.severity) &&
			(!fulltext ||
				x.elementName.toLowerCase().indexOf(fulltext) >= 0 ||
				x.nodeName.toLowerCase().indexOf(fulltext) >= 0 ||
				x.message.toLowerCase().indexOf(fulltext) >= 0 ||
				x.description.toLowerCase().indexOf(fulltext) >= 0),
	)
}
