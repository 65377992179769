import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { CsvImporterStepEnum } from '../context/types'
import { CsvFields } from '../types/CsvField'

type Fields = Record<
	| CsvImporterStepEnum.TABLE_BASICS
	| CsvImporterStepEnum.TABLE_EXTENDED
	| CsvImporterStepEnum.COLUMNS,
	CsvFields
>

export const useFields = () => {
	const { t } = useAppContext()

	const fields = useMemo<Fields>(
		() => ({
			[CsvImporterStepEnum.TABLE_BASICS]: [
				{
					name: 'tableName',
					label: t('TABLE_NAME'),
					required: true,
				},
				{
					name: 'tableCode',
					label: t('TABLE_CODE'),
				},
				{
					name: 'tableStereotype',
					label: t('TABLE_STEREOTYPE_CODE'),
				},
				{
					name: 'tableDescription',
					label: t('TABLE_DESCRIPTION'),
				},
				{
					name: 'tableComment',
					label: t('TABLE_COMMENT'),
				},
			],
			[CsvImporterStepEnum.TABLE_EXTENDED]: [
				{
					name: 'tableScript',
					label: t('SCRIPT'),
				},
				{
					name: 'tablePreScript',
					label: t('TABLE_PRE_TABLE_SCRIPT'),
				},
				{
					name: 'tablePostScript',
					label: t('TABLE_POST_TABLE_SCRIPT'),
				},
			],
			[CsvImporterStepEnum.COLUMNS]: [
				{
					name: 'name',
					label: t('NAME'),
					required: true,
				},
				{
					name: 'code',
					label: t('CODE'),
				},
				{
					name: 'domainName',
					label: t('DOMAIN_NAME'),
				},
				{
					name: 'dataType',
					label: t('DOMAIN_DATA_TYPE'),
				},
				{
					name: 'stereotypeCode',
					label: t('STEREOTYPE_CODE'),
				},
				{
					name: 'defaultValue',
					label: t('DOMAIN_DEFAULT_VALUE'),
				},
				{
					name: 'mandatory',
					label: t('DOMAIN_MANDATORY'),
				},
				{
					name: 'description',
					label: t('DESCRIPTION'),
				},
				{
					name: 'comment',
					label: t('COMMENT'),
				},
				{
					name: 'indexName',
					label: t('INDEX_NAME'),
				},
				{
					name: 'primaryKeyName',
					label: t('PRIMARY_KEY_NAME'),
				},
			],
		}),
		[t],
	)

	return fields
}
