import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { ValueWrapper } from '../ValueWrapper'
import { Portal } from '@/components/Portal/Portal'
import {
	AceEditorProps,
	AceEditorWithoutForm,
} from '@/components/AceEditor/AceEditor'
import ReactAce from 'react-ace/lib/ace'
import { TableColumnProps } from '@/types'

type AceEditorMainProps<T> = {
	value: string
	disabled: boolean
	onFocus: () => void
	onBlur: () => void
	isFocused: boolean
	onChange: (name: string, value: string | boolean) => void
	prop: TableColumnProps<T>
	fromProps?: boolean
	autoFocus?: boolean
	onDisableEditing?: () => void
} & AceEditorProps

export const CLASSNAME_TEXTAREA_POPUP = 'className__textarea--popup'

export const AceEditor = <T,>({
	value,
	disabled,
	onFocus,
	onBlur,
	isFocused,
	onChange,
	fromProps,
	prop,
	autoFocus,
	onDisableEditing,
	...aceEditorProps
}: AceEditorMainProps<T>) => {
	// focus textarea popup in table view
	useEffect(() => {
		if (!isFocused && !fromProps) {
			onFocus()
		}
	}, [isFocused, onFocus, fromProps])

	return (
		<>
			{fromProps && (
				<AceEditorWithoutForm
					value={typeof value === 'string' ? value : ''}
					name={prop.field}
					onChange={(value: string) => onChange(prop.field, value)}
					disabled={disabled}
					onFocus={onFocus}
					onBlur={onBlur}
					autoFocus={autoFocus}
					{...aceEditorProps}
				/>
			)}
			{!fromProps && isFocused && (
				<AceEditorTable
					value={value}
					disabled={disabled}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={onChange}
					fromProps={fromProps}
					prop={prop as any}
					isFocused={true}
					onDisableEditing={onDisableEditing}
					{...aceEditorProps}
				/>
			)}
		</>
	)
}

const AceEditorTable = <T,>({
	value,
	disabled,
	onFocus,
	onBlur,
	onChange,
	prop,
	onDisableEditing,
	...aceEditorProps
}: AceEditorMainProps<T>) => {
	const refAce = useRef<ReactAce | null>(null)
	const refChangeFirst = useRef(true)

	const [referenceElement, setReferenceElement] =
		useState<HTMLDivElement | null>(null)

	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null,
	)

	const { styles, attributes } = usePopper(referenceElement, popperElement)

	useEffect(() => {
		refAce.current?.editor.focus()
	}, [])

	return (
		<>
			<ValueWrapper setRefWrapper={setReferenceElement}>{value}</ValueWrapper>
			<Portal>
				<div
					ref={setPopperElement}
					style={styles.popper}
					{...attributes.popper}
				>
					<AceEditorWithoutForm
						value={typeof value === 'string' ? value : ''}
						width="500px"
						name={prop.field}
						onChange={(value: string) => {
							const isFirstCharNewLine =
								value.length === 1 && value.match(/\r\n|\r|\n/g)

							// do not add newline on enter
							if (refChangeFirst.current && isFirstCharNewLine) {
								refChangeFirst.current = false

								return
							}

							onChange(prop.field as string, value)
						}}
						disabled={disabled}
						style={{
							zIndex: 6,
							padding: '6px',
						}}
						onBlur={() => {
							onDisableEditing?.()
							onBlur()
						}}
						onFocus={onFocus}
						ref={refAce}
						className={CLASSNAME_TEXTAREA_POPUP}
						{...aceEditorProps}
					/>
				</div>
			</Portal>
		</>
	)
}
