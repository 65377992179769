import React from 'react'
import styled, { css } from 'styled-components'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { Button, DropdownMenu } from '@/components'
import { useAppContext } from '@/hooks'

type NodeButtonsProps = {
	buttons?: React.ReactNode
	moreButtons?: React.ReactNode
}

export const NodeButtons = ({ buttons, moreButtons }: NodeButtonsProps) => {
	const { t } = useAppContext()

	return (
		<ButtonsContainer>
			{buttons}

			{moreButtons && (
				<DropdownMenu
					trigger={(isOpen) => (
						<div>
							<MoreActionsButton
								isOpen={isOpen}
								icon={faEllipsisV}
								ariaLabel={t('MORE_ACTIONS')}
							/>
						</div>
					)}
					contentStyle={{ padding: '0', width: '160px', zIndex: '9999' }}
					overlayStyle={{ display: 'none' }}
					arrow={false}
				>
					<MoreActions role="menu">{moreButtons}</MoreActions>
				</DropdownMenu>
			)}
		</ButtonsContainer>
	)
}

const ButtonsContainer = styled.div`
	display: flex;
	height: 100%;
	position: relative;
`

const MoreActions = styled.div`
	button {
		width: 100%;
		text-align: left;
		border-left: 0;
		justify-content: flex-start;
	}
`

const MoreActionsButton = styled(Button)<{ isOpen: boolean }>`
	color: ${(props) =>
		props.isOpen
			? 'white !important'
			: props.theme.colors.button.default.background};

	${(props) =>
		props.isOpen &&
		css`
			background-color: ${props.theme.colors.button.primary
				.background} !important;
		`}

	&:hover {
		color: #555;
	}
`
