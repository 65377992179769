import { getTablesByTechnicalColumnCSV } from 'src/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import FileSaver from 'file-saver'
import { useState, useCallback, useMemo } from 'react'
import { TechnicalColumnDto } from '@/endpoints/models'

export const useDownloadImpactCsv = () => {
	const [isDownloading, setIsDownloading] = useState(false)
	const request = useApiRequest()

	const onDownloadCsv = useCallback(
		async (technicalColumn: TechnicalColumnDto) => {
			setIsDownloading(true)

			const csv = await request(
				getTablesByTechnicalColumnCSV(technicalColumn?.id || 0),
			)

			const blob = new Blob([new Uint8Array(csv.data as ArrayBuffer)], {
				type: 'application/text',
			})

			FileSaver.saveAs(
				blob,
				`${technicalColumn.name}-${technicalColumn.id}-impact.csv`,
			)

			setIsDownloading(false)
		},
		[request],
	)

	return useMemo(
		() => ({
			isDownloading,
			onDownloadCsv,
		}),
		[isDownloading, onDownloadCsv],
	)
}
