import React, { useState, useEffect } from 'react'
import { CellProps } from './types'
import { useAppContext } from '@/hooks'
import { Input } from '@/components/UberForm/Input/Text/styles'
import { ModalForm } from '@/components/Modal/ModalForm'
import { AceEditor } from '@/components/AceEditor/AceEditor'
import { CopyTextButton } from './CopyTextButton'

export const ScriptModalCell = ({
	value,
	onChange,
	isDisabled,
	label,
	isEditMode,
}: CellProps) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [aceValue, setAceValue] = useState('')

	const onSubmit = () => {
		setModalOpen(false)
		onChange(aceValue)
	}

	useEffect(() => {
		if (value) {
			setAceValue(value)
		}
	}, [value])

	return (
		<>
			<Input
				className="textInputWithoutBorder"
				type="text"
				value={value}
				onClick={() => setModalOpen(true)}
				$compact={false}
				disabled={isDisabled}
				readOnly
			/>

			<ModalForm
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				onSubmit={onSubmit}
				header={label}
				contentStyle={{ width: '31.25rem' }}
				hasSubmitButton={isEditMode}
				cancelTitle={isEditMode ? t('CANCEL') : t('CLOSE')}
				footerBefore={<CopyTextButton text={aceValue} />}
			>
				<AceEditor
					value={aceValue}
					onChange={(value: string) => {
						setAceValue(value)
					}}
					onKeyPress={({ key, metaKey }) => {
						if (metaKey && key === 'Enter') {
							onSubmit()
						}
					}}
					autoFocus
					height={250}
					disabled={!isEditMode}
				/>
			</ModalForm>
		</>
	)
}
