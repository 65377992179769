import React from 'react'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { NativeMap, isNotUndefined } from '@/utils/collections'
import styled from 'styled-components'
import { TreeNode } from '@/components/Tree/types'

export interface ExtendedTreeNode extends TreeNode {
	node: StructureDto
}

export const mapToTree = (
	node: StructureDto,
	children: NativeMap<number[]>,
	nodes: NativeMap<StructureDto>,
	query?: string,
	alphabet?: boolean,
): ExtendedTreeNode => {
	let label: React.ReactNode = node.name || 'unnamed'

	if (query && typeof label === 'string') {
		const start = label.toLowerCase().indexOf(query.toLowerCase())

		if (start >= 0) {
			label = (
				<>
					{label.substring(0, start)}
					<HighlightSpan>{label.substring(start, query.length)}</HighlightSpan>
					{label.substring(start + query.length)}
				</>
			)
		}
	}

	return {
		title: node.name,
		label,
		key: node.id || 0,
		children: getChildren(node, children, nodes, query, alphabet ?? false),
		node,
	}
}

const getChildren = (
	node: StructureDto,
	children: NativeMap<number[]>,
	nodes: NativeMap<StructureDto>,
	query: string | undefined,
	alphabet: boolean,
): ExtendedTreeNode[] | undefined => {
	if (!node.hasChildren) {
		return undefined
	}

	if (children[node.id] === undefined) {
		return []
	}

	const childs = children[node.id]

	if (!childs || childs.length === 0) {
		return undefined
	}

	return childs
		.map((childId) => {
			const mapped = nodes[childId]

			return mapped
		})
		.filter(isNotUndefined)
		.sort((a, b) => sortNodes(a, b, alphabet))
		.map((child) => mapToTree(child, children, nodes, query, alphabet))
}

export const sortNodes = (
	a: StructureDto,
	b: StructureDto,
	alphabet: boolean,
) => {
	if (!alphabet && a.orderPriority && b.orderPriority) {
		return a.orderPriority - b.orderPriority
	}

	return a.name.localeCompare(b.name)
}

const HighlightSpan = styled.span`
	background: #ffff80;
`

export type TreePanelDrag = {
	nodeId: number
	type: StructureObjectDto.TypeEnum
	parentStructureId?: number
}

export const TREE_PANEL_ORDER = 'TREE_PANEL_ORDER'
