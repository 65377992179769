import React from 'react'
import { StructureObjectDto } from '@/endpoints/models'
import { SystemUsersProperties } from './Properties'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { createEmptySystemUser } from '@/store/modules/system/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/hooks'
import { TableAndProperties } from '@/components'

type UsersProps = {
	node: StructureObjectDto
	data: OpenedSystemData
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
}

export const Users = ({ node, data, onChange }: UsersProps) => {
	const { t } = useAppContext()

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			createEmpty={createEmptySystemUser}
			itemsKey={'systemUsers'}
			idCounterKey={'systemUserLastId'}
			onChange={onChange}
			properties={SystemUsersProperties(t)}
			tabKey="users"
			maxWidth={800}
		/>
	)
}
