import { OpenedTab } from '@/store/modules/tab/types'

export const getDpDetailEditMode = (
	openedTabs: OpenedTab[],
	currentTab: OpenedTab | undefined,
) => {
	const match = openedTabs.find(
		(tab) => tab.nodeId === currentTab?.systemNodeId,
	)

	return match?.editMode
}
