import React from 'react'
import { TemplateListDto } from '@/endpoints/models'
import { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { Flex } from '@/components/Layout'
import { Button, DialogWrapper, RemoveModal } from '@/components'
import { TemplateModal } from '../components/TemplateModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faHistory, faTimes } from '@fortawesome/free-solid-svg-icons'
import { TemplateDiff } from '../components/TemplateDiff'
import { deleteTemplate } from '@/endpoints'
import { LightTableColumn } from '@/types'

export const useTemplatesColumns = (handleSave: () => void) => {
	const { t } = useAppContext()

	const textAreaHeight = "1.875rem"

	return useMemo<LightTableColumn<TemplateListDto>[]>(
		() => [
			{
				field: 'technologyName',
				title: t('TECHNOLOGY'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'structureType',
				title: t('TEMPLATE_ELEMENT'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'stereotypeName',
				title: t('STEREOTYPE'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'objectSettingsName',
				title: t('OBJECT_TYPE'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'comment',
				title: t('TEMPLATE_COMMENT'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'description',
				title: t('TEMPLATE_DESCRIPTION'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'authorName',
				title: t('TEMPLATE_AUTHOR'),
				width: 80,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'fileExtension',
				title: t('FILE_EXTENSION'),
				width: 100,
				maxWidth: 100,
				formatter: (cell) => cell || '-',
			},
			{
				field: 'version',
				title: t('TEMPLATE_VERSION'),
				width: 58,
				maxWidth: 58,
				formatter: (cell) => cell || '-',
			},
			{
				field: '',
				width: 85,
				maxWidth: 85,
				// eslint-disable-next-line react/display-name
				formatter: (_, template) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<TemplateModal
										textAreaHeight={textAreaHeight}
										template={template}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && <TemplateDiff template={template} onClose={onClose} />
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('VERSIONS')} icon={faHistory} />
								</Button>
							)}
						</DialogWrapper>

						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										removeId={template.id}
										callback={deleteTemplate}
										textOnModal={t('REMOVE_DESC', [
											template.description || t('TEMPLATE'),
										])}
										header={t('TEMPLATE_DELETE')}
										onRemove={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				),
			},
		],
		[handleSave, t],
	)
}
