import { CheckBoxFormField, Form } from '@/components/UberForm'
import { updateSettings } from '@/store/modules/settings/actions'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { faSubscript } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback } from 'react'
import { TitleContainer, TitleIcon, TitleText } from '../../styles'
import { Container } from '@/components'

type ScriptEditorForm = {
	autocompleteKeywords: boolean
	autocompleteSnippets: boolean
	autocompleteElements: boolean
}

export const ScriptEditor = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const settings = useAppStore((state) => state.settings)

	const scriptEditor = useAppStore(
		(state) => state.settings.userInterface.scriptEditor,
	)

	const onChange = useCallback(
		(values: ScriptEditorForm) => {
			dispatch(
				updateSettings({
					...settings,
					userInterface: {
						scriptEditor: values,
					},
				}),
			)
		},
		[dispatch, settings],
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon color="#63bed4" icon={faSubscript} />
				<TitleText>{t('SCRIPT_EDITOR')}</TitleText>
			</TitleContainer>
			<Container>
				<Form<ScriptEditorForm>
					defaultValues={scriptEditor}
					onChange={onChange}
				>
					<CheckBoxFormField
						title={t('AUTOCOMPLETE_KEYWORDS')}
						name="autocompleteKeywords"
					/>

					<CheckBoxFormField
						title={t('AUTOCOMPLETE_SNIPPETS')}
						name="autocompleteSnippets"
					/>

					<CheckBoxFormField
						title={t('AUTOCOMPLETE_ELEMENTS')}
						name="autocompleteElements"
					/>
				</Form>
			</Container>
		</>
	)
}
