import { AnyObject } from '@/components/UberForm/types'
import { LANGUAGE_KEY } from '@/context/Locale/Locale'
import { ObjectWithId } from './types'
import { FormValue } from '@/types'

export interface NativeMap<T> {
	[key: string]: T | undefined
}

/**
 * Creates map from array using specified key.
 * @param collection array of items
 * @param key key to be used in the map
 */
export function keyMap<T, K extends keyof T>(
	collection: T[],
	key: K,
	source = {} as NativeMap<T>,
): NativeMap<T> {
	return collection.reduce((acc, item) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		acc[item[key] as any] = item

		return acc
	}, source)
}

/**
 * Creates map from array using specified key and specified value key.
 * @param collection array of items
 * @param key key to be used in the map
 * @param valueKey key extraced from item
 */
export function keyValueMap<T, K1 extends keyof T, K2 extends keyof T>(
	collection: T[],
	key: K1,
	valueKey: K2,
	source = {} as NativeMap<T[K2]>,
): NativeMap<T[K2]> {
	return collection.reduce((acc, item) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		acc[item[key] as any] = item[valueKey]

		return acc
	}, source)
}

/**
 * Merges multiple arrays into one array containing only unique items.
 * @param arrays arrays to merge
 */
export function uniqueMerge<T>(...arrays: T[][]) {
	const items: NativeMap<T> = {}

	arrays.forEach((array) => {
		array.forEach((item) => {
			items[JSON.stringify(item)] = item
		})
	})

	return Object.keys(items).map((key) => items[key])
}

const ucfirst = (value: string) =>
	value.charAt(0).toUpperCase() + value.slice(1)

export function flatternFilters(
	filter: NativeMap<FormValue>,
): NativeMap<string | boolean | string[]> {
	if (!filter) {
		return {}
	}

	const flattern = (
		current: NativeMap<string | boolean | string[] | number>,
		data: NativeMap<FormValue>,
		prefix: string,
	) => {
		Object.keys(data).forEach((key) => {
			const value = data[key]
			const newKey = prefix.length > 0 ? prefix + ucfirst(key) : key

			if (value !== undefined && value !== null) {
				if (typeof value === 'object' && !Array.isArray(value)) {
					flattern(current, value, newKey)
				} else if (value !== '' && !(Array.isArray(value) && !value.length)) {
					current[newKey] = value
				}
			}
		})
	}

	const flat = {} as NativeMap<string | boolean | string[]>
	flattern(flat, filter, '')

	return flat
}

/**
 * Generates array containing numbers between start and end (including).
 * @param start beginning number
 * @param end ending number (including)
 * @param step range step, defaults to 1
 */
export function range(start: number, end: number, step = 1) {
	const result = [] as number[]

	for (let i = start; i <= end; i += step) {
		result.push(i)
	}

	return result
}

/**
 * Finds first match in specified array.
 * @param items list of items
 * @param key key used for matching
 * @param value value to be matched agains key
 * @param notFound value returned when there is no match
 */
export function firstKeyMatch<T, K extends keyof T>(
	items: T[],
	key: K,
	value: T[K],
	notFound?: T,
): T | undefined {
	if (!items) {
		return notFound
	}

	for (const item of items) {
		if (item[key] === value) {
			return item
		}
	}

	return notFound
}

/**
 * Splits props into two.
 * @param original all props merged into one object
 * @param splitKeys keys to be removed from the original and returned as new props
 * @TODO: Wrong return type, we have to somehow bend Pick to return object only with specified keys
 */
export function splitProps<T, K extends (keyof T)[]>(
	original: T,
	splitKeys: K,
): any {
	return splitKeys.reduce((props, key) => {
		props[key] = original[key]
		delete original[key]

		return props
	}, {} as T)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shallowEqual(a: any, b: any) {
	if (a === b) {
		return true
	}

	if ((!a && b) || (!b && a)) {
		return false
	}

	const aKeys = Object.keys(a)
	const bKeys = Object.keys(b)

	if (aKeys.length !== bKeys.length) {
		return false
	}

	// tslint:disable-next-line: prefer-for-of
	for (let i = 0; i < aKeys.length; i++) {
		if (!b.hasOwnProperty(aKeys[i]) || a[aKeys[i]] !== b[aKeys[i]]) {
			return false
		}
	}

	return true
}

export function compareFlatArrays<T>(
	a: T[] | null | undefined,
	b: T[] | null | undefined,
) {
	if (a === b) {
		return true
	}

	if (!a || !b) {
		return false
	}

	if (a.length !== b.length) {
		return false
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false
		}
	}

	return true
}

export const detectChanges = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	a: { [key: string]: any },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	b: { [key: string]: any },
) => {
	const messages = [] as string[]

	Object.entries(a).forEach(([key, value]) => {
		const bValue = b[key]

		if (bValue !== value) {
			messages.push(`${key}: ${value} !== ${bValue}`)
			//messages.push(JSON.stringify(value))
			//messages.push(JSON.stringify(bValue))
		}
	})

	return messages
}

/**
 * Creates value, label collection from enum
 * @param enum enumeration
 */
export function enumToValueLabelCollection(enumeration: {
	[key: string]: any
}): { value: string; label: string }[] {
	return Object.keys(enumeration).map((entry) => ({
		value: entry,
		label: enumeration[entry],
	}))
}

export function enumToLabelCollection(
	enumeration: {
		[key: string]: any
	},
	prefix: string,
	t: (key: LANGUAGE_KEY, values?: any) => string,
): { value: string; label: string }[] {
	return Object.keys(enumeration).map((entry) => ({
		value: entry,
		label: t((prefix + '_' + enumeration[entry]) as LANGUAGE_KEY),
	}))
}

/**
 * Provides type-safe way of filtering out nulls
 * @param it
 */
export function isNotNull<T>(it: T): it is NonNullable<T> {
	return it != null
}

/**
 * Provides type-safe way of filtering out undefined
 * @param it
 */
export function isNotUndefined<T>(it: T): it is NonNullable<T> {
	return it != undefined
}

/** Move element in array from one index to another */
export const arrayMoveIndex = <T>(
	arr: T[],
	fromIndex: number,
	toIndex: number,
) => {
	const updatedArray = [...arr]
	const element = arr[fromIndex]
	updatedArray.splice(fromIndex, 1)
	updatedArray.splice(toIndex, 0, element)

	return updatedArray
}

/** Check if object has all properties equal to the values object */
export const hasObjectAllValues = <T extends AnyObject>(
	item: T,
	values: Partial<T>,
) => {
	const valuesEntries = Object.entries(values)

	const valuesEquals = valuesEntries.filter(([key, value]) => {
		if (value === undefined || value === null) {
			return true
		}

		if (Array.isArray(item[key]) && item[key].includes(value)) {
			return true
		}

		if (item[key] === value) {
			return true
		}

		return false
	})

	if (valuesEquals.length === valuesEntries.length) {
		return true
	}

	return false
}

/**
 * This function updates or insert new values to the custom attributes array.
 * Based on the id of the updatedCustomAttribute, it will either update the existing
 * attribute or insert a new one.
 * @param customAttributes array of custom attributes coming from Settings page.
 * @param updatedCustomAttribute custom attribute that is being updated.
 */
export const customAttributesUpsert = (
	customAttributes: ObjectWithId[],
	updatedCustomAttribute: ObjectWithId,
) => {
	const attributesToBeUpdated = [...customAttributes]

	const matchedAttrIndex = attributesToBeUpdated.findIndex(
		(attrToBeUpdated) => {
			return attrToBeUpdated.id === updatedCustomAttribute.id
		},
	)

	const isAttributeAlreadyPresent = matchedAttrIndex > -1

	if (isAttributeAlreadyPresent) {
		attributesToBeUpdated[matchedAttrIndex] = updatedCustomAttribute

		return attributesToBeUpdated
	} else {
		return [...attributesToBeUpdated, updatedCustomAttribute]
	}
}
