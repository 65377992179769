import styled, { css } from 'styled-components'

import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import { FormValue } from '@/types'

import { FormFieldContext, withFormField } from '../UberForm/FormFieldContext'

type CodeInputFormlessProps = FormFieldContext & {
	autoFocus?: boolean
	error?: string | null
	onBlurCustom?: () => void
	onFocusCustom?: () => void
	readonly?: boolean
	readonlyContainer?: (value?: string) => React.ReactNode
	value?: string
}

export const CodeInputFormless = ({
	name,
	value,
	onChange,
	error,
	onFocus,
	onBlur,
	onFocusCustom,
	onBlurCustom,
	autoFocus,
	readonly,
	readonlyContainer,
	disabled,
}: CodeInputFormlessProps) => {
	const handleChange = (value: FormValue) => {
		onChange && onChange(value)
	}

	const onFocusHandler = (e: any) => {
		if (onFocus) {
			onFocus(e)
		}

		if (onFocusCustom) {
			onFocusCustom()
		}
	}

	const onBlurHandler = (e: any) => {
		if (onBlur) {
			onBlur(e)
		}

		if (onBlurCustom) {
			onBlurCustom()
		}
	}

	const renderReadonly = readonlyContainer ? (
		readonlyContainer(value)
	) : (
		<FormlessTextStyled value={value} disabled />
	)

	const renderInteractive = (
		<FormlessTextStyled
			name={name}
			value={value}
			onFocus={onFocusHandler}
			onBlur={onBlurHandler}
			error={error}
			onChange={handleChange}
			autoFocus={autoFocus}
			disabled={disabled}
		/>
	)

	return readonly ? renderReadonly : renderInteractive
}

const FormlessTextStyled = styled(FormlessText)<
	React.ComponentProps<
		typeof FormlessText & { disabled: boolean; error?: string }
	>
>`
	background: ${(props) =>
		props.disabled ? props.theme.colors.input.disabled.background : ''};

	${(props) =>
		props.error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`

export const CodeInput = withFormField(CodeInputFormless)
