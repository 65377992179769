import styled from 'styled-components'

export const SwitchButton = styled.div`
	margin-top: 1rem;
	margin-left: 1rem;
	border-radius: 1.25rem;
	display: flex;

	button {
		&:first-child {
			border-radius: 1.25rem 0 0 1.25rem;
		}
		&:last-child {
			border-radius: 0 1.25rem 1.25rem 0;
		}
	}
`

export const ListEditTabWrapper = styled.div`
	height: 25rem;
`
