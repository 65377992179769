import { useMemo } from 'react'
import { DomainDto, StereotypeDto } from '@/endpoints/models'
import { TableColumn, TableConstraint } from '@/endpoints/schemas'
import { TableColumnFlat } from '@/store/modules/table/types'
import { useAppContext, useMemoObject } from '@/hooks'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

const customDomainPredicate =
	(domains: DomainDto[], constraints: TableConstraint[]) =>
	(item: TableColumnFlat) =>
		!domains.find((x) => x.id === item.domainId && x.custom) ||
		isPrimaryOrForeignKey(constraints)(item)

const isPrimaryOrForeignKey =
	(constraints: TableConstraint[]) => (item: TableColumn) =>
		!!constraints?.find(
			(x) =>
				(x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
					x.constraintType ===
						TableConstraint.ConstraintTypeEnum.FOREIGN_KEY) &&
				x.columns?.find((c) => c.uuid === item.uuid),
		)

const foreignKeyValue =
	(constraints: TableConstraint[]) => (item: TableColumn) =>
		!!constraints?.find(
			(x) =>
				x.constraintType === TableConstraint.ConstraintTypeEnum.FOREIGN_KEY &&
				x.columns?.find((c) => c.uuid === item.uuid),
		)

const primaryKeyValue =
	(constraints: TableConstraint[]) => (item: TableColumn) => {
		const keyOrder = constraints
			.filter(
				(x) =>
					x.constraintType === TableConstraint.ConstraintTypeEnum.PRIMARY_KEY,
			)
			.map((x) => x.columns?.findIndex((c) => c.uuid === item.uuid))
			.find((x) => x !== undefined && x >= 0)

		return keyOrder !== undefined ? `PK [${keyOrder + 1}]` : null
	}

export const useColumnProperties = (
	domains: DomainDto[],
	constraints: TableConstraint[],
	stereotypes: { data: StereotypeDto[] | undefined; reloading: boolean },
	canGenerateNaming: boolean,
) => {
	const { t } = useAppContext()
	const cachedConstraints = useMemoObject(constraints)

	const properties = useMemo<TableColumnProps<TableColumnFlat>[]>(() => {
		return [
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				autoWidth: true,
				showWhenPanelOpened: true,
				minWidth: 50,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				autoWidth: true,
				showWhenPanelOpened: true,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'description',
				label: t('TABLE_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 100,
			},
			{
				field: 'comment',
				label: t('TABLE_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: 100,
			},
			{
				field: 'domainId',
				label: t('TABLE_COLUMNS_DOMAIN'),
				type: ItemPropertyType.OPTION,
				optionValue: 'id',
				optionLabel: 'name',
				options: domains,
				isNumeric: true,
				disabled: isPrimaryOrForeignKey(cachedConstraints),
				propertiesWidth: '50%',
				width: 100,
			},
			{
				field: 'dataType',
				label: t('TABLE_COLUMNS_DATA_TYPE'),
				type: ItemPropertyType.TEXT,
				disabled: customDomainPredicate(domains, cachedConstraints),
				width: 100,
			},
			{
				field: 'defaultValue',
				label: t('DOMAIN_DEFAULT_VALUE'),
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'stereotypeId',
				label: t('TABLE_COLUMNS_STEREOTYPE'),
				type: ItemPropertyType.OPTION,
				optionValue: 'id',
				optionLabel: 'name',
				options: stereotypes.data || [],
				isNumeric: true,
				isLoading: stereotypes.reloading,
				width: 100,
			},
			{
				field: 'virtualColumnScript',
				label: t('TABLE_COLUMNS_VIRTUAL_COLUMN_SCRIPT'),
				value: (column) =>
					column.stereotypeCode && column.stereotypeCode === 'VIRTUAL_COLUMN'
						? column.virtualColumnScript
						: '',
				type: ItemPropertyType.ACE_EDITOR,
				disabled: (column) => column.stereotypeCode !== 'VIRTUAL_COLUMN',
				aceEditorProps: {
					autocompleteConfig: {
						disableSystemTablesAndColumns: true,
					},
				},
				width: 100,
			},
			{
				label: t('TABLE_COLUMNS_PRIMARY_KEY_ORDER'),
				tableLabel: t('TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT'),
				type: ItemPropertyType.TEXT,
				disabled: true,
				value: primaryKeyValue(cachedConstraints),
				width: 40,
				canFilter: false,
			},
			{
				field: 'notNullFlag',
				label: t('TABLE_COLUMNS_MANDATORY'),
				tableLabel: t('TABLE_COLUMNS_MANDATORY_SHORT'),
				type: ItemPropertyType.CHECKBOX,
				disabled: customDomainPredicate(domains, cachedConstraints),
				width: 40,
				canFilter: false,
			},
			{
				label: t('TABLE_COLUMNS_FOREIGN_KEY'),
				tableLabel: t('TABLE_COLUMNS_FOREIGN_KEY_SHORT'),
				type: ItemPropertyType.CHECKBOX,
				disabled: true,
				value: foreignKeyValue(cachedConstraints),
				width: 40,
				canFilter: false,
			},
		]
	}, [
		t,
		canGenerateNaming,
		domains,
		stereotypes.data,
		stereotypes.reloading,
		cachedConstraints,
	])

	return properties
}
