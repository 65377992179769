import React, { useEffect, useCallback } from 'react'
import { WriteMappingDataForm } from '@/store/modules/writeMapping/types'
import { createEmptyWriteMappingColumn } from '@/store/modules/writeMapping/helpers'
import { useAppStore, useAppDispatch } from '@/hooks'
import { TableAndProperties } from '@/components'
import { initApiNode } from '@/store/modules/apiNode/actions'
import { useApi } from '@/endpoints/hooks'
import { getWriteColumnFromAllStructures } from '@/endpoints'
import { WriteMappingColumn } from '@/endpoints/schemas/writeMapping'
import { ApiNodeColumnDto } from '@/endpoints/models'
import { UpdateDeepPartial } from '@/store/utils'
import {
	getColumnMappingsOptions,
	getFilteredColumns,
} from '@/pages/User/pages/Home/utils'
import { WriteMappingColumnProps } from '@/pages/User/pages/Home/pages/WriteMappingDetail/pages/Columns/types'
import { useColumnsProperties } from './useColumnsProperties'

export const Columns = ({ node, data, onChange }: WriteMappingColumnProps) => {
	const dispatch = useAppDispatch()

	const apiNodes = useAppStore((state) => state.apiNode.nodes)
	const readMappings = useAppStore((state) => state.readMapping.mappings)

	const { data: writeColumns } = useApi(
		getWriteColumnFromAllStructures(Number(node.id)),
	)

	const parentNode = node.parentStructureId
		? apiNodes[node.parentStructureId]
		: undefined

	const rowSetCode = readMappings[node.id]?.form.rowsetCode

	const properties = useColumnsProperties({
		nodeId: node.id,
		parentNode,
		rowSetCode,
		items: data.form.columns || [],
		writeColumns,
	})

	const handleChange = useCallback(
		(v: UpdateDeepPartial<WriteMappingDataForm>) => {
			if (v.columns) {
				Object.values(v.columns).forEach((c) => {
					if (c.columnCode) {
						//FIXME - Is this the right way to check the column from API? How to make sure it is actually loaded?
						const x = getColumnMappingsOptions(
							getFilteredColumns(parentNode, writeColumns),
							rowSetCode,
						).find((col) => col.columnCode == c.columnCode)

						//FIXME - what is the best way to cast these 2 enums?
						if (x?.sourceType === ApiNodeColumnDto.SourceTypeEnum.API_NODE) {
							c.columnSourceType =
								WriteMappingColumn.WriteMappingColumnSourceType.API_NODE
						} else {
							c.columnSourceType =
								WriteMappingColumn.WriteMappingColumnSourceType.ROW_SET
						}

						c.columnSourceColumnUuid = x?.columnUuid
						c.columnSourceStructureId = x?.sourceStructureId
						c.columnSourceStructureCode = x?.sourceStructureCode
					}
				})
			}

			onChange(v)
		},
		[writeColumns, onChange, parentNode, rowSetCode],
	)

	useEffect(() => {
		if (!parentNode && node.parentStructureId) {
			dispatch(initApiNode({ nodeId: node.parentStructureId }))
		}
	}, [dispatch, node.parentStructureId, parentNode])

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			isRowOrderable
			createEmpty={createEmptyWriteMappingColumn}
			itemsKey="columns"
			idCounterKey="columnsLastId"
			onChange={handleChange}
			properties={properties}
			tabKey="column"
			existsKey="columnCode"
		/>
	)
}
