import { TableColumn, TableConstraintColumn } from '@/endpoints/schemas'
import { PossibleForeignKeys } from '@/types'

export const getFkSelectOptions = (
	possibleForeignKeys: PossibleForeignKeys | undefined,
	sortedForeignKeys: TableConstraintColumn[],
	pk: TableColumn,
) =>
	possibleForeignKeys?.[pk.uuid]
		?.map((fk) => ({
			value: fk.uuid,
			label: fk.name,
		}))
		// do not show columns already used as FK in the same constraint
		.filter(
			(opt) =>
				!sortedForeignKeys.find(
					(cur) => cur.uuid === opt.value && cur.foreignColumnUuid !== pk.uuid,
				),
		)
