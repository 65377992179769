import { useMemo } from 'react'

import { EnvironmentDto, StructureObjectDto } from '@/endpoints/models'
import { SystemEnvironment } from '@/endpoints/schemas'
import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { TableColumnProps } from '@/types'

import { ActionsColumn } from './ActionsColumn'
import { LatestRelease } from './LatestRelease'

const productionCheckboxPredicate = (
	item: SystemEnvironment,
	listOfValues: SystemEnvironment[],
) => !!listOfValues?.find((i) => i.production && item.id !== i.id)

export const useEnvironmentProperties = (
	systemNode: StructureObjectDto,
	canGenerateNaming: boolean,
	envs?: EnvironmentDto[],
) => {
	const { t } = useAppContext()

	const properties = useMemo<TableColumnProps<SystemEnvironment>[]>(
		() => [
			{
				field: 'name',
				label: t('FIELD_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('FIELD_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				width: 150,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'description',
				label: t('FIELD_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
			{
				field: 'connection',
				label: t('FIELD_CONNECTION'),
				type: ItemPropertyType.TEXT,
				width: 150,
			},
			{
				field: 'gitUrl',
				label: t('FIELD_GIT_URL'),
				type: ItemPropertyType.TEXT,
				width: 150,
			},
			{
				field: 'gitLoginName',
				label: t('FIELD_GIT_LOGIN_NAME'),
				type: ItemPropertyType.TEXT,
				width: 150,
			},
			{
				field: 'gitPasswordToken',
				label: t('FIELD_GIT_PASSWORD_TOKEN'),
				type: ItemPropertyType.SECRET,
				width: 150,
			},
			{
				field: 'directDeployment',
				label: t('FIELD_DIRECT_DEPLOYMENT'),
				type: ItemPropertyType.CHECKBOX,
				hideInTable: true,
				width: 150,
			},
			{
				field: 'rememberPassword',
				label: t('FIELD_REMEMBER_PASSWORD'),
				type: ItemPropertyType.CHECKBOX,
				hideInTable: true,
				width: 150,
			},
			{
				field: 'production',
				label: t('FIELD_PRODUCTION'),
				type: ItemPropertyType.CHECKBOX,
				disabled: productionCheckboxPredicate,
				width: 150,
			},
			{
				field: 'latestRelease',
				label: t('LATEST_RELEASE'),
				type: ItemPropertyType.TEXT,
				disabled: true,
				width: 150,
				formatter: (props) => (
					<LatestRelease {...props} envs={envs} systemNode={systemNode} />
				),
			},
			{
				field: 'actions',
				label: '',
				type: ItemPropertyType.TEXT,
				width: 150,
				formatter: ActionsColumn,
			},
		],
		[canGenerateNaming, envs, systemNode, t],
	)

	return properties
}
