import { QueueData } from '@/endpoints/schemas'

export interface OpenedQueueData {
	form: QueueData
	original: QueueData
	tab: QueueTab
	dirty: boolean
	parsedEditMode: boolean
}

export enum QueueTab {
	Overview = 'Overview',
	PhysicalOptions = 'PhysicalOptions',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Dependencies = 'Dependencies',
}
