import { mapDispatchToProps } from './components/ErrorContainer'
import React from 'react'

export enum ErrorType {
	HARD,
	SOFT,
}

export type ErrorContainerProps = Readonly<
	ReturnType<typeof mapDispatchToProps> & {
		children: React.ReactNode
	}
>

export type ErrorContainerState = {
	open: boolean
	timestamp: Date | null
	error: Error | null
	body: string | null
	type: ErrorType
	response?: Response
}

export interface ConfirmationOptions {
	catchOnCancel?: boolean
	title: string
	description: string | React.ReactNode
	cancel?: boolean
}

export interface ConfirmationDialogProps extends ConfirmationOptions {
	open: boolean
	onSubmit: (cancel?: boolean) => void
	onClose: () => void
}

export type AppProps = {
	token?: string
}
