import React, { useCallback, useRef } from 'react'
import { TreeItem } from './TreeItem'
import { TreeNode, TreeProps } from './types'
import { TreeContainer } from './styles'

export const Tree = <N extends TreeNode>({
	expanded,
	highlighted,
	className,
	roots,
	onItemContextMenu,
	onItemDblClick,
	onItemExpand,
	onItemClick,
	actionComponent,
	iconComponent,
	focused,
	onFocused,
	getDragDrop,
	onItemCheckChange,
	showCheckboxes,
	isCheckedFunc,
}: TreeProps<N>) => {
	const containerRef = useRef<HTMLDivElement>(null)

	const handleFocus = useCallback(
		async (e: HTMLElement) => {
			const container = containerRef.current

			if (container && e) {
				const offset =
					e.getBoundingClientRect().top - container.getBoundingClientRect().top

				container.scrollTop = offset - 10
			}

			onFocused && onFocused()
		},
		[containerRef.current],
	)

	return (
		<TreeContainer ref={containerRef} role="tree" className={className || ''}>
			{roots.map((root) => (
				<TreeItem<N>
					node={root}
					key={root.key}
					isCheckedFunc={isCheckedFunc}
					showCheckbox={showCheckboxes}
					onCheckChange={onItemCheckChange}
					onItemClick={onItemClick}
					onItemExpand={onItemExpand}
					onItemDblClick={onItemDblClick}
					onItemContextMenu={onItemContextMenu}
					actionComponent={actionComponent}
					iconComponent={iconComponent}
					highlighted={highlighted}
					level={0}
					expanded={expanded}
					onFocus={handleFocus}
					focused={focused}
					getDragDrop={getDragDrop}
				/>
			))}
		</TreeContainer>
	)
}
