import React, { useCallback, useMemo, useRef, useState } from 'react'

import { MoveDir } from '../types'
import styled, { css } from 'styled-components'
import { EditCell } from './Cell'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { errorGet, valueGet } from '../utils'
import { useAppContext } from '@/hooks'
import { RowOrderer } from './RowOrderer'
import { RowOrderable } from '../context/types'
import { useTablePropertiesContext } from '../context/TablePropertiesContext'
import { ITEM_HEIGHT } from '../tableProperties/constants'
import { ColumnsPickerValue } from '@/components/ColumnsPicker/types'
import { FormValue, TableColumnProps, PropertiesErrorProps } from '@/types'

interface RowProps<T> {
	index: number
	properties: TableColumnProps<T>[]
	isReadOnly: boolean
	item: T
	listOfValues: T[]
	onChange?: (
		item: T,
		key: string,
		value: FormValue | ColumnsPickerValue,
	) => void
	onSelect?: (index: number) => void
	onDelete?: (item: T) => void
	isDeletable?: (item: T, itemIndex: number) => boolean
	isReadonly?: (item: T) => boolean
	errors?: PropertiesErrorProps
	isFocused: boolean
	focusedField: number | null | undefined
	isRowOrderable: RowOrderable
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	onFocus: (row: number, field: number) => void
	onMove: (dir: MoveDir) => void
	onDragChanged?: (isDragging: boolean) => void
	onRowDrop?: (dropIndex: number) => void
	$isDragging?: boolean
	isDragOver?: boolean
	columnWidths?: Record<string, number>
}

export const Row = <T,>({
	index,
	properties,
	item,
	isReadOnly,
	onChange,
	onSelect,
	onDelete,
	listOfValues,
	isDeletable,
	onRowOrderChanged,
	columnWidths,
	isReadonly,
	errors,
	isFocused,
	focusedField,
	onFocus,
	onMove,
	isRowOrderable,
	$isDragging,
	isDragOver,
	onDragChanged,
	onRowDrop,
}: RowProps<T>) => {
	const { t } = useAppContext()
	const readonlyMode = !!(isReadOnly || (isReadonly && isReadonly(item)))
	const containerRef = useRef<HTMLDivElement>(null)
	const [isRowDragging, setIsRowDragging] = useState(false)

	const {
		state: { onDragEnter },
	} = useTablePropertiesContext()

	const handleFocus = useCallback(
		(field: number) => {
			if (!isFocused || focusedField !== field) {
				onFocus(index, field)
				onSelect && onSelect(index)
			}
		},
		[focusedField, index, isFocused, onFocus, onSelect],
	)

	const handleOrderDragStart = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			onDragChanged?.(true)

			if (containerRef.current) {
				e.dataTransfer.setDragImage(containerRef.current, 0, 0)
			}

			setIsRowDragging(true)
		},
		[onDragChanged],
	)

	const handleOrderDragEnd = useCallback(() => {
		onDragChanged?.(false)

		setIsRowDragging(false)
	}, [onDragChanged])

	const handleOrderDragOver = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault()
		},
		[],
	)

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault()

			if (
				onRowDrop &&
				isRowOrderable.enabled &&
				(isRowOrderable.up || isRowOrderable.down)
			) {
				onRowDrop(index)
			}
		},
		[
			index,
			isRowOrderable.down,
			isRowOrderable.enabled,
			isRowOrderable.up,
			onRowDrop,
		],
	)

	const handleDragEnter = useCallback(
		() => onDragEnter(index),
		[index, onDragEnter],
	)

	const isLastRow = useMemo(
		() => index + 1 === listOfValues.length,
		[index, listOfValues.length],
	)

	return (
		<>
			{(!readonlyMode || !isDeletable || isDeletable(item, index)) && (
				<Container
					onClick={onSelect && (() => onSelect(index))}
					role="row"
					onDrop={handleDrop}
					onDragOver={handleOrderDragOver}
					ref={containerRef}
					$isDragOver={isDragOver && $isDragging}
					$isRowDragging={isRowDragging}
					onDragEnter={handleDragEnter}
					$isLastRow={isLastRow}
				>
					{isRowOrderable.enabled && onRowOrderChanged && (
						<RowOrderer
							onOrderDragStart={handleOrderDragStart}
							onOrderDragEnd={handleOrderDragEnd}
							onRowOrderChanged={onRowOrderChanged}
							index={index}
							isRowOrderable={isRowOrderable}
						/>
					)}

					{properties.map((prop, propIndex) => {
						const value = valueGet(prop, item)
						const error = errorGet(properties, prop, item, errors)

						return (
							<EditCell<T>
								index={propIndex}
								key={prop.field}
								item={item}
								listOfValues={listOfValues}
								value={value}
								prop={prop}
								readonly={readonlyMode}
								columnWidth={
									prop.field && columnWidths && columnWidths[prop.field]
								}
								onChange={onChange}
								error={error}
								isFocused={isFocused && focusedField === propIndex}
								onFocus={handleFocus}
								onMove={onMove}
							/>
						)
					})}
					<Actions>
						{!readonlyMode && (!isDeletable || isDeletable(item, index)) && (
							<Delete
								onClick={onDelete ? () => onDelete(item) : undefined}
								title={t('DELETE')}
							>
								<FontAwesomeIcon icon={faTimes} />
							</Delete>
						)}
					</Actions>
				</Container>
			)}
		</>
	)
}

const Container = styled.div<{
	$isDragOver?: boolean
	$isRowDragging: boolean
	$isLastRow: boolean
}>`
	display: flex;
	align-items: stretch;
	height: ${ITEM_HEIGHT}px;
	padding-left: 2px;
	${(props) =>
		props.$isRowDragging &&
		css`
			background: #f0f0ff;
		`}

	${(props) =>
		props.$isDragOver &&
		!props.$isRowDragging &&
		css`
			background: #f0f0f0;
		`}

	${(props) =>
		props.$isLastRow &&
		css`
			padding-bottom: 2px;
		`}
`

const Actions = styled.div`
	position: sticky;
	top: 0;
	right: 0;
	flex-grow: 0;
	flex-shrink: 0;
	width: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	background: white;

	button {
		padding: 0;
		margin: auto;
		height: 20px;
	}
`

const Delete = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
	color: ${(props) => props.theme.colors.danger.base};
	}
	&:hover svg {
		color: ${(props) => props.theme.colors.danger.light};
	}
`
