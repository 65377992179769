import { NativeMap } from '@/utils/collections'
import { OpenedQueueData, QueueTab } from './types'
import {
	QUEUE_INIT,
	QUEUE_SAVE,
	QUEUE_SELECT_TAB,
	QUEUE_UPDATE,
} from './constants'
import { updateTabData } from '@/store/utils'
import { ensureValidData } from '../helpers'
import { Actions } from './actions'
import { QueueData } from '@/endpoints/schemas'
import { defaultQueueData, getInitialQueueData } from './helpers'

type State = Readonly<typeof initialState>

const initialState = {
	queues: {} as NativeMap<OpenedQueueData>,
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case QUEUE_INIT: {
			const { node, editMode, force } = action

			const previous = state.queues[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: QueueData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultQueueData())
				: getInitialQueueData(node.name)

			return {
				...state,
				queues: {
					...state.queues,
					[node.id]: {
						form: data,
						dirty: false,
						parsedEditMode: editMode,
						tab: previous ? previous.tab : QueueTab.Overview,
						original: data,
					},
				},
			}
		}

		case QUEUE_UPDATE: {
			const { node, update } = action

			return {
				...state,
				queues: updateTabData(state.queues, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...update,
					},
					dirty: true,
				})),
			}
		}

		case QUEUE_SAVE: {
			const {
				metadata: { nodeId },
			} = action

			return {
				...state,
				queues: updateTabData(state.queues, nodeId, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		case QUEUE_SELECT_TAB: {
			const { node, tab } = action

			return {
				...state,
				queues: updateTabData(state.queues, node.id, (node) => ({
					...node,
					tab,
				})),
			}
		}

		default: {
			return state
		}
	}
}
