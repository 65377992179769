import { DetailTabContextState, Actions, ActionTypeEnum } from './types'

export const reducer = (
	state: DetailTabContextState,
	action: Actions
): DetailTabContextState => {
	switch (action.type) {
		case ActionTypeEnum.DETAIL_TAB_UPDATE:
			return {
				...state,
				...action.update
			}

		default:
			return state
	}
}
