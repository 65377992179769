import { faBomb, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Ref } from 'react'
import styled from 'styled-components'

import { Button, DialogWrapper } from '@/components'
import { Flex } from '@/components/Layout'
import { TranslationHelper } from '@/context/Locale/Locale'
import {
	DomainComboDto,
	StereotypeDto,
	TechnicalColumnDto,
} from '@/endpoints/models'
import { TechnicalImpactModal } from '@/pages/User/pages/Settings/pages/TechnicalColumns/components/TechnicalColumnImpactModal'
import { TechnicalRemoveModal } from '@/pages/User/pages/Settings/pages/TechnicalColumns/components/TechnicalColumnRemoveModal'
import { LightTableColumn } from '@/types'

import { CheckBoxFormField, Form } from '../../../../../../components/UberForm'
import { columnsAudit } from '../../utils'
import { TechColumnModal } from './components/TechColumnModal'

export const createColumnProperties =
	(ref: Ref<Form<TechnicalColumnDto>>) =>
	(
		t: TranslationHelper,
		handleSave: () => void,
		domains: DomainComboDto[] | undefined,
		stereotypes: StereotypeDto[],
	): LightTableColumn<TechnicalColumnDto>[] => {
		const actionButtons = (
			_: string | number | boolean | number[] | undefined,
			techCol: TechnicalColumnDto,
		) => (
			<Flex>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<TechnicalImpactModal
								technicalColumn={techCol}
								open={opened}
								onClose={onClose}
							/>
						)
					}
				>
					{(onClick) => (
						<Button size="sm" schema="transparent" onClick={onClick}>
							<FontAwesomeIcon
								title={t('SHOW_IMPACTED_TABLES')}
								icon={faBomb}
							/>
						</Button>
					)}
				</DialogWrapper>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<TechColumnModal
								stereotypes={stereotypes}
								techCol={techCol}
								onSave={handleSave}
								onClose={onClose}
								ref={ref}
							/>
						)
					}
				>
					{(onClick) => (
						<Button size="sm" schema="transparent" onClick={onClick}>
							<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
						</Button>
					)}
				</DialogWrapper>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<TechnicalRemoveModal
								technicalColumn={techCol}
								onRemove={handleSave}
								onClose={onClose}
							/>
						)
					}
				>
					{(onClick) => (
						<Button size="sm" schema="transparent" onClick={onClick}>
							<FontAwesomeIcon
								title={t('REMOVE')}
								color="#d00"
								icon={faTimes}
							/>
						</Button>
					)}
				</DialogWrapper>
			</Flex>
		)

		const getDomain = (id: number | undefined) =>
			domains?.find((dom) => dom.id === id)

		return [
			{
				field: 'name',
				title: t('TABLE_COLUMNS_NAME'),
				width: 50,
				maxWidth: 210,
			},
			{
				field: 'code',
				title: t('TABLE_COLUMNS_CODE'),
				width: 50,
				maxWidth: 170,
			},
			{
				field: 'domainId',
				title: t('TABLE_COLUMNS_DOMAIN'),
				width: 50,
				maxWidth: 170,
				formatter: (_, techCol) =>
					domains?.find((domain) => domain.id === techCol.domainId)?.name,
			},
			{
				field: 'notNull',
				title: t('TECH_COLUMN_NOT_NULL'),
				maxWidth: 80,
				width: 60,
				tdStyle: { justifyContent: 'center' },
				formatter: (_, techCol) => (
					<CheckboxSimpleTable
						disabled
						name="notNull"
						initialValue={getDomain(techCol.domainId)?.notNullFlag}
					/>
				),
			},
			{
				field: 'stereotypeIds',
				title: t('TABLE_COLUMNS_STEREOTYPE'),
				width: 50,
				formatter: (_, techCol) =>
					techCol.stereotypeIds
						?.map(
							(stId) => stereotypes?.find((stereo) => stereo.id === stId)?.name,
						)
						.join(', '),
			},
			...columnsAudit(t),
			{
				field: '',
				width: 90,
				maxWidth: 90,
				formatter: actionButtons,
			},
		]
	}

export const CheckboxSimpleTable = styled(CheckBoxFormField)`
	flex: 0;
	margin: 0;
`
