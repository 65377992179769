import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import styled, { css } from 'styled-components'
import cn from 'classnames'

import Tooltip from '../../../Tooltip/Tooltip'
import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { EnhancedPureComponentWithContext } from '@/components/EnhancedComponents'
import { FormValue } from '@/types'

export interface CheckboxProps extends FormFieldContext {
	title?: React.ReactNode
	tooltip?: string
	value?: boolean
	className?: string
	hideCheckboxTitle?: boolean
	isHidden?: boolean
}

export class CheckBox extends EnhancedPureComponentWithContext<CheckboxProps> {
	state = {
		focused: false,
	}
	componentDidMount() {
		const { register } = this.props

		if (register) {
			register(this)
		}
	}

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange && this.props.onChange(e.target.checked)
	}

	recap = (value: FormValue) => {
		return this.context.t(value ? 'CHECKBOX_TRUE' : 'CHECKBOX_FALSE')
	}

	render() {
		const {
			value,
			disabled,
			id,
			name,
			title,
			tooltip,
			isHorizontal,
			className,
			isCompact,
			hideCheckboxTitle,
			isHidden,
			isFieldHighlighted,
		} = this.props

		return (
			<Label
				className={cn('checkbox', className)}
				$isHorizontal={!!isHorizontal}
				$compact={!!isCompact}
				checked={value ? value : false}
				$focused={this.state.focused}
				disabled={disabled}
				$isHidden={isHidden}
			>
				<Input
					type="checkbox"
					id={id}
					name={name}
					disabled={disabled}
					onChange={this.onChange}
					checked={value ? value : false}
					onFocus={() => this.setState({ focused: true })}
					onBlur={() => this.setState({ focused: false })}
				/>
				{title && !hideCheckboxTitle && (
					<span>
						<Span
							$compact={!!isCompact}
							$isFieldHighlighted={isFieldHighlighted}
						>
							{title}
						</Span>

						{tooltip && (
							<Tooltip content={tooltip}>
								<FontAwesomeIcon icon={faInfo} color="#ccc" />
							</Tooltip>
						)}
					</span>
				)}
			</Label>
		)
	}
}

export const Label = styled.label<{
	$isHorizontal: boolean
	$compact: boolean
	checked: boolean
	$focused: boolean
	disabled?: boolean
	$isHidden?: boolean
}>`
	&&& {
		position: relative;
		display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
		align-items: center;
		margin-bottom: 0;
		padding: ${(props) => (props.$compact ? '0 4px' : '4px')};
		${(props) => css`
			font-size: ${props.theme.font.size.medium};
			font-weight: ${props.theme.font.weight.medium};
			${props.disabled &&
			css`
				&:hover::before {
					box-shadow: 0 0 5px ${props.theme.colors.primary.shadowColor};
				}
			`}
		`}

		${(props) =>
			props.$compact &&
			css`
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-color: ${props.$compact
					? `${props.theme.colors.input.$compact?.horizontalBorder} ${props.theme.colors.input.$compact?.verticalBorder}`
					: props.theme.colors.input.border};
				border-width: 1px;
				border-style: solid;
			`}

	${(props) =>
			props.$isHorizontal &&
			css`
				margin-top: 10px;
			`}

	${(props) => {
			const { primary } = props.theme.mainColors
			const { disabled, focused } = props.theme.colors.input

			return css`
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 15px;
					height: 15px;
					border: 1px solid ${primary};
					transition: box-shadow 0.25s ease-in;
					box-shadow: 0 0 5px transparent;
					${props.disabled &&
					css`
						background: ${disabled.background};
						border: 1px solid ${disabled.border};
					`}
					${props.$focused &&
					css`
						box-shadow: 0 0 0 1px ${focused.boxShadow};
					`}
				}
				&::after {
					content: '';
					position: absolute;
					top: calc(50% - 3px);
					left: 3px;
					width: 4px;
					height: 8px;
					border: solid transparent;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg) translateY(-50%);
					transition: border-color 0.25s ease-in;
					${props.checked &&
					css`
						border: solid ${primary};
						border-width: 0 2px 2px 0;
					`}
				}
			`
		}}
	}
`

export const Span = styled.span<{
	$compact: boolean
	$isFieldHighlighted?: boolean
}>`
	display: flex;
	margin-left: ${(props) => (props.$compact ? '17px' : '20px')};
	background: ${(props) =>
		props.$isFieldHighlighted ? props.theme.colors.warn.light : 'inherit'};
`

export const Input = styled.input`
	margin: 0;
	opacity: 0;
	width: 0;
	height: 0;
`

export default withFormField(CheckBox)
