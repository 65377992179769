import {
	CEZ_STAGE_AUTH_URL,
	DEV_AUTH_URL,
	DRMAX_STAGE_AUTH_URL,
} from '@/constants'

const PROD_AUTH_URL = `${window.location.protocol}//${window.location.hostname}/auth`

const tenantId = (() => {
	switch (window.location.hostname) {
		case '127.0.0.1':
		case 'localhost':
			return 'adastra'
		default:
			return window.location.hostname.split('.')[0]
	}
})()

const getAuthUrl = (() => {
	switch (import.meta.env.VITE_DEV_ENV) {
		case 'dev':
			return DEV_AUTH_URL

		case 'cez':
			return CEZ_STAGE_AUTH_URL

		case 'drmax':
			return DRMAX_STAGE_AUTH_URL

		default:
			return PROD_AUTH_URL
	}
})()

export const appConfig = {
	authUrl: getAuthUrl,
	apiUrl: '/api/mdd-backend',
	clientId: 'mdd-web',
	defaultTenant: 'adastra',
	realm: tenantId,
}
