import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deleteStereotype } from 'src/endpoints'

import { Button, DialogWrapper, RemoveModal } from '@/components'
import { Flex } from '@/components/Layout'
import { Form } from '@/components/UberForm'
import { TranslationHelper } from '@/context/Locale/Locale'
import { StereotypeDto } from '@/endpoints/models'
import { LightTableColumn } from '@/types'

import { StereoTypeModal } from './components/StereoTypeModal'

export const createStereoTypesColumns =
	(ref: React.Ref<Form<StereotypeDto>>) =>
	(
		t: TranslationHelper,
		handleSave: () => void,
	): LightTableColumn<StereotypeDto>[] => {
		return [
			{
				field: 'name',
				title: t('NAME'),
				width: 50,
			},
			{
				field: 'code',
				title: t('CODE'),
				width: 50,
				maxWidth: 300,
			},
			{
				field: 'type',
				title: t('TYPE'),
				width: 50,
			},
			{
				field: 'category',
				title: t('CATEGORY'),
				width: 50,
			},
			{
				field: 'description',
				title: t('TABLE_GENERAL_DESCRIPTION'),
				width: 50,
			},
			{
				field: '',
				width: 60,
				maxWidth: 60,
				formatter: (_, stereoType: any) => (
					<Flex>
						{!stereoType.internal && stereoType.category !== 'BEHAVIORAL' && (
							<>
								<DialogWrapper
									dialog={(opened, onClose) =>
										opened && (
											<StereoTypeModal
												stereoType={stereoType}
												onSave={handleSave}
												onClose={onClose}
												ref={ref}
											/>
										)
									}
								>
									{(onClick) => (
										<Button size="sm" schema="transparent" onClick={onClick}>
											<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
										</Button>
									)}
								</DialogWrapper>

								<DialogWrapper
									dialog={(opened, onClose) =>
										opened && (
											<RemoveModal
												callback={deleteStereotype}
												onRemove={handleSave}
												removeId={stereoType.id}
												textOnModal={t('REMOVE_DESC', [stereoType.name])}
												header={t('REMOVE_STEREOTYPE')}
												onClose={onClose}
											/>
										)
									}
								>
									{(onClick) => (
										<Button size="sm" schema="transparent" onClick={onClick}>
											<FontAwesomeIcon
												title={t('REMOVE')}
												color="#d00"
												icon={faTimes}
											/>
										</Button>
									)}
								</DialogWrapper>
							</>
						)}
					</Flex>
				),
			},
		]
	}
