import React from 'react'
import styled, { css } from 'styled-components'

const MARGIN = '0 -8px'
const PADDING = '0 8px'

const LAYOUT = {
	small: {
		WIDTH: ['100%', '100%', '50%', '25%', '16.666%'],
	},
	medium: {
		WIDTH: ['100%', '100%', '50%', '33.3333%', '25%'],
	},
	big: {
		WIDTH: ['100%', '100%', '50%'],
	},
	custom: null,
}

type ColProps = {
	children: React.ReactNode
	/** If size is custom - provide custom width */
	size: keyof typeof LAYOUT
	width?: string | string[]
	noSpacing?: boolean
	onClick?: () => void
}

export const Col = ({
	size,
	width,
	noSpacing,
	children,
	onClick,
}: ColProps) => (
	<ColStyled
		width={size === 'custom' ? width : LAYOUT[size].WIDTH}
		noSpacing={noSpacing}
		onClick={onClick}
	>
		{children}
	</ColStyled>
)

type RowProps = {
	children: React.ReactNode
	noSpacing?: boolean
	$alignItems?: 'center' | 'flex-end' | 'flex-start'
}

export const Row = styled.div<RowProps>`
	display: flex;
	margin: ${(props) => (props.noSpacing ? '' : MARGIN)};
	flex-wrap: wrap;
	align-items: ${(props) => props.$alignItems};
`

interface DeviceProps {
	mobile: string
	tablet: string
	laptop: string
	desktop: string
}

const devices: DeviceProps = {
	mobile: '425px',
	tablet: '768px',
	laptop: '1024px',
	desktop: '1440px',
}

const getBreakpoint = (type: keyof DeviceProps) =>
	`@media screen and (min-width: ${devices[type]})`

const device = {
	mobile: getBreakpoint('mobile'),
	tablet: getBreakpoint('tablet'),
	laptop: getBreakpoint('laptop'),
	desktop: getBreakpoint('desktop'),
}

type deviceKeys = keyof typeof device
const devicesKeys = Object.keys(device) as deviceKeys[]

const getResponsiveCss = (index: number, width: string[]) => {
	const mediaQuery = device[devicesKeys[index - 1]]

	return index >= 0
		? css`
				${mediaQuery &&
				css`
					${mediaQuery} {
						width: ${width[index]};
					}
				`}
		  `
		: css`
				width: ${width[index]};
		  `
}

type ColStyled = Omit<ColProps, 'size'>

const ColStyled = styled.div<ColStyled>`
	${(props) => {
		const { noSpacing, width } = props

		return css`
			padding: ${noSpacing ? '' : PADDING};
			box-sizing: border-box;
			${width instanceof Array
				? width.map((w, i) => getResponsiveCss(i, width))
				: css`
						width: ${width};
				  `}
		`
	}}
`
