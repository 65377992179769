import { useAppContext } from '@/hooks'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TRANSFER_KEY_CSV_COLUMN } from '../../../constants'
import { useCsvImporterContext } from '../../../context/CsvImporterContext'
import { ActionTypeEnum } from '../../../context/types'
import { CsvField } from '../../../types/CsvField'
import {CsvColumnCard, CsvColumnCardProps} from './CsvColumnCard'
import {useDrop} from "react-dnd";

type CsvFieldCardProps = {
	field: CsvField
}

export const CsvFieldCard = ({
	field: { name, label, required },
}: CsvFieldCardProps) => {
	const { t } = useAppContext()

	const {
		state: { mapping },
		dispatch,
	} = useCsvImporterContext()

	const [{isOver},drop] = useDrop(
		() => ({
			accept: TRANSFER_KEY_CSV_COLUMN,
			drop: (item:CsvColumnCardProps , monitor) => {
				dispatch({
					type: ActionTypeEnum.CSV_MAPPING_UPDATE,
					mapping: {
						[name]: item.columnIndex,
					},
				})
			},
			collect: monitor => ({
				isOver: monitor.isOver()
			}),
		}),
		[name]
	)

	const columnIndexMapped = useMemo(() => mapping[name], [mapping, name])

	return (
		<Container
			ref={drop}
			isOver={isOver}
		>
			<Label>
				{label} {required && <Required>*</Required>}&nbsp;
				{!isNil(columnIndexMapped) && (
					<RemoveIcon
						onClick={() =>
							dispatch({
								type: ActionTypeEnum.CSV_MAPPING_UPDATE,
								mapping: {
									[name]: undefined,
								},
							})
						}
					>
						<FontAwesomeIcon
							title={t('REMOVE')}
							color="#000"
							icon={faTimes}
							style={{ height: '0.85rem' }}
						/>
					</RemoveIcon>
				)}
			</Label>
			{!isNil(columnIndexMapped) ? (
				<CsvColumnCard columnIndex={columnIndexMapped} />
			) : (
				<Wrapper>{t('DRAG_COLUMN_HERE')}</Wrapper>
			)}
		</Container>
	)
}

const Container = styled.div<{ isOver: boolean }>`
	opacity: ${(props) => props.isOver ? 0.5 : 1};
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 4px;
	border: 2px dashed #ddd;
	background: #f0f0f0;
	height: 64px;
	align-items: center;
	justify-content: center;
`

const Label = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2px;
`

const Required = styled.span`
	color: ${(props) => props.theme.colors.danger.base};
`

const RemoveIcon = styled.div`
	cursor: pointer;
	margin-left: auto;
`
