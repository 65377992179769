import React, { useMemo } from 'react'
import { createEmptyViewColumn } from '@/store/modules/view/helpers'
import { useAppContext } from '@/hooks'
import { TableAndProperties } from '@/components'
import { duplication } from './validation'
import { ColumnsComponentProps } from './types'
import { useColumnsProperties } from './useColumnsProperties'

export const ColumnsComponent = ({
	node,
	data,
	onChange,
}: ColumnsComponentProps) => {
	const { t } = useAppContext()
	const properties = useColumnsProperties({ parentId: node.parentStructureId })

	const errors = useMemo(
		() => duplication(data.form.columns || [], t),
		[data.form.columns, t],
	)

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			createEmpty={createEmptyViewColumn}
			itemsKey="columns"
			idCounterKey="columnsLastId"
			onChange={onChange}
			properties={properties}
			tabKey="column"
			errors={errors}
		/>
	)
}

export const Columns = ColumnsComponent
