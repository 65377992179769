import { CustomAttributeForm } from '@/hooks'

export interface ApiCollectionData {
	name: string
	code: string
	description?: string
	comment?: string
	columns: ApiCollectionColumn[]
	columnsLastId: number
	stereotypeId?: number
	stereotypeCode?: string
	type?: ApiCollectionType
	status?: ApiCollectionData.ApiCollectionStatusEnum
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: string
	customAttributes?: CustomAttributeForm[]
}

export enum ApiCollectionType {
	IN = 'in',
	OUT = 'out',
	IN_OUT = 'in_out',
	INTERNAL = 'internal',
}

export interface ApiCollectionColumn {
	id: number
	uuid?: string
	order: number
	name: string
	code?: string
	description?: string
	comment?: string
	domainId?: number
	dataType?: string
	defaultValue?: string
	notNull?: boolean
}

export namespace ApiCollectionData {
	export enum ApiCollectionStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen',
	}
}
