import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormlessFormField } from '../FormField'
import CheckBox, { CheckboxProps } from '../Input/CheckBox/CheckBox'
import { FormFieldProps } from '@/components/UberForm/types'

/**
 * Form field with checkbox.
 */
export default class CheckboxFormField extends React.PureComponent<
	FormFieldProps &
		Omit<CheckboxProps, 'onChange'> & {
			formless?: boolean
			valueCustom?: boolean
		}
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'title',
			'tooltip',
			'hideCheckboxTitle',
			'isHidden',
		]) as CheckboxProps

		fieldProps.title = this.props.title

		fieldProps.hideTitle =
			fieldProps.hideTitle === undefined ? true : fieldProps.hideTitle

		fieldProps.showTitlePlaceholder =
			fieldProps.showTitlePlaceholder === undefined
				? true
				: fieldProps.showTitlePlaceholder

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps} hideTooltip={true} showTitlePlaceholder={false}>
				<CheckBox
					{...inputProps}
					{...(fieldProps.valueCustom ? { value: fieldProps.valueCustom } : {})}
				/>
			</FF>
		)
	}
}
