import React, { useEffect, useCallback } from 'react'
import { ReadMappingDataForm } from '@/store/modules/readMapping/types'
import { createEmptyReadMappingColumn } from '@/store/modules/readMapping/helpers'
import { useAppStore, useAppDispatch } from '@/hooks'
import { TableAndProperties } from '@/components'
import { initApiNode } from '@/store/modules/apiNode/actions'
import { useApi } from '@/endpoints/hooks'
import { getApiNodeColumnFromAllStructures } from '@/endpoints'
import { ReadMappingColumn } from '@/endpoints/schemas/readMapping'
import { ApiNodeColumnDto } from '@/endpoints/models'
import { UpdateDeepPartial } from '@/store/utils'
import { ReadMappingColumnProps } from '@/pages/User/pages/Home/pages/ReadMappingDetail/pages/Columns/types'
import {
	getColumnMappingsOptions,
	getFilteredColumns,
} from '@/pages/User/pages/Home/utils'
import { useColumnsProperties } from './useColumnsProperties'
import { ApiNodeColumn } from '@/endpoints/schemas/api-node'

export const Columns = ({ node, data, onChange }: ReadMappingColumnProps) => {
	const dispatch = useAppDispatch()

	const apiNodes = useAppStore((state) => state.apiNode.nodes)
	const readMappings = useAppStore((state) => state.readMapping.mappings)

	const { data: apiNodeColumns } = useApi(
		getApiNodeColumnFromAllStructures(Number(node.parentStructureId)),
	)

	const rowSetCode = node.id
		? readMappings[node.id]?.form.rowsetCode
		: undefined

	const parentNode = node.parentStructureId
		? apiNodes[node.parentStructureId]
		: undefined

	const items = data.form.columns || []

	const allMappingColumns = getColumnMappingsOptions(
		getFilteredColumns(parentNode, apiNodeColumns),
		rowSetCode,
	).map<ApiNodeColumn>((column) => ({
		id: -1,
		order: 0,
		name: column.columnName,
		code: column.columnCode,
		mandatory: false,
	}))

	const availableColumns = allMappingColumns?.filter(
		(column) => !items?.find((item) => item?.columnCode == column.code),
	)

	const properties = useColumnsProperties({
		apiNodeColumns: allMappingColumns,
		availableColumns,
	})

	useEffect(() => {
		if (!parentNode && node.parentStructureId) {
			dispatch(initApiNode({ nodeId: node.parentStructureId }))
		}
	}, [dispatch, node.parentStructureId, parentNode])

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ReadMappingDataForm>) => {
			if (v.columns) {
				Object.values(v.columns).forEach((c) => {
					if (c.columnCode) {
						//FIXME - Is this the right way to check the column from API? How to make sure it is actually loaded?
						const x = getColumnMappingsOptions(
							getFilteredColumns(parentNode, apiNodeColumns),
							rowSetCode,
						).find((col) => col.columnCode == c.columnCode)

						//FIXME - what is the best way to cast these 2 enums?
						if (x?.sourceType === ApiNodeColumnDto.SourceTypeEnum.API_NODE) {
							c.columnSourceType =
								ReadMappingColumn.ReadMappingColumnSourceType.API_NODE
						} else {
							c.columnSourceType =
								ReadMappingColumn.ReadMappingColumnSourceType.ROW_SET
						}

						c.columnSourceColumnUuid = x?.columnUuid
						c.columnSourceStructureId = x?.sourceStructureId
						c.columnSourceStructureCode = x?.sourceStructureCode
					}
				})
			}

			onChange(v)
		},
		[apiNodeColumns, onChange, parentNode, rowSetCode],
	)

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			isRowOrderable
			createEmpty={createEmptyReadMappingColumn}
			itemsKey="columns"
			idCounterKey="columnsLastId"
			onChange={handleChange}
			properties={properties}
			tabKey="column"
			existsKey="columnCode"
		/>
	)
}
