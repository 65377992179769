import React, { useCallback } from 'react'
import { CodeInputFormless } from '@/components/CodeInput/CodeInput'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { FormValue } from '@/types'

type CodeFieldComponentProps = {
	name: string
	value: string | undefined
	title: string
	nameValue: string | undefined
	onChange: (value: FormValue, field: string) => void
}

const CodeFieldComponent = ({
	name,
	value,
	title,
	onChange,
}: CodeFieldComponentProps) => {
	const handleChange = useCallback(
		(value: string) => {
			onChange(value, name)
		},
		[onChange, name],
	)

	return (
		<FormlessFormField name={name} title={title}>
			<CodeInputFormless value={value} onChange={handleChange} />
		</FormlessFormField>
	)
}

export const CodeField = React.memo(CodeFieldComponent)
