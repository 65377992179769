import React, { useState } from 'react'
import styled from 'styled-components'
import { faTrash, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppStore, useAppContext } from '@/hooks'
import { Button } from '@/components'
import { deleteNode, deselectNodes } from '@/store/modules/node/actions'
import { Modal } from '@/components/Modal/Modal'
import { StructureObjectDto } from '@/endpoints/models'

type Props = {
	onClose: (removed: boolean) => void
}

export const DeleteNodesModal = ({ onClose }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [loading, setLoading] = useState(false)
	const selectedNodes = useAppStore((state) => state.node.selectedNodes)
	const { nodes, children } = useAppStore((state) => state.node)

	const handleYesClick = async () => {
		setLoading(true)

		// delete only parent nodes (children are deleted automatically)
		const nodesParents = selectedNodes.filter((nodeId) => {
			if (
				selectedNodes.some(
					(selectedId) => children[selectedId]?.includes(nodeId),
				)
			) {
				return false
			}

			return true
		})

		const deleteRequests = nodesParents.map((nodeId) =>
			dispatch(deleteNode(nodes[nodeId] as StructureObjectDto)),
		)

		dispatch(deselectNodes(selectedNodes))
		await Promise.allSettled(deleteRequests)

		setLoading(false)
		onClose(true)
	}

	return (
		<Modal
			open={true}
			contentStyle={{
				width: '400px',
			}}
			header={t('DELETE_SELECTED_ELEMENTS')}
			footer={(close) => (
				<>
					<Button
						icon={faTrash}
						schema="danger"
						onClick={handleYesClick}
						isLoading={loading}
					>
						{t('DELETE')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('NO')}
					</Button>
				</>
			)}
			onClose={() => onClose(false)}
		>
			<PopupText>
				<span>
					{t('NUMBER_OF_SELECTED_ELEMENTS')}: {selectedNodes.length}
				</span>
			</PopupText>
		</Modal>
	)
}

const PopupText = styled.div`
	display: flex;
	align-items: center;
`
