import { useRef, useEffect, useCallback } from 'react'

/**
 * Attaches event callback to target when mounting and de attaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useEvent = <E extends Event>(
	target: EventTarget,
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => {
	// Hold reference to callback
	const callbackRef = useRef(callback)
	callbackRef.current = callback

	// Since we use ref, .current will always be correct callback
	const listener = useCallback((e: any) => {
		if (callbackRef.current) {
			callbackRef.current(e as E)
		}
	}, [])

	useEffect(() => {
		// Add our listener on mount
		target.addEventListener(event, listener, cancelBubble)

		// Remove it on dismount
		return () => target.removeEventListener(event, listener)
	}, [])
}

/**
 * Attaches event callback to document when mounting  and de attaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useDocumentEvent = <E extends Event>(
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => useEvent(document, event, callback, cancelBubble)

/**
 * Attaches event callback to window when mounting and de attaches it when unmounting.
 * @param target
 * @param event
 * @param callback
 * @param cancelBubble
 */
export const useWindowEvent = <E extends Event>(
	event: string,
	callback: (e: E) => void,
	cancelBubble = false
) => useEvent(window, event, callback, cancelBubble)
