import { lockNode } from '@/store/modules/node/actions'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { useCallback, useEffect, useRef } from 'react'
import { useBatchEditContext } from '../context/BatchEditContext'
import { ActionTypeEnum } from '../context/types'

/** Lock all nodes for editing  */
export const useLocking = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const loggedUserId = useAppStore((state) => state.auth.user?.id)
	const refLocked = useRef(false)

	const {
		state: { selectedStructures, locking, errors },
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const lockNodes = useCallback(async () => {
		const locks = selectedStructures.map((node) => {
			if (node.lockTime && node.lockUserId !== loggedUserId) {
				dispatchContext({
					type: ActionTypeEnum.SET,
					state: {
						errors: {
							...errors,
							[node.id]: `${t('ELEMENT')} ${node.name} ${t('LOCKED_BY_USER')}`,
						},
					},
				})
			}

			if (node.lockTime) {
				return new Promise((resolve) => resolve(undefined))
			}

			return dispatch(lockNode(node))
		})

		await Promise.allSettled(locks)
		refLocked.current = true
		dispatchContext({ type: ActionTypeEnum.SET, state: { locking: false } })
	}, [dispatch, dispatchContext, errors, loggedUserId, selectedStructures, t])

	useEffect(() => {
		if (locking || refLocked.current) {
			return
		}

		dispatchContext({ type: ActionTypeEnum.SET, state: { locking: true } })

		lockNodes()
	}, [dispatch, dispatchContext, lockNodes, locking, selectedStructures])
}
