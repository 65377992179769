import { useAppContext } from '@/hooks'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { useFields } from './useFields'

export const useStepErrors = () => {
	const { t } = useAppContext()

	const {
		state: { step, mapping },
	} = useCsvImporterContext()

	const fields = useFields()

	const errors = useMemo(() => {
		const stepFields = fields[step as keyof typeof fields]

		if (!stepFields) {
			return undefined
		}

		const errors = stepFields
			.filter((field) => field.required)
			.map((field) =>
				!isNil(mapping[field.name])
					? undefined
					: `${field.label}: ${t('FIELD_IS_NOT_MATCHED')}`,
			)
			.filter((v) => v) as string[]

		if (errors.length === 0) {
			return undefined
		}

		return errors
	}, [step, fields, mapping])

	return errors
}
