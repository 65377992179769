import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { SystemUsers } from '@/endpoints/schemas'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const SystemUsersProperties = memoizeOne(
	(t: TranslationHelper): TableColumnProps<SystemUsers>[] => [
		{
			field: 'name',
			label: t('FIELD_USERNAME'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true,
			autoWidth: true,
		},
		{
			field: 'comment',
			label: t('FIELD_COMMENT'),
			type: ItemPropertyType.TEXT,
			showWhenPanelOpened: true,
			width: 150,
		},
		{
			field: 'description',
			label: t('FIELD_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			width: 200,
		},
	],
)
