import { StructureObjectDto } from '@/endpoints/models'

export const isEditableNodeType = (type: StructureObjectDto.TypeEnum) =>
	[
		StructureObjectDto.TypeEnum.TABLE,
		StructureObjectDto.TypeEnum.VIEW,
		StructureObjectDto.TypeEnum.MAPPING,
		StructureObjectDto.TypeEnum.WORKFLOW,
		StructureObjectDto.TypeEnum.SYSTEM,
		StructureObjectDto.TypeEnum.API_COLLECTION,
		StructureObjectDto.TypeEnum.API_NODE,
		StructureObjectDto.TypeEnum.MAPPING_READ,
		StructureObjectDto.TypeEnum.QUEUE,
	].includes(type)

export const hasNodePermissions = (type: StructureObjectDto.TypeEnum) =>
	[
		StructureObjectDto.TypeEnum.FOLDER,
		StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
		StructureObjectDto.TypeEnum.SYSTEM,
	].includes(type)

export const isImportableNodeType = (type: StructureObjectDto.TypeEnum) =>
	[
		StructureObjectDto.TypeEnum.ROOT,
		StructureObjectDto.TypeEnum.SYSTEM,
		StructureObjectDto.TypeEnum.FOLDER,
		StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
		StructureObjectDto.TypeEnum.TABLE,
		StructureObjectDto.TypeEnum.API_NODE,
		StructureObjectDto.TypeEnum.QUEUE,
	].includes(type)

export const isDublicableNodeType = (type: StructureObjectDto.TypeEnum) =>
	[
		StructureObjectDto.TypeEnum.SYSTEM,
		StructureObjectDto.TypeEnum.FOLDER,
		StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
		StructureObjectDto.TypeEnum.TABLE,
		StructureObjectDto.TypeEnum.VIEW,
		StructureObjectDto.TypeEnum.API_NODE,
		StructureObjectDto.TypeEnum.API_COLLECTION,
		StructureObjectDto.TypeEnum.MAPPING,
		StructureObjectDto.TypeEnum.MAPPING_DEFAULT,
		StructureObjectDto.TypeEnum.MAPPING_READ,
		StructureObjectDto.TypeEnum.QUEUE,
	].includes(type)

export const isNestableNodeType = (type: StructureObjectDto.TypeEnum) =>
	[
		StructureObjectDto.TypeEnum.SYSTEM,
		StructureObjectDto.TypeEnum.FOLDER,
		StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
	].includes(type)
