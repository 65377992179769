import * as React from 'react'
import { ConfirmationDialog } from './ConfirmationDialog'
import { ConfirmationOptions } from '../types'
import { useCallback } from 'react'

const ConfirmationServiceContext = React.createContext<
	(options: ConfirmationOptions) => Promise<ConfirmationOptions | null>
>(Promise.reject)

export const useConfirmation = () =>
	React.useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const [confirmationState, setConfirmationState] =
		React.useState<ConfirmationOptions | null>(null)

	const awaitingPromiseRef = React.useRef<{
		resolve: (options: ConfirmationOptions | null) => void
		reject: () => void
	}>()

	const openConfirmation = useCallback((options: ConfirmationOptions) => {
		setConfirmationState(options)

		return new Promise<ConfirmationOptions | null>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [])

	const handleClose = () => {
		if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject()
		}

		setConfirmationState(null)
	}

	const handleSubmit = (cancel?: boolean) => {
		if (awaitingPromiseRef.current) {
			const options = confirmationState
				? { ...confirmationState, cancel }
				: null

			awaitingPromiseRef.current.resolve(options)
		}

		setConfirmationState(null)
	}

	return (
		<>
			<ConfirmationServiceContext.Provider value={openConfirmation}>
				{children}
			</ConfirmationServiceContext.Provider>

			<ConfirmationDialog
				open={Boolean(confirmationState)}
				onSubmit={handleSubmit}
				onClose={handleClose}
				title={confirmationState?.title || ''}
				description={confirmationState?.description || ''}
			/>
		</>
	)
}
