import { Item } from '@/components/Properties/context/types'
import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { RowExpander } from './RowExpander'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const useDpStructureTableColumns = (
	envCode: string | undefined,
): TableColumnProps<Item>[] => {
	const { t } = useAppContext()

	return useMemo(() => {
		return [
			{
				field: 'expander',
				label: 'Expand',
				cell: ({ row }) => {
					return <RowExpander row={row} envCode={envCode} />
				},
				type: ItemPropertyType.CUSTOM_JSX,
				width: 50,
			},
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				autoWidth: true,
				disabled: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				autoWidth: true,
				disabled: true,
				canGenerateCodeFieldName: true,
			},
			{
				field: 'type',
				label: 'Type',
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'fullPath',
				label: 'Path',
				type: ItemPropertyType.TEXT,
				width: 250,
				disabled: true,
			},
		]
	}, [envCode, t])
}
