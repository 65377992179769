/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Tree } from '@/components'
import {
	faLock,
	faUsers,
	faCog,
	faFont,
	faMicrochip,
	faPuzzlePiece,
	faTrademark,
	faMoneyBillWave,
	faIndustry,
	faColumns,
	faBox,
	faBorderStyle,
	faImage,
	faSubscript,
	faWrench,
	faList,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { SettingsItem } from '../types'
import { useAuthContext, useAppContext } from '@/hooks'
import { TreeNode } from '@/components/Tree/types'

type Props = {
	selected: SettingsItem | null
	onSelected: (item: SettingsItem) => void
}

interface SettingsNode extends TreeNode {
	key: SettingsItem
}

const icons = {
	[SettingsItem.Security]: faLock,
	[SettingsItem.Users]: faUsers,
	[SettingsItem.Configuration]: faCog,
	[SettingsItem.Technologies]: faMicrochip,
	[SettingsItem.Acronyms]: faFont,
	[SettingsItem.Templates]: faPuzzlePiece,
	[SettingsItem.Naming]: faTrademark,
	[SettingsItem.BusinessDomains]: faMoneyBillWave,
	[SettingsItem.Domains]: faIndustry,
	[SettingsItem.TechnicalColumns]: faColumns,
	[SettingsItem.ObjectSettings]: faBox,
	[SettingsItem.StereoTypes]: faBorderStyle,
	[SettingsItem.CustomAttributes]: faWrench,
	[SettingsItem.CustomLovs]: faList,
	[SettingsItem.UserInterface]: faImage,
	[SettingsItem.ScriptEditor]: faSubscript,
} as const

const colors = {
	[SettingsItem.Security]: '#B4B80A',
	[SettingsItem.Users]: '#3387EE',
	[SettingsItem.Configuration]: '#C0C0C0',
	[SettingsItem.Technologies]: '#C0C0C0',
	[SettingsItem.Acronyms]: '#000',
	[SettingsItem.Templates]: '#000',
	[SettingsItem.Naming]: '#000',
	[SettingsItem.BusinessDomains]: '#8b9a80',
	[SettingsItem.Domains]: '#dfb69d',
	[SettingsItem.TechnicalColumns]: '#000',
	[SettingsItem.ObjectSettings]: '#602655',
	[SettingsItem.StereoTypes]: '#000',
	[SettingsItem.CustomAttributes]: '#000',
	[SettingsItem.CustomLovs]: '#4102b6',
	[SettingsItem.UserInterface]: '#29852e',
	[SettingsItem.ScriptEditor]: '#63bed4',
} as const

const IconComponent = (item: SettingsNode) =>
	item.key in icons && (
		<FontAwesomeIcon
			icon={icons[item.key]}
			color={colors[item.key]}
			fixedWidth
		/>
	)

export const TreeView = ({ onSelected, selected }: Props) => {
	const [expanded, setExpanded] = useState([
		SettingsItem.Security,
		SettingsItem.Configuration,
		SettingsItem.UserInterface,
	] as SettingsItem[])

	const auth = useAuthContext()

	const { t } = useAppContext()

	return (
		<Container>
			<TitleText>{t('SETTINGS_TITLE')}</TitleText>
			<Tree<SettingsNode>
				roots={[
					{
						key: SettingsItem.Security,
						title: 'Security',
						children: [
							...(auth.canManageUsers()
								? [
										{
											key: SettingsItem.Users,
											title: t('USERS'),
										},
									]
								: []),
						],
					},
					{
						key: SettingsItem.Configuration,
						title: 'Configuration',
						children: [
							...(auth.canManageLOVs()
								? [
										{
											key: SettingsItem.Acronyms,
											title: t('ACRONYMS'),
										},
										{
											key: SettingsItem.Technologies,
											title: t('TECHNOLOGIES'),
										},
										{
											key: SettingsItem.Templates,
											title: t('TEMPLATES'),
										},
										{
											key: SettingsItem.Naming,
											title: t('NAMING'),
										},
										{
											key: SettingsItem.BusinessDomains,
											title: t('BUSINESS_DOMAINS'),
										},
										{
											key: SettingsItem.Domains,
											title: t('DOMAINS'),
										},
										{
											key: SettingsItem.TechnicalColumns,
											title: t('TECHNICAL_COLUMNS_TITLE'),
										},
										{
											key: SettingsItem.ObjectSettings,
											title: t('OBJECT_SETTINGS'),
										},
										{
											key: SettingsItem.StereoTypes,
											title: t('STEREOTYPES'),
										},
										{
											key: SettingsItem.CustomAttributes,
											title: t('CUSTOM_ATTRIBUTES'),
										},
										{
											key: SettingsItem.CustomLovs,
											title: t('CUSTOM_LOV'),
										},
									]
								: []),
						],
					},
					{
						key: SettingsItem.UserInterface,
						title: 'User interface',
						children: [
							{
								key: SettingsItem.ScriptEditor,
								title: t('SCRIPT_EDITOR'),
							},
						],
					},
				]}
				onItemExpand={(item, expand) => {
					if (expand) {
						setExpanded([...expanded, item.key])
					} else {
						setExpanded(expanded.filter((key) => key !== item.key))
					}
				}}
				onItemClick={(item) => onSelected(item.key)}
				expanded={expanded}
				highlighted={selected}
				iconComponent={IconComponent}
			/>
		</Container>
	)
}

const Container = styled.div`
	flex-grow: 1;
	background-color: ${(props) =>
		props.theme.colors.layout.treePanel.backgroundColor};
`

const TitleText = styled.div`
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 8px 12px;
	border-bottom: 1px solid #ddd;
	background: ${(props) =>
		props.theme.colors.layout.treePanel.headerBackgroundColor};
`
