import React, { useCallback, useState } from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button } from '@/components'
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { UploadFile } from '../../UploadFile'
import { useImport } from '../hooks/useImport'
import CsvImporter from '@/pages/User/pages/Home/components/TreePanel/components/CsvImporter/CsvImporter'
import { CsvOnMapped } from './CsvImporter/context/types'
import styled from 'styled-components'
import { useScrollbarWidth } from '@/hooks/useScrollbarWidth'

type ImportStructureModalProps = {
	onClose: () => void
	node?: StructureDto
	title?: string
}

export const ImportStructureModal = ({
	node,
	onClose,
	title,
}: ImportStructureModalProps) => {
	const { t } = useAppContext()

	const {
		loading,
		handleFile,
		handleImport,
		structure,
		csvData,
		setCsvRows,
		setImportProps,
		file,
		setFile,
	} = useImport(onClose, node?.id)

	const [csvMapped, setCsvMapped] = useState<boolean>(false)

	const onMapped = useCallback<CsvOnMapped>(
		(data, importStructureProps, csvMapped) => {
			setCsvMapped(csvMapped ?? false)
			setImportProps(importStructureProps)
			setCsvRows(data)
		},
		[setCsvRows, setImportProps],
	)

	const scrollbarWidth = useScrollbarWidth()

	return (
		<Modal
			open={true}
			onClose={onClose}
			resizable={true}
			maximizeButtonVisible
			contentStyle={{ width: 565, height: 900 }}
			header={title ? title : t('IMPORT_STRUCTURE')}
			bodyStyle={{
				paddingBottom: '55px',
			}}
			footerStyle={{
				position: 'absolute',
				bottom: 0,
				width: `calc(100% - ${scrollbarWidth}px)`,
				boxSizing: 'border-box',
				background: '#fff',
			}}
			footer={
				<>
					<Button
						schema="primary"
						icon={faUpload}
						disabled={
							loading || (!structure && !csvData) || (!!csvData && !csvMapped)
						}
						isLoading={loading}
						onClick={handleImport}
					>
						{t('IMPORT_BUTTON')}
					</Button>
					<Button schema="transparent" onClick={onClose} icon={faTimes}>
						{t('CANCEL')}
					</Button>
				</>
			}
		>
			<UploadFile
				file={file}
				handleFile={handleFile}
				loading={loading}
				onRemoveFile={() => {
					setFile(null)
					onMapped()
				}}
			/>
			{csvData && node?.type !== StructureObjectDto.TypeEnum.FOLDER && (
				<ErrorText>{t('CSV_CAN_BE_IMPORTED_ONLY_IN_FOLDER')}</ErrorText>
			)}
			{csvData && node && node?.type === StructureObjectDto.TypeEnum.FOLDER && (
				<CsvImporter
					nodeId={node?.id}
					inputData={csvData}
					onMapped={onMapped}
				/>
			)}
		</Modal>
	)
}

const ErrorText = styled.div`
	color: ${(props) => props.theme.colors.danger.base};
	text-align: center;
`
