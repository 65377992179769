import { useEffect } from 'react'

const hasAnyParentClass = (element: Element, className?: string): boolean => {
	if (!className) {
		return false
	}

	if (
		element.className &&
		element.className.split(' ').indexOf(className) >= 0
	) {
		return true
	}

	return element.parentNode
		? hasAnyParentClass(element.parentNode as Element, className)
		: false
}

/**
 * Call function when user clicks outside HTML element
 * @param ref useRef
 * @param onClickOutside call when user clicks outside ref
 * @param classNameToOmit if element or any of his parents have this className, do not call onClickOutside
 */
export const useClickOutside = (
	onClickOutside: () => void,
	element?: HTMLElement | null,
	classNamesToOmit?: string[]
) => {
	const handleClickOutside = (event: MouseEvent) => {
		if (
			(element &&
				!element.contains(event.target as Element) &&
				classNamesToOmit?.every(
					classNameToOmit =>
						!hasAnyParentClass(event.target as Element, classNameToOmit)
				)) ??
			true
		) {
			onClickOutside()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	})
}
