import { createReleaseEnvironment, getNodeDetail } from '@/endpoints'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	ReleaseDto,
	ReleaseEnvironmentDto,
	StructureDto,
} from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import { ModalForm } from '@/components/Modal/ModalForm'
import { SelectFormField } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import styled from 'styled-components'

type AddEnvironmentProps = {
	node: StructureDto
	deploymentPackage: ReleaseDto
	envs: ReleaseEnvironmentDto[]
	onClose: () => void
}

type FormData = {
	envIds: string[]
}

export const AddEnvironment = ({
	node,
	envs,
	deploymentPackage,
	onClose,
}: AddEnvironmentProps) => {
	const { t } = useAppContext()

	const { data: systemDetail } = useApi(getNodeDetail(node.id))

	const envsOptions = useMemo(() => {
		if (!systemDetail?.data) {
			return []
		}

		const pushedData = JSON.parse(systemDetail.data) as SystemData

		const envsFiltered = pushedData.environments.filter(
			(systemEnv) => !envs.some((env) => env.id === systemEnv.id),
		)

		return envsFiltered
	}, [envs, systemDetail])

	const request = useApiRequest()

	const handleSubmit = async (form: FormData) => {
		for (const id of form.envIds) {
			const env = {
				id: parseFloat(id),
			}

			await request(
				createReleaseEnvironment(node.id, deploymentPackage.id!, env),
			)
		}

		onClose()
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('ADD')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			initialValues={{
				envIds: [],
			}}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px',
			}}
			header={t('ADD_ENVIRONMENTS')}
		>
			<Warning>{t('ONLY_PUSHED_ENVIRONMENTS_CAN_BE_ADDED')}</Warning>
			<SelectFormField
				name="envIds"
				title={t('ENVIRONMENTS')}
				options={envsOptions}
				valueKey="id"
				labelKey="name"
				clearable
				isMulti
				required
				placeholder=""
			/>
		</ModalForm>
	)
}

const Warning = styled.div`
	margin-bottom: 10px;
`
