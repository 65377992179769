import React, {
	useContext,
	useReducer,
	createContext,
	useMemo,
	useEffect,
	useCallback,
	useRef,
	Reducer,
} from 'react'
import {
	ActionTypeEnum,
	TablePropertiesContextState,
	TablePropertiesContextType,
	Actions,
	TablePropertiesContextProps,
} from './types'
import { reducer } from './reducer'
import { MoveDir } from '../types'
import { useDebounceCallback } from '@/hooks'
import { valueOrLabelGet } from '../utils'
import { ItemPropertyType } from '@/enums'

const TablePropertiesContext = createContext<
	TablePropertiesContextType | undefined
>(undefined)

export const useTablePropertiesContext = () =>
	useContext(TablePropertiesContext) as TablePropertiesContextType

const getCanvasContext = () =>
	document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D

export const TablePropertiesContextProvider = ({
	children,
	items,
	properties,
	readonly,
	onChange,
	onSelect,
	onDelete,
	isDeletable,
	isReadonly,
	isRowOrderable,
	onRowOrderChanged,
	errors,
	selectedItemIndex,
	propertiesHidden,
	isLastRowOrderable,
	columnWidthsParent,
}: TablePropertiesContextProps) => {
	const refContainer = useRef<HTMLDivElement>(null)

	const [
		{
			focused,
			dirLast,
			columnWidths,
			draggingRowIndex,
			dragOverRowIndex,
			...state
		},
		dispatch,
	] = useReducer<Reducer<TablePropertiesContextState, Actions>>(reducer, {
		focused: null,
		dirLast: null,
		columnWidths: {} as Record<string, number>,
		draggingRowIndex: null,
		dragOverRowIndex: null,
		handleMove: () => undefined,
		handleFocus: () => undefined,
		handleRowDrop: () => undefined,
		onDragEnter: () => undefined,
		resolveIsRowOrderable: () => ({ enabled: false }),
		tableProps: [],
		items,
		properties,
		readonly,
		onChange,
		onDelete,
		isDeletable,
		isReadonly,
		isRowOrderable,
		onRowOrderChanged,
		errors,
		selectedItemIndex,
		propertiesHidden,
		isLastRowOrderable,
		refContainer,
		scrollbarWidth: null,
	})

	useEffect(() => {
		dispatch({
			type: ActionTypeEnum.SYNC_CONTEXT_AND_PROPS,
			payload: {
				items,
				properties,
				readonly,
				onChange,
				onSelect,
				onDelete,
				isDeletable,
				isReadonly,
				isRowOrderable,
				onRowOrderChanged,
				errors,
				selectedItemIndex,
				propertiesHidden,
				isLastRowOrderable,
			},
		})
	}, [
		items,
		properties,
		readonly,
		onChange,
		onSelect,
		onDelete,
		isDeletable,
		isReadonly,
		isRowOrderable,
		onRowOrderChanged,
		errors,
		selectedItemIndex,
		propertiesHidden,
		isLastRowOrderable,
	])

	const tableProps = useMemo(
		() =>
			properties.filter(
				(prop) =>
					!(
						prop.hideInTable ||
						(!propertiesHidden && !prop.showWhenPanelOpened)
					),
			),
		[properties, propertiesHidden],
	)

	const getFontStyles = () => {
		if (!refContainer.current) {
			return ' '
		}

		const styles = getComputedStyle(refContainer.current)

		return [
			styles.getPropertyValue('font-weight'),
			styles.getPropertyValue('font-style'),
			styles.getPropertyValue('font-size'),
			styles.getPropertyValue('font-family'),
		].join(' ')
	}

	const updateColumnWidths = () => {
		const ctx = getCanvasContext()
		ctx.font = getFontStyles()
		const columns = tableProps.filter((prop) => prop.autoWidth)

		if (columns.length > 0) {
			const widths = columns.reduce(
				(acc, column) => {
					if (column.field) {
						if (columnWidthsParent?.[column.field]) {
							acc[column.field] = columnWidthsParent[column.field]

							return acc
						}

						const maxWidth = items.reduce(
							(acc, item) => {
								const width =
									ctx.measureText(valueOrLabelGet(column, item) as string)
										.width + (column.type === ItemPropertyType.OPTION ? 30 : 15)

								return acc === undefined || width > acc ? width : acc
							},
							undefined as number | undefined,
						)

						if (maxWidth) {
							acc[column.field as string] = maxWidth
						}
					}

					return acc
				},
				{} as Record<string, number>,
			)

			dispatch({
				type: ActionTypeEnum.COLUMNS_WIDTHS_SET,
				payload: {
					columnWidths: widths,
				},
			})
		}
	}

	useEffect(() => {
		updateColumnWidths()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, columnWidthsParent])

	const handleMove = useCallback(
		(dir: MoveDir) => {
			dispatch({
				type: ActionTypeEnum.DIR_LAST_SET,
				payload: {
					dirLast: dir,
				},
			})

			if (!focused) {
				dispatch({
					type: ActionTypeEnum.FOCUSED_SET,
					payload: {
						focused: {
							row: 0,
							field: 0,
						},
					},
				})

				return
			}

			const { row, field } = focused
			let rowUpdated,
				fieldUpdated = 0

			switch (dir) {
				case MoveDir.Up: {
					rowUpdated = row > 0 ? row - 1 : items.length - 1
					fieldUpdated = field
					break
				}

				case MoveDir.Down: {
					rowUpdated = row < items.length - 1 ? row + 1 : 0
					fieldUpdated = field

					break
				}

				case MoveDir.Left: {
					rowUpdated = row > 0 && field === 0 ? row - 1 : row
					fieldUpdated = field > 0 ? field - 1 : tableProps.length - 1
					break
				}

				case MoveDir.Right: {
					const nextRow =
						row < items.length - 1 && field === tableProps.length - 1

					const nextField = field < tableProps.length - 1

					rowUpdated = nextRow ? row + 1 : row

					fieldUpdated = nextField ? field + 1 : 0
					break
				}
			}

			onSelect && onSelect(rowUpdated)

			dispatch({
				type: ActionTypeEnum.FOCUSED_SET,
				payload: {
					focused: {
						row: rowUpdated,
						field: fieldUpdated,
					},
				},
			})
		},
		[focused, onSelect, items.length, tableProps.length],
	)

	useEffect(() => {
		// skip disabled cells in table with arrow controls
		if (focused && dirLast) {
			const { disabled, hideInTable } = properties[focused.field]

			if (
				!hideInTable &&
				((typeof disabled === 'boolean' && disabled) ||
					(disabled && disabled(items[focused.row], items)))
			) {
				handleMove(dirLast)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focused, dirLast])

	const handleFocus = useCallback(
		(rowUpdate: number, fieldUpdate: number) => {
			if (focused) {
				// set MoveDir to left if Shift + Tab is used
				const { field, row } = focused
				let dirLast = MoveDir.Right

				if (fieldUpdate < field && rowUpdate === row) {
					dirLast = MoveDir.Left
				} else if (fieldUpdate > field && rowUpdate < row) {
					dirLast = MoveDir.Left
				}

				dispatch({
					type: ActionTypeEnum.DIR_LAST_SET,
					payload: {
						dirLast,
					},
				})
			}

			dispatch({
				type: ActionTypeEnum.FOCUSED_SET,
				payload: {
					focused: { row: rowUpdate, field: fieldUpdate },
				},
			})
		},
		[focused],
	)

	const handleRowDrop = useCallback(
		(dropIndex: number) => {
			if (draggingRowIndex === null || draggingRowIndex === undefined) {
				return
			}

			if (onRowOrderChanged) {
				onRowOrderChanged(draggingRowIndex, dropIndex)
			}
		},
		[draggingRowIndex, onRowOrderChanged],
	)

	const resolveIsRowOrderable = useCallback(
		(itemIndex: number) => {
			if (
				!readonly &&
				isRowOrderable &&
				(items.length - 1 > itemIndex || isLastRowOrderable)
			) {
				return {
					up: itemIndex != 0,
					down:
						itemIndex + 1 < items.length &&
						items[itemIndex + 1] &&
						items.length - 2 > itemIndex,
					enabled: true,
				}
			}

			return { enabled: !!isRowOrderable && !readonly }
		},
		[isLastRowOrderable, isRowOrderable, items, readonly],
	)

	const onDragEnter = useDebounceCallback((rowIndex: number) => {
		const isRowOrderable = resolveIsRowOrderable(rowIndex)

		if (
			isRowOrderable.enabled &&
			(isRowOrderable.up || isRowOrderable.down) &&
			dragOverRowIndex !== rowIndex
		) {
			dispatch({
				type: ActionTypeEnum.DRAG_OVER_INDEX_SET,
				payload: { dragOverRowIndex: rowIndex },
			})
		}
	}, 100)

	const context = useMemo<TablePropertiesContextType>(
		() => ({
			state: {
				...state,
				focused,
				dirLast,
				columnWidths,
				draggingRowIndex,
				dragOverRowIndex,
				handleMove,
				handleFocus,
				handleRowDrop,
				resolveIsRowOrderable,
				tableProps,
				items,
				properties,
				readonly,
				onChange,
				onDelete,
				isDeletable,
				isReadonly,
				isRowOrderable,
				onRowOrderChanged,
				selectedItemIndex,
				propertiesHidden,
				isLastRowOrderable,
				onDragEnter,
			},
			dispatch,
		}),
		[
			focused,
			dirLast,
			columnWidths,
			draggingRowIndex,
			dragOverRowIndex,
			handleMove,
			handleFocus,
			handleRowDrop,
			resolveIsRowOrderable,
			tableProps,
			items,
			properties,
			readonly,
			onChange,
			onDelete,
			isDeletable,
			isReadonly,
			isRowOrderable,
			onRowOrderChanged,
			selectedItemIndex,
			propertiesHidden,
			isLastRowOrderable,
			state,
			dispatch,
			onDragEnter,
		],
	)

	TablePropertiesContext.displayName = 'TablePropertiesContext'

	return (
		<TablePropertiesContext.Provider value={context}>
			{children}
		</TablePropertiesContext.Provider>
	)
}
