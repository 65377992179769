import { IdCodeName, QueueData } from '@/endpoints/schemas'
import { ensureValidData } from '../helpers'

export const defaultQueueData = (
	defaultObjectSettings: IdCodeName | undefined = undefined,
): Readonly<QueueData> => ({
	name: '',
	code: '',
	description: '',
	comment: '',
	objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
	script: '',
	preScript: '',
	postScript: '',
})

export const getInitialQueueData = (
	name: string,
	code = '',
	defaultObjectSettings: IdCodeName | undefined = undefined,
) =>
	ensureValidData(
		{
			name,
			code,
			objectSettings: defaultObjectSettings ? [defaultObjectSettings] : [],
		},
		defaultQueueData(defaultObjectSettings),
	)
