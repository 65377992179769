import React from 'react'
import { MessageModal } from '@/components'
import { useAppDispatch, useAppStore } from '@/hooks'
import { setApiError } from '@/store/modules/apiError/actions'
import { Description, Message } from '@/pages/App/styles'

export const ApiErrorMessage = () => {
	const dispatch = useAppDispatch()
	const { title, description, message } = useAppStore((state) => state.apiError)

	return (
		<>
			{title && description && (
				<MessageModal
					opened={true}
					onClose={() =>
						dispatch(
							setApiError({ title: null, description: null, message: null }),
						)
					}
					type="error"
					title={title}
					width={message ? 800 : undefined}
					stickyFooter
					message={
						<>
							<Description>{description}</Description>
							{message && <Message>{message}</Message>}
						</>
					}
				/>
			)}
		</>
	)
}
