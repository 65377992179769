import { DomainDto, StructureDto } from '@/endpoints/models'
import { ApiCollectionData } from '@/endpoints/schemas/api-collection'
import { TableAndProperties } from '@/components'
import { OpenedApiCollectionData } from '@/store/modules/apiCollection/types'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/hooks'
import React, { useCallback, useMemo } from 'react'
import { ColumnProperties } from './Properties'
import { duplication } from './validation'
import { createEmptyColumn } from '@/store/modules/apiCollection/helpers'
import { useDomainTechnology } from '@/utils/domain'

type Props = {
	node: StructureDto
	data: OpenedApiCollectionData
	systemNodeId: number
	editMode: boolean
	onChange: (v: UpdateDeepPartial<ApiCollectionData>) => void
	domains: DomainDto[]
}

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	onChange,
	domains,
}: Props) => {
	const { t } = useAppContext()
	const { getDomainData } = useDomainTechnology(systemNodeId)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ApiCollectionData>) => {
			if (v.columns) {
				Object.values(v.columns).forEach((c) => {
					if (c.domainId) {
						const d = domains.find((d) => d.id === c.domainId)
						const domainData = getDomainData(d)

						if (domainData) {
							c.dataType = domainData.dataType
							c.defaultValue = domainData.defaultValue
						} else {
							c.dataType = undefined
						}
					}
				})
			}

			onChange(v)
		},
		[domains, onChange, getDomainData],
	)

	const errors = useMemo(
		() => duplication(data.form.columns, t),
		[data.form.columns, t],
	)

	const properties = ColumnProperties(t, domains)

	return (
		<>
			<TableAndProperties
				node={node}
				data={data.form}
				createEmpty={createEmptyColumn}
				itemsKey={'columns'}
				idCounterKey={'columnsLastId'}
				onChange={handleChange}
				properties={properties}
				tabKey="column"
				errors={errors}
			/>
		</>
	)
}

export const Columns = React.memo(ColumnsComponent)
