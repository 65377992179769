import { ImportReturnMessage } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import React, { useCallback, useMemo } from 'react'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { ActionTypeEnum } from '../context/types'
import { getDuplicates } from '../utils/getDuplicates'
import { CheckboxCompact } from './useCsvImporterColumns'
import { LightTableColumn } from '@/types'

export type CheckDuplicateTable = ImportReturnMessage & { index: number }

export const useCheckDuplicateTables = () => {
	const { t } = useAppContext()

	const {
		state: { checkResult, createAnywayTableNames, onMapped },
		dispatch,
	} = useCsvImporterContext()

	const duplicates = useMemo(() => getDuplicates(checkResult), [checkResult])

	const isAllDuplicatesChecked = useMemo(
		() => duplicates?.length === createAnywayTableNames.length,
		[duplicates, createAnywayTableNames],
	)

	const duplicateTablesToggleAll = useCallback(() => {
		if (createAnywayTableNames.length > 0) {
			dispatch({
				type: ActionTypeEnum.CREATE_ANYWAY_TABLE_NAMES_SET,
				createAnywayTableNames: [],
			})

			onMapped

			return
		}

		dispatch({
			type: ActionTypeEnum.CREATE_ANYWAY_TABLE_NAMES_SET,
			createAnywayTableNames: duplicates ?? [],
		})
	}, [createAnywayTableNames, duplicates])

	const duplicateTablesData = useMemo<CheckDuplicateTable[]>(() => {
		return (
			checkResult?.warnings
				?.filter((warning) =>
					[
						ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_FOLDER,
						ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_SYSTEM,
					].includes(
						(warning.messageType as ImportReturnMessage.MessageTypeEnum) ?? '',
					),
				)
				.map((m, i) => ({ ...m, index: i })) ?? []
		)
	}, [checkResult])

	const duplicateTablesColumns = useMemo<
		LightTableColumn<CheckDuplicateTable>[]
	>(() => {
		return [
			{
				field: null,
				title: t('TABLE_NAME'),
				width: 50,
				formatter: (_, row) => row.object,
			},
			{
				field: null,
				title: t('CREATE_ANYWAY'),
				maxWidth: 100,
				width: 40,
				tdStyle: { justifyContent: 'center' },
				// eslint-disable-next-line react/display-name
				formatter: (_, row) => (
					<CheckboxCompact
						name="default"
						value={createAnywayTableNames.includes(row.object as string)}
						onChange={() =>
							dispatch({
								type: ActionTypeEnum.CREATE_ANYWAY_TABLE_NAMES_TOGGLE,
								createAnywayTableName: row.object as string,
							})
						}
					/>
				),
			},
		]
	}, [createAnywayTableNames])

	return {
		duplicateTablesColumns,
		duplicateTablesData,
		duplicateTablesToggleAll,
		isAllDuplicatesChecked,
		duplicates,
	}
}
