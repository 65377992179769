import Keycloak from 'keycloak-js'
import { appConfig } from '@/config/appConfig'

const { clientId, realm, authUrl } = appConfig

const keycloak = new Keycloak({
	url: authUrl,
	realm,
	clientId,
})

export default keycloak
