import { StructureObjectDto, UserDto } from '@/endpoints/models'

export const isLocked = (node: StructureObjectDto) => !!node.lockUserId

export const isLockedByMe = (node: StructureObjectDto, user: UserDto | null) =>
	!!user && user.id === node.lockUserId

export const isLockedByOther = (
	node: StructureObjectDto,
	user: UserDto | null,
) => isLocked(node) && !isLockedByMe(node, user)

export const isEditable = (node: StructureObjectDto, user: UserDto | null) =>
	node.canEdit && (!node.lockUserId || isLockedByMe(node, user))
