import { CsvImporterContextState, Actions, ActionTypeEnum } from './types'

export const reducer = (
	state: CsvImporterContextState,
	action: Actions
): CsvImporterContextState => {
	switch (action.type) {
		case ActionTypeEnum.STEP_SET:
			return {
				...state,
				step: action.step
			}
		case ActionTypeEnum.FORM_DATA_CHECK_SET:
			return {
				...state,
				formDataCheck: action.formDataCheck
			}
		case ActionTypeEnum.ROWS_SET:
			return {
				...state,
				rows: action.rows
			}
		case ActionTypeEnum.CHECK_RESULT_SET:
			return {
				...state,
				checkResult: action.checkResult
			}
		case ActionTypeEnum.INPUT_DATA_PARSED_SET:
			return {
				...state,
				inputDataParsed: action.inputDataParsed,
				firstRow: action.inputDataParsed?.data?.[0]
			}
		case ActionTypeEnum.HAS_HEADERS_SET:
			return {
				...state,
				hasHeaders: action.hasHeaders
			}
		case ActionTypeEnum.CSV_MAPPING_UPDATE:
			return {
				...state,
				mapping: {
					...state.mapping,
					...action.mapping
				}
			}

		default:
			return state
	}
}
