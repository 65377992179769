import React, { useEffect, useMemo, useState } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { UserDto, UserRolesDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { CheckBoxFormField } from '@/components/UberForm'
import { useApiRequest } from '@/endpoints/hooks'
import { editUserRoles, getUserRoles } from '@/endpoints'
import { Loader } from '@/components'
import { Modal } from '@/components/Modal/Modal'

type Props = {
	user: UserDto
	userRoles?: UserRolesDto.RolesEnum[]
	onSave: () => void
	onClose: () => void
}

type FormValues = {
	[K in UserRolesDto.RolesEnum]?: boolean
}

export const PermissionsModal = ({ user, onSave, onClose }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const [roles, setRoles] = useState<FormValues>()

	useEffect(() => {
		request(getUserRoles(user.id)).then((value) => {
			if (value == null) {
				return
			}

			setRoles(
				value.data?.roles.reduce(
					(acc, p) => ({
						...acc,
						[p]: true,
					}),
					{},
				),
			)
		})
	}, [])

	const handleSubmit = async (form: FormValues) => {
		const roles = Object.keys(form).filter(
			(k) => form[k as UserRolesDto.RolesEnum],
		) as UserRolesDto.RolesEnum[]

		await request(editUserRoles(user.id, { body: { roles } }))

		onSave()
		onClose()
	}

	const permissions = useMemo(
		() => [
			{
				value: UserRolesDto.RolesEnum.MANAGE_USERS,
				label: t('PERMISSION_MANAGE_USERS'),
			},
			{
				value: UserRolesDto.RolesEnum.MANAGE_LOV,
				label: t('PERMISSION_MANAGE_LOV'),
			},
			{
				value: UserRolesDto.RolesEnum.CREATE_DELETE_SYSTEM,
				label: t('PERMISSION_CREATE_DELETE_SYSTEM'),
			},
			{
				value: UserRolesDto.RolesEnum.FORCE_UNLOCK,
				label: t('PERMISSION_FORCE_UNLOCK'),
			},
		],
		[t],
	)

	if (roles == undefined) {
		return (
			<Modal>
				<Loader loaded={false} />
			</Modal>
		)
	}

	return (
		<ModalForm<FormValues>
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px',
			}}
			initialValues={roles}
			header={t('USER_PERMISSIONS_EDIT')}
			onSubmit={handleSubmit}
		>
			{permissions.map((p) => (
				<CheckBoxFormField key={p.value} name={p.value} title={p.label} />
			))}
		</ModalForm>
	)
}
