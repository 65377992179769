import { Table } from '@/components'
import { DpObjectContent } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/types'
import { useTableColumns } from '@/hooks'
import { useDpsSubRowsTableColumns } from './useDpsSubRowsTableColumns'

interface DpStructureSubRowsTableProps {
	data: DpObjectContent[]
	editMode: boolean
}

export const DpStructureSubRowsTable = ({
	data,
	editMode,
}: DpStructureSubRowsTableProps) => {
	const ENV_TABLE_WIDTH = 1200

	const columns = useTableColumns({
		tableData: [data ?? []],
		properties: useDpsSubRowsTableColumns(),
	})

	return (
		<Table
			columns={columns}
			data={data}
			maxWidth={ENV_TABLE_WIDTH}
			isEditMode={true}
			hasLastRowEdit={false}
			hasLeftAction={false}
			hasDnd={false}
		/>
	)
}
