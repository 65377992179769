import { Actions } from './actions'
import { UPDATE_SETTINGS } from './constants'

export type State = Readonly<typeof initialState>
export type SettingsSession = State

export const getSettingsSession = (state: State) => ({
	...state
})

const initialState = {
	userInterface: {
		scriptEditor: {
			autocompleteKeywords: true,
			autocompleteSnippets: true,
			autocompleteElements: true
		}
	}
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case UPDATE_SETTINGS: {
			return {
				...state,
				...action.payload
			}
		}

		default: {
			return state
		}
	}
}
