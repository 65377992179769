import React from 'react'
import { ValueContainer, ValueOverflow } from './PropertyFieldStyles'

type Props = {
	children: React.ReactNode
	disabled?: boolean
	setRefWrapper?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
	title?: string
}

export const ValueWrapper = ({
	disabled,
	children,
	setRefWrapper,
	title,
}: Props) => {
	return (
		<ValueContainer disabled={disabled} ref={setRefWrapper} title={title}>
			<ValueOverflow>{children}</ValueOverflow>
		</ValueContainer>
	)
}
