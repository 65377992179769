import React from 'react'
import styled from 'styled-components'

import { Tabs, TabProps } from '@/components/Tabs/Tabs'
import {
	ReadMappingTab,
	OpenedReadMappingData,
	ReadMappingDataForm,
} from '@/store/modules/readMapping/types'
import { Overview } from '../pages/Overview/Overview'
import { StructureObjectDto } from '@/endpoints/models'
import { Columns } from '../pages/Columns/Columns'
import { Sources } from '../pages/Sources/Sources'
import { Others } from '../pages/Others/Others'
import { useAppContext } from '@/hooks'
import { Deployments } from '../../../components/Deployments/Deployments'
import { Preview } from '../../../components/Preview/Preview'
import { Validation } from '../../../components/Validation/Validation'
import { UpdateDeepPartial } from '@/store/utils'
import { Dependencies } from '../../../components/Dependencies/Dependencies'

type Props = {
	node: StructureObjectDto
	data: OpenedReadMappingData
	editMode: boolean
	systemNodeId: number
	onTabChange: (tab: TabProps) => void
	onChange: (v: UpdateDeepPartial<ReadMappingDataForm>) => void
}

export const ReadMappingTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	onTabChange,
	onChange,
}: Props) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: ReadMappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={ReadMappingTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
							node={node}
						/>
					),
				},
				{
					id: ReadMappingTab.Columns,
					title: t('TAB_COLUMN_MAPPINGS'),
					content: (
						<Columns
							key={ReadMappingTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: ReadMappingTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={ReadMappingTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: ReadMappingTab.Others,
					title: t('TAB_OTHERS'),
					content: (
						<Others
							key={ReadMappingTab.Others}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: ReadMappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: <Preview key={ReadMappingTab.Preview} node={node} />,
				},
				{
					id: ReadMappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={ReadMappingTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: ReadMappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={ReadMappingTab.Validation} node={node} />,
				},
				{
					id: ReadMappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={ReadMappingTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
