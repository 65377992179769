import { WorkflowData } from '@/endpoints/schemas'

export interface OpenedWorkflowData {
	form: WorkflowDataForm
	original: WorkflowDataForm
	tab: WorkflowTab
	dirty: boolean
	parsedEditMode: boolean
	graph: UserGraphData | null
}

export interface UserGraphData {
	offset?: {
		x: number
		y: number
	}
	zoom?: number
	selected?: string
}

export type WorkflowDataForm = Partial<WorkflowData>

export enum WorkflowTab {
	Overview = 'Overview',
	Graph = 'Graph',
	Deployments = 'Deployments',
	Preview = 'Preview',
	Validation = 'Validation',
	Dependencies = 'Dependencies'
}
