import { getColumnsByDomainCSV } from 'src/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { DomainDto } from '@/endpoints/models'
import FileSaver from 'file-saver'
import { useState, useCallback, useMemo } from 'react'

export const useDownloadImpactCsv = () => {
	const [isDownloading, setIsDownloading] = useState(false)
	const request = useApiRequest()

	const onDownloadCsv = useCallback(
		async (domain: DomainDto) => {
			setIsDownloading(true)

			const csv = await request(getColumnsByDomainCSV(domain?.id || 0))

			const blob = new Blob([new Uint8Array(csv.data as ArrayBuffer)], {
				type: 'application/text',
			})

			FileSaver.saveAs(blob, `${domain.name}-${domain.id}-impact.csv`)
			setIsDownloading(false)
		},
		[request],
	)

	return useMemo(
		() => ({
			isDownloading,
			onDownloadCsv,
		}),
		[isDownloading, onDownloadCsv],
	)
}
