import React, { useCallback, useMemo } from 'react'
import { RelationDto, StructureDto } from '@/endpoints/models'
import { Button, DialogWrapper, LightTable, TabsActionsBar } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Empty } from '@/components/Layout'
import { useAppContext } from '@/hooks'
import { useApi } from '@/endpoints/hooks'
import { getNodeRelations } from '@/endpoints'
import { DependenciesModal } from './components/DependenciesModal'
import { useDependenciesColumns } from './hooks/useDependenciesColumns'
import {
	DependenciesFilter,
	DependenciesFilterValues,
} from './components/DependenciesFilter'
import { useFilter } from '@/pages/User/pages/Settings/hooks/useFilter'
import { useDependenciesFilteredData } from './hooks/useDependenciesFilteredData'
import { enumToLabelCollection } from '@/utils/collections'
import { TypeEnum } from './types'
import { MainContentWrap } from '@/styles'

type Props = {
	node: StructureDto
	editMode: boolean
}

export const Dependencies = ({ node, editMode }: Props) => {
	const { t } = useAppContext()
	const dependencies = useApi(getNodeRelations(node.id))
	const { filter, onChangeFilter } = useFilter<DependenciesFilterValues>()

	const handleSave = useCallback(() => {
		dependencies.invalidate()
	}, [dependencies])

	const statusStates = useMemo(
		() => enumToLabelCollection(RelationDto.StatusEnum, 'RELATION_STATUS', t),
		[t],
	)

	const typeStates = useMemo(
		() => enumToLabelCollection(TypeEnum, 'RELATION_TYPE', t),
		[t],
	)

	const columns = useDependenciesColumns(node, handleSave, editMode, typeStates)

	const dependenciesData = useDependenciesFilteredData(
		filter,
		dependencies.data,
	)

	return (
		<>
			{node.canEdit && (
				<TabsActionsBar>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<DependenciesModal
									onClose={onClose}
									onSave={() => dependencies.invalidate()}
									node={node}
								/>
							)
						}
					>
						{(onClick) => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
								disabled={!editMode}
							>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActionsBar>
			)}
			<MainContentWrap>
				<DependenciesFilter
					onChangeFilter={onChangeFilter}
					statusStates={statusStates}
					typeStates={typeStates}
				/>
				<LightTable<RelationDto>
					data={dependenciesData ?? []}
					columns={columns}
					keyField={'id'}
					isLoading={dependencies.loading}
					sortFunction={(a, b, sort) => {
						const isAsc = sort.order === 'asc'
						const field = sort.field as keyof RelationDto | null
						let result = 0

						if (field) {
							let fieldA = a[field] ?? 0
							let fieldB = b[field] ?? 0

							if (field === 'rightSideStructure') {
								if (a.rightSideStructure?.id === node.id) {
									fieldA = a.leftSideStructure?.name ?? ''
								} else {
									fieldA = a.rightSideStructure?.name ?? ''
								}

								if (b.rightSideStructure?.id === node.id) {
									fieldB = b.leftSideStructure?.name ?? ''
								} else {
									fieldB = b.rightSideStructure?.name ?? ''
								}
							}

							if (fieldA > fieldB) {
								result = isAsc ? 1 : -1
							}

							if (fieldA < fieldB) {
								result = isAsc ? -1 : 1
							}
						}

						return result
					}}
				/>

				{(dependenciesData?.length ?? -1) === 0 && (
					<Empty>{t('NOTHING_HERE')}</Empty>
				)}
			</MainContentWrap>
		</>
	)
}
