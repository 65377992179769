export default {
	weight: {
		medium: 'normal'
	},
	size: {
		small: '85%',
		medium: '100%',
		big: '125%'
	}
}
