import React, { useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { TableColumn, TableConstraintColumn } from '@/endpoints/schemas'
import { useAppContext, useAppDispatch } from '@/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
	AddIcon,
	Box,
	BoxColumn,
	BoxColumnFK,
	BoxFKs,
	ColumnTitle,
	FormInputFK,
	ItemText,
	PrimaryKey,
	SelectFK,
	Wrapper,
} from './styles'
import { ForeignKeyProps } from './types'
import { FormValue } from '@/types'
import { generateCode } from '@/store/utils'
import { getFkSelectOptions } from './utils'
import { isEmpty } from 'lodash'

export const ForeignKey = ({
	value: initialValue,
	name,
	onChange,
	disabled,
	onFocus,
	onBlur,
	fromProps,
	primaryKeys,
	possibleForeignKeys,
	foreignKeyTableId,
	error,
	readonly,
}: ForeignKeyProps) => {
	const dispatch = useAppDispatch()
	const { t } = useAppContext()

	const foreignKeys = useMemo<TableConstraintColumn[]>(
		() => (initialValue ? initialValue : []),
		[initialValue],
	)

	const sortedForeignKeys = [...foreignKeys].sort((a, b) => a.order - b.order)

	useEffect(() => {
		// remove columns for other constraint types (Primary key etc.)
		const valueFiltered =
			sortedForeignKeys.filter((v) => v.foreignColumnUuid) ?? []

		onChange && onChange(valueFiltered, name)
	}, [])

	const [newColumns, setNewColumns] = useState<{
		[primaryKeyUUID: string]: { inProgress: boolean; defaultValue: string }
	}>({})

	const onClear = (pk: TableColumn) => {
		const valueClear = sortedForeignKeys.filter(
			(v: TableConstraintColumn) => v.foreignColumnUuid !== pk.uuid,
		)

		onChange && onChange(valueClear, name)
	}

	const handleFieldChange = (valueSelect: FormValue, pk: TableColumn) => {
		const fk = possibleForeignKeys?.[pk.uuid]?.find(
			(fk) => fk.uuid === valueSelect,
		)

		if (fk) {
			onChange &&
				onChange(
					[
						...sortedForeignKeys.filter((v) => v.foreignColumnUuid !== pk.uuid),
						{
							code: fk.code ?? '',
							uuid: fk.uuid,
							foreignColumnCode: pk.code,
							foreignColumnUuid: pk.uuid,
							order: pk.order,
						},
					],
					name,
				)
		} else {
			onClear(pk)
		}
	}

	const onAddNew = (pk: TableColumn) => {
		setNewColumns((prev) => ({
			...prev,
			[pk.uuid]: {
				inProgress: true,
				defaultValue: pk.name,
			},
		}))
	}

	const onClose = (pk: TableColumn) => {
		setNewColumns((prev) => ({
			...prev,
			[pk.uuid]: {
				inProgress: false,
				defaultValue: pk.name,
			},
		}))
	}

	const createColumn = async (nameColumn: string, pk: TableColumn) => {
		const codeApi = await dispatch(generateCode(nameColumn))

		onChange &&
			onChange(
				[
					...sortedForeignKeys.filter((v) => v.foreignColumnUuid !== pk.uuid),
					{
						code: codeApi.result,
						uuid: v4(),
						name: nameColumn,
						foreignColumnCode: pk.code,
						foreignColumnUuid: pk.uuid,
						order: pk.order,
					},
				],
				name,
			)

		onClose(pk)
	}

	const onKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement>,
		pk: TableColumn,
	) => {
		switch (e.key) {
			case 'Enter': {
				if (e.currentTarget.value) {
					createColumn(e.currentTarget.value, pk)
				}

				break
			}

			case 'Escape': {
				onClose(pk)
			}
		}
	}

	if (!foreignKeyTableId) {
		if (!isEmpty(sortedForeignKeys)) {
			return (
				<ul>
					{sortedForeignKeys.map((fkConstraint) => {
						return <li key={fkConstraint?.uuid}>{fkConstraint?.code}</li>
					})}
				</ul>
			)
		} else {
			return <>{t('TABLE_CONSTRAINTS_SELECT_TABLE')}</>
		}
	}

	if (!primaryKeys || !primaryKeys.length) {
		return <>{t('TABLE_CONSTRAINTS_NO_PRIMARY_KEYS')}</>
	}

	return (
		<Wrapper>
			{primaryKeys.map((pk, i) => {
				const selectedFkValue = sortedForeignKeys.find(
					(v) => v.foreignColumnUuid === pk.uuid,
				)

				const isFirst = i === 0

				const { inProgress, defaultValue } = newColumns[pk.uuid] ?? {
					inProgress: false,
					defaultValue: '',
				}

				return (
					<Box key={pk.uuid}>
						<BoxColumn>
							{isFirst && (
								<ColumnTitle>{t('TABLE_CONSTRAINTS_PRIMARY_KEY')}</ColumnTitle>
							)}
							<PrimaryKey title={pk.name}>
								<ItemText>{pk.name}</ItemText>
								<ItemText>{pk.dataType}</ItemText>
							</PrimaryKey>
						</BoxColumn>

						<BoxColumnFK>
							{isFirst && (
								<ColumnTitle>{t('TABLE_CONSTRAINTS_FOREIGN_KEY')}</ColumnTitle>
							)}

							<BoxFKs>
								{inProgress ? (
									<FormInputFK
										error={error}
										onKeyDown={(e) => onKeyDown(e, pk)}
										onBlur={() => onClose(pk)}
										autoFocus
										$fromProps
										defaultValue={defaultValue}
									/>
								) : (
									<>
										{readonly ? (
											<>
												{getFkSelectOptions(
													possibleForeignKeys,
													sortedForeignKeys,
													pk,
												)?.find((o) => o.value === selectedFkValue?.uuid)
													?.label ?? t('NO_FOREIGN_KEY_SELECTED')}
											</>
										) : (
											<>
												<SelectFK
													fromProps={!!fromProps}
													name={pk.uuid}
													options={getFkSelectOptions(
														possibleForeignKeys,
														sortedForeignKeys,
														pk,
													)}
													hideTitle={true}
													initialValue={
														selectedFkValue ? selectedFkValue.uuid : null
													}
													value={selectedFkValue ? selectedFkValue.uuid : null}
													onChange={(value) => handleFieldChange(value, pk)}
													disabled={disabled || readonly}
													clearable={true}
													allowEmpty
													formless
													onFocus={onFocus}
													onBlur={onBlur}
													error={error}
													openMenuOnFocus
													placeholder={t(
														'TABLE_CONSTRAINTS_SELECT_FOREIGN_KEY',
													)}
												/>
												<AddIcon
													schema="default"
													onClick={() => onAddNew(pk)}
													disabled={disabled || readonly}
													title={t('ADD_NEW')}
													icon={faPlus}
												/>
											</>
										)}
									</>
								)}
							</BoxFKs>
						</BoxColumnFK>
					</Box>
				)
			})}
		</Wrapper>
	)
}
