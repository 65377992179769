import { AnyObject } from '@/components/UberForm/types'
import { Actions } from './actions'
import { UPDATE_FORM_HIGHLIGHTS } from './constants'

export type State = Readonly<typeof initialState>

const initialState = {
	active: false,
	prevVersionValues: null as null | AnyObject,
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case UPDATE_FORM_HIGHLIGHTS: {
			return {
				...state,
				...action.payload,
			}
		}

		default: {
			return state
		}
	}
}
