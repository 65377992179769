import { faColumns, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useMemo, useRef } from 'react'
import {
	getAllDomainsForCombo,
	getAllTechnicalColumns,
	getStereotypes,
	updateTechnicalColumn,
} from 'src/endpoints'

import { Button, Container, DialogWrapper, LightTable } from '@/components'
import { Form } from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { StereotypeDto, TechnicalColumnDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { excludeCustomDomains } from '@/utils/domain'

import { useFilter } from '../../hooks/useFilter'
import {
	TitleActions,
	TitleContainer,
	TitleIcon,
	TitleText,
} from '../../styles'
import {
	auditFieldsRemove,
	rowOrderChangeHandler,
	usePageItemsSort,
	useSettingsPageSaveHandler,
} from '../../utils'
import { createColumnProperties } from './Columns'
import { TechColumnModal } from './components/TechColumnModal'
import {
	TechnicalColumnsFilter,
	TechnicalColumnsFilterValues,
} from './components/TechnicalColumnsFilter'
import { useTechnicalColumnsFilteredData } from './hooks/useTechnicalColumnsFilteredData'

interface TechnicalColumsProps {
	textAreaHeight?: string
}

export const TechnicalColumns = ({ textAreaHeight }: TechnicalColumsProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const { filter, onChangeFilter } = useFilter<TechnicalColumnsFilterValues>()
	const refForm = useRef<Form<TechnicalColumnDto>>(null)

	const techColumns = useApi(getAllTechnicalColumns())
	const domains = useApi(getAllDomainsForCombo())

	const domainsData = useMemo(
		() => excludeCustomDomains(domains.data),
		[domains.data],
	)

	const stereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.TABLE }),
	)

	const historyStereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.TABLE_HISTORY }),
	)

	const stereotypesWithHistory = useMemo(
		() => (stereotypes.data || []).concat(historyStereotypes.data || []),
		[stereotypes, historyStereotypes],
	)

	const handleSave = useSettingsPageSaveHandler(techColumns)

	const columns = createColumnProperties(refForm)(
		t,
		handleSave,
		domainsData,
		stereotypesWithHistory,
	)

	useEffect(() => {
		if (
			techColumns &&
			techColumns.data &&
			techColumns.data?.length > 0 &&
			techColumns.data[0].ordering === null
		) {
			Promise.all(
				techColumns.data.map((item, i) => {
					auditFieldsRemove(item)

					return request(
						updateTechnicalColumn(item.id as number, { ...item, ordering: i }),
					)
				}),
			).then(() => techColumns.invalidate())
		}
	}, [request, techColumns])

	const sortedTechColumns = usePageItemsSort(techColumns)

	const filteredTechColumns = useTechnicalColumnsFilteredData(
		filter,
		sortedTechColumns,
	)

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<TechColumnModal
				textAreaHeight={textAreaHeight}
				stereotypes={stereotypesWithHistory}
				position={techColumns.data ? techColumns.data.length : 0}
				onClose={onClose}
				onSave={handleSave}
				ref={refForm}
			/>
		)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faColumns} />
				<TitleText>{t('TECHNICAL_COLUMNS_TITLE')}</TitleText>
				<TitleActions>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<TechnicalColumnsFilter
					onChangeFilter={onChangeFilter}
					stereotypes={stereotypesWithHistory}
				/>
				<LightTable
					dragArrowVisible={false}
					isRowOrderable
					onRowOrderChanged={(oldIndex, newIndex) =>
						rowOrderChangeHandler(
							oldIndex,
							newIndex,
							techColumns,
							request,
							updateTechnicalColumn,
						)
					}
					columns={columns}
					keyField={'id'}
					data={filteredTechColumns}
					style={{
						marginLeft: 15,
					}}
				/>
			</Container>
		</>
	)
}
