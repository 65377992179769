import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '@/store'
import { EnhancedPureComponent } from '@/components'
import { Dispatch, bindActionCreators } from 'redux'
import { openTab } from '@/store/modules/tab/actions'
import { StructureObjectDto } from '@/endpoints/models'

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> & {
		node: StructureObjectDto | null
	}

const mapStateToProps = (state: RootState) => ({
	nodes: state.node.nodes,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			openTab,
		},
		dispatch,
	)

class BreadcrumbComponent extends EnhancedPureComponent<Props> {
	handleClick = (node: StructureObjectDto) => {
		this.props.openTab(node, true)
	}

	getBreadcrumbs = (node: StructureObjectDto, acc: StructureObjectDto[]) => {
		const { nodes } = this.props

		if (!node.parentStructureId) {
			return acc
		}

		const parentNode = nodes[node.parentStructureId]

		if (parentNode) {
			this.getBreadcrumbs(parentNode, acc)
			acc.push(parentNode)
		}

		return acc
	}

	render() {
		const { node, nodes } = this.props

		if (!node || !nodes) {
			return null
		}

		const breadcrumbs = this.getBreadcrumbs(node, [])

		return (
			<Container role="navigation" aria-label="Breadcrumb">
				{breadcrumbs.map((n) => (
					<Item key={n.id} onClick={() => this.handleClick(n)} title={n.name}>
						<ItemText> {n.name}</ItemText>
					</Item>
				))}
				<ActiveItem title={node.name}>
					<ItemText>{node.name}</ItemText>
				</ActiveItem>
			</Container>
		)
	}
}

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 6px 10px;
	background: #fff;
`
export const Item = styled.a`
	display: flex;
	margin: 0 4px 2px 4px;
	color: #333;
	user-select: none;
	&:hover {
		color: #777;
	}
	&:not(:last-child):after {
		content: '>';
		margin-left: 8px;
		color: #999;
	}
`

export const ActiveItem = styled(Item)`
	font-weight: 600;
`

const ItemText = styled.div`
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const Breadcrumb = connect(
	mapStateToProps,
	mapDispatchToProps,
)(BreadcrumbComponent)
