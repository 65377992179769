import styled, { css } from 'styled-components'

export const Container = styled.div<{ relative?: boolean }>`
	${(props) =>
		props.relative &&
		css`
			position: relative;
		`}
	padding: 16px;
`
