import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppStore, useAppContext } from '@/hooks'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import {
	StructureObjectDto,
	NewHistoryDto,
	StructureDto,
} from '@/endpoints/models'
import {
	CheckBoxFormField,
	SelectFormField,
	TextAreaFormField,
} from '@/components/UberForm'
import { unlockNode, loadNodeChildren } from '@/store/modules/node/actions'
import { pushHistory } from '@/store/modules/history/actions'
import { ModalForm } from '@/components/Modal/ModalForm'
import { Flex } from '@/components/Layout'
import { cancelEditingTab } from '@/store/modules/tab/actions'
import { useTabContext } from '@/context/TabContext/TabContext'
import { getApiRef, useApi, useApiRequest } from '@/endpoints/hooks'
import {
	addStructureToRelease,
	createSystemRelease,
	getAllNodeHistory,
	getNodeDetail,
	getSystemReleases,
} from 'src/endpoints'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { RELEASE_OBJECTS_FORBIDDEN } from '../../pages/SystemDetail/pages/Releases/constants'
import { loadNode } from '@/store/modules/node/general-actions'
import { usePrePushValidation } from './hooks/usePrePushValidation'
import { ValidationsModal } from './components/ValidationsModal'
import { updateDialogSettings } from '@/store/modules/user/actions'
import DpCreationSelectOption from './DpCreationSelectOption'

type Props = {
	onClose: () => void
	beforePush?: () => Promise<void>
	afterPush?: (values: Partial<FormDataPushModal>) => void
	/** Node for single edit */
	node?: StructureDto
	/** Nodes for batch edit */
	nodes?: StructureDto[]
	onExitEditMode?: (node: StructureDto) => Promise<void>
}

const FIX_IMPACTS_ELEMENT_TYPES = [StructureObjectDto.TypeEnum.TABLE]

export type FormDataPushModal = NewHistoryDto & {
	exitEditMode: boolean
	unlockNode?: boolean
	releaseId?: number
	fixImpacts?: boolean
}

export const PushModal = ({
	node: nodeInit,
	onExitEditMode,
	onClose,
	beforePush,
	afterPush,
	nodes,
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const { onSaveError } = useTabContext()
	const nodesMap = useAppStore((state) => state.node.nodes)
	const { isLoading, validations } = usePrePushValidation(nodeInit?.id)
	const [isReleaseLoading, setIsReleaseLoading] = useState(false)
	const [inputValue, setInputValue] = useState('')

	const dialog = useAppStore((state) => state.user.dialog.settings)

	const node = useMemo(
		() => (nodeInit ? nodeInit : nodes?.[0]),
		[nodeInit, nodes],
	) as StructureDto

	const systemId = useMemo(
		() => findSystemNodeId(node, nodesMap),
		[node, nodesMap],
	)

	const systemNode = useAppStore((state) => state.node.nodes[systemId])

	const request = useApiRequest()

	const handleCreateReleaseResponse = (response: any) => {
		// Reset states
		setIsReleaseLoading(false)
		setInputValue('')

		return response
	}

	const handleCreateReleaseFailure = (error: any) => {
		console.error('Error creating release:', error)
		throw error
	}

	const createRelease = async (systemNode: StructureDto, name: string) => {
		try {
			const response = await request(
				createSystemRelease(systemNode?.id, {
					name: name,
					description: '',
				}),
			)

			return handleCreateReleaseResponse(response)
		} catch (error) {
			return handleCreateReleaseFailure(error)
		}
	}

	const {
		data: releases,
		loading,
		invalidate,
	} = useApi(getSystemReleases(systemId, { onlyOpen: true }))

	useEffect(() => {
		invalidate()
	}, [isReleaseLoading])

	const canAddToRelease = useMemo(
		() => !RELEASE_OBJECTS_FORBIDDEN.includes(node.type),
		[node.type],
	)

	const handleSubmit = async (form: Partial<FormDataPushModal>) => {
		try {
			await beforePush?.()

			// Batch edit
			if (nodes) {
				if (form.releaseId) {
					const releases = nodes.map((node) =>
						request(
							addStructureToRelease(systemId, form.releaseId!, [node.id]),
						),
					)

					await Promise.allSettled(releases)
				}

				const histories = nodes.map((node) =>
					dispatch(
						pushHistory(node, {
							description: form.description || '',
						}),
					),
				)

				await Promise.allSettled(histories)

				const children = nodes.map((node) =>
					dispatch(loadNodeChildren(node.id)),
				)

				await Promise.allSettled(children)
				const nodesLoaded = nodes.map((node) => dispatch(loadNode(node.id)))
				await Promise.allSettled(nodesLoaded)

				nodes.forEach((node) => {
					getApiRef(getAllNodeHistory(node.id)).invalidate()
					getApiRef(getNodeDetail(node.id)).invalidate()
				})

				if (form.exitEditMode) {
					nodes.forEach((node) => dispatch(cancelEditingTab(node)))

					if (form.unlockNode) {
						const unlocks = nodes.map((node) => dispatch(unlockNode(node)))
						await Promise.allSettled(unlocks)
					}
					// TODO: Maybe needed even for batch edit?
					// await onExitEditMode(node)
				}
			}
			// Single element edit
			else {
				if (form.releaseId) {
					await request(
						addStructureToRelease(systemId, form.releaseId, [node.id]),
					)
				}

				await dispatch(
					pushHistory(
						node,
						{
							description: form.description || '',
						},
						form.fixImpacts,
					),
				)

				await dispatch(loadNodeChildren(node.id))
				await dispatch(loadNode(node.id))
				getApiRef(getAllNodeHistory(node.id)).invalidate()
				getApiRef(getNodeDetail(node.id)).invalidate()

				if (form.exitEditMode) {
					dispatch(cancelEditingTab(node))
					form.unlockNode && (await dispatch(unlockNode(node)))
					await onExitEditMode?.(node)
				}
			}
		} catch (e) {
			onSaveError(e)
		}

		afterPush?.(form)
		onClose()
	}

	return (
		<ModalForm<FormDataPushModal>
			open={true}
			saveTitle={t('PUSH')}
			saveIcon={faUpload}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			onClose={onClose}
			contentStyle={{
				width: '440px',
			}}
			header={t('PUSH_MODAL_HEADER')}
			initialValues={{
				exitEditMode: dialog.keepEditOnExit,
				unlockNode: dialog.unlockOnExit,
			}}
			isLoading={isLoading}
			footerBefore={<ValidationsModal validations={validations} />}
		>
			<TextAreaFormField
				title={t('PUSH_DESCRIPTION')}
				name="description"
				required={true}
			/>
			<Flex>
				<CheckBoxFormField
					name="exitEditMode"
					className="flex-1"
					value={dialog.keepEditOnExit}
					onChange={(checked: boolean) => {
						dispatch(
							updateDialogSettings({
								keepEditOnExit: checked,
								unlockOnExit: checked ? dialog.unlockOnExit : false,
							}),
						)
					}}
					title={t('PUSH_EXIT_EDIT_MODE')}
				/>
				{dialog.keepEditOnExit && (
					<CheckBoxFormField
						name="unlockNode"
						className="flex-1"
						title={nodes ? t('PUSH_LOCK_NODES') : t('PUSH_LOCK_NODE')}
						value={dialog.unlockOnExit}
						onChange={(checked: boolean) => {
							dispatch(updateDialogSettings({ unlockOnExit: checked }))
						}}
					/>
				)}
				{nodeInit &&
					FIX_IMPACTS_ELEMENT_TYPES.includes(nodeInit.type) &&
					validations.length > 0 && (
						<CheckBoxFormField
							name="fixImpacts"
							className="flex-1"
							title={t('FIX_IMPACTS')}
						/>
					)}
			</Flex>

			{canAddToRelease && (
				<div className="flex items-end">
					<SelectFormField
						name="releaseId"
						title={
							nodes
								? t('ADD_ELEMENTS_TO_DEPLOYMENT_PKG')
								: t('ADD_ELEMENT_TO_DEPLOYMENT_PKG')
						}
						options={releases}
						valueKey="id"
						labelKey="name"
						allowEmpty
						clearable
						loading={loading}
						onInputChange={(e) => {
							setInputValue(e)
						}}
						components={{
							NoOptionsMessage: () => {
								return (
									<DpCreationSelectOption
										systemNode={systemNode as StructureDto}
										inputValue={inputValue}
										isReleaseLoading={isReleaseLoading}
										setIsReleaseLoading={setIsReleaseLoading}
										createRelease={createRelease}
									/>
								)
							},
						}}
					/>
				</div>
			)}
		</ModalForm>
	)
}
