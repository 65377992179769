import React from 'react'
import { toLocaleString } from '@/utils/numbers'
import { HistoryDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

interface LastVersionInfoProps {
	data: HistoryDto | undefined
}

const LastVersionInfo = ({ data }: LastVersionInfoProps) => {
	const { t } = useAppContext()

	if (data) {
		return (
			<>
				<span>{t('LAST_VERSION', [data.version, data.authorName])}</span>
				<span>&nbsp;{toLocaleString(data.created)}</span>
			</>
		)
	}

	return <span>N/A</span>
}

export default LastVersionInfo
