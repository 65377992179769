import React, { useMemo } from 'react'
import { UserRolesDto} from '@/endpoints/models'
import keycloak from '@/endpoints/keycloak'

export interface AuthContextInterface {
	canManageUsers: () => boolean
	canManageLOVs: () => boolean
	canCreateSystem: () => boolean
	canDeleteSystem: () => boolean
	canForceUnlock: () => boolean
	canSeeSettings: () => boolean
}

export const AuthContext = React.createContext<AuthContextInterface | null>(
	null
)

export const AuthContextProvider = ({
	children
}: {
	children: React.ReactNode
}) => {
	const model = useMemo(
		(): AuthContextInterface => ({
			canManageUsers: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.MANAGE_USERS),
			canManageLOVs: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.MANAGE_LOV),
			canCreateSystem: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.CREATE_DELETE_SYSTEM),
			canDeleteSystem: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.CREATE_DELETE_SYSTEM),
			canForceUnlock: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.FORCE_UNLOCK),
			canSeeSettings: () =>
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.MANAGE_USERS) ||
				keycloak.hasRealmRole(UserRolesDto.RolesEnum.MANAGE_LOV)
		}),
		[]
	)

	return <AuthContext.Provider value={model}>{children}</AuthContext.Provider>
}
