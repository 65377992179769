import React, { Suspense } from 'react'

import { Loader } from '@/components'
import { Tabs } from '@/components/Tabs/Tabs'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import {
	FolderData,
	OpenedFolderData,
	OpenedFolderTab,
} from '@/store/modules/folder/types'

import { Dependencies } from '../../../components/Dependencies/Dependencies'
import { Validation } from '../../../components/Validation/Validation'
import { Overview } from '../pages/Overview/Overview'

const NewDiagram = React.lazy(() =>
	import('./FolderDiagram').then((module) => ({
		default: module.FolderDiagram,
	})),
)

const Diagram = React.lazy(
	() => import(/* webpackChunkName: "diagram" */ '../pages/Diagram/Diagram'),
)

type FolderTabsProps = {
	data: OpenedFolderData
	editMode: boolean
	node: StructureDto
	onChange: (v: Partial<FolderData>) => void
	systemNodeId: number
}

export const FolderTabs = ({
	node,
	onChange,
	editMode,
	data,
	systemNodeId,
}: FolderTabsProps) => {
	const { t } = useAppContext()

	const folderTabs = [
		{
			id: OpenedFolderTab.Overview,
			title: t('TAB_OVERVIEW'),
			content: (
				<Overview
					key={OpenedFolderTab.Overview}
					node={node}
					isEditMode={!editMode}
					onChange={onChange}
					data={data}
					systemNodeId={systemNodeId}
				/>
			),
		},
		{
			id: OpenedFolderTab.Diagram,
			title: t('TAB_DIAGRAM'),
			content: (
				<Suspense
					key={OpenedFolderTab.Diagram}
					fallback={<Loader loaded={false} />}
				>
					<Diagram data={data} nodeId={node.id} onChange={onChange} />
				</Suspense>
			),
		},
		{
			id: OpenedFolderTab.NewDiagram,
			title: t('TAB_DIAGRAM_NEW'),
			content: (
				<Suspense
					key={OpenedFolderTab.Diagram}
					fallback={<Loader loaded={false} />}
				>
					<NewDiagram nodeId={node.id} isEditMode={editMode} />
				</Suspense>
			),
			isDevMode: true,
		},
		{
			id: OpenedFolderTab.Validation,
			title: t('TAB_VALIDATION'),
			content: <Validation key={OpenedFolderTab.Validation} node={node} />,
		},
		{
			id: OpenedFolderTab.Dependencies,
			title: t('DEPENDENCIES'),
			content: (
				<Dependencies
					key={OpenedFolderTab.Dependencies}
					node={node}
					editMode={editMode}
				/>
			),
		},
	]

	const getTabs = () => {
		const domainName = window.location.hostname

		if (
			domainName === 'localhost' ||
			domainName === 'adastra.metacroc-dev.com'
		) {
			return folderTabs
		}

		return folderTabs.filter((tab) => !tab.isDevMode)
	}

	return <Tabs tabs={getTabs()} />
}
