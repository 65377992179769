import { TableColumn } from '@/endpoints/schemas'
import { TranslationHelper } from '@/context/Locale/Locale'
import { isNil } from 'lodash'
import { PropertiesErrorProps } from '@/types'

/** Check if columns do not have same name or code */
export const duplication = (
	columns: TableColumn[],
	technicalColumns: TableColumn[],
	t: TranslationHelper,
): PropertiesErrorProps => {
	const columnsAll = [...columns, ...technicalColumns]

	const isDefined = (value?: string) => !isNil(value) && value !== ''

	return columns
		.filter((data) => {
			const duplicateCount = columnsAll.reduce((acc, d) => {
				if (
					(d?.name === data?.name || d?.code === data?.code) &&
					isDefined(data?.name) &&
					isDefined(data?.code) &&
					// newly added row in table - do not count
					isDefined(d?.name)
				) {
					return acc + 1
				} else {
					return acc
				}
			}, 0)

			return duplicateCount > 1
		})
		.reduce(
			(acc, d) => ({
				...acc,
				[d.id]: {
					name: {
						message: t('VALIDATOR_DUPLICATE'),
					},
					code: {
						message: t('VALIDATOR_DUPLICATE'),
					},
				},
			}),
			{},
		)
}
