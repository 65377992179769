import { FOLDER_INIT, FOLDER_UPDATE, FOLDER_SAVE } from './constants'
import { StructureDetailDto, StructureObjectDto } from '@/endpoints/models'
import { AppDispatch, apiCallAction } from '@/store/utils'
import { FolderData } from './types'
import { RootState } from '@/store'
import { updateDataNode } from '@/endpoints'
import omit from 'lodash/omit'
import { DiagramData, NewDiagramData } from '@/endpoints/schemas/diagram'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'
import { IncrementalData } from 'gojs'

type FolderInit = {
	type: typeof FOLDER_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

type FolderUpdate = {
	type: typeof FOLDER_UPDATE
	node: StructureObjectDto
	update: any
}

type FolderSave = {
	type: typeof FOLDER_SAVE
	payload: void
	metadata: {
		nodeId: number
	}
}

export const initFolder =
	({ nodeId, editMode = false, version, force, envId }: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: FOLDER_INIT,
			node,
			editMode,
			force,
		} as FolderInit)
	}

export const updateFolder = (
	node: StructureObjectDto,
	update: Partial<FolderData> | NewDiagramData | string,
): Action => ({
	type: FOLDER_UPDATE,
	node,
	update,
})

export const saveFolder =
	(node: StructureObjectDto) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const opened = getState().folder.folders[node.id]

		if (!opened) {
			throw new Error(`Saving unopened state ${node.id}`)
		}

		const {
			name,
			diagram,
			newDiagram,
			diagramSvg,
			generateCode,
			generateTableColumnCode,
		} = opened.form

		const formData = omit(opened.form, [
			'generateCode',
			'generateTableColumnCode',
		])

		const folderData: FolderData = {
			...formData,
			name: name as string,
			diagram: diagram as DiagramData,
			newDiagram: newDiagram as NewDiagramData,
			diagramSvg: diagramSvg as string,
			namingConfig: {
				generateCode,
				generateTableColumnCode,
			},
		}

		await dispatch(
			apiCallAction<FolderSave>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(folderData),
					}),
				FOLDER_SAVE,
				{ nodeId: node.id },
			),
		)
	}

export type Action = FolderInit | FolderUpdate | FolderSave
