import { Cell } from '@tanstack/react-table'
import { useMemo } from 'react'

import { valueGet } from '@/components/Properties/utils'
import { EditableCell } from '@/components/Table'
import { EditableCellProps, UseColumnsProps } from '@/components/Table/types'
import { calculateFieldValues, getTextWidth } from '@/components/Table/utils'
import { useMemoObject } from '@/hooks/index'

/**
 * Custom hook to generate table columns for the main Table, the Table.tsx component.
 *
 * @template Item - The type of the items in the table.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Array} params.properties - The properties of the table columns.
 * @param {Array} params.tableData - The data for the table.
 * @param {React.RefObject} params.refWrapper - The ref for the table wrapper.
 * @param {Object} params.lastEditedCell - The last edited cell in the table.
 * @param {React.RefObject} params.lastEditedCellRef - The ref for the last edited cell.
 *
 * @returns {Array} The generated columns for the table.
 */

export const useTableColumns = <Item extends object>({
	properties,
	tableData,
	refWrapper,
	lastEditedCell,
	lastEditedCellRef,
	node,
}: UseColumnsProps<Item>): Array<any> => {
	const propertiesWithAutoWidth = properties?.filter((prop) => prop.autoWidth)

	const propWidths = propertiesWithAutoWidth?.reduce(
		(prev, acc) => calculateFieldValues(acc, tableData, prev), // Use the function here
		{} as Record<string, string>,
	)

	const cachedPropWidths = useMemoObject(propWidths ?? {})

	const columns = useMemo(() => {
		if (!properties) {
			return []
		}

		return properties.map((property) => {
			const maxValue = cachedPropWidths[property.field]

			const autoWidth =
				maxValue &&
				getTextWidth(
					maxValue,
					refWrapper,
					property.field === 'objectCode' ? 450 : 180,
					property.field === 'objectCode',
				)

			return {
				id: property.field,
				header: property.label,
				accessorKey: property.field,
				isVisible: !property.hideInTable,
				size: property.autoWidth ? autoWidth : property.width,
				minSize: property.minWidth,
				cell: (cellProps: Cell<any, any>) => {
					const customCellProps = cellProps as unknown as EditableCellProps
					const { cell, row, table } = customCellProps
					const item = cell.row.original
					const value = valueGet(property, item)
					const handleChange = table?.options?.meta?.handleChange

					const isLastEditedCell =
						lastEditedCell?.rowId === row?.index &&
						lastEditedCell?.fieldName === cell.column.id

					const renderEditableCell = (shouldAttachRef: boolean) => {
						return (
							<EditableCell
								autoFocus={true}
								properties={properties}
								columnModelProps={property}
								value={value}
								handleChange={handleChange}
								cell={cell}
								node={node}
								ref={shouldAttachRef ? lastEditedCellRef : undefined}
							/>
						)
					}

					return renderEditableCell(isLastEditedCell)
				},
			}
		})
	}, [
		cachedPropWidths,
		lastEditedCell,
		lastEditedCellRef,
		properties,
		refWrapper,
	])

	return columns
}
