import React, { useCallback, useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { Button, LightTable, Loader, Modal } from '@/components'
import { DomainDto, ImpactedColumnDto } from '@/endpoints/models'
import { useApi } from '@/endpoints/hooks'
import { getColumnsByDomain } from '@/endpoints'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useOpenTab } from '@/pages/User/pages/Home/components/Dependencies/hooks/useOpenTab'
import { useNavigate } from 'react-router-dom'
import { useDownloadImpactCsv } from './hooks/useDownloadImpactCsv'
import { formatterCell1, formatterCell2 } from '../../../utils'
import { LightTableColumn, ModalProps } from '@/types'

type Props = Partial<ModalProps> & {
	domain: DomainDto
	children?: React.ReactNode
}

export const DomainImpactModal = ({ open, onClose, domain }: Props) => {
	const { t } = useAppContext()
	const { openTabById } = useOpenTab()
	const navigate = useNavigate()

	const handleEdit = useCallback(
		(tableId?: number) => {
			if (tableId === undefined) {
				return
			}

			navigate('/')
			openTabById(tableId)
		},
		[openTabById],
	)

	const columns = useApi(getColumnsByDomain(domain.id))

	const { isDownloading, onDownloadCsv } = useDownloadImpactCsv()

	const columnsLightTable = useMemo<LightTableColumn<ImpactedColumnDto>[]>(
		() => [
			{
				field: 'tableName',
				title: t('TABLE'),
				width: 175,
				formatter: (cell) => formatterCell1(cell),
			},
			{
				field: 'columnName',
				title: t('COLUMN'),
				width: 175,
				formatter: (cell) => formatterCell1(cell),
			},
			{
				field: 'tableId',
				width: 30,
				maxWidth: 30,
				formatter: (cell) => formatterCell2(cell, handleEdit, t),
			},
		],
		[handleEdit, t],
	)

	const isSomethingImpacted = (columns.data?.length ?? 0) > 0

	return (
		<Modal
			open={open}
			onClose={onClose}
			header={t('IMPACTED_TABLES_AND_COLUMNS')}
			stickyFooter
			footer={
				<Button icon={faCheck} onClick={onClose}>
					{t('CLOSE')}
				</Button>
			}
			contentStyle={{
				width: 700,
				minWidth: 700,
			}}
			resizable
		>
			<>
				<Loader loaded={!columns.loading} $absolute />
				{!columns.loading && (
					<>
						<div style={{ marginBottom: '10px', display: 'flex' }}>
							{t('IMPACTED_COLUMNS')}: {columns.data?.length ?? ''}
							{isSomethingImpacted && (
								<Button
									onClick={() => onDownloadCsv(domain)}
									isLoading={isDownloading}
									disabled={isDownloading}
									style={{ marginLeft: 'auto' }}
								>
									{t('DOWNLOAD_IMPACT_AS_CSV')}
								</Button>
							)}
						</div>

						{isSomethingImpacted && (
							<LightTable<ImpactedColumnDto>
								data={columns.data || []}
								columns={columnsLightTable}
								keyField={'columnUuid'}
							/>
						)}
					</>
				)}
			</>
		</Modal>
	)
}
