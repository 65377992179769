import React, { useState } from 'react'
import without from 'lodash/without'
import property from 'lodash/property'
import { Button } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { TableColumnFlat } from '@/store/modules/table/types'
import { Checkbox } from '@/components/Properties/components/inputs/Checkbox'
import { UpdateDeepPartial } from '@/store/utils'
import { MappingDataForm } from '@/store/modules/mapping/types'
import { MappingColumn } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import {
	ButtonContainer,
	FooterContainer,
	ContentContainer,
	FillButtonContent,
	EllipsisWrap,
} from './styles'

type FillColumnsButtonProps = {
	availableColumns: TableColumnFlat[]
	mappingColumns: MappingColumn[]
	onChange: (data: UpdateDeepPartial<MappingDataForm>) => void
}

const mapTableColumnToMappingColumn = (
	column: TableColumnFlat,
): MappingColumn => ({
	id: column.id,
	columnCode: column.code ?? '',
	mapping: '',
})

export const FillColumnsButton = ({
	availableColumns,
	mappingColumns,
	onChange,
}: FillColumnsButtonProps) => {
	const { t } = useAppContext()
	const [isModalOpen, setModalOpen] = useState(false)
	const [selectedColumnIds, setSelectedColumnIds] = useState<number[]>([])

	const isAllSelected = selectedColumnIds.length === availableColumns.length

	const onFillColumns = () => {
		const currentColumns = mappingColumns.filter(({ columnCode }) =>
			Boolean(columnCode),
		)

		const columnsToAdd = availableColumns
			.filter(({ id }) => selectedColumnIds.includes(id))
			.map(mapTableColumnToMappingColumn)

		if (columnsToAdd.length) {
			onChange({
				columns: [...currentColumns, ...columnsToAdd],
			})
		}

		setModalOpen(false)
	}

	return (
		<ButtonContainer>
			<Button onClick={() => setModalOpen(true)}>
				<FillButtonContent>
					<div>Fill columns</div>
					<EllipsisWrap>
						<FontAwesomeIcon icon={faEllipsisH} />
					</EllipsisWrap>
				</FillButtonContent>
			</Button>
			<Modal
				open={isModalOpen}
				onClose={() => setModalOpen(false)}
				footer={
					<FooterContainer>
						<Button
							schema="primaryOutlined"
							onClick={() => setModalOpen(false)}
						>
							Cancel
						</Button>
						<Button schema="primary" onClick={onFillColumns}>
							Fill Columns
						</Button>
					</FooterContainer>
				}
			>
				<ContentContainer>
					<Checkbox
						label={t('SELECT_ALL')}
						disabled={false}
						isFocused={false}
						checked={isAllSelected}
						onChange={() =>
							isAllSelected
								? setSelectedColumnIds([])
								: setSelectedColumnIds(availableColumns.map(property('id')))
						}
					/>
					{availableColumns.map(({ id, name }) => (
						<Checkbox
							key={id}
							label={name}
							disabled={false}
							isFocused={false}
							checked={selectedColumnIds.includes(id)}
							onChange={() =>
								selectedColumnIds.includes(id)
									? setSelectedColumnIds((prev) => without(prev, id))
									: setSelectedColumnIds((prev) => [...prev, id])
							}
						/>
					))}
				</ContentContainer>
			</Modal>
		</ButtonContainer>
	)
}
