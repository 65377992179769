import React, { useState, useCallback, useEffect, useMemo } from 'react'
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import update from 'immutability-helper'
import { TableWrap } from './styles'
import { TableProps } from './types'
import { TableHeader } from './TableHeader'
import { Empty } from '../Layout'
import { TableRow } from './TableRow'
import isEqual from 'lodash/isEqual'
import { useAppContext } from '@/hooks'
import { TableRowNoDnd } from './TableRowNoDnd'

export const Table = ({
	actionCellWidth = '3.75rem',
	columns = [],
	data,
	isEditMode,
	hasDnd = true,
	hasLastRowEdit,
	hasLeftAction = true,
	handleChange,
	onEdit,
	onDelete,
	onSaveData,
	onRowSelect,
	maxWidth,
}: TableProps) => {
	const [items, setItems] = useState(data)

	const { t } = useAppContext()

	useEffect(() => {
		if (!isEqual(data, items)) {
			setItems(data)
		}
	}, [data])

	const visibleColumns = useMemo(
		() => columns.filter((column) => column.isVisible),
		[columns],
	)

	const table = useReactTable({
		data: items,
		columns: visibleColumns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getSubRows: (row) => row?.subRows,
		debugTable: true,
		meta: {
			handleChange,
		},
	})

	const onMoveRow = useCallback(
		(dragIndex: number, dropIndex: number) => {
			const draggedItem = items?.[dragIndex]

			const replacedItems = update(items, {
				$splice: [
					[dragIndex, 1],
					[dropIndex, 0, draggedItem],
				],
			})

			const updatedItems = replacedItems?.map((item, index) => {
				return {
					...item,
					order: index,
				}
			})

			setItems(updatedItems)
			onSaveData && onSaveData(updatedItems)
		},
		[items, onSaveData],
	)

	const tableRows = table.getRowModel().rows

	return (
        <TableWrap $maxWidth={maxWidth} $actionCellWidth={actionCellWidth}>
            <table>
                <TableHeader hasLeftAction={hasLeftAction} table={table} />
                <tbody>
                    {tableRows.map((row, index) => {
                        const isLastRow = index === tableRows.length - 1

                        const commonProps = {
                            actionCellWidth,
                            isEditMode,
                            isLastRow,
                            hasLastRowEdit,
                            hasLeftAction,
                            onDelete,
                            onEdit,
                            onMoveRow,
                            onSelect: onRowSelect,
                            row,
                        }

                        return hasDnd ? (
                            <TableRow {...commonProps} />
                        ) : (
                            <TableRowNoDnd {...commonProps} />
                        )
                    })}
                </tbody>
            </table>
            {!tableRows.length && <Empty>{t('NOTHING_HERE')}</Empty>}
            {/*<pre>{JSON.stringify(table.getState(), null, 2)}</pre>*/}
        </TableWrap>
	)
}
