import styled from 'styled-components'

export const ButtonContainer = styled.div`
	margin: 1rem 0 0 2rem;
`

export const FooterContainer = styled.div`
	display: flex;
	gap: 0.75rem;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`
export const FillButtonContent = styled.div`
	display: flex;
`

export const EllipsisWrap = styled.div`
	margin-left: 0.5rem;
	display: flex;
	align-items: flex-end;
	height: 1rem;
`
