import React from 'react'
import { createRoot } from 'react-dom/client'
import { Keycloak } from './Keycloak'

const container = document.getElementById('application')

if (!container) {
	throw new Error('No application container found')
}

const root = createRoot(container)
root.render(<Keycloak />)
