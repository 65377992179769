import { useAppContext, useAppDispatch } from '@/hooks'
import {
	EnvironmentDto,
	ReleaseDto,
	StructureObjectDto,
} from '@/endpoints/models'
import { selectSystemTab, updateSystem } from '@/store/modules/system/actions'
import { SystemTab } from '@/store/modules/system/types'
import { openTab } from '@/store/modules/tab/actions'
import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { SystemEnvironment } from '@/endpoints/schemas'

interface LatestReleaseProps {
	envs?: EnvironmentDto[]
	item: SystemEnvironment
	fromProps?: boolean
	systemNode: StructureObjectDto
}

export const LatestRelease: React.FC<LatestReleaseProps> = ({
	envs,
	item,
	fromProps,
	systemNode,
}) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const onReleaseRedirect = async (release?: ReleaseDto) => {
		if (!release) {
			return
		}

		dispatch(selectSystemTab(systemNode, SystemTab.Releases))

		dispatch(updateSystem(systemNode, { selectedReleaseId: release.id! }))

		dispatch(openTab(systemNode, false))
	}

	const env = useMemo(
		() => envs?.find((env) => env.frontendId === item.id),
		[envs, item],
	)

	return (
		<div
			style={
				fromProps
					? {
							marginBottom: '10px',
							padding: '0 10px',
							width: '100%',
							cursor: 'pointer',
						}
					: { padding: '0 5px', cursor: 'pointer' }
			}
			onClick={() => onReleaseRedirect(env?.lastRelease)}
		>
			{fromProps && `${t('LATEST_RELEASE')}: `}
			{env?.lastRelease?.name && (
				<>
					<FontAwesomeIcon icon={faEdit} />
					&nbsp;
				</>
			)}
			{env?.lastRelease?.name ?? 'N/A'}
		</div>
	)
}
