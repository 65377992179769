import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Text } from '@/components/UberForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const NavContainer = styled.nav`
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	background: ${(props) => props.theme.colors.layout.topBackgroundColor};
	color: white;
	padding: 0.625rem 0.625rem 0.625rem 1.25rem;
	height: 2.75rem;
`
export const UserCompany = styled.div`
	font-size: 1.5rem;
	font-weight: 200;
	margin-left: 0.5rem;
	&:before {
		content: '-';
		margin-right: 0.5rem;
	}
`
export const StyledLink = styled(Link)<{
	current: boolean
}>`
	display: inline-block;
	padding: 0.875rem 0.875rem 0.4375rem 0.875rem;
	margin-bottom: 0.4375rem;
	margin-right: 0.625rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 0.9375rem;
	color: white;
	text-decoration: none;
	transition: 0.25s all;
	border-bottom: ${(props) =>
		props.current ? '1px solid #fff' : '1px solid transparent'};
	&:hover {
		background-color: rgba(144, 202, 249, 0.08);
	}
`
export const SearchWrap = styled.div`
	margin-left: auto;
	flex-grow: 1;
	margin-right: 0.625rem;
	max-width: 31.25rem;
	position: relative;
`
export const StyledText = styled(Text)`
	${(props) => css`
		color: #fff;
		&&&:focus {
			border-color: ${props.theme.colors.input.border};
		}
	`}
`

export const UserMenuButton = styled.button<{ $isOpen: boolean }>`
	font-size: 16px;
	user-select: none;
	cursor: pointer;
	color: white;
	&:hover {
		color: #f0f0f0;
	}
`

export const Container = styled.div`
	padding: 5px 0 5px 0;
	position: relative;
	color: ${(props) => props.theme.colors.text.base};
	background-color: white;
	box-shadow: ${(props) => props.theme.styles.rightMenusShadows};
`

export const MenuButton = styled.button`
	background: transparent;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 8px 12px;
	display: flex;
	cursor: pointer;
	width: 100%;
	&:hover {
		background: ${(props) => props.theme.colors.button.primary.background};
		color: white;
	}
`

export const Separator = styled.div`
	height: 5px;
`

export const UserIcon = styled(FontAwesomeIcon)`
	margin: 0 4px 0 8px;
`

export const UserName = styled.div``

export const UserDropIcon = styled(FontAwesomeIcon)``

export const MenuButtonIcon = styled(FontAwesomeIcon)`
	flex: 0;
`

export const UserBackgroundIcon = styled(FontAwesomeIcon)`
	position: absolute;
	opacity: 0.15;
	top: 13px;
	left: 15px;
	font-size: 64px;
`

export const MenuButtonText = styled.div`
	flex: 1;
`

export const MenuItem = styled.div`
	padding: 6px 10px;
	text-align: right;
`
