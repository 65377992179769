import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	Button,
	DialogWrapper,
	TableAndProperties,
	Loader,
	TabsActionsBar,
} from '@/components'
import { initReadMapping } from '@/store/modules/readMapping/actions'
import { isNotUndefined } from '@/utils/collections'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { AddModal } from '../../../../components/AddModal'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'
import { Container } from '../../components/StyledComponents'
import { MappingsProperties } from './Properties'

type Props = {
	node: StructureDto
}

export const Mappings = ({ node }: Props) => {
	const { t } = useAppContext()
	const [isLoading, setLoading] = useState(false)
	const { openTabById } = useOpenTab()

	const dispatch = useAppDispatch()
	const children = useAppStore((state) => state.node.children)
	const nodes = useAppStore((state) => state.node.nodes)
	const readMappings = useAppStore((state) => state.readMapping.mappings)
	const refInit = useRef(false)

	const childMappings = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map((id) => nodes[id])
					.filter(isNotUndefined)
					.filter(
						(node) =>
							node.type === StructureObjectDto.TypeEnum.MAPPING_READ ||
							node.type === StructureObjectDto.TypeEnum.MAPPING_WRITE,
					)
			: []
	}, [children, node.id, nodes])

	useEffect(() => {
		if (refInit.current) {
			return
		}

		setLoading(true)

		Promise.all(
			childMappings.map((node: any) =>
				dispatch(initReadMapping({ nodeId: node.id })),
			),
		).then(() => {
			refInit.current = true
			setLoading(false)
		})
	}, [childMappings, dispatch])

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById],
	)

	const mappingDetails = childMappings.map((node: any) => ({
		node,
		detail: readMappings[node.id],
	}))

	const data = useMemo(
		() => ({
			mappings:
				mappingDetails?.map((detail: any) => ({
					...detail.detail?.form,
					id: detail.node.id,
					type: detail.node.type,
					parentStructureId: detail.node.parentStructureId,
				})) ?? [],
			counter: mappingDetails?.length ?? 0,
		}),
		[mappingDetails],
	)

	const mappingWriteDialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				type={StructureObjectDto.TypeEnum.MAPPING_WRITE}
				onClose={onClose}
				node={node}
			/>
		)

	const mappingReadDialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				type={StructureObjectDto.TypeEnum.MAPPING_READ}
				onClose={onClose}
				node={node}
			/>
		)

	return (
		<>
			{node.canEdit && (
				<TabsActionsBar>
					<DialogWrapper dialog={mappingReadDialog}>
						{(onClick) => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW_MAPPING_READ')}
							</Button>
						)}
					</DialogWrapper>
					<DialogWrapper dialog={mappingWriteDialog}>
						{(onClick) => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW_MAPPING_WRITE')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActionsBar>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!isLoading} $absolute />
				</LoaderContainer>

				<TableAndProperties
					node={node}
					data={data}
					itemsKey={'mappings'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={MappingsProperties(t, onEdit)}
					tabKey="mappings"
					onChange={() => undefined}
					createEmpty={() => ({}) as any}
					disablePanelProperties
				/>
			</Container>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
