import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React, { memo, useMemo } from 'react'
import Tooltip from '@/components/Tooltip/Tooltip'
import { Container, Icon } from './styles'
import { ButtonProps } from './types'

const Button = ({
	disabled = false,
	name,
	schema,
	type = 'button',
	size = 'md',
	children,
	icon,
	onClick,
	isLoading = false,
	onMouseOver,
	onMouseDown,
	tooltip,
	ariaLabel,
	className,
	coloredIcon,
	title,
	style,
}: ButtonProps) => {
	const hasContent = !!children

	let iconToShow = icon

	if (isLoading) {
		iconToShow = faSpinner
	}

	let contents = useMemo(
		() => (
			<>
				{iconToShow && (
					<Icon
						disabled={disabled || false}
						$hasContent={hasContent}
						$coloredIcon={coloredIcon}
						$schema={schema || 'primary'}
					>
						<FontAwesomeIcon icon={iconToShow} spin={isLoading} />
					</Icon>
				)}
				{children}
			</>
		),
		[
			children,
			iconToShow,
			disabled,
			hasContent,
			coloredIcon,
			schema,
			isLoading,
		],
	)

	if (tooltip) {
		contents = <Tooltip content={tooltip}>{contents}</Tooltip>
	}

	return (
		<Container
			className={className}
			disabled={disabled || false}
			name={name}
			onClick={!disabled ? onClick : undefined}
			onMouseOver={onMouseOver}
			onMouseDown={onMouseDown}
			type={type}
			$hasContent={hasContent}
			$schema={schema || 'primary'}
			size={size || 'md'}
			aria-label={ariaLabel}
			title={title}
			style={style}
		>
			{contents}
		</Container>
	)
}

export default memo(Button)
