import React, { useCallback, useState } from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button } from '@/components'
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { UploadFile } from '../UploadFile'
import { useImport } from './hooks/useImport'
import CsvImporter from './CsvImporter/CsvImporter'
import { CsvOnMapped } from './CsvImporter/context/types'

type Props = {
	onClose: () => void
	node?: StructureDto
	title?: string
}

export const ImportColumnsCsv = ({ node, onClose, title }: Props) => {
	const { t } = useAppContext()

	const {
		loading,
		handleFile,
		handleImport,
		csvData,
		setCsvRows,
		setImportProps,
		file,
		setFile,
	} = useImport(onClose, node?.id)

	const [csvMapped, setCsvMapped] = useState<boolean>(false)

	const onMapped = useCallback<CsvOnMapped>(
		(data, importStructureProps, csvMapped) => {
			setCsvMapped(csvMapped ?? false)
			setImportProps(importStructureProps)
			setCsvRows(data)
		},
		[setCsvRows, setImportProps],
	)

	return (
		<Modal
			open={true}
			onClose={onClose}
			resizable={true}
			maximizeButtonVisible
			contentStyle={{ width: 700, minWidth: 700 }}
			header={title ? title : t('IMPORT_COLUMNS')}
			footer={
				<>
					<Button
						schema="primary"
						icon={faUpload}
						disabled={loading || !csvData || (!!csvData && !csvMapped)}
						isLoading={loading}
						onClick={handleImport}
					>
						{t('IMPORT_BUTTON')}
					</Button>
					<Button schema="transparent" onClick={onClose} icon={faTimes}>
						{t('CANCEL')}
					</Button>
				</>
			}
		>
			<UploadFile
				file={file}
				handleFile={handleFile}
				loading={loading}
				onRemoveFile={() => {
					setFile(null)
					onMapped()
				}}
			/>
			{csvData && node && (
				<CsvImporter
					nodeId={node?.id}
					inputData={csvData}
					onMapped={onMapped}
				/>
			)}
		</Modal>
	)
}
