import styled from 'styled-components'
import { Button } from '@/components'

export const ItemTitle = styled.div`
	color: #aaa;
	margin-bottom: 0.2rem;
`

export const ItemValue = styled.div`
	overflow-x: auto;
	margin-bottom: 1rem;
	padding-bottom: 0.2rem;

	pre {
		margin: 0;
	}
`

export const RefreshButton = styled(Button)`
	&& {
		flex-grow: 0;
		flex-basis: 9.3rem;
		margin-left: auto;
		width: auto;
	}
`
export const AppContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow: auto;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.layout.appBackgroundColor};
`
export const Description = styled.p`
	font-size: 110%;
	word-break: break-all;
	font-weight: 600;
	white-space: pre-wrap;
`
export const PopupText = styled.div`
	display: flex;
	align-items: center;
`
export const Message = styled.p`
	white-space: pre-wrap;
	word-break: break-all;
	margin-top: 20px;
`
