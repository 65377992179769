import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import {
	Form,
	TextAreaFormField,
	CheckBoxFormField,
	SelectFormField,
	TextFormField,
	NamingInputFormField,
} from '@/components/UberForm'
import { Flex } from '@/components/Layout/Flex'
import {
	OpenedMappingData,
	MappingDataForm,
} from '@/store/modules/mapping/types'
import {
	useAppContext,
	useAppDispatch,
	useCustomAttributesInitValues,
} from '@/hooks'
import { IdCodeName, MappingDataEnums } from '@/endpoints/schemas'
import { booleanString } from '@/utils/booleans'
import { uniqueNameValidator } from '@/utils/validators'
import { useApi } from '@/endpoints/hooks'
import { getStereotypes, getUsersForCombo, getSystemUsers } from '@/endpoints'
import {
	StereotypeDto,
	StructureObjectDto,
	ObjectSettingsUpdateDto,
} from '@/endpoints/models'
import { Col, Row, Legend, FieldSet } from '@/components/Layout'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { isInterfaceStereotype } from '@/utils/mapping'
import ObjectSettingsControl from '../../../../components/ObjectSettingsControl'
import { useNamingMapping } from './useNamingMapping'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'
import { MainContentWrap } from '@/styles'

interface Props {
	node?: StructureObjectDto
	data: OpenedMappingData
	editMode: boolean
	defaultMapping: boolean
	systemNodeId: number
	onChange: (v: Partial<MappingDataForm>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureObjectDto[]
}

export const Overview = ({
	node,
	onChange,
	data,
	editMode,
	systemNodeId,
	defaultMapping,
	selectedNodes,
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const refForm = useRef<Form<MappingDataForm>>(null)

	const isInterface =
		data.form.defaultMappingType ===
		MappingDataEnums.MappingDefaultMappingTypeEnum.INTERFACE

	const stereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.MAPPING }),
	)

	const [users, isUserLoading] = useApi(getUsersForCombo())

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId),
	)

	const handleObjectSettingsChange = (newValue: IdCodeName[]) => {
		onChange({
			objectSettings: newValue,
		})
	}

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes,
	} = useCustomAttributesInitValues(
		data.form,
		defaultMapping
			? StructureObjectDto.TypeEnum.MAPPING_DEFAULT
			: StructureObjectDto.TypeEnum.MAPPING,
	)

	const mappingStates = useMemo(
		() => [
			{
				label: t('MAPPING_STATUS_LOGICAL_IN_PROGRESS'),
				value: MappingDataEnums.MappingStatusEnum.LOGICAL_IN_PROGRESS,
			},
			{
				label: t('MAPPING_STATUS_LOGICAL_COMPLETED'),
				value: MappingDataEnums.MappingStatusEnum.LOGICAL_COMPLETED,
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_IN_PROGRESS'),
				value: MappingDataEnums.MappingStatusEnum.PHYSICAL_IN_PROGRESS,
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_COMPLETED'),
				value: MappingDataEnums.MappingStatusEnum.PHYSICAL_COMPLETED,
			},
			{
				label: t('MAPPING_STATUS_FROZEN'),
				value: MappingDataEnums.MappingStatusEnum.FROZEN,
			},
		],
		[t],
	)

	const onChangeForm = (
		item: Partial<MappingDataForm>,
		source?: FormlessFormField<MappingDataForm>,
	) => {
		if (source) {
			const name = source.props.name

			if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
				const user = users?.find((u) => u.id === item[name])

				const userName =
					name === 'responsiblePersonId'
						? 'responsiblePersonName'
						: 'responsibleAnalystName'

				onChange({
					[name]: user?.id,
					[userName]: user?.value,
				})

				return
			}

			if (name === 'stereotypeId') {
				const stereotype = stereotypes.data?.find((s) => s.id === item[name])

				const { customAttributesForm, customAttributesRedux } =
					getCustomAttributesReset(stereotype?.id)

				refForm.current?.setValues(customAttributesForm)

				onChange({
					stereotypeId: stereotype?.id,
					stereotypeCode: stereotype?.code,
					customAttributes: customAttributesRedux,
				})

				return
			}

			if (name === 'ownerId') {
				const owner = systemUsers?.find((u) => u.id === item[name])

				onChange({
					[name]: owner?.id,
					ownerName: owner?.name,
				})

				return
			}

			onChange(
				parseCustomAttribute(
					{
						[name]: item[name],
					},
					data.form.customAttributes,
					customAttributes,
				),
			)
		}
	}

	const namingConfigs = useNamingMapping(
		data.form,
		defaultMapping,
		editMode,
		node,
	)

	return (
		<MainContentWrap>
			<Form<MappingDataForm>
				onChange={onChangeForm}
				defaultValues={{ ...data.form, ...initialValuesCustomAttributes }}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
				ref={refForm}
			>
				<Row $alignItems="flex-end">
					<Col size="medium">
						{namingConfigs === undefined || namingConfigs.length === 1 ? (
							<TextFormField
								title={t('MAPPING_NAME')}
								name="name"
								initialValue={data.form.name}
								validators={[uniqueNameValidator(dispatch, data.original.name)]}
								disabled={Boolean(selectedNodes)}
							/>
						) : (
							<NamingInputFormField
								node={node}
								name="name"
								title={t('MAPPING_NAME')}
								callNamingWorkingDataParams={namingConfigs[0]}
								initialValue={data.form.name}
								disabled={Boolean(selectedNodes)}
							/>
						)}
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('MAPPING_CODE')}
							callNamingWorkingDataParams={
								namingConfigs === undefined
									? undefined
									: namingConfigs?.length > 1
										? namingConfigs[1]
										: namingConfigs[0]
							}
							initialValue={data.form.code}
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('MAPPING_STEREOTYPE')}
							initialValue={data.form.stereotypeId}
							fieldIsLoading={stereotypes.reloading}
							options={
								isInterface
									? stereotypes.data?.filter((s) =>
											isInterfaceStereotype(s.code),
										)
									: stereotypes.data?.filter(
											(s) => !isInterfaceStereotype(s.code),
										) || []
							}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
							disabled={defaultMapping && !isInterface}
						/>
					</Col>
					<Col size="small">
						<Flex>
							<Checkbox
								name="truncate"
								title={t('MAPPING_TRUNCATE')}
								initialValue={data.form.truncate}
								disabled={defaultMapping}
							/>
							<Checkbox
								name="distinct"
								title={t('MAPPING_DISTINCT')}
								initialValue={data.form.distinct}
								disabled={defaultMapping}
							/>
						</Flex>
					</Col>
				</Row>

				<Row>
					<Col size="big">
						<TextAreaFormField
							name="description"
							title={t('MAPPING_DESCRIPTION')}
							initialValue={data.form.description}
						/>
					</Col>
					<Col size="big">
						<TextAreaFormField
							name="comment"
							title={t('MAPPING_COMMENT')}
							initialValue={data.form.comment}
						/>
					</Col>
				</Row>
				<ObjectSettingsControl
					name="objectSettings"
					label={t('OBJECT_SETTINGS')}
					onChange={handleObjectSettingsChange}
					disabled={!editMode}
					data={data.form.objectSettings}
					type={ObjectSettingsUpdateDto.StructureTypeEnum.MAPPING}
				/>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('MAPPING_STATUS')}
								initialValue={data.form.status}
								options={mappingStates}
								valueKey="value"
								labelKey="label"
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers || []}
								fieldIsLoading={isSystemUserLoading}
								initialValue={data.form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</MainContentWrap>
	)
}

const Checkbox = styled(CheckBoxFormField)`
	flex: 0;
`

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
