import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { stopEvent } from '@/utils/events'
import { TreeNode, TreeItemDropDirection, TreeItemProps } from './types'
import { useWindowEvent } from '@/hooks'
import {
	Children,
	Container,
	DragDropContainer,
	Title,
	TitleActions,
	TitleArrow,
	TitleDropUp,
	TitleIcon,
	TitleText,
} from './styles'

export const TreeItem = <N extends TreeNode>({
	expanded,
	node,
	onItemClick,
	onItemExpand,
	onItemDblClick,
	highlighted,
	level,
	focused,
	actionComponent,
	hideArrows,
	iconComponent,
	onItemContextMenu,
	onFocus,
	onCheckChange,
	showCheckbox,
	isCheckedFunc,
	getDragDrop,
}: TreeItemProps<N>) => {
	const { title, children, label } = node
	const isHighlighted = !!(highlighted && highlighted === node.key)
	const [dropActiveUp, setDropActiveUp] = useState(false)
	const [isCtrl, setIsCtrl] = useState(false)
	const [targetParent, setTargetParent] = useState<EventTarget | null>(null)
	const [dropActiveInside, setDropActiveInside] = useState(false)
	const itemRef = useRef<HTMLDivElement>(null)

	useWindowEvent<KeyboardEvent>('keydown', (e) => {
		setIsCtrl(e.ctrlKey)
	})

	useWindowEvent<KeyboardEvent>('keyup', (e) => {
		setIsCtrl(e.ctrlKey)
	})

	useEffect(() => {
		const item = itemRef.current

		if (onFocus && item && focused === node.key) {
			onFocus(item)
		}
	}, [itemRef, focused, onFocus])

	const isExpanded = useMemo(
		() => expanded.includes(node.key),
		[expanded, node.key],
	)

	const handleClick = useCallback(() => {
		if (onItemClick) {
			onItemClick(node)
		}

		if (onItemExpand && node.children !== undefined && !isExpanded) {
			onItemExpand(node, true)
		}
	}, [node, onItemClick, onItemExpand, isExpanded])

	const handleDblClick = useCallback(() => {
		if (onItemDblClick) {
			onItemDblClick(node)
		}
	}, [onItemDblClick, node])

	const handleIconClick = useCallback(() => {
		if (onItemClick) {
			onItemClick(node)
		}

		if (onItemExpand && node.children !== undefined) {
			onItemExpand(node, !isExpanded)
		}
	}, [node, isExpanded, onItemClick, onItemExpand])

	const handleMouseDown = useCallback((event: React.MouseEvent) => {
		if (event.nativeEvent.detail > 1) {
			event.stopPropagation()
			event.preventDefault()
		}
	}, [])

	const handleContextMenu = useCallback(
		(event: React.MouseEvent) => {
			if (onItemContextMenu) {
				onItemContextMenu(node, event)
			}
		},
		[onItemContextMenu, node],
	)

	const dragDrop = useMemo(
		() => (getDragDrop ? getDragDrop(node) : null),
		[node, getDragDrop],
	)

	return (
		<Container>
			<DragDropContainer
				$highlighted={isHighlighted}
				$level={level}
				$hasChildren={!!children}
				$hideArrows={!!hideArrows}
				$isActive={dropActiveInside}
			>
				<Title
					ref={itemRef}
					$level={level}
					$hasChildren={!!children}
					$hideArrows={!!hideArrows}
					onContextMenu={handleContextMenu}
					role="treeitem"
					{...(dragDrop && {
						draggable: dragDrop.draggable,
						onDragStart: dragDrop.onDragStart,
						onDragEnter: dragDrop.onDragEnter,
						onDragOver: dragDrop.onDragOver,
						onDragLeave: dragDrop.onDragLeave,
					})}
					{...(dragDrop?.canDropInside && {
						onDragEnter: stopEvent((e: any) => {
							dragDrop.onDragEnter(e)
							setDropActiveInside(true)
							setTargetParent(e.target)
						}),
						onDragLeave: (e: any) => {
							// prevent call from children
							if (targetParent == e.target) {
								e.preventDefault()
								e.stopPropagation()
								dragDrop.onDragLeave(e)
								setDropActiveInside(false)
							}
						},
						onDrop: stopEvent((e: any) => {
							dragDrop.onDrop(e, TreeItemDropDirection.INSIDE)
							setDropActiveInside(false)
						}),
					})}
				>
					{dragDrop && (
						<TitleDropUp
							$isActive={dropActiveUp}
							onDragEnter={stopEvent(() => setDropActiveUp(true))}
							onDragLeave={stopEvent(() => setDropActiveUp(false))}
							onDrop={stopEvent((e: any) => {
								dragDrop.onDrop(e, TreeItemDropDirection.UP)
								setDropActiveUp(false)
							})}
						/>
					)}
					{children && !hideArrows && (
						<TitleArrow
							$isExpanded={isExpanded}
							onClick={handleIconClick}
							onMouseDown={handleMouseDown}
							role="button"
						>
							<FontAwesomeIcon icon={faChevronRight} fixedWidth />
						</TitleArrow>
					)}
					{showCheckbox && (
						<input
							type="checkbox"
							name="nodeSelect"
							checked={isCheckedFunc && isCheckedFunc(node)}
							onChange={(e) => {
								onCheckChange?.(e.currentTarget.checked, node, isCtrl)
							}}
						/>
					)}
					<TitleIcon
						onClick={!hideArrows ? handleClick : handleIconClick}
						onDoubleClick={!hideArrows ? handleDblClick : undefined}
						onMouseDown={handleMouseDown}
						role="button"
					>
						{iconComponent && iconComponent(node, isExpanded, isHighlighted)}
					</TitleIcon>

					<TitleText
						onClick={handleClick}
						onDoubleClick={handleDblClick}
						onMouseDown={handleMouseDown}
						title={title}
					>
						{label || title}
					</TitleText>
					<TitleActions>
						{actionComponent &&
							actionComponent(node, isExpanded, isHighlighted)}
					</TitleActions>
				</Title>
			</DragDropContainer>
			{isExpanded && children && (
				<Children $level={level}>
					{children.map((c) => (
						<TreeItem<N>
							node={c}
							key={c.key}
							showCheckbox={showCheckbox}
							isCheckedFunc={isCheckedFunc}
							onCheckChange={onCheckChange}
							onItemClick={onItemClick}
							onItemExpand={onItemExpand}
							onItemDblClick={onItemDblClick}
							onItemContextMenu={onItemContextMenu}
							iconComponent={iconComponent}
							actionComponent={actionComponent}
							highlighted={highlighted}
							level={level + 1}
							expanded={expanded}
							onFocus={onFocus}
							focused={focused}
							getDragDrop={getDragDrop}
						/>
					))}
				</Children>
			)}
		</Container>
	)
}
