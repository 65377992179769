import styled from 'styled-components'

interface TabTitleProps {
	$isSelected: boolean
}

export const TabTitle = styled.span<TabTitleProps>`
	display: inline-block;
	text-transform: uppercase;
	user-select: none;
	background-color: ${(props) => (props.$isSelected ? '#fff' : '#f0f0f0')};
	padding: 8px 16px;
	cursor: pointer;
	&:hover {
		background-color: ${(props) => (props.$isSelected ? '#fff' : '#f5f5f5')};
	}
	&:not(:first-child) {
		border-left: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	}
	&:last-child {
		border-right: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	}
`

export const TabTitleIcon = styled.span`
	margin-left: 5px;
`

export const TabsBar = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	background-color: #fcfcfc;
`

export const TabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex-grow: 1;
	min-height: 0;
`

export const TabsContent = styled.div`
	flex-grow: 1;
	overflow-x: auto;
	overflow-y: hidden;
	align-content: flex-start;
`
