import { useCallback, useMemo } from 'react'
import {
	computeImpactTCChange,
	createTechnicalColumn,
	updateTechnicalColumn,
} from 'src/endpoints'

import { useApiRequest } from '@/endpoints/hooks'
import { TechnicalColumnDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useConfirmation } from '@/pages/App/components/ConfirmationService'

export const useConfirmationTechnicalColumn = () => {
	const { t } = useAppContext()
	const confirm = useConfirmation()
	const request = useApiRequest()

	const handleUpdate = async (
		technicalColumn: TechnicalColumnDto,
		postProcess: (technicalColumn: TechnicalColumnDto | null) => void,
	) => {
		const impact = await request(
			computeImpactTCChange(technicalColumn?.id || 0, technicalColumn),
		)

		if (impact.data?.affectedElements?.TABLE) {
			const description = (
				<>
					<div>
						{t('TECHNICAL_COLUMN_UPDATE_AFFECTED_MESSAGE', [
							impact.data?.affectedElements?.TABLE || 0,
						])}
					</div>
				</>
			)

			confirm({
				catchOnCancel: true,
				title: t('TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE'),
				description: description,
			})
				.then((options) => {
					if (options?.cancel) {
						postProcess(technicalColumn)

						return
					}

					request(
						updateTechnicalColumn(technicalColumn?.id || 0, technicalColumn, {
							updateElements: true,
						}),
					).then((value) => postProcess(value.data))
				})
				.catch(() => {
					request(
						updateTechnicalColumn(technicalColumn?.id || 0, technicalColumn, {
							updateElements: false,
						}),
					).then((value) => postProcess(value.data))
				})
		} else {
			request(
				updateTechnicalColumn(technicalColumn?.id || 0, technicalColumn, {
					updateElements: false,
				}),
			).then((value) => postProcess(value.data))
		}
	}

	const handleCreate = async (
		technicalColumn: TechnicalColumnDto,
		postProcess: (technicalColumn: TechnicalColumnDto | null) => void,
	) => {
		if (
			technicalColumn.stereotypeIds &&
			technicalColumn.stereotypeIds?.length > 0
		) {
			const description = (
				<>
					<div>{t('TECHNICAL_COLUMN_ADD_TO_TABLES')}</div>
				</>
			)

			confirm({
				catchOnCancel: true,
				title: t('TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE'),
				description: description,
			})
				.then((options) => {
					if (options?.cancel) {
						postProcess(technicalColumn)

						return
					}

					request(
						createTechnicalColumn(technicalColumn, {
							updateElements: true,
						}),
					).then((value) => postProcess(value.data))
				})
				.catch(() => {
					request(
						createTechnicalColumn(technicalColumn, {
							updateElements: false,
						}),
					).then((value) => postProcess(value.data))
				})
		} else {
			await request(
				createTechnicalColumn(technicalColumn, {
					updateElements: false,
				}),
			).then((value) => postProcess(value.data))
		}
	}

	const onConfirmationTechnicalColumn = useCallback(
		async (
			technicalColumn: TechnicalColumnDto,
			postProcess: (technicalColumn: TechnicalColumnDto | null) => void,
		) => {
			if (technicalColumn?.id) {
				await handleUpdate(technicalColumn, postProcess)
			} else {
				await handleCreate(technicalColumn, postProcess)
			}
		},
		[confirm, request, t],
	)

	return useMemo(
		() => ({ onConfirmationTechnicalColumn }),
		[onConfirmationTechnicalColumn],
	)
}
