import {
	PartitioningStrategy,
	RetentionTimesEnum,
	TableOverview,
} from '@/endpoints/schemas'
import { TranslationHelper } from '@/context/Locale/Locale'
import { useMemo } from 'react'

export const useEntityTypes = (t: TranslationHelper) =>
	useMemo(
		() => [
			{
				label: t('ENTITY_TYPE_AGGREGATE'),
				value: TableOverview.EntityTypeEnum.AGGREGATE,
			},
			{
				label: t('ENTITY_TYPE_AUXILIARY'),
				value: TableOverview.EntityTypeEnum.AUXILIARY,
			},
			{
				label: t('ENTITY_TYPE_COMPOSITION'),
				value: TableOverview.EntityTypeEnum.COMPOSITION,
			},
			{
				label: t('ENTITY_TYPE_FUNDAMENTAL'),
				value: TableOverview.EntityTypeEnum.FUNDAMENTAL,
			},
			{
				label: t('ENTITY_TYPE_METADATA'),
				value: TableOverview.EntityTypeEnum.METADATA,
			},
			{
				label: t('ENTITY_TYPE_RELATION'),
				value: TableOverview.EntityTypeEnum.RELATION,
			},
			{
				label: t('ENTITY_TYPE_SNAPSHOT'),
				value: TableOverview.EntityTypeEnum.SNAPSHOT,
			},
			{
				label: t('ENTITY_TYPE_SPECIALISATION'),
				value: TableOverview.EntityTypeEnum.SPECIALISATION,
			},
			{
				label: t('ENTITY_TYPE_TRANSACTION'),
				value: TableOverview.EntityTypeEnum.TRANSACTION,
			},
		],
		[t],
	)

export const useStrategies = (t: TranslationHelper) =>
	useMemo(
		() => [
			{
				label: t('TABLE_RETENTION_STRATEGY_INFINITE'),
				value: TableOverview.RetentionStrategyEnum.INFINITE,
			},
			{
				label: t('TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY'),
				value: TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY,
			},
			{
				label: t('TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM'),
				value: TableOverview.RetentionStrategyEnum.KEEP_FULL_HISTORY_AND_EOM,
			},
		],
		[t],
	)

export const useRetentionTimes = (t: TranslationHelper) =>
	useMemo(
		() => [
			{
				label: t('DAYS'),
				value: RetentionTimesEnum.DAYS,
			},
			{
				label: t('MONTHS'),
				value: RetentionTimesEnum.MONTHS,
			},
			{
				label: t('YEARS'),
				value: RetentionTimesEnum.YEARS,
			},
		],
		[t],
	)

export const getPartitionStrategies = (
	isHistoryMode: boolean,
	t: TranslationHelper,
) => {
	const partitionStrategies = [
		{
			label: t('PARITITION_STRATEGY_NO_PARTITION'),
			value: PartitioningStrategy.NO_PARTITION,
		},
		{
			label: t('PARITITION_STRATEGY_KEY'),
			value: PartitioningStrategy.KEY,
		},
		{
			label: t('PARITITION_STRATEGY_SOURCE_SYSTEM'),
			value: PartitioningStrategy.SOURCE_SYSTEM,
		},
		{
			label: t('PARITITION_STRATEGY_SNAPSHOT_DATA'),
			value: PartitioningStrategy.SNAPSHOT_DATA,
		},
		{
			label: t('PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT'),
			value: PartitioningStrategy.SOURCE_AND_SNAPSHOT,
		},
		{
			label: t('PARITITION_STRATEGY_KEY_AND_SNAPSHOT'),
			value: PartitioningStrategy.KEY_AND_SNAPSHOT,
		},
		{
			label: t('PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE'),
			value: PartitioningStrategy.SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE,
		},
		{
			label: t('PARITITION_STRATEGY_KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE'),
			value: PartitioningStrategy.KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE,
		},

		{
			label: t('PARITITION_STRATEGY_SOURCE_SYSTEM_AND_VALID_TO'),
			value: PartitioningStrategy.SOURCE_SYSTEM_AND_VALID_TO,
		},
	]

	if (isHistoryMode) {
		return partitionStrategies
	}

	return partitionStrategies.slice(0, partitionStrategies.length - 1)
}
