import React from 'react'

export enum TreeItemDropDirection {
	UP = 'UP',
	INSIDE = 'INSIDE',
}

export type GetDragDrop<N> = (node: N) => DragDrop
export interface DragDrop {
	draggable: boolean
	onDragStart: (e: React.DragEvent) => void
	onDrop: (e: React.DragEvent, dropDirection: TreeItemDropDirection) => void
	onDragLeave: (e: React.DragEvent) => void
	onDragEnter: (e: React.DragEvent) => void
	onDragOver: (e: React.DragEvent) => void
	canDropInside?: boolean
}

export interface TreeNode {
	key: string | number
	title: string
	label?: React.ReactNode
	children?: this[]
}

export interface TreeNodeProps<N extends TreeNode> {
	onItemClick?: (node: N) => void
	onItemDblClick?: (node: N) => void
	onItemContextMenu?: (node: N, e: React.MouseEvent) => void
	onItemExpand?: (node: N, expanded: boolean) => void
	onFocus?: (element: HTMLElement) => void
	iconComponent?: (
		node: N,
		expanded: boolean,
		highlighted: boolean,
	) => React.ReactNode
	actionComponent?: (
		node: N,
		expanded: boolean,
		highlighted: boolean,
	) => React.ReactNode
	hideArrows?: boolean
	focused?: string | number | null
	getDragDrop?: GetDragDrop<N>
	onCheckChange?: (isChecked: boolean, item: N, isSingle: boolean) => void
	showCheckbox?: boolean
	isChecked?: boolean
	isCheckedFunc?: (item: N) => boolean
}

export type TreeItemProps<N extends TreeNode> = TreeNodeProps<N> & {
	node: N
	highlighted: string | number | null
	level: number
	expanded: (string | number)[]
}

export type TreeProps<N extends TreeNode> = TreeNodeProps<N> & {
	roots: N[]
	expanded: (string | number)[]
	highlighted: string | number | null
	className?: string
	focused?: string | number
	onFocused?: () => void
	onItemCheckChange?: (isChecked: boolean, item: N, isSingle: boolean) => void
	showCheckboxes?: boolean
	isCheckedFunc?: (item: N) => boolean
}
