import { HttpMethod, request } from '@/endpoints/utils'
import { DpStatus } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/types'

export const changeSystemReleaseStatus = (
	systemId: number,
	releaseId: number,
	newStatus: DpStatus,
) =>
	request<void>(
		HttpMethod.PATCH,
		'structure/system/{systemId}/release/{releaseId}/status/{newStatus}',
		{ systemId, releaseId, newStatus },
	)
