import { HISTORY_PUSH, HISTORY_REVERT, HISTORY_GET } from './constants'
import {
	HistoryDto,
	NewHistoryDto,
	StructureObjectDto,
} from '@/endpoints/models'
import { apiCallAction } from '@/store/utils'
import {
	createNodeHistory,
	deleteDataNode,
	getAllNodeHistory,
} from 'src/endpoints'
import { loadNodeChildren } from '../node/actions'

interface PushHistory {
	type: typeof HISTORY_PUSH
	payload: HistoryDto
}

interface RevertHistory {
	type: typeof HISTORY_REVERT
	payload: void
}

interface GetHistory {
	type: typeof HISTORY_GET
	payload: HistoryDto[]
	metadata: {
		structureId: number
	}
}

export const pushHistory =
	(node: StructureObjectDto, data: NewHistoryDto, fixImpacts?: boolean) =>
	async (dispatch: (action: object) => Promise<object>) => {
		await dispatch(
			apiCallAction<PushHistory>(
				() =>
					createNodeHistory(node.id, data, {
						fixImpacts,
					}),
				HISTORY_PUSH,
			),
		)

		if (node.parentStructureId) {
			dispatch(loadNodeChildren(node.parentStructureId))
		}

		dispatch(loadHistory(node.id))
	}

export const revertHistory = (id: number) =>
	apiCallAction<RevertHistory>(() => deleteDataNode(id), HISTORY_REVERT)

export const loadHistory = (structureId: number) =>
	apiCallAction<GetHistory>(() => getAllNodeHistory(structureId), HISTORY_GET, {
		structureId,
	})

export type Actions = PushHistory | RevertHistory | GetHistory
