import { DomainDto } from '@/endpoints/models'
import { SystemMapping } from '@/endpoints/schemas'
import { RegExpProps } from '@/components/RegExpValidatedInput/RegExpValidatedInput'
import { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

const regExpCreator =
	(domains: DomainDto[]) =>
	(item: SystemMapping): RegExpProps | undefined => {
		if (!item.domainId) {
			return undefined
		}

		const domain = domains.find((x) => x.id === item.domainId && x.hasTemplate)

		if (!domain || !domain.templateValueRegex) {
			return undefined
		}

		return {
			sampleValue: domain.templateValueFormat,
			expression: new RegExp(domain.templateValueRegex),
		}
	}

export const useConstantsProperties = (
	domains: DomainDto[],
	canGenerateNaming: boolean,
) => {
	const { t } = useAppContext()

	const properties = useMemo<TableColumnProps<SystemMapping>[]>(
		() => [
			{
				field: 'name',
				label: t('FIELD_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('FIELD_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				autoWidth: true,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'domainId',
				label: t('FIELD_DOMAIN'),
				type: ItemPropertyType.OPTION,
				options: domains,
				optionValue: 'id',
				optionLabel: 'name',
				isNumeric: true,
				width: 150,
			},
			{
				field: 'value',
				label: t('FIELD_VALUE'),
				type: ItemPropertyType.REGEXP_VALIDATED,
				regExp: regExpCreator(domains),
				width: 150,
			},
			{
				field: 'description',
				label: t('FIELD_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
		],
		[canGenerateNaming, domains, t],
	)

	return properties
}
