import qs from 'qs'

import { joinUrl } from '@/utils/url'
import { appConfig } from '@/config/appConfig'

export enum HttpMethod {
	POST = 'POST',
	GET = 'GET',
	PUT = 'PUT',
	DELETE = 'DELETE',
	PATCH = 'PATCH',
}

export enum ResultType {
	JSON,
	STRING,
	ARRAY_BUFFER,
}

export type ApiErrorJson = {
	errorCode: string
	errorDescription: string
	errorMessage: string
}

const resultTypeToAccept = {
	[ResultType.JSON]: 'application/json; charset=utf-8',
	[ResultType.STRING]: 'application/json; charset=utf-8',
	[ResultType.ARRAY_BUFFER]: 'application/octet-stream, application/json',
}

const baseUrl = appConfig.apiUrl

export class ApiError extends Error {
	response: Response

	constructor(m: string, response: Response) {
		super(m)

		this.response = response

		Object.setPrototypeOf(this, ApiError.prototype)
	}
}

export class NetworkError extends Error {
	constructor(m: string) {
		super(m)

		Object.setPrototypeOf(this, NetworkError.prototype)
	}
}

export const request = <R>(
	method: HttpMethod,
	url: string,
	pathParams?: { [key: string]: string | number | boolean | undefined },
	query?: { [key: string]: string | number | boolean | undefined },
	body?:
		| string
		| Blob
		| ArrayBufferView
		| ArrayBuffer
		| FormData
		| URLSearchParams
		| ReadableStream<Uint8Array>,
	result = ResultType.JSON,
	abortController?: AbortController,
) => {
	// First calculate called url - this is later used as cache key
	if (pathParams) {
		Object.entries(pathParams).forEach(([key, value]) => {
			url = url.replace(
				`{${key}}`,
				value !== undefined ? encodeURIComponent(value) : '',
			)
		})
	}

	const qsQuery = qs.stringify(query)
	const callUrl = joinUrl(baseUrl, url) + (qsQuery ? '?' + qsQuery : '')

	// Build callback method
	const call = async (token: string | null): Promise<R> => {
		let res: Response

		if (!token) {
			throw new Error('Unauthorized - token is null')
		}

		try {
			res = await fetch(callUrl, {
				method,
				body,
				headers: {
					Authorization: 'Bearer ' + (token ? token : null),
					'X-TenantId': appConfig.realm,
					Accept: resultTypeToAccept[result],
					...(!(body instanceof FormData) && {
						'Content-Type': 'application/json; charset=utf-8',
					}),
				},
				signal: abortController?.signal,
			})
		} catch (err) {
			throw new NetworkError(err as string)
		}

		if (!res.ok) {
			throw new ApiError(`${res.status} ${res.statusText}`, res)
		}

		try {
			switch (result) {
				case ResultType.JSON:
					const text = await res.text()

					return (text ? JSON.parse(text) : null) as R
				case ResultType.STRING:
					return (await res.text()) as unknown as R
				case ResultType.ARRAY_BUFFER:
					return res.arrayBuffer() as unknown as R
				default:
					throw new Error(`Uknown result type ${ResultType[result]}`)
			}
		} catch (err) {
			throw new ApiError('Failed to parse response: ' + err, res)
		}
	}

	// Save cache key to callback method
	call.__key = `${method} ${callUrl}`

	return call
}
