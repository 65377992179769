import styled, { css } from 'styled-components'
import { Tabs } from '@/components/Tabs/Tabs'

interface MainContentWrap {
	$isRowOrderable?: boolean
}

export const MainContentWrap = styled.div<MainContentWrap>`
	padding: 16px 32px 16px 16px;
	${(props: MainContentWrap) => css`
		padding-left: ${props.$isRowOrderable ? '0' : '16px'};
	`}
	overflow: auto;
	height: 100%;
	box-sizing: border-box;
`

export const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
