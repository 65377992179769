import React, { useEffect, useState, useMemo, useRef } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import {
	CustomAttributeDto,
	CustomAttributeCreateDto,
	CustomAttributeUpdateDto,
} from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import {
	SelectFormField,
	Form,
	TextAreaFormField,
	TextFormField,
	Validators,
} from '@/components/UberForm'
import { useApiRequest, useApi } from '@/endpoints/hooks'
import {
	getCustomAttribute,
	createCustomAttribute,
	updateCustomAttribute,
	getAllCustomLovs,
} from 'src/endpoints'
import { Flex } from '@/components/Layout'
import { enumToValueLabelCollection } from '@/utils/collections'
import { useCustomAttributesStereotypes } from '../hooks/useCustomAttributesStereotypes'

type CustomAttributesModalProps = {
	customAttribute?: Partial<CustomAttributeDto>
	onSave: () => void
	onClose: () => void
	position?: number
	textAreaHeight?:string
}

export const CustomAttributesModal = ({
	customAttribute,
	onSave,
	onClose,
	position,
	textAreaHeight
}: CustomAttributesModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const refForm = useRef<Form<CustomAttributeDto>>(null)
	const stereotypes = useCustomAttributesStereotypes()

	const customAttributeDetail = useApi(
		getCustomAttribute(customAttribute?.id as number),
	)

	const customLovs = useApi(getAllCustomLovs())
	const [selectedType, setSelectedType] = useState(customAttribute?.type)

	const handleSubmit = async (form: Partial<CustomAttributeDto>) => {
		if (!form.ordering) {
			form.ordering = position && position + 1
		}

		const actionFn = () => {
			if (customAttribute && customAttribute.id) {
				return updateCustomAttribute(
					customAttribute.id,
					form as CustomAttributeUpdateDto,
				)
			} else {
				return createCustomAttribute(form as CustomAttributeCreateDto)
			}
		}

		const result = await request(actionFn())

		if (result) {
			customAttributeDetail.invalidate()
			onSave()
			onClose()
		}
	}

	const formData = useMemo(
		() => customAttributeDetail?.data || ({} as CustomAttributeDto),
		[customAttributeDetail],
	)

	const initialValues = useMemo(
		() => ({
			name: formData.name,
			description: formData.description,
			type: formData.type,
			structureTypes: formData.structureTypes,
			stereotypeIds: formData.stereotypeIds,
			regex: formData.regex,
			customLovId: formData.customLovId,
			ordering: formData.ordering,
		}),
		[formData],
	)

	useEffect(() => {
		refForm.current?.setValues(initialValues)

		setSelectedType(initialValues.type)
	}, [initialValues])

	const header = () => (
		<>
			{customAttribute
				? t('CUSTOM_ATTRIBUTE_UPDATE')
				: t('CUSTOM_ATTRIBUTE_NEW')}
		</>
	)

	return (
		<>
			<ModalForm<CustomAttributeDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				maximizeButtonVisible
				initialValues={initialValues}
				formRef={refForm}
				header={header}
				onSubmit={handleSubmit}
			>
				<Flex style={{ alignItems: 'flex-start' }}>
					<TextFormField
						name="name"
						title={t('NAME')}
						required
						validators={[Validators.includesPeriod()]}
					/>
					<TextAreaFormField textAreaHeight={textAreaHeight} name="description" title={t('DESCRIPTION')} />
				</Flex>
				<Flex>
					<SelectFormField
						title={t('ELEMENT_TYPES')}
						name="structureTypes"
						placeholder=" "
						options={enumToValueLabelCollection(
							CustomAttributeCreateDto.StructureTypesEnum,
						)}
						valueKey="value"
						labelKey="label"
						allowEmpty
						required
						isMulti
					/>
					<SelectFormField
						title={t('STEREOTYPES')}
						name="stereotypeIds"
						options={stereotypes.data ?? []}
						loading={stereotypes.loading}
						valueKey="id"
						labelKey="name"
						allowEmpty
						isMulti
						isNumeric
					/>
					<SelectFormField
						title={t('INPUT_TYPE')}
						name="type"
						options={enumToValueLabelCollection(CustomAttributeDto.TypeEnum)}
						valueKey="value"
						labelKey="label"
						allowEmpty
						onChange={(value: any) => {
							refForm.current?.setValues(
								{
									regex: undefined,
								},
								true,
							)

							setSelectedType(value as CustomAttributeDto.TypeEnum)
						}}
						required
					/>
				</Flex>
				<Flex>
					<div style={{ width: '33.333%' }}>
						<SelectFormField
							title={t('CUSTOM_LOV')}
							name="customLovId"
							options={customLovs.data || []}
							valueKey="id"
							labelKey="name"
							allowEmpty
							required={selectedType === CustomAttributeDto.TypeEnum.LOV}
							disabled={selectedType !== CustomAttributeDto.TypeEnum.LOV}
							isNumeric
						/>
					</div>
					<TextFormField
						name="regex"
						title={t('REGEX_VALIDATION')}
						disabled={selectedType !== CustomAttributeDto.TypeEnum.STRING}
						validators={
							selectedType === CustomAttributeDto.TypeEnum.STRING
								? [Validators.isRightRegexSyntax(true)]
								: undefined
						}
					/>
				</Flex>
			</ModalForm>
		</>
	)
}
