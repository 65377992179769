import styled, { css } from 'styled-components'
import Select, { StylesConfig } from 'react-select'
import { colors } from '@/styles'
import * as theme from '@/styles'
import { StyledSelectProps } from '@/components/UberForm/Input/Select/types'

// Use the object way to define styles as priority over the css way
export const customStyles: StylesConfig = {
	menuPortal: (baseStyles) => ({
		...baseStyles,
		zIndex: 999,
	}),
	option: (baseStyles, state) => {
		const { base, light } = colors.primary

		return {
			...baseStyles,
			background: state.isSelected ? base : state.isFocused ? light : '#fff',
			':hover': {
				...baseStyles[':hover'],
				background: state.isSelected ? '#000' : light,
			},
			':active': {
				...baseStyles[':active'],
				background: state.isSelected ? '#000' : light,
			},
		}
	},
	indicatorSeparator: (baseStyles) => ({
		...baseStyles,
	}),
	control: (baseStyles, state) => ({
		...baseStyles,
		borderColor: `${theme.colors.input.border}`,
		borderRadius: `${theme.input.borderRadius}`,
		boxShadow: state.isFocused
			? `0 0 0 1px ${theme.mainColors.primary}`
			: baseStyles.boxShadow,
		'&:hover': {
			boxShadow: state.isFocused
				? `0 0 0 1px ${theme.mainColors.primary}`
				: baseStyles.borderColor,
		},
		minHeight: 'auto',
		height: '100%',
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		color: `${theme.colors.input.placeholder}`,
	}),

	container: (baseStyles) => ({
		...baseStyles,
		width: '100%',
	}),
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
	height: 100%;
	width: ${(props) => props.customWidth || '100%'};
	${(props) => css`
		.react-select__control {
			.react-select__value-container {
				margin-right: ${props.isClearable ? '55px' : '26px'};
			}
		}
		.react-select__indicators {
			position: absolute;
			top: 50%;
			right: 0;
			.react-select__indicator {
				padding: 8px 4px;
			}
			transform: translate(0, -50%);
			height: 100%;
		}
		.Select-input {
			height: 24px;
		}

		.Select-input > input {
			line-height: 22px;
		}

		.Select-value {
			line-height: 1;
			margin-bottom: -1px;
			margin-top: 0;
		}

		.react-select__single-value {
			overflow: initial;
		}
		span.Select-arrow-zone {
			top: 1px;
		}
	`}

	${(props) =>
		props.$compact &&
		css`
			div.react-select__control {
				min-height: auto;
				border-radius: 0;
				border-color: ${props.theme.colors.input.$compact.horizontalBorder}
					${props.theme.colors.input.$compact.verticalBorder};
				border-width: 0;
				box-shadow: none;
				:hover {
					box-shadow: none;
				}

				div.react-select__value-container {
					margin-right: ${props.isClearable ? '38px' : '18px'};
				}
			}

			.react-select__indicators {
				div.react-select__indicator {
					padding: 0;
				}
			}

			.react-select__indicator-separator {
				width: 0;
			}
			.Select-input {
				height: 20px;
			}

			.Select-input > input {
				line-height: 16px;
			}

			.react-select__value-container {
				top: 0;
				white-space: nowrap;
				flex-wrap: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 1px 0 1px;
				padding: 1px 3px;

				.react-select__multi-value {
					transition: 0.2s all;
					&:hover {
						flex-shrink: 0;
					}
				}
			}
		`}
` as Select
