import { ResizeData } from '@/components/Modal/types'
import { CSSProperties } from 'react'

export const getResizedStyle = (
	resizedData: ResizeData,
	isResizing: boolean,
	relativeMaxWidth: number,
	relativeMaxHeight: number,
): CSSProperties => {
	const resizingStyle = !isResizing
		? {
				transitionDuration: '0.25s',
				transitionProperty: 'all',
				transitionTimingFunction: 'ease-in',
		  }
		: {}

	if (resizedData.isMaximized) {
		return {
			width: `${relativeMaxWidth * 100}%`,
			height: `${relativeMaxHeight * 100}%`,
			...resizingStyle,
		}
	} else {
		return {
			width:
				resizedData.width && resizedData.width <= 0 ? 1 : resizedData.width,
			height:
				resizedData.height && resizedData.height <= 0 ? 1 : resizedData.height,
			...resizingStyle,
		}
	}
}

export const getResizedData = (
	e: MouseEvent,
	relativeMaxWidth: number,
	relativeMaxHeight: number,
): ResizeData => {
	const width = (e.clientX - window.innerWidth / 2) * 2
	const height = (e.clientY - window.innerHeight / 2) * 2

	return {
		width: width <= 0 ? 1 : width,
		height: height <= 0 ? 1 : height,
		isMaximized:
			window.innerWidth * relativeMaxWidth < width &&
			window.innerHeight * relativeMaxHeight < height,
	}
}
