import { BatchEditContextState, Actions, ActionTypeEnum } from './types'

export const reducer = (
	state: BatchEditContextState,
	action: Actions
): BatchEditContextState => {
	switch (action.type) {
		case ActionTypeEnum.SET:
			return {
				...state,
				...action.state
			}
		case ActionTypeEnum.FORM_CHANGED_UPDATE:
			return {
				...state,
				formChanged: {
					...state.formChanged,
					...action.formChanged
				}
			}

		default:
			return state
	}
}
