import { ApiNodeColumn } from '@/endpoints/schemas/api-node'
import { TranslationHelper } from '@/context/Locale/Locale'
import { PropertiesErrorProps } from '@/types'

/** Check if columns do not have same name or code */
export const duplication = (
	columns: ApiNodeColumn[],
	t: TranslationHelper,
): PropertiesErrorProps => {
	return columns
		.filter((data) => {
			const duplicateCount = columns.reduce((acc, d) => {
				if (
					(d.name === data.name || d.code === data.code) &&
					data.name !== '' &&
					data.code !== '' &&
					// newly added row in table - do not count
					d.name !== ''
				) {
					return acc + 1
				} else {
					return acc
				}
			}, 0)

			return duplicateCount > 1
		})
		.reduce(
			(acc, d) => ({
				...acc,
				[d.id]: {
					name: {
						message: t('VALIDATOR_DUPLICATE'),
					},
					code: {
						message: t('VALIDATOR_DUPLICATE'),
					},
				},
			}),
			{},
		)
}
