import { CsvImportReturnDto, ImportColumn } from '@/endpoints/models'
import { ImportStructureProps } from '../../hooks/useImport'

export type InputDataParsed = Papa.ParseResult<string[]>

export type CsvOnMapped = (
	data?: ImportColumn[],
	importStructureProps?: ImportStructureProps,
	csvMapped?: boolean,
) => void

/** Field name to csv column index map */
export type CsvMapping = {
	[fieldName: string]: number | undefined
}
export interface CsvImporterContextState {
	nodeId: number
	/** Callback for parent component after mapping */
	onMapped: CsvOnMapped
	step: CsvImporterStepEnum
	hasHeaders: boolean
	/** Mapping between csv column & field */
	mapping: CsvMapping
	/** Clear values set by onMapped callback  */
	resetImport: () => void
	checkResult?: CsvImportReturnDto
	formDataCheck?: ImportStructureProps
	/** Mapped data */
	rows?: ImportColumn[]
	inputDataParsed?: InputDataParsed
	firstRow?: string[]
}

export interface CsvImporterContextType {
	state: CsvImporterContextState
	dispatch: React.Dispatch<Actions>
}

export enum CsvImporterStepEnum {
	PREVIEW,
	COLUMNS,
	CHECK,
}

export enum ActionTypeEnum {
	STEP_SET,
	FORM_DATA_CHECK_SET,
	ROWS_SET,
	CHECK_RESULT_SET,
	INPUT_DATA_PARSED_SET,
	HAS_HEADERS_SET,
	CSV_MAPPING_UPDATE,
}

type ActionChangeStep = {
	type: ActionTypeEnum.STEP_SET
	step: CsvImporterStepEnum
}

type ActionFormDataCheckSet = {
	type: ActionTypeEnum.FORM_DATA_CHECK_SET
	formDataCheck: ImportStructureProps
}

type ActionRowsSet = {
	type: ActionTypeEnum.ROWS_SET
	rows: ImportColumn[]
}

type ActionCheckResultSet = {
	type: ActionTypeEnum.CHECK_RESULT_SET
	checkResult?: CsvImportReturnDto
}

type ActionInputDataParsedSet = {
	type: ActionTypeEnum.INPUT_DATA_PARSED_SET
	inputDataParsed?: InputDataParsed
}

type ActionHasHeadersSet = {
	type: ActionTypeEnum.HAS_HEADERS_SET
	hasHeaders: boolean
}

type ActionCsvMappingUpdate = {
	type: ActionTypeEnum.CSV_MAPPING_UPDATE
	mapping: CsvMapping
}

export type Actions =
	| ActionChangeStep
	| ActionFormDataCheckSet
	| ActionRowsSet
	| ActionCheckResultSet
	| ActionInputDataParsedSet
	| ActionHasHeadersSet
	| ActionCsvMappingUpdate
