import React from 'react'
import { Button, LightTable, Container, DialogWrapper } from '@/components'
import { faBox, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/hooks'
import { getAllObjectSettings } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { ObjectSettingsDto } from '@/endpoints/models'
import { ObjectSettingsModal } from './components/ObjectSettingsModal'
import {
	TitleContainer,
	TitleText,
	TitleActions,
	TitleIcon,
} from '../../styles'
import { useObjectSettingsColumns } from './hooks/useObjectSettingsColumns'
import { useFilter } from '../../hooks/useFilter'
import {
	ObjectSettingsFilter,
	ObjectSettingsFilterValues,
} from './components/ObjectSettingsFilter'
import { useObjectSettingsFilteredData } from './hooks/useObjectSettingsFilteredData'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

interface ObjectSettingsProps{
	textAreaHeight?:string
}

export const ObjectSettings = ( {textAreaHeight}:ObjectSettingsProps) => {
	const { t } = useAppContext()
	const objectSettings = useApi(getAllObjectSettings())
	const { filter, onChangeFilter } = useFilter<ObjectSettingsFilterValues>()

	const handleSave = useSettingsPageSaveHandler(objectSettings)

	const columns = useObjectSettingsColumns(handleSave)

	const objectSettingsFiltered = useObjectSettingsFilteredData(
		filter,
		objectSettings.data,
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faBox} color="#602655" />
				<TitleText>{t('OBJECT_SETTINGS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && (
								<ObjectSettingsModal textAreaHeight={textAreaHeight} onSave={handleSave} onClose={onClose} />
							)
						}
					>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<ObjectSettingsFilter onChangeFilter={onChangeFilter} />
				<LightTable<ObjectSettingsDto>
					data={objectSettingsFiltered}
					columns={columns}
					keyField={'id'}
					isLoading={objectSettings.loading}
				/>
			</Container>
		</>
	)
}
