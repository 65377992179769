import { FormValue } from '@/types'

export function isEmpty(value: FormValue) {
	if (value === null || value === undefined) {
		return true
	}

	if (typeof value === 'string') {
		return value.trim().length === 0
	}

	if (Array.isArray(value)) {
		return value.length === 0
	}
}
