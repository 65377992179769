import memoizeOne from 'memoize-one'
import { TranslationHelper } from '@/context/Locale/Locale'
import { SystemUser, TablePermission } from '@/endpoints/models'
import { capitalize } from 'lodash'
import { ApiHookResult } from '@/endpoints/hooks'
import { MappingPermission } from '@/endpoints/schemas'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const PermissionsProperties = memoizeOne(
	(
		t: TranslationHelper,
		permissions: MappingPermission.PermissionsEnum[],
		systemUsers: ApiHookResult<SystemUser[]>,
	): TableColumnProps<TablePermission>[] => {
		return [
			{
				field: 'userId',
				label: t('SYSTEM_USER'),
				type: ItemPropertyType.OPTION,
				options: systemUsers.data ?? [],
				optionValue: 'id',
				optionLabel: 'name',
				flex: 1,
				showWhenPanelOpened: true,
				isNumeric: true,
			},
			{
				field: 'permissions',
				label: t('PERMISSIONS'),
				type: ItemPropertyType.OPTIONS,
				flex: 2,
				showWhenPanelOpened: true,
				options: permissions.map((p) => ({ value: p, label: capitalize(p) })),
			},
		]
	},
)
