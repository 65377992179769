import { DomainDto } from '@/endpoints/models'
import { ApiCollectionColumn } from '@/endpoints/schemas/api-collection'
import { TranslationHelper } from '@/context/Locale/Locale'
import memoizeOne from 'memoize-one'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

const customDomainPredicate =
	(domains: DomainDto[]) => (item: ApiCollectionColumn) =>
		!domains.find((x) => x.id === item.domainId && x.custom === true)

export const ColumnProperties = memoizeOne(
	(
		t: TranslationHelper,
		domains: DomainDto[],
	): TableColumnProps<ApiCollectionColumn>[] => [
		{
			field: 'name',
			label: t('TABLE_COLUMNS_NAME'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true,
			autoWidth: true,
		},
		{
			field: 'code',
			label: t('TABLE_COLUMNS_CODE'),
			type: ItemPropertyType.GENERATED_CODE,
			codeSource: 'name',
			flex: 1,
			propertiesWidth: '50%',
			showWhenPanelOpened: true,
			autoWidth: true,
		},
		{
			field: 'description',
			label: t('TABLE_COLUMNS_DESCRIPTION'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5,
			width: 200,
		},
		{
			field: 'comment',
			label: t('TABLE_COLUMNS_COMMENT'),
			type: ItemPropertyType.TEXTAREA,
			flex: 1.5,
			width: 200,
		},
		{
			field: 'domainId',
			label: t('TABLE_COLUMNS_DOMAIN'),
			type: ItemPropertyType.OPTION,
			flex: 1,
			optionValue: 'id',
			optionLabel: 'name',
			options: domains,
			isNumeric: true,
			propertiesWidth: '50%',
			width: 200,
		},
		{
			field: 'dataType',
			label: t('TABLE_COLUMNS_DATA_TYPE'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			disabled: customDomainPredicate(domains),
			propertiesWidth: '50%',
			width: 200,
		},
		{
			field: 'defaultValue',
			label: t('DOMAIN_DEFAULT_VALUE'),
			type: ItemPropertyType.TEXT,
			flex: 1,
			propertiesWidth: '50%',
			width: 200,
		},
		{
			field: 'notNull',
			label: t('TABLE_COLUMNS_MANDATORY'),
			tableLabel: t('TABLE_COLUMNS_MANDATORY_SHORT'),
			type: ItemPropertyType.CHECKBOX,
			flex: 0.3,
			propertiesWidth: '33.3333%',
			width: 60,
		},
	],
)
