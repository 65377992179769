import { StructureObjectDto } from '@/endpoints/models'
import { store } from '@/store'
import { initFolder } from '@/store/modules/folder/actions'
import { initSystem } from '@/store/modules/system/actions'
import { AppDispatch } from '@/store/utils'
import { nextFrame } from '@/utils/async'
import get from 'lodash/get'
import { NamingTypeEnum, GenerateCodeEnum } from '@/types'

const PATHS = {
	[NamingTypeEnum.GENERATE_CODE]: 'form.generateCode',
	[NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE]: 'form.generateTableColumnCode',
}

/** Check parent folders & system if the node can auto generate naming */
export const getCanGenerateNaming = async (
	nodeId: number,
	namingType?: NamingTypeEnum,
): Promise<boolean> => {
	const { folders } = store.getState().folder
	const { systems } = store.getState().system
	const { nodes } = store.getState().node
	const dispatch = store.dispatch as AppDispatch
	const parentId = nodes[nodeId]?.parentStructureId

	if (parentId === undefined) {
		return false
	}

	const parentNode = nodes[parentId]
	let generateCode: GenerateCodeEnum | undefined = undefined

	switch (parentNode?.type) {
		case StructureObjectDto.TypeEnum.FOLDER: {
			if (folders[parentId] === undefined) {
				await dispatch(initFolder({ nodeId: parentId, editMode: false }))
				await nextFrame()

				return await getCanGenerateNaming(nodeId, namingType)
			}

			generateCode = get(
				folders[parentId],
				PATHS[namingType ?? NamingTypeEnum.GENERATE_CODE],
			)

			break
		}

		case StructureObjectDto.TypeEnum.SYSTEM: {
			if (systems[parentId] === undefined) {
				await dispatch(initSystem({ nodeId: parentId, editMode: false }))
				await nextFrame()

				return await getCanGenerateNaming(nodeId, namingType)
			}

			generateCode = get(
				systems[parentId],
				PATHS[namingType ?? NamingTypeEnum.GENERATE_CODE],
			)

			break
		}
	}

	if (generateCode === GenerateCodeEnum.YES) {
		return true
	}

	if (generateCode === GenerateCodeEnum.NO) {
		return false
	}

	if (generateCode === undefined || generateCode === GenerateCodeEnum.INHERIT) {
		return await getCanGenerateNaming(parentId, namingType)
	}

	return false
}
