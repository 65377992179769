import styled, { css } from 'styled-components'

interface FlexProps {
	$align?: 'center' | 'stretch'
}

interface FlexItemProps {
	flex?: number | string
}

export const Flex = styled.div<FlexProps>`
	display: flex;
	${(props: FlexProps) => css`
		align-items: ${props.$align || 'center'};
	`}
`

export const FlexItem = styled.div<FlexItemProps>`
	${(props: FlexItemProps) => css`
		flex: ${props.flex || 1};
	`}
`
