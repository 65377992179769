/* eslint-disable @typescript-eslint/no-explicit-any */
import { LinkedList, LinkedListItem } from './linked-list'
import { NativeMap } from './collections'

/**
 * LRU Cache
 *
 * Very simple LRU cache - items that are used are pushed to back of the queue.
 * Once the cache is full, items in the front of the queue are removed.
 */
export class LRUCache<T> {
	size = 100
	items = {} as NativeMap<{ position: LinkedListItem<string>; item: T }>
	queue = new LinkedList<string>()

	constructor(size = 100) {
		this.size = size
	}

	get(key: string) {
		const item = this.items[key]

		// Move item to back - it was used
		if (item) {
			this.queue.remove(item.position)
			item.position = this.queue.push(key)
		}

		return item?.item
	}

	set(key: string, item: T) {
		if (this.queue.length >= this.size) {
			// Get first item in queue - it was least recently used
			const lastItem = this.queue.shift()

			if (lastItem !== undefined) {
				// Remove item from actual cache
				delete this.items[lastItem]
			}
		}

		this.items[key] = { position: this.queue.push(key), item }
	}

	unset(key: string) {
		const existing = this.items[key]

		if (existing) {
			this.queue.remove(existing.position)
		}

		delete this.items[key]
	}
}
