import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	Button,
	DialogWrapper,
	TableAndProperties,
	Loader,
	TabsActionsBar,
} from '@/components'
import { isNotUndefined } from '@/utils/collections'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AddModal } from '../../../../components/AddModal'
import { Container } from '../../components/StyledComponents'
import { useApi } from '@/endpoints/hooks'
import { getNodesOfSpecificParent } from '@/endpoints'
import { initApiCollection } from '@/store/modules/apiCollection/actions'
import { CollectionsProperties } from './Properties'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'

type Props = {
	node: StructureDto
}

export const Collections = ({ node }: Props) => {
	const { t } = useAppContext()
	const [isLoading, setLoading] = useState(false)
	const { openTabById } = useOpenTab()

	const dispatch = useAppDispatch()
	const children = useAppStore((state) => state.node.children)
	const nodes = useAppStore((state) => state.node.nodes)
	const nodeChildren = useApi(getNodesOfSpecificParent(node.id))

	const childCollections = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map((id) => nodes[id])
					.filter(isNotUndefined)
					.filter(
						(node) => node.type === StructureObjectDto.TypeEnum.API_COLLECTION,
					)
			: []
	}, [children])

	useEffect(() => {
		setLoading(true)

		Promise.all(
			childCollections.map((node: any) =>
				dispatch(initApiCollection({ nodeId: node.id })),
			),
		).then(() => setLoading(false))
	}, [childCollections])

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById],
	)

	const collectionDetails = childCollections?.map((node: any) => {
		//FIXME - How should the data be gathered properly?

		const x = node
		const jsonData = x?.data ? JSON.parse(x?.data) : null

		return {
			node,
			jsonData,
			detail: nodeChildren[node?.id],
		}
	})

	const data = useMemo(
		() => ({
			collections:
				collectionDetails?.map((detail: any) => ({
					...detail.jsonData,
					id: detail.node.id,
					parentStructureId: detail.node.parentStructureId,
				})) ?? [],
			counter: collectionDetails?.length ?? 0,
		}),
		[collectionDetails],
	)

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				type={StructureObjectDto.TypeEnum.API_COLLECTION}
				onClose={onClose}
				node={node}
			/>
		)

	return (
		<>
			{node.canEdit && (
				<TabsActionsBar>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActionsBar>
			)}
			<Container>
				<LoaderContainer>
					<Loader loaded={!isLoading} $absolute />
				</LoaderContainer>

				<TableAndProperties
					node={node}
					data={data}
					itemsKey={'collections'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={CollectionsProperties(t, onEdit)}
					tabKey="collections"
					onChange={() => undefined}
					createEmpty={() => ({}) as any}
					disablePanelProperties
				/>
			</Container>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
