import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { historyApiSlice } from '@/rtkQueries'

import { rootReducer } from './modules'

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(historyApiSlice.middleware),
	devTools: {
		trace: true,
	},
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export * from './modules'
