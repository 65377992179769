import React from 'react'
import { getSystemEnvironments } from 'src/endpoints'

import { TableAndProperties } from '@/components'
import { useApi } from '@/endpoints/hooks'
import { StructureDto } from '@/endpoints/models'
import { createEmptySystemEnvironment } from '@/store/modules/system/helpers'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { UpdateDeepPartial } from '@/store/utils'

import { useEnvironmentProperties } from './Properties'

type EnvironmentsProps = {
	data: OpenedSystemData
	node: StructureDto
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
}

export const Environments = ({ node, data, onChange }: EnvironmentsProps) => {
	const canGenerateNaming = data.original.generateTableColumnCode
	const { data: envs } = useApi(getSystemEnvironments(node.id))
	const properties = useEnvironmentProperties(node, !!canGenerateNaming, envs)

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			createEmpty={createEmptySystemEnvironment}
			itemsKey={'environments'}
			idCounterKey={'environmentsLastId'}
			onChange={onChange}
			properties={properties}
			tabKey="environments"
			maxWidth={1920}
		/>
	)
}
