import { getStereotypeCategories, getStereotypeTypes } from 'src/endpoints'
import { useApi } from '@/endpoints/hooks'
import { Form, SelectFormField } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import React from 'react'
import { FilterCell, FilterContainer } from '../../../components/Filter'
import { StereotypeUpdateDto } from '@/endpoints/models'

export type StereoTypeFilterValues = {
	type?: StereotypeUpdateDto.TypeEnum | undefined
	category?: 'DEFAULT' | 'BEHAVIORAL' | 'USER_DEFINED' | undefined
}

type Props = {
	onChangeFilter: (values: StereoTypeFilterValues) => void
}

export const StereoTypeFilter = ({ onChangeFilter }: Props) => {
	const { t } = useAppContext()
	const types = useApi(getStereotypeTypes())
	const categories = useApi(getStereotypeCategories())

	return (
		<Form<StereoTypeFilterValues> onChange={onChangeFilter}>
			<FilterContainer>
				<FilterCell width={50} />
				<FilterCell width={50} $maxWidth={300} />
				<FilterCell width={50}>
					<SelectFormField
						title={t('TYPE')}
						name="type"
						options={types.data || []}
						fieldIsLoading={types.loading}
						valueKey="code"
						labelKey="code"
						clearable
					/>
				</FilterCell>
				<FilterCell width={50}>
					<SelectFormField
						title={t('CATEGORY')}
						name="category"
						options={categories.data || []}
						fieldIsLoading={categories.loading}
						valueKey="code"
						labelKey="code"
						clearable
					/>
				</FilterCell>
				<FilterCell width={50} />
				<FilterCell width={60} $maxWidth={60} />
			</FilterContainer>
		</Form>
	)
}
