import { useState } from 'react'
import { TreeView } from './components/TreeView'
import { View } from './components/View'
import { SettingsItem } from './types'
import { TreeContainer, ViewContainer } from './styles'
import { NavigationSplitPane } from '../../components/NavigationSplitPane'

export const Settings = () => {
	const [selected, setSelected] = useState(null as SettingsItem | null)

	return (
		<NavigationSplitPane
			navigationPanel={
				<TreeContainer>
					<TreeView
						selected={selected}
						onSelected={(type) => setSelected(type)}
					/>
				</TreeContainer>
			}
		>
			<ViewContainer>
				<View item={selected} />
			</ViewContainer>
		</NavigationSplitPane>
	)
}
