import { SystemData, SystemMapping, SystemUsers } from '@/endpoints/schemas'
import omit from 'lodash/omit'
import { ensureValidData } from '../helpers'
import { SystemDataForm } from './types'
import { GenerateCodeEnum } from '@/types'

export const defaultSystemData: Readonly<SystemData> = {
	name: '',
	code: '',
	constants: [],
	constantsLastId: 0,
	lookups: [],
	lookupsLastId: 0,
	environments: [],
	environmentsLastId: 0,
	systemUsers: [],
	systemUserLastId: 0,
	namingConfig: {
		generateCode: GenerateCodeEnum.YES,
		generateTableColumnCode: GenerateCodeEnum.YES,
	},
}

export const getInitialSystemData = (
	name: string,
	code = '',
	systemTechnologyId?: number,
	workflowTechnologyId?: number,
): SystemData =>
	ensureValidData(
		{
			name,
			code,
			systemTechnologyId,
			workflowTechnologyId,
		},
		defaultSystemData,
	)

export const createEmptySystemMapping = (): SystemMapping =>
	({
		id: -1,
		name: '',
		code: '',
		value: '',
	}) as SystemMapping

export const createEmptySystemEnvironment = (): SystemMapping =>
	({
		id: -1,
		name: '',
		code: '',
	}) as SystemMapping

export const createEmptySystemUser = (): SystemUsers =>
	({
		id: -1,
		name: '',
		comment: '',
		description: '',
	}) as SystemUsers

export const getSystemDataForm = (data: SystemData) => {
	const dataForm = {
		...data,
	}

	if (typeof dataForm.lookupsLastId !== 'number') {
		dataForm.lookupsLastId = Math.max(0, ...dataForm.lookups.map((c) => c.id))
	}

	if (typeof dataForm.constantsLastId !== 'number') {
		dataForm.constantsLastId = Math.max(
			0,
			...dataForm.constants.map((c) => c.id),
		)
	}

	if (typeof dataForm.systemUserLastId !== 'number') {
		dataForm.systemUserLastId = Math.max(
			0,
			...dataForm.systemUsers.map((c) => c.id),
		)
	}

	if (typeof dataForm.environmentsLastId !== 'number') {
		dataForm.environmentsLastId = Math.max(
			0,
			...dataForm.environments.map((c) => c.id),
		)
	}

	const systemDataForm: SystemDataForm = {
		...omit(data, 'namingConfig'),
		generateCode: dataForm.namingConfig?.generateCode,
		generateTableColumnCode: dataForm.namingConfig?.generateTableColumnCode,
	}

	return systemDataForm
}
