import styled from 'styled-components'

export const ResultWrap = styled.div`
	cursor: pointer;
	padding: 0.5rem 0.5rem;

	&:hover {
		background-color: #eee;
	}
`

export const Name = styled.div``

export const Icon = styled.span`
	margin-right: 0.2rem;
`

export const Data = styled.div`
	overflow: hidden;
	color: #999;
`

export const MatchedText = styled.span`
	font-weight: bold;
`
