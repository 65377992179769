import styled from 'styled-components'
import { FormInput, Select } from '../../PropertyFieldStyles'
import Button from '@/components/Button/Button'

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`

export const Box = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	&:not(:last-child) {
		border-bottom: 1px solid #ddd;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}
`

export const BoxColumn = styled.div`
	display: flex;
	flex-direction: column;
`

export const BoxColumnFK = styled(BoxColumn)`
	flex: 1;
	height: 100%;
`

export const BoxFKs = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

export const ColumnTitle = styled.div`
	width: 100%;
	margin-top: 5px;
	margin-bottom: 8px;
`

const Common = styled.div`
	height: 30px;
	width: 170px;
	padding: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const PrimaryKey = styled(Common)`
	background: #f0f0f0;
	margin-right: 10px;
	flex-wrap: wrap;
`

export const ItemText = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	&:nth-child(2) {
		padding-bottom: 1px;
		font-size: 10px;
	}
`

export const SelectFK = styled(Select)<{ fromProps?: boolean }>`
	width: 100%;
	.react-select__input {
		width: 100%;
		overflow: hidden;
	}
`

export const FormInputFK = styled(FormInput)`
	flex: 1;
	border: 1px solid ${(props) => props.theme.colors.input.border};
	padding: 7px;
`

export const AddIcon = styled(Button)`
	height: 32px;
	width: 31px;
	margin-bottom: 1px;
	display: flex;
	align-items: center;
	color: hsl(0, 0%, 80%);
	border-left: none;
	background: #fff;
`
