import { UserDto } from '@/endpoints/models'
import { Actions } from './actions'
import {
	USER_GET,
	USER_LIST_GET,
	USER_CONTEXT_LOADED,
	USER_DIALOG_SIZE_CHANGED,
	USER_DIALOG_SETTINGS_UPDATE,
} from './constants'
import { NativeMap } from '@/utils/collections'
import { NodeSession } from '../node'
import { TabSession } from '../tab'
import { tryParseJSON } from '@/utils/json'
import { SettingsSession } from '../settings'
import { Dialog } from './types'

export const SESSION_VERSION = '1.0'

type State = Readonly<typeof initialState>

export type UserSession = {
	node: NodeSession
	tab: TabSession
	dialog: Dialog
	settings: SettingsSession
	__version: string
}

const initialState = {
	map: {} as NativeMap<UserDto>,
	list: [] as UserDto[],
	session: null as UserSession | null,
	dialog: {
		settings: {},
		dimensions: {},
	} as Dialog,
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case USER_GET: {
			return {
				...state,
				map: {
					...state.map,
					[action.payload.id]: action.payload,
				},
			}
		}

		case USER_LIST_GET: {
			return {
				...state,
				list: action.payload,
			}
		}

		case USER_DIALOG_SIZE_CHANGED: {
			return {
				...state,
				dialog: {
					...state.dialog,
					dimensions: {
						...state.dialog.dimensions,
						[action.dialogId]: {
							width: action.width,
							height: action.height,
							isMaximized: action.isMaximized,
						},
					},
				},
			}
		}

		case USER_DIALOG_SETTINGS_UPDATE:
			return {
				...state,
				dialog: {
					...state.dialog,
					settings: {
						...state.dialog.settings,
						...action.payload,
					},
				},
			}

		case USER_CONTEXT_LOADED: {
			const session = tryParseJSON<UserSession>(action.payload.context ?? '')

			if (session && session.__version !== SESSION_VERSION) {
				console.warn(
					`User session version mismatch, (expected: ${SESSION_VERSION}, got: ${session.__version}) - session discaded`,
				)

				return state
			}

			return {
				...state,
				session,
				dialog: {
					settings: session?.dialog?.settings || {},
					dimensions: session?.dialog?.dimensions || {},
				},
			}
		}

		default:
			return state
	}
}
