import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ForwardedRef, forwardRef, ReactElement } from 'react'

import { Portal } from '@/components'
import { ModalFormProps } from '@/components/Modal/types'
import { useAppContext } from '@/hooks'
import { splitProps } from '@/utils/collections'

import Button from '../Button/Button'
import { Form, Submit } from '../UberForm'
import { Modal } from './Modal'

const ForwardedModalFormInner = <T extends object>(
	props: ModalFormProps<T>,
	ref: ForwardedRef<Form<T>>,
): ReactElement => {
	const { t } = useAppContext()

	const {
		saveTitle,
		saveIcon,
		saveSchema,
		cancelTitle,
		cancelIcon,
		cancelSchema,
		children,
		footer,
		footerBefore,
		hasSubmitButton = true,
	} = props

	let iconSave: IconProp = faCheck

	if (saveIcon) {
		iconSave = saveIcon
	}

	let iconCancel: IconProp = faTimes

	if (cancelIcon) {
		iconCancel = cancelIcon
	}

	const formProps = { ...props }

	const modalProps = splitProps(formProps, [
		'children',
		'contentStyle',
		'bodyStyle',
		'header',
		'footer',
		'open',
		'onClose',
		'disablePortal',
		'headerStyle',
		'resizable',
		'maximizeButtonVisible',
		'maximized',
		'height',
	])

	return (
		<Portal>
			<Form<T> ref={ref} {...formProps}>
				<Modal
					{...modalProps}
					disablePortal
					footer={(close) =>
						footer || (
							<>
								{footerBefore}
								{hasSubmitButton && (
									<Submit schema={saveSchema || 'success'} icon={iconSave}>
										{saveTitle || t('SAVE')}
									</Submit>
								)}
								<Button
									onClick={close}
									schema={cancelSchema || 'transparent'}
									icon={iconCancel}
								>
									{cancelTitle || t('CANCEL')}
								</Button>
							</>
						)
					}
				>
					{children}
				</Modal>
			</Form>
		</Portal>
	)
}

export const ForwardedModalForm = forwardRef(ForwardedModalFormInner) as <
	T extends object,
>(
	props: ModalFormProps<T> & { ref?: ForwardedRef<Form<T>> },
) => ReactElement
