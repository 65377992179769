import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import { mainColors } from '@/styles'

export const TREE_ITEM_GAP = 6

export const Container = styled.div`
	position: relative;
`

export const DragDropContainer = styled.div<{
	$highlighted: boolean
	$level: number
	$hasChildren: boolean
	$hideArrows: boolean
	$isActive: boolean
}>`
	padding: 3px 0;

	${(props) => css`
		padding-left: ${props.$level * 10 +
		(!props.$hasChildren && !props.$hideArrows ? 20 : 0)}px;
	`}

	${(props) =>
		props.$highlighted &&
		css`
			background: #e3eeff;
		`}

	&:hover {
		background-color: ${(props) =>
			props.$highlighted ? '#e3eeff' : '#f3f3f3'};
	}

	&:active {
		background-color: #f0f0f0;
	}

	background: ${(props) =>
		props.$isActive && lighten(0.3, mainColors.highlight)};
`

export const TitleText = styled.div`
	position: relative;
	padding: 2px 5px 2px 0;
	flex: 1;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const TitleDropUp = styled.div<{ $isActive: boolean }>`
	position: absolute;
	top: -${TREE_ITEM_GAP}px;
	left: 0;
	width: 100%;
	height: ${TREE_ITEM_GAP}px;
	z-index: 1;
	background: ${(props) =>
		props.$isActive ? mainColors.highlight : 'transparent'};
`

export const TitleArrow = styled.div<{ $isExpanded: boolean }>`
	padding: 2px 0;
	margin-right: 3px;
	flex-grow: 0;
	flex-shrink: 0;
	transition: transform 0.1s;
	color: #777;

	${(props) =>
		props.$isExpanded &&
		css`
			transform: rotate(90deg);
		`}
`

export const TitleIcon = styled.div`
	padding: 2px 5px 2px 0px;
	flex-grow: 0;
	flex-shrink: 0;
`

export const TitleActions = styled.div`
	transition: 0.15s;
`

export const Title = styled.div<{
	$level: number
	$hasChildren: boolean
	$hideArrows: boolean
}>`
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition-duration: 0.2s;
	transition-property: background, opacity;
	box-sizing: border-box;
`

export const Children = styled.div<{ $level: number }>`
	position: relative;

	&::before {
		content: '';
		border-left: 1px solid #ddd;
		height: 100%;
		width: 1px;
		position: absolute;
		z-index: 1;
		top: -3px;
		${(props) => css`
			left: ${8 + props.$level * 10}px;
		`}
	}
`

export const TreeContainer = styled.div`
	overflow: auto;
	max-height: 100%;
	padding-left: 2px;
	padding-bottom: 5px;
`
