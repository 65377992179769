import { NativeMap } from '@/utils/collections'
import {
	PageStructureReleaseInfoDto,
	ReleaseEnvironmentDto,
	StructureObjectDto,
} from '@/endpoints/models'
import React from 'react'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { RELEASE_OBJECTS_FORBIDDEN } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/constants'

export const getAllChildren = (
	nodeId: number,
	childrenMap: NativeMap<number[]>,
): number[] => {
	const children = childrenMap[nodeId]
	const childrenIds = [] as number[]

	if (children) {
		for (const child of children) {
			const childrenNested = getAllChildren(child, childrenMap)
			childrenIds.push(...childrenNested)
		}
	}

	return [nodeId, ...childrenIds]
}

export const getEnvSelectOptions = (
	options: ReleaseEnvironmentDto[] | undefined,
) => {
	return options?.map((env) => ({
		value: env.code,
		label: env.code,
	}))
}

const getNodeIDFromEvent = (event: React.DragEvent): number => {
	return parseFloat(event.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID))
}

const isValidNode = (
	nodeId: number,
	objects: PageStructureReleaseInfoDto | undefined,
	nodeState: any,
) => {
	return (
		!RELEASE_OBJECTS_FORBIDDEN.includes(
			nodeState.nodes[nodeId]?.type as StructureObjectDto.TypeEnum,
		) &&
		!objects?.content?.some(
			(object) => object.structureId === nodeState.nodes[nodeId]?.id,
		)
	)
}

export const retrieveNodeData = (
	event: React.DragEvent,
	objects: PageStructureReleaseInfoDto | undefined,
	nodeState: any,
) => {
	const addedNodeId = getNodeIDFromEvent(event)

	if (isValidNode(addedNodeId, objects, nodeState)) {
		return addedNodeId
	}

	// return `null` or some other appropriate value based on your usage
	return null
}
