import React from 'react'
import { DomainDto, StructureDto } from '@/endpoints/models'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { UpdateDeepPartial } from '@/store/utils'
import { TableAndProperties } from '@/components'
import { createEmptySystemMapping } from '@/store/modules/system/helpers'
import { useConstantsProperties } from './Properties'

type ConstantsProps = {
	node: StructureDto
	data: OpenedSystemData
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
	domains: DomainDto[]
}

export const Constants = ({
	node,
	data,
	onChange,
	domains,
}: ConstantsProps) => {
	const canGenerateNaming = data.original.generateTableColumnCode
	const properties = useConstantsProperties(domains, !!canGenerateNaming)

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			createEmpty={createEmptySystemMapping}
			itemsKey={'constants'}
			idCounterKey={'constantsLastId'}
			onChange={onChange}
			properties={properties}
			tabKey="constant"
			maxWidth={1280}
		/>
	)
}
