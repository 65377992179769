import { useAppDispatch } from '@/hooks'
import { generateCode } from '@/store/utils'

export const getNameCode = (code: string, isLowerCase: boolean) => {
	if (isLowerCase) {
		return code?.toLowerCase()
	}

	return code
}

export const useGetCode = async (v: string) => {
	const dispatch = useAppDispatch()

	const response = await dispatch(generateCode(v))

	const { result } = response

	return result
}
