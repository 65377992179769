import React from 'react'
import Button from '../../../Button/Button'
import { withForm } from '../../FormContext'
import { ResetProps } from '@/components/UberForm/Input/Reset/types'

class Reset extends React.PureComponent<ResetProps> {
	componentDidUpdate(prevProps: ResetProps, prevState: never) {
		if (prevProps.itemId !== this.props.itemId) {
			this.handleReset()
		}
	}

	render() {
		const {
			children,
			size,
			schema,
			icon,
			form,
			disabled,
			isColumnPage
		} = this.props

		const { submitting = false } = form || {}

		return isColumnPage ? null : (
			<Button
				onClick={this.handleReset}
				disabled={disabled || submitting}
				schema={schema}
				size={size}
				icon={icon}
			>
				{children}
			</Button>
		)
	}

	private handleReset = () => {
		if (this.props.form) {
			this.props.form?.reset(this.props.values)
		}
	}
}

export default withForm(Reset) as typeof Reset
