import React, { useMemo, useState } from 'react'
import { Table } from '@/components'
import { ReleaseDto, StructureDto } from '@/endpoints/models'
import { deleteReleaseEnvironment } from '@/endpoints'
import { useAppContext } from '@/hooks'
import { DeleteModal } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/components'
import { EnvironmentEditModal } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/components/Environments/EnvironmentEditModal'
import { Column } from '@tanstack/react-table'

interface EnvTableProps {
	columns: Column<any>[]
	sortedEnvData: ReleaseDto[]
	hasLastRowEdit: boolean
	editMode: boolean
	node: StructureDto
	reloadEnvs: () => void
	deploymentPackage: ReleaseDto
}

export const EnvTable = ({
	columns,
	sortedEnvData,
	editMode,
	node,
	reloadEnvs,
	deploymentPackage,
}: EnvTableProps) => {
	const { t } = useAppContext()
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)
	const [isEditOpen, setIsEditOpen] = useState(false)
	const [envRowRecord, setEnvRowRecord] = useState<ReleaseDto>()
	const ENV_TABLE_WIDTH = 1200

	const deleteRequest = useMemo(
		() =>
			deleteReleaseEnvironment(
				node.id,
				deploymentPackage?.id as number,
				envRowRecord?.id as number,
			),
		[node.id, deploymentPackage.id, envRowRecord?.id],
	)

	const onDelete = (rowDp: ReleaseDto) => {
		setIsDeleteOpen(true)
		setEnvRowRecord(rowDp)
	}

	const onEdit = (rowDp: ReleaseDto) => {
		setIsEditOpen(true)
		setEnvRowRecord(rowDp)
	}

	return (
		<>
			<Table
				columns={columns}
				data={sortedEnvData}
				maxWidth={ENV_TABLE_WIDTH}
				isEditMode={editMode}
				onDelete={onDelete}
				onEdit={onEdit}
				hasLastRowEdit
				hasLeftAction={false}
				hasDnd={false}
			/>

			{isDeleteOpen && (
				<DeleteModal
					object={deploymentPackage}
					deleteRequest={deleteRequest}
					onClose={() => {
						reloadEnvs()
						setIsEditOpen(false)
					}}
					saveTitle={t('REMOVE_ELEMENT_FROM_RELEASE')}
					description={t('REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION', [
						deploymentPackage.name ?? '',
					])}
				/>
			)}

			{isEditOpen && (
				<EnvironmentEditModal
					env={envRowRecord as ReleaseDto}
					node={node}
					release={deploymentPackage}
					onClose={() => {
						reloadEnvs()
						setIsEditOpen(false)
					}}
				/>
			)}
		</>
	)
}
