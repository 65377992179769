import { SelectFormField } from '@/components/UberForm'
import CheckboxFormField from '@/components/UberForm/Helpers/CheckBoxFormField'
import { MassExportConfigDto } from '@/endpoints/models/base'
import { DdlModalType } from '@/types'
import { useStructureStatus } from '@/pages/User/pages/Home/utils'
import { ExportConfigFieldsProps } from './types'

export const ExportConfigFields = ({
	enableExportStrategy,
	massExportSorting,
	massExportSortingOptions,
	massExportStrategies,
	massExportStrategy,
	setIgnoreErrors,
	setMassExportSorting,
	setMassExportStrategy,
	setStructureStatus,
	t,
	type,
}: ExportConfigFieldsProps) => {
	const nodeStates = useStructureStatus(t)

	return (
		<>
			{type === DdlModalType.CreateGit ? (
				<></>
			) : (
				<>
					<SelectFormField
						name="massExportStatus"
						title={t('MASS_DOWNLOAD_STATUS')}
						options={nodeStates}
						valueKey="value"
						labelKey="label"
						allowEmpty
						onChange={(value) => {
							console.log('value', value)

							setStructureStatus(value)
						}}
						isMulti
					/>
					{(enableExportStrategy || type === DdlModalType.PushToGit) && (
						<SelectFormField
							name="massExportStrategy"
							formless
							title={t('MASS_DOWNLOAD_STRATEGY')}
							options={massExportStrategies || []}
							valueKey="value"
							labelKey="label"
							onChange={(val) =>
								setMassExportStrategy(
									val as MassExportConfigDto.MassExportStrategyEnum,
								)
							}
							initialValue={massExportStrategy}
						/>
					)}

					<SelectFormField
						name="massExportSorting"
						formless
						title={t('MASS_DOWNLOAD_SORTING')}
						options={massExportSortingOptions || []}
						valueKey="value"
						labelKey="label"
						onChange={(val) => setMassExportSorting(val)}
						initialValue={massExportSorting}
					/>
					<CheckboxFormField
						name="ignoreErrors"
						title={t('IGNORE_ERRORS')}
						onChange={(val) => setIgnoreErrors(val)}
					/>
				</>
			)}
		</>
	)
}
