import React from 'react'
import { TableHeaderProps } from './types'
import { TableHeaderContent } from '@/components/Table/TableHeaderContent'
import { TableActionTh } from '@/components/Table/styles'

export const TableHeader = ({ hasLeftAction, table }: TableHeaderProps) => {
	return (
		<thead>
			{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id}>
					{hasLeftAction && <TableActionTh>DND placeholder</TableActionTh>}
					{headerGroup.headers.map((header) => (
						<TableHeaderContent key={header.id} header={header} table={table} />
					))}
					<TableActionTh>X</TableActionTh>
				</tr>
			))}
		</thead>
	)
}
