import { useMemo } from 'react'
import { ViewColumn } from '@/endpoints/schemas'
import { useAppContext, useAppStore } from '@/hooks'
import { parseGenerateTableColumnCode } from './utils'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	parentId: number | undefined
}

export const useColumnsProperties = ({ parentId }: HookOptions) => {
	const { t } = useAppContext()
	const folders = useAppStore((state) => state.folder.folders)
	const parentFolder = parentId ? folders[parentId] : undefined
	const generateCode = parentFolder?.form?.generateTableColumnCode
	const hasGenerateCode = parseGenerateTableColumnCode(generateCode)

	const properties = useMemo<TableColumnProps<ViewColumn>[]>(
		() => [
			{
				field: 'name',
				label: t('VIEW_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				propertiesWidth: '50%',
				showWhenPanelOpened: true,
				width: 50,
			},
			{
				field: 'code',
				label: t('VIEW_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				flex: 1,
				propertiesWidth: '50%',
				showWhenPanelOpened: true,
				disabled: hasGenerateCode,
				disableGenerateCode: !hasGenerateCode,
				width: 50,
			},
			{
				field: 'description',
				label: t('VIEW_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 50,
			},
			{
				field: 'comment',
				label: t('VIEW_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 50,
			},
			{
				field: 'mapping',
				label: t('VIEW_COLUMNS_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				flex: 1,
				width: 50,
			},
		],
		[hasGenerateCode, t],
	)

	return properties
}
