import { StructureObjectDto, StructureDetailDto } from '@/endpoints/models'
import { WorkflowDataForm, WorkflowTab, UserGraphData } from './types'
import {
	WORKFLOW_INIT,
	WORKFLOW_SAVE,
	WORKFLOW_UPDATE,
	WORKFLOW_SELECT_TAB,
	WORKFLOW_UPDATE_GRAPH,
} from './constants'
import { apiCallAction, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode } from '@/endpoints'
import { AppDispatch } from '@/store/utils'
import { loadNodeOrHistoryVersion } from '../node/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

interface InitWorkflow {
	type: typeof WORKFLOW_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveWorkflow {
	type: typeof WORKFLOW_SAVE
	payload: void
	metadata: {
		node: StructureObjectDto
	}
}

interface UpdateWorkflow {
	type: typeof WORKFLOW_UPDATE
	node: StructureObjectDto
	update: Partial<WorkflowDataForm>
}

interface WorkflowSelectTab {
	type: typeof WORKFLOW_SELECT_TAB
	node: StructureObjectDto
	tab: WorkflowTab
}

interface WorkflowUpdateGraph {
	type: typeof WORKFLOW_UPDATE_GRAPH
	node: StructureObjectDto
	update: UpdateDeepPartial<UserGraphData>
}

export const initWorkflow =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: WORKFLOW_INIT,
			node,
			editMode,
			force,
		} as InitWorkflow)
	}

export const updateWorkflow = (
	node: StructureObjectDto,
	update: Partial<WorkflowDataForm>,
): Actions => ({
	type: WORKFLOW_UPDATE,
	node,
	update,
})

export const saveWorkflow = (node: StructureObjectDto) =>
	apiCallAction(
		(getState) => {
			const workflow = getState().workflow.workflows[node.id]

			if (!workflow) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			return updateDataNode(node.id, {
				data: JSON.stringify(workflow.form),
			})
		},
		WORKFLOW_SAVE,
		{ node },
	)

export const selectWorkflowTab = (
	node: StructureObjectDto,
	tab: WorkflowTab,
): Actions => ({
	type: WORKFLOW_SELECT_TAB,
	node,
	tab,
})

export const updateWorkflowGraphData = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<UserGraphData>,
): Actions => ({
	type: WORKFLOW_UPDATE_GRAPH,
	node,
	update,
})

export type Actions =
	| InitWorkflow
	| SaveWorkflow
	| UpdateWorkflow
	| WorkflowSelectTab
	| WorkflowUpdateGraph
