import React from 'react'
import styled, { css } from 'styled-components'

import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import { StructureDto } from '@/endpoints/models'
import {
	useAppDispatch,
	useAppStore,
	useDebounce,
	useEffectWithoutMount,
} from '@/hooks'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import {
	callNamingWorkingData,
	CallNamingWorkingDataParams,
} from '@/store/modules/table/utils/callNaming'
import { FormValue } from '@/types'
import { getNameCode, NativeMap } from '@/utils'

import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { useCanGenerateNaming } from './useCanGenerateNaming'

export type NamingProps = FormFieldContext & {
	autoFocus?: boolean
	callNamingWorkingDataParams?: Readonly<CallNamingWorkingDataParams>
	error?: string | null
	node?: StructureDto
	onBlurCustom?: () => void
	onFocusCustom?: () => void
	readonlyContainer?: (value?: FormValue) => React.ReactNode
	updateNaming?: boolean
}

export const NamingInputFormless = ({
	id,
	node,
	callNamingWorkingDataParams,
	name,
	value,
	onChange,
	error,
	onFocus,
	onBlur,
	onFocusCustom,
	onBlurCustom,
	autoFocus,
	isReadOnly,
	readonlyContainer,
	disabled,
	updateNaming = true,
}: NamingProps) => {
	const dispatch = useAppDispatch()
	const systemsSlice = useAppStore((state) => state.system.systems)
	const nodes = useAppStore((state) => state.node.nodes)

	const systemId = findSystemNodeId(
		node as StructureDto,
		nodes as NativeMap<StructureDto>,
	)

	const isLowerCase = systemsSlice[systemId]?.form?.letterCaseToggle as boolean

	const debouncedParams = useDebounce(callNamingWorkingDataParams, 200)
	const canGenerateNaming = useCanGenerateNaming(node?.id)

	useEffectWithoutMount(() => {
		if (updateNaming && canGenerateNaming && debouncedParams) {
			const naming = dispatch(
				callNamingWorkingData(debouncedParams as CallNamingWorkingDataParams),
			)

			naming.then((naming) => {
				const result = naming?.result

				if (result !== value) {
					const parsedResult = getNameCode(result, isLowerCase)
					onChange?.(parsedResult)
				}
			})
		}
	}, [updateNaming, canGenerateNaming, debouncedParams])

	const handleChange = (value: FormValue) => {
		onChange?.(value)
	}

	const renderFormlessTextStyled = () =>
		readonlyContainer ? (
			readonlyContainer(value)
		) : (
			<FormlessTextStyled value={value} disabled />
		)

	return (
		<>
			{isReadOnly ? (
				renderFormlessTextStyled()
			) : (
				<FormlessTextStyled
					id={id}
					name={name}
					value={value}
					onFocus={(e: any) => {
						onFocus && onFocus(e)
						onFocusCustom && onFocusCustom()
					}}
					onBlur={(e: any) => {
						onBlur && onBlur(e)
						onBlurCustom && onBlurCustom()
					}}
					error={error}
					onChange={handleChange}
					autoFocus={autoFocus}
					disabled={disabled || canGenerateNaming}
				/>
			)}
		</>
	)
}

const FormlessTextStyled = styled(FormlessText)<
	React.ComponentProps<typeof FormlessText & { error?: string }>
>`
	${(props) =>
		props.error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`

export const NamingInput = withFormField(NamingInputFormless)
