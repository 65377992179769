import { StructureObjectDto, StructureDetailDto } from '@/endpoints/models'
import { ViewData, ViewPermission } from '@/endpoints/schemas'
import { ViewTab, ViewDataForm } from './types'
import { VIEW_INIT, VIEW_SAVE, VIEW_UPDATE, VIEW_SELECT_TAB } from './constants'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode } from '@/endpoints'
import { inflateTablePermission } from '../table/helpers'
import { loadNodeOrHistoryVersion } from '../node/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

interface InitView {
	type: typeof VIEW_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveView {
	type: typeof VIEW_SAVE
	payload: void
	metadata: {
		node: StructureObjectDto
	}
}

interface UpdateView {
	type: typeof VIEW_UPDATE
	node: StructureObjectDto
	update: UpdateDeepPartial<ViewDataForm>
}

interface SelectViewTab {
	type: typeof VIEW_SELECT_TAB
	node: StructureObjectDto
	tab: ViewTab
}

export const initView =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: VIEW_INIT,
			node,
			editMode,
			force,
		} as InitView)
	}

export const updateView = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<ViewDataForm>,
): Actions => ({
	type: VIEW_UPDATE,
	node,
	update,
})

export const saveView = (node: StructureObjectDto) =>
	apiCallAction(
		(getState) => {
			const view = getState().view.views[node.id]

			if (!view) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			const formData: ViewData = {
				...view.form,
				columns: (view.form.columns || []).filter((x) => x.name),
				objectPermissions: view.form.objectPermissions
					.filter((x) => x.userName)
					.map((x) => inflateTablePermission(x)) as ViewPermission[],
			}

			return updateDataNode(node.id, {
				data: JSON.stringify(formData),
			})
		},
		VIEW_SAVE,
		{ node },
	)

export const selectViewTab = (
	node: StructureObjectDto,
	tab: ViewTab,
): Actions => ({
	type: VIEW_SELECT_TAB,
	node,
	tab,
})

export type Actions = InitView | SaveView | UpdateView | SelectViewTab
