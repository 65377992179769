import { WorkflowData } from '@/endpoints/schemas'

export const getInitialWorkflowData = (
	name: string,
	code = '',
): WorkflowData => ({
	name,
	code,
	graph: {
		executeReadNodes: [],
		executeWriteNodes: [],
		parallelMergeNodes: [],
		parallelStartNodes: [],
		links: [],
	},
})
