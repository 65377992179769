import styled from 'styled-components'
import { SelectFormField } from '@/components/UberForm'

export const StyledDiffWrap = styled.div`
	pre {
		white-space: pre-wrap;
	}
`
export const StyledSelectVersion = styled(SelectFormField)`
	margin-left: 10px;
	flex: 0 1;
	font-size: 14px;
	min-width: 130px;
`
