import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useApiRequest } from '@/endpoints/hooks'
import { useDebounce } from '@/hooks'
import { searchFullTextJson } from '@/endpoints'
import { FullTextSearchDto } from '@/endpoints/models'
import { Results } from './components/Results'
import { SearchWrap, StyledText } from '@/components/NavBar/styles'

export const Search = () => {
	const request = useApiRequest()
	const [search, setSearch] = useState('')
	const [loading, setLoading] = useState(false)
	const [focused, setFocused] = useState(false)

	const [results, setResults] = useState(
		undefined as FullTextSearchDto[] | undefined,
	)

	const query = useDebounce(search, 300)

	useEffect(() => {
		async function load() {
			setLoading(true)

			const { data: res } = await request(searchFullTextJson(query))

			if (res !== null) {
				setResults(res)
			}

			setLoading(false)
		}

		if (query && query.length > 0) {
			load()
		} else {
			setResults(undefined)
			setLoading(false)
		}
	}, [query])

	return (
		<SearchWrap>
			<StyledText
				inputIcon={faSearch}
				value={search}
				onChange={(v) => setSearch(v as string)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				clearable
				name="NavBarSearch"
			/>

			{focused && (results || loading) && (
				<Results results={results} loading={loading} />
			)}
		</SearchWrap>
	)
}
