import queryString from 'querystring'
import { flatternFilters, NativeMap } from './collections'

interface QueryObject {
	[key: string]: QueryValue
}

type QueryValue =
	| number
	| string
	| boolean
	| null
	| undefined
	| string[]
	| { from: string | undefined; to: string | undefined }

export function encodeQueryString(values: QueryObject): string {
	// Additional logic to properly handle arrays and ranges
	let newValues = Object.keys(values).reduce(
		(acc, key) => {
			const value = values[key]

			if (Array.isArray(value)) {
				acc[`${key}[]`] = value.join(',')
			} else if (
				value &&
				typeof value === 'object' &&
				(value.from !== undefined || value.to !== undefined)
			) {
				acc[`${key}[-]`] = `${value.from}-${value.to}`
			} else {
				acc[key] =
					value !== undefined && value !== null ? value.toString() : null
			}

			return acc
		},
		{} as NativeMap<string | null>,
	)

	newValues = flatternFilters(newValues) as NativeMap<string | null>

	return queryString.stringify(newValues)
}

const ARRAY_KEY_REGEX = /(.*)\[\]$/
const RANGE_KEY_REGEX = /(.*)\[-\]$/

export function decodeQueryString(querystring: string): QueryObject {
	if (querystring && querystring.charAt(0) === '?') {
		querystring = querystring.substring(1)
	}

	const values = queryString.parse(querystring)

	// Additional logic to properly handle arrays and ranges
	return Object.keys(values).reduce((acc, key) => {
		const value = values[key]
		let match: RegExpExecArray | null

		// tslint:disable: no-conditional-assignment
		if ((match = ARRAY_KEY_REGEX.exec(key))) {
			acc[match[1]] = value?.toString().split(',')
		} else if ((match = RANGE_KEY_REGEX.exec(key))) {
			const range = value?.toString().split('-')
			acc[match[1]] = { from: range?.[0], to: range?.[1] }
		} else {
			acc[key] = value
		}

		return acc
	}, {} as QueryObject)
}

export function getQsValue(key: string, defaultValue?: number | string | null) {
	const values = decodeQueryString(location.search)
	const value = values[key]

	return value === undefined ? defaultValue : value
}

export function setQsValues(values: QueryObject) {
	const query = encodeQueryString({
		...decodeQueryString(location.search),
		...values,
	})

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	history.replaceState(null, null as any, query.length > 0 ? `?${query}` : '')
}
