import styled, { createGlobalStyle, css } from 'styled-components'

import reset from './reset'

export const GlobalStyle = createGlobalStyle`
${reset} 

* {
	padding: 0;
	margin: 0;
}

body {
	font-family: Blinker, Arial, Helvetica, sans-serif;
	font-size: 0.875rem;
	${(props) => css`
		color: ${props.theme.colors.text.base};
	`}
	
}

h1 {
	font-size: 1.5rem;
}

input[type="password"] {
    border: none !important;
    font-family: Verdana,serif;
    letter-spacing: 0.125rem;
}

	
input[type=text],
input[type=password],
input[type=number],
textarea {
	${(props) => css`
		border: 1px solid ${props.theme.colors.input.border};
		padding: ${props.theme.input.padding};
		border-radius: ${props.theme.input.borderRadius};
		font-size: ${props.theme.input.fontSize};
		width: ${props.theme.input.width};
		transition:
			border-color,
			background-color,
			box-shadow 0.2s ease;

		:disabled {
			border-color: ${props.theme.colors.input.disabled.border};
			background: ${props.theme.colors.input.disabled.background};
		}
	`}
}

.react-select__option--is-focused.react-select__option--is-selected {
			background: #000 !important;
}

button, a {
	cursor: pointer;
}

.ace_editor, .ace_editor * {
	font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
	font-size: 12px !important;
	font-weight: 400 !important;
	letter-spacing: 0 !important;
}
`
export const ThCell = styled.div<{ flex?: string | number }>`
	border-width: 1px;
	border-style: solid;
	border-left-width: 0;
	box-sizing: border-box;

	padding: 8px 6px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	${(props) => css`
		flex: ${props.flex || 1};
		background: ${props.theme.colors.primary.base};
		border-color: ${props.theme.colors.form.$compact.header.borderColor};
		color: #fff;
	`}
	&:first-child {
		border-left-width: 1px;
	}
`
