import { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { Item } from '@/components/Properties/context/types'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const useDpTableColumns = (): TableColumnProps<Item>[] => {
	const { t } = useAppContext()

	return useMemo(() => {
		return [
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				autoWidth: true,
				disabled: true,
			},
			{
				field: 'status',
				label: 'Status',
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'description',
				label: t('TABLE_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXT,
				width: 250,
				disabled: true,
			},
		]
	}, [t])
}
