import React, { useState, PropsWithChildren, useEffect } from 'react'
import SplitPane, { Pane } from 'split-pane-react'
import { useDebounce } from '@/hooks'
import { Sash } from './styles'
import { getInitSizes, setNavigationWidth } from './utils'

type PaneProps = {
	navigationPanel: React.ReactNode
}

export const NavigationSplitPane = ({
	navigationPanel,
	children,
}: PropsWithChildren<PaneProps>) => {
	const [sizes, setSizes] = useState<number[]>(getInitSizes)
	const navWidth = useDebounce(sizes[0], 1000)

	useEffect(() => {
		setNavigationWidth(navWidth)
	}, [navWidth])

	return (
		<SplitPane
			split="vertical"
			sizes={sizes}
			onChange={setSizes}
			sashRender={() => <Sash />}
		>
			<Pane minSize={120} maxSize={500}>
				{navigationPanel}
			</Pane>
			<>{children}</>
		</SplitPane>
	)
}
