import styled, { css } from 'styled-components'
import { CheckBoxFormField, SelectFormField } from '@/components/UberForm'

export const Container = styled.div`
	border: 1px solid #ddd;
	width: 100%;
`

export const Column = styled.div`
	display: flex;
	padding: 5px 3px;
`

export const Values = styled.div`
	padding: 5px;
`

export const AddAction = styled.div`
	border-top: 1px solid #ddd;
	display: flex;
	align-items: center;
	margin-top: 10px;
`

export const Name = styled.div`
	flex: 1;
`

export const Action = styled.div<{ $isHidden?: boolean }>`
	margin-left: 5px;
	cursor: pointer;

	${(props) => css`
		visibility: ${props.$isHidden ? 'hidden' : 'initial'};
	`}
`

export const SelectField = styled(SelectFormField)`
	width: 100%;
	border: 0;
	margin-bottom: 1px;
	border-right: 1px solid #ddd;

	.react-select__control {
		border: 0;
	}
` as unknown as new <T>() => SelectFormField<T>

export const CheckBoxField = styled(CheckBoxFormField)`
	width: 42px;
	margin: 0 6px 0 10px;
	flex: 0 1 auto;
	& label {
		height: auto;
		padding: 0 0 0 16px;
	}
`
