import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from '@/components'
import { DeleteModal } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/components/DeleteModal'
import { ReleaseDto, StructureDto } from '@/endpoints/models'
import { deleteSystemRelease } from '@/endpoints'
import { openTab } from '@/store/modules/tab/actions'
import { useAppContext, useAppDispatch } from '@/hooks'
import { Select } from '@/components/UberForm'
import { DpStatusEnum, DpStatusOptions } from '../constants'
import { DpStatus } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/types'
import { Column } from '@tanstack/react-table'

interface DpTableProps {
	columns: Column<any>[]
	deploymentPackages: ReleaseDto[] | undefined
	hasLastRowEdit: boolean
	editMode: boolean
	node: StructureDto
	reloadReleases: () => void
}

export const DpTable = ({
	columns,
	deploymentPackages,
	editMode,
	node,
	reloadReleases,
}: DpTableProps) => {
	const dispatch = useAppDispatch()
	const [deploymentPackage, setDeploymentPackage] = useState<ReleaseDto>({})
	const [isOpen, setIsOpen] = useState(false)
	const [envOption, setEnvOption] = useState<DpStatus>()
	const { t } = useAppContext()

	const deleteRequest = () =>
		deleteSystemRelease(node.id, deploymentPackage?.id ?? 0)

	const onDelete = async (deploymentPackage: ReleaseDto) => {
		setIsOpen(true)
		setDeploymentPackage(deploymentPackage)
	}

	const handleEdit = useCallback((dPackage: ReleaseDto) => {
		dispatch(
			openTab(node, false, true, {
				id: dPackage.id,
				isReleaseTab: true,
				name: dPackage.name,
				parentNode: node,
				editMode,
			}),
		)
	}, [])

	const onChange = (e: any) => {
		setEnvOption(e)
	}

	useEffect(() => {
		if (deploymentPackages) {
			setEnvOption(DpStatusOptions[2].value)
		}
	}, [deploymentPackages])

	const filterDpData = (envOption: DpStatus) => {
		switch (envOption) {
			case DpStatusEnum.Cancel:
				return deploymentPackages?.filter(
					(dp) => dp.status === DpStatusEnum.Cancel,
				)
			case DpStatusEnum.Completed:
				return deploymentPackages?.filter(
					(dp) => dp.status === DpStatusEnum.Completed,
				)

			case DpStatusEnum.Open:
				return deploymentPackages?.filter(
					(dp) => dp.status === DpStatusEnum.Open,
				)
			default:
				return deploymentPackages
		}
	}

	const filteredPackages = useMemo(
		() => filterDpData(envOption),
		[envOption, deploymentPackages],
	)

	return (
		<>
			<div className="mb-4" style={{ maxWidth: '12.5rem' }}>
				<label className="mb-1 self-start">{t('STATUS')}</label>
				<Select
					options={[...DpStatusOptions, { value: 'ALL', label: 'ALL' }]}
					onChange={onChange}
					value={envOption}
					clearable={false}
				/>
			</div>

			<Table
				columns={columns}
				data={filteredPackages}
				maxWidth={1200}
				isEditMode={editMode}
				onDelete={onDelete}
				onEdit={handleEdit}
				hasLastRowEdit
				hasLeftAction={false}
			/>
			{isOpen && (
				<DeleteModal
					object={deploymentPackage}
					deleteRequest={deleteRequest()}
					onClose={() => {
						reloadReleases()
						setIsOpen(false)
					}}
				/>
			)}
		</>
	)
}
