import { Button } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { unlockNode } from '@/store/modules/node/actions'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useAuthContext,
} from '@/hooks'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useMemo, useState } from 'react'
import { BatchEditModal } from './BatchEditModal'
import { useBatchEditContext } from './context/BatchEditContext'
import { ActionTypeEnum } from './context/types'

export const BatchEditUnlock = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [skipped, setSkipped] = useState(false)
	const [unlocking, setUnlocking] = useState(false)
	const loggedUserId = useAppStore((state) => state.auth.user?.id)
	const auth = useAuthContext()
	const canForceUnlock = useMemo(() => auth.canForceUnlock(), [auth])

	const {
		state: { selectedNodes, selectedStructures, onClose },
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const unlock = useCallback(async () => {
		setUnlocking(true)

		const unlocks = selectedStructures.map(async (node) => {
			if (node.lockTime && node.lockUserId !== loggedUserId) {
				await dispatch(unlockNode(node))
			}
		})

		await Promise.allSettled(unlocks)
		setUnlocking(false)
	}, [dispatch, loggedUserId, selectedStructures])

	const lockedByOthers = useMemo(
		() =>
			selectedStructures.filter(
				(node) => node.lockTime && node.lockUserId !== loggedUserId,
			),
		[loggedUserId, selectedStructures],
	)

	const allUnlocked = useMemo(() => {
		if (lockedByOthers.length > 0) {
			return false
		}

		return true
	}, [lockedByOthers.length])

	const onClick = useCallback(() => {
		if (canForceUnlock) {
			unlock()

			return
		}

		dispatchContext({
			type: ActionTypeEnum.SET,
			state: {
				selectedNodes: selectedNodes.filter(
					(nodeId) => !lockedByOthers.some((node) => node.id === nodeId),
				),
			},
		})

		setSkipped(true)
	}, [canForceUnlock, dispatchContext, lockedByOthers, selectedNodes, unlock])

	return (
		<>
			{allUnlocked || skipped ? (
				<BatchEditModal />
			) : (
				<Modal
					open={true}
					header={t('WARNING_LOCKED_ELEMENTS_BY_OTHER_USERS')}
					footer={(close) => (
						<>
							<Button
								icon={faCheck}
								onClick={onClick}
								isLoading={unlocking}
								schema="warn"
							>
								{canForceUnlock ? t('UNLOCK') : t('PROCEED')}
							</Button>
							<Button schema="transparent" onClick={close} icon={faTimes}>
								{t('CANCEL')}
							</Button>
						</>
					)}
					onClose={() => onClose()}
				>
					<div>
						{canForceUnlock
							? t('SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_PROCEED')
							: t('SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_SKIP')}
					</div>
				</Modal>
			)}
		</>
	)
}
