import { CsvImportReturnDto, ImportReturnMessage } from '@/endpoints/models'

export const getDuplicates = (checkResult?: CsvImportReturnDto | null) =>
	checkResult?.warnings
		?.filter((warning) =>
			[
				ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_FOLDER,
				ImportReturnMessage.MessageTypeEnum.TABLE_ALREADY_EXISTS_IN_SYSTEM,
			].includes(
				(warning.messageType as ImportReturnMessage.MessageTypeEnum) ?? '',
			),
		)
		.map((warning) => warning.object as string) ?? []
