import { StructureObjectDto } from '@/endpoints/models'

export const RELEASE_OBJECTS_FORBIDDEN = [
	StructureObjectDto.TypeEnum.SYSTEM,
	StructureObjectDto.TypeEnum.FOLDER,
	StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
	StructureObjectDto.TypeEnum.API_COLLECTION,
]

export enum DpStatusEnum {
	Cancel = 'CANCEL',
	Completed = 'COMPLETED',
	Open = 'OPEN',
}

export const DpStatusOptions = [
	{
		value: DpStatusEnum.Cancel,
		label: 'CANCEL',
	},
	{
		value: DpStatusEnum.Completed,
		label: 'COMPLETED',
	},
	{
		value: DpStatusEnum.Open,
		label: 'OPEN',
	},
]
