import React, { useMemo } from 'react'
import { StructureDto } from '@/endpoints/models'
import { OpenedMappingData } from '@/store/modules/mapping/types'
import { createEmptyMappingColumn } from '@/store/modules/mapping/helpers'
import { TableAndProperties } from '@/components'
import { UpdateDeepPartial } from '@/store/utils'
import { useParentColumns } from './useParentColumns'
import { useColumnsProperties } from './useColumnsProperties'
import { FillColumnsButton } from './FillColumnsButton'
import { useMemoObject } from '@/hooks'

type ColumnsProps = {
	node: StructureDto
	data: OpenedMappingData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<any>) => void
}

export const Columns = ({ node, data, editMode, onChange }: ColumnsProps) => {
	const mappingColumns = useMemoObject(data.form.columns || [])

	const parentColumns = useParentColumns({
		parentNodeId: node.parentStructureId,
		editMode,
	})

	const availableColumns = useMemo(() => {
		const selectedColumnCodes = mappingColumns.map(
			({ columnCode }) => columnCode,
		)

		return parentColumns.filter(
			({ code }) => code && !selectedColumnCodes.includes(code),
		)
	}, [mappingColumns, parentColumns])

	const properties = useColumnsProperties({
		parentColumns,
		availableColumns,
		editMode,
	})

	return (
		<div className="relative h-full">
			{editMode && availableColumns.length > 0 && (
				<FillColumnsButton
					availableColumns={availableColumns}
					mappingColumns={mappingColumns}
					onChange={onChange}
				/>
			)}

			<TableAndProperties
				node={node}
				data={data.form}
				createEmpty={createEmptyMappingColumn}
				itemsKey="columns"
				idCounterKey="columnsLastId"
				onChange={onChange}
				properties={properties}
				tabKey="column"
				existsKey="columnCode"
			/>
		</div>
	)
}
