import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormlessFormField } from '../FormField'
import Select from '../Input/Select/Select'
import { SelectProps } from '@/components/UberForm/Input/Select/types'
import { FormFieldProps } from '../types'

/**
 * Form field with select input.
 */
export default class SelectFormField<T> extends React.PureComponent<
	FormFieldProps &
		Omit<SelectProps<T>, 'onChange'> & {
			formless?: boolean
		}
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'valueKey',
			'labelKey',
			'options',
			'allowEmpty',
			'isMulti',
			'placeholder',
			'clearable',
			'formatOptionLabel',
			'isNumeric',
			'autoFocus',
			'openMenuOnFocus',
			'onMenuClose',
			'refInput',
			'onFocus',
			'onBlur',
			'onInputChange',
			'components',
		]) as SelectProps<Record<string, any>>

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<Select {...inputProps} />
			</FF>
		)
	}
}
