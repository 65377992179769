import React from 'react'
import styled from 'styled-components'
import { Form, TextAreaFormField } from '@/components/UberForm'
import { OpenedViewData } from '@/store/modules/view/types'
import { useAppContext } from '@/hooks'
import { ViewData } from '@/endpoints/schemas'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Source } from '../../../../components/Source/Source'
import { MainContentWrap } from '@/styles'

interface Props {
	data: OpenedViewData
	editMode: boolean
	onChange: (v: Partial<ViewData>) => void
}

export const Sources = ({ onChange, data, editMode }: Props) => {
	const { t } = useAppContext()

	return (
		<StyledContainer>
			<Form<ViewData>
				onChange={onChange}
				defaultValues={data.form}
				disabled={!editMode}
			>
				<Source
					data={data}
					onChange={onChange}
					sourceFieldName="sourceScript"
				/>
				<StyledWrapper>
					<TextAreaFormField
						name="sourceDescription"
						title={t('VIEW_FIELD_DESCRIPTION', [t('VIEW_SOURCE')])}
						initialValue={data.form.sourceDescription}
					/>
					<AceEditorField
						name="criteriaScript"
						title={t('VIEW_CRITERIA')}
						initialValue={data.form.criteriaScript}
					/>
					<TextAreaFormField
						name="criteriaDescription"
						title={t('VIEW_FIELD_DESCRIPTION', [t('VIEW_CRITERIA')])}
						initialValue={data.form.criteriaDescription}
					/>
				</StyledWrapper>
			</Form>
		</StyledContainer>
	)
}

const StyledContainer = styled(MainContentWrap)`
	textarea {
		border-radius: 0;
	}
`

const StyledWrapper = styled(MainContentWrap)`
	margin-left: 0.875rem;
`
