import { RefObject, useState, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export const useResizeObserver = (ref: RefObject<Element>) => {
	const [entry, setEntry] = useState(null as ResizeObserverEntry | null)

	useLayoutEffect(() => {
		if (ref.current) {
			const observer = new ResizeObserver(([entry]) => setEntry(entry))
			observer.observe(ref.current)

			return () => {
				observer.disconnect()
			}
		}
	}, [ref])

	return entry
}
