import {
	QUEUE_INIT,
	QUEUE_UPDATE,
	QUEUE_SAVE,
	QUEUE_SELECT_TAB,
} from './constants'
import { StructureDetailDto, StructureObjectDto } from '@/endpoints/models'
import { AppDispatch, apiCallAction } from '@/store/utils'
import { RootState } from '@/store'
import { updateDataNode } from '@/endpoints'
import { QueueData } from '@/endpoints/schemas'
import { QueueTab } from './types'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

type QueueInit = {
	type: typeof QUEUE_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

type QueueUpdate = {
	type: typeof QUEUE_UPDATE
	node: StructureObjectDto
	update: any
}

type QueueSave = {
	type: typeof QUEUE_SAVE
	payload: void
	metadata: {
		nodeId: number
	}
}

interface SelectQueueTab {
	type: typeof QUEUE_SELECT_TAB
	node: StructureObjectDto
	tab: QueueTab
}

export const initQueue =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: QUEUE_INIT,
			node,
			editMode,
			force,
		} as QueueInit)
	}

export const updateQueue = (
	node: StructureObjectDto,
	update: Partial<QueueData>,
) => ({
	type: QUEUE_UPDATE,
	node,
	update,
})

export const saveQueue =
	(node: StructureObjectDto) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const opened = getState().queue.queues[node.id]

		if (!opened) {
			throw new Error(`Saving unopened state ${node.id}`)
		}

		await dispatch(
			apiCallAction<QueueSave>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(opened.form),
					}),
				QUEUE_SAVE,
				{ nodeId: node.id },
			),
		)
	}

export const selectQueueTab = (node: StructureObjectDto, tab: QueueTab) => ({
	type: QUEUE_SELECT_TAB,
	node,
	tab,
})

export type Actions = QueueInit | QueueUpdate | QueueSave | SelectQueueTab
