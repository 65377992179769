import { ReactNode, useCallback, useState } from 'react'

import {
	TabsBar,
	TabsContainer,
	TabsContent,
	TabTitle,
	TabTitleIcon,
} from './styles'

export interface TabProps {
	content: ReactNode
	icons?: ReactNode
	id: string
	isDevMode?: boolean
	title: string
}

interface Props {
	className?: string
	onChange?: (tab: TabProps) => void
	selectedTabId?: string
	tabs: TabProps[]
}

export const Tabs = ({ tabs, className, onChange, selectedTabId }: Props) => {
	const [selectedTab, setSelectedTab] = useState(0)

	const handleChange = useCallback(
		(index: number) => {
			setSelectedTab(index)

			if (onChange) {
				onChange(tabs[index])
			}
		},
		[onChange, tabs],
	)

	const isSelected = useCallback(
		(index: number, tab: TabProps) =>
			(selectedTabId && selectedTabId === tab.id) ||
			(selectedTabId === undefined && index == selectedTab),
		[selectedTabId, selectedTab],
	)

	return (
		<TabsContainer className={className}>
			<TabsBar role="tablist">
				{tabs.map((tab, i) => {
					const selected = isSelected(i, tab)

					return (
						<TabTitle
							role="tab"
							key={tab.title}
							onClick={() => handleChange(i)}
							$isSelected={selected}
							aria-selected={selected}
						>
							<span>{tab.title}</span>
							{tab.icons && <TabTitleIcon>{tab.icons}</TabTitleIcon>}
						</TabTitle>
					)
				})}
			</TabsBar>
			<TabsContent role="tabpanel">
				{tabs.map((tab, i) => isSelected(i, tab) && tab.content)}
			</TabsContent>
		</TabsContainer>
	)
}
