import React from 'react'

import { Text } from '@/components/UberForm'
import { StructureDto } from '@/endpoints/models'
import { FormValue } from '@/types'

interface SecretInputProps {
	error: string | undefined
	isEditable: boolean
	item: any
	node: StructureDto | undefined
	textOnChange: ((value: FormValue) => void) | undefined
	textRef: React.ForwardedRef<unknown> | undefined
	value: string
}

export const SecretInput = ({
	value,
	textOnChange,
	textRef,
	error,
	item,
}: SecretInputProps) => {
	const handleInputType = (itemValue: string, value: string) => {
		if (itemValue === value) {
			return 'password'
		} else {
			return 'text'
		}
	}

	return (
		<Text
			autoFocus
			type={handleInputType(item.gitPasswordToken, value)}
			value={value}
			onChange={textOnChange}
			className="textInputWithoutBorder"
			error={error}
			refInput={textRef as React.RefObject<HTMLInputElement>}
		/>
	)
}
