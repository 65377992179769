import React, { useCallback } from 'react'
import { TableAndProperties } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { UpdateDeepPartial } from '@/store/utils'
import { createEmptyPermission } from '@/store/modules/table/helpers'
import { useSystemUsers } from '../../../../utils'
import { OpenedViewData, ViewDataForm } from '@/store/modules/view/types'
import { usePermissionsProperties } from './usePermissionsProperties'

type PermissionsProps = {
	node: StructureDto
	data: OpenedViewData
	onChange: (v: UpdateDeepPartial<ViewDataForm>) => void
	systemNodeId: number
}

export const Permissions = ({
	node,
	data,
	onChange,
	systemNodeId,
}: PermissionsProps) => {
	const { data: systemUsers } = useSystemUsers(systemNodeId)
	const properties = usePermissionsProperties({ systemUsers })

	const handleChange = useCallback(
		(update: UpdateDeepPartial<ViewDataForm>) => {
			Object.values(update.objectPermissions ?? {}).forEach((permission) => {
				if (permission.userId) {
					const user = systemUsers?.find(
						(user) => user.id === permission.userId,
					)

					permission.userCode = user?.code
					permission.userComment = user?.comment
					permission.userDescription = user?.description
					permission.userName = user?.name
				}
			})

			onChange(update)
		},
		[onChange, systemUsers],
	)

	return (
		<TableAndProperties
			idKey="id"
			existsKey="userId"
			node={node}
			data={data.form}
			createEmpty={createEmptyPermission}
			itemsKey="objectPermissions"
			idCounterKey="objectPermissionLastId"
			onChange={handleChange}
			isRowOrderable
			properties={properties}
			tabKey="permissions"
		/>
	)
}
