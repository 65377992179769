import {
	CustomAttributeCreateDto,
	CustomAttributeUpdateDto,
} from '@/endpoints/models'
import { Form, SelectFormField } from '@/components/UberForm'
import { enumToValueLabelCollection } from '@/utils/collections'
import { useAppContext } from '@/hooks'
import React from 'react'
import { FilterCell, FilterContainer } from '../../../components/Filter'
import { useCustomAttributesStereotypes } from '../hooks/useCustomAttributesStereotypes'

export type CustomAttributesFilterValues = {
	stereotypeIds?: number[]
	structureTypes?: CustomAttributeUpdateDto.StructureTypesEnum[]
}

type Props = {
	onChangeFilter: (values: CustomAttributesFilterValues) => void
}

export const CustomAttributesFilter = ({ onChangeFilter }: Props) => {
	const { t } = useAppContext()

	const stereotypes = useCustomAttributesStereotypes()

	return (
		<Form<CustomAttributesFilterValues> onChange={onChangeFilter}>
			<FilterContainer>
				<FilterCell />
				<FilterCell />
				<FilterCell>
					<SelectFormField
						title={t('TEMPLATE_ELEMENT')}
						name="structureTypes"
						options={enumToValueLabelCollection(
							CustomAttributeCreateDto.StructureTypesEnum,
						)}
						isNumeric
						clearable
						allowEmpty
					/>
				</FilterCell>
				<FilterCell>
					<SelectFormField
						title={t('STEREOTYPE')}
						name="stereotypeIds"
						options={stereotypes.data}
						fieldIsLoading={stereotypes.loading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						allowEmpty
					/>
				</FilterCell>
				<FilterCell width={76} $maxWidth={76} />
				<FilterCell width={200} $maxWidth={200} />
				<FilterCell width={170} $maxWidth={170} />
				<FilterCell width={58} $maxWidth={58} />
				<FilterCell width={60} $maxWidth={60} style={{ marginRight: 0 }} />
			</FilterContainer>
		</Form>
	)
}
