import { useState, useCallback } from 'react'

export const useLocalStore = <R>(storeKey: string, defaultValue: R) => {
	const existing = localStorage.getItem(storeKey)

	const [value, setValue] = useState(
		existing !== null ? JSON.parse(existing) : defaultValue
	)

	const setter = useCallback(
		(newValue: R) => {
			localStorage[storeKey] = JSON.stringify(newValue)

			return setValue(newValue)
		},
		[setValue, storeKey]
	)

	return [value, setter]
}
