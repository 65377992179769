import { ApiCollectionData } from '@/endpoints/schemas/api-collection'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'
import { ensureValidData } from '../helpers'
import { TAB_CLOSE } from '../tab/constants'
import { Actions } from './actions'
import {
	API_COLLECTION_INIT,
	API_COLLECTION_SAVE,
	API_COLLECTION_UPDATE,
} from './constants'
import {
	defaultApiCollectionData,
	getInitialApiCollectionData,
} from './helpers'
import { OpenedApiCollectionData } from './types'
import { GeneralNodeActions } from '@/store/modules/tab/types'

export type State = Readonly<typeof initialState>

const initialState = {
	items: {} as NativeMap<OpenedApiCollectionData>,
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions,
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const items = { ...state.items }
			delete items[action.nodeId]

			return {
				...state,
				items: items,
			}
		}

		case API_COLLECTION_INIT: {
			const { node, editMode, force } = action
			const previous = state.items[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: ApiCollectionData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultApiCollectionData)
				: getInitialApiCollectionData(node.name)

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map((c) => c.id))
			}

			const apiCollectionNode: OpenedApiCollectionData = {
				form: {
					...data,
				},
				original: data,
				selected: previous
					? previous.selected
					: {
							column: null,
					  },
				dirty: false,
				parsedEditMode: editMode,
			}

			return {
				...state,
				items: {
					...state.items,
					[node.id]: apiCollectionNode,
				},
			}
		}

		case API_COLLECTION_SAVE: {
			const id = action.metadata.node.id
			const item = state.items[id]

			const nodes = {
				...state.items,
				[id]: {
					...item,
					dirty: false,
				},
			}

			return {
				...state,
				items: nodes,
			}
		}

		case API_COLLECTION_UPDATE: {
			const { node, update } = action

			return {
				...state,
				items: updateTabData(state.items, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...update,
					},
					dirty: true,
				})),
			}
		}

		default:
			return state
	}
}
