import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { logout } from '@/store/modules/auth/actions'
import { Button, Modal } from '@/components'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { RefreshButton, ItemTitle, ItemValue } from '../styles'
import { ErrorType, ErrorContainerState, ErrorContainerProps } from '../types'

export const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			logout,
		},
		dispatch,
	)

const initialState = {
	open: true,
	timestamp: null,
	error: null,
	body: null,
	type: ErrorType.HARD,
}

class ErrorContainerComponent extends React.PureComponent<
	ErrorContainerProps,
	ErrorContainerState,
	React.PropsWithChildren
> {
	constructor(props: ErrorContainerProps) {
		super(props)

		this.state = initialState
	}

	static getDerivedStateFromError(error: Error) {
		return {
			open: true,
			error,
			timestamp: new Date(),
		}
	}

	componentDidMount() {
		window.addEventListener(
			'unhandledrejection',
			this.handlePromiseRejection.bind(this),
		)
	}

	componentWillUnmount() {
		window.removeEventListener(
			'unhandledrejection',
			this.handlePromiseRejection.bind(this),
		)
	}

	private async handlePromiseRejection({ reason }: PromiseRejectionEvent) {
		const error =
			reason instanceof Error ? reason : new Error('Unable to fetch data')

		const response = reason?.response as Response | undefined

		this.setState({
			open: true,
			error,
			response,
			timestamp: new Date(),
		})

		if (response instanceof Response) {
			try {
				const data = await response.text()

				try {
					const json = JSON.parse(data)

					this.setState({
						body: json.errorDescription || json.error || json.message || data,
						type: response.status === 423 ? ErrorType.SOFT : ErrorType.HARD,
					})
				} catch (error) {
					this.setState({ body: data })
				}
			} catch (error) {
				console.warn('Failed to load response body', error)
			}
		}
	}

	private handleRefresh() {
		window.location.reload()
	}

	render() {
		const { error, body, timestamp, type, open, response } = this.state
		const { children } = this.props
		const isErrorSoft = type === ErrorType.SOFT

		if (error) {
			return (
				<>
					<Modal
						open={open}
						closeOnEscape={isErrorSoft}
						zIndex={1001}
						contentStyle={{
							width: 'auto',
							minWidth: '250px',
							maxWidth: '50%',
						}}
						header={
							response
								? 'Error while communicating with server'
								: 'Runtime error'
						}
						footer={
							isErrorSoft ? (
								<Button
									schema="warn"
									onClick={() => this.setState({ open: false })}
								>
									Close
								</Button>
							) : (
								<RefreshButton onClick={this.handleRefresh} icon={faRedo}>
									Reload page
								</RefreshButton>
							)
						}
					>
						<div>
							<ItemTitle>Timestamp</ItemTitle>
							<ItemValue>{timestamp && timestamp.toISOString()}</ItemValue>
							<ItemTitle>Version</ItemTitle>
							<ItemValue>{process.env.VERSION}</ItemValue>
							{response && (
								<>
									<ItemTitle>Url</ItemTitle>
									<ItemValue>{response.url}</ItemValue>
								</>
							)}
							<ItemTitle>Message</ItemTitle>
							<ItemValue>{error.message}</ItemValue>
							{response && (
								<>
									<ItemTitle>Status</ItemTitle>
									<ItemValue>
										{response.status} {response.statusText}
									</ItemValue>
									<ItemTitle>Body</ItemTitle>
									<ItemValue>{body}</ItemValue>
								</>
							)}
							{!response && (
								<>
									<ItemTitle>Stack</ItemTitle>
									<ItemValue>
										<pre>{error.stack}</pre>
									</ItemValue>
								</>
							)}
						</div>
					</Modal>
					{isErrorSoft && children}
				</>
			)
		}

		return children
	}
}

export const ErrorContainer = connect(
	null,
	mapDispatchToProps,
)(ErrorContainerComponent) as React.FC<React.PropsWithChildren>
