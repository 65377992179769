import go from 'gojs'
import { setupNodeTemplates, setupLinkTemplates } from './templates'

const $ = go.GraphObject.make

export const initDiagram = () => {
	const diagram = $(go.Diagram, { scale: 1 })

	diagram.model = $(go.GraphLinksModel, { linkKeyProperty: 'key' })

	diagram.layout = $(go.LayeredDigraphLayout, {
		direction: 180,
		layerSpacing: 60,
		columnSpacing: 30,
	})

	diagram.nodeTemplateMap = setupNodeTemplates()
	diagram.linkTemplateMap = setupLinkTemplates()

	diagram.grid.visible = true
	diagram.undoManager.isEnabled = true

	return diagram
}

export const initPalette = () => {
	const palette = $(go.Palette)

	palette.layout = $(go.GridLayout, { wrappingColumn: 1 })
	palette.nodeTemplateMap = setupNodeTemplates()

	palette.maxSelectionCount = 1

	return palette
}
