import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ForwardedRef, useCallback, useMemo } from 'react'

import { Form } from '@/components/UberForm'
import { TranslationHelper } from '@/context/Locale/Locale'
import { ApiHookResult } from '@/endpoints/hooks'
import { AdjustResultDto } from '@/endpoints/models'
import { generateCode } from '@/store/utils'
import { LightTableColumn } from '@/types'
import { splitProps } from '@/utils/collections'
import { toLocaleString } from '@/utils/numbers'

import { Action, CellValue } from './styles'
import { Audit } from './types'
import { ElipsisOverflowProps } from './types'

export const auditFieldsRemove = (formValues: Record<string, any>) =>
	splitProps(formValues, [
		'updatedBy',
		'updatedAt',
		'version',
		'createdBy',
		'createdAt',
		'deleted',
	])

export const auditFieldsAdd = (auditObject?: Record<string, any>) => ({
	updatedAt: auditObject?.updatedAt,
	updatedBy: auditObject?.updatedBy,
	version: auditObject?.version,
})

export const columnsAudit = (t: TranslationHelper): any =>
	[
		{
			field: 'updatedBy',
			title: t('UPDATED_BY'),
			width: 200,
			maxWidth: 200,
		},
		{
			field: 'updatedAt',
			title: t('UPDATED_AT'),
			width: 170,
			maxWidth: 170,
			formatter: (_) => <div>{toLocaleString(_ as string)}</div>,
		},
		{
			field: 'version',
			title: t('VERSION'),
			width: 58,
			maxWidth: 58,
		},
	] as LightTableColumn<Audit>[]

export const useSettingsPageSaveHandler = (data: ApiHookResult<any>) =>
	useCallback(() => {
		data.invalidate()
	}, [data])

export const rowOrderChangeHandler = (
	oldIndex: number,
	newIndex: number,
	dataSource: ApiHookResult<any[]>,
	request: any,
	apiMethod: any,
) => {
	if (dataSource) {
		const movedItem = dataSource?.data?.find((_, index) => index === oldIndex)

		const remainingItems =
			dataSource?.data?.filter((_, index) => index !== oldIndex) || []

		const reorderedItems = [
			...remainingItems.slice(0, newIndex),
			movedItem,
			...remainingItems.slice(newIndex),
		] as any[]

		Promise.all(
			reorderedItems
				.map((item, i) => {
					if (item.ordering !== i) {
						auditFieldsRemove(item)

						return request(
							apiMethod(item.id as number, {
								...item,
								ordering: i,
							}),
						)
					}
				})
				.filter((item) => item !== undefined),
		).then(() => dataSource.invalidate())
	}
}

export const usePageItemsSort = (dataSource: ApiHookResult<any[]>) => {
	return useMemo(() => {
		if (dataSource.data) {
			return dataSource.data?.sort((a, b) => {
				return !a.ordering && !b.ordering ? a.ordering - b.ordering : 0
			})
		}

		return []
	}, [dataSource.data])
}

const ElipsisOverflow = ({ cell }: ElipsisOverflowProps) => (
	<div className="overflow-hidden" title={`${cell}`}>
		<CellValue>{cell}</CellValue>
	</div>
)

export const formatterCell1 = (cell: string | number | undefined | boolean) => (
	<ElipsisOverflow cell={cell} />
)

export const formatterCell2 = (
	cell: string | number | undefined | boolean,
	handleEdit: (tableId?: number) => void,
	t: any,
) => (
	<Action
		onClick={() => handleEdit(cell as number | undefined)}
		title={t('EDIT')}
	>
		<FontAwesomeIcon icon={faEdit} />
	</Action>
)

export const onNameChange = async (
	dispatch: (action: Promise<AdjustResultDto>) => Promise<AdjustResultDto>,
	ref: ForwardedRef<Form<any>>,
	value: string,
) => {
	const getCode = async (
		dispatch: (action: Promise<AdjustResultDto>) => Promise<AdjustResultDto>,
		value: string,
	) => {
		const response = await dispatch(generateCode(value))
		const { result } = response
		return result
	}

	const code = await getCode(dispatch, value)

	if (ref && 'current' in ref && ref.current) {
		await ref.current.setValues({ code })
	}
}
