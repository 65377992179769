import { DpStructureSubRowsTable } from '@/pages/User/pages/Home/pages/SystemDetail/pages/Releases/components/Structure/DpStructureSubRowsTable'
import React from 'react'
import { Row } from '@tanstack/react-table'

interface TableSubRowContentProps {
	row: Row<any>
}

export const TableSubRowContent = ({ row }: TableSubRowContentProps) => {
	return <DpStructureSubRowsTable data={row.original.subRows} editMode={true} />
}
