import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { StructureObjectDto } from '@/endpoints/models'
import { Button, DialogWrapper, TabsActionsBar } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Flex } from '@/components/Layout/Flex'
import { openTab } from '@/store/modules/tab/actions'
import { Empty } from '@/components/Layout'
import { useAppContext, useAppStore, useAppDispatch } from '@/hooks'
import { Workflow } from './Workflows/components/Workflow'
import { AddModal } from '../../../components/AddModal'
import { isNotUndefined } from '@/utils/collections'
import { WorkflowsProps } from '@/pages/User/pages/Home/types'
import { MainContentWrap, ThCell } from '@/styles'

export const Workflows = ({ node }: WorkflowsProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const children = useAppStore((state) => state.node.children)
	const nodes = useAppStore((state) => state.node.nodes)

	const workflowChildren = useMemo(() => {
		const myChildren = children && children[node.id]

		return myChildren
			? myChildren
					.map((id) => nodes[id])
					.filter(isNotUndefined)
					.filter((node) => node.type === StructureObjectDto.TypeEnum.WORKFLOW)
			: []
	}, [children, node.id, nodes])

	const handleEdit = useCallback(
		(node: StructureObjectDto) => {
			dispatch(openTab(node, false))
		},
		[dispatch],
	)

	return (
		<>
			{node.canEdit && (
				<TabsActionsBar>
					<Flex $align="center">
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<AddModal
										type={StructureObjectDto.TypeEnum.WORKFLOW}
										onClose={onClose}
										node={node}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" icon={faPlus} onClick={onClick}>
									{t('ADD_NEW')}
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				</TabsActionsBar>
			)}
			<MainContentWrap>
				<Flex $align="stretch">
					<ThCell>{t('WORKFLOW_NAME')}</ThCell>
					<ThCell>{t('WORKFLOW_CODE')}</ThCell>
					<Actions />
				</Flex>

				{workflowChildren.map((workflow) => (
					<Workflow key={workflow.id} node={workflow} onEdit={handleEdit} />
				))}

				{workflowChildren.length === 0 && <Empty>{t('NOTHING_HERE')}</Empty>}
			</MainContentWrap>
		</>
	)
}

const Actions = styled.div`
	flex: 1;
	width: 16px;
`
