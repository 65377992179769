import { apiCallAction } from '@/store/utils'
import { NODE_LOAD, NODE_LOAD_HISTORY_VERSION } from './constants'
import { HistoryDetailDto, StructureDetailDto } from '@/endpoints/models'
import { getNodeDetail, getNodeHistoryDetailByVersion } from '@/endpoints'

export interface LoadNode {
	type: typeof NODE_LOAD
	payload: StructureDetailDto
}

export interface LoadNodeHistoryVersion {
	type: typeof NODE_LOAD_HISTORY_VERSION
	payload: HistoryDetailDto
}

export const loadNode = (id: number) => {
	return apiCallAction<LoadNode>(() => getNodeDetail(id), NODE_LOAD)
}

export const loadNodeHistoryVersion = (
	id: number,
	version: number,
	envId?: number,
) =>
	apiCallAction<LoadNodeHistoryVersion>(
		() => getNodeHistoryDetailByVersion(id, version, { envId }),
		NODE_LOAD_HISTORY_VERSION,
	)
