import React from 'react'
import { DialogWrapper, Button } from '@/components'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { Permissions } from './Permissions'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

export const PermissionsButton = ({ node }: { node: StructureObjectDto }) => {
	const { t } = useAppContext()

	return (
		<DialogWrapper
			dialog={(opened, onClose) => (
				<Permissions
					nodeId={node.id}
					isReadOnly={!node.canEdit}
					onClose={onClose}
					opened={opened}
				/>
			)}
		>
			{(open) => (
				<Button onClick={open} icon={faKey}>
					{t('BUTTON_PERMISSIONS')}
				</Button>
			)}
		</DialogWrapper>
	)
}
