import React, { useState } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { DomainDto, DomainTechnologyDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import {
	TextFormField,
	CheckBoxFormField,
	TextAreaFormField,
} from '@/components/UberForm'
import { useApi } from '@/endpoints/hooks'
import { getAllSystemTechnologies } from '@/endpoints'
import { Column, Row } from '../../../styles'
import { auditFieldsRemove, auditFieldsAdd } from '../../../utils'
import { AuditForm } from '../../../components/AuditForm'
import { DomainTechnologyRow } from './DomainTechnologyRow'
import styled from 'styled-components'
import { useConfirmationDomain } from './hooks/useConfirmationDomain'

type DomainModalProps = {
	domain?: Partial<DomainDto>
	onSave: () => void
	onClose: () => void
	textAreaHeight?:string
}

export const DomainModal = ({ domain, onSave, onClose,textAreaHeight }: DomainModalProps) => {
	const { t } = useAppContext()
	const systemTechnologies = useApi(getAllSystemTechnologies())

	const [hasTemplate, setHasTemplate] = useState(domain?.hasTemplate ?? false)
	const [custom, setCustom] = useState(domain?.custom ?? false)

	const [, setTextValueFlag] = useState(domain?.textValueFlag ?? false)

	const { onConfirmationDomain } = useConfirmationDomain()

	const [domainTechnologies, setDomainTechnologies] = useState(
		domain?.domainTechnologies ?? [],
	)

	const handleSubmit = async (form: Partial<DomainDto>) => {
		auditFieldsRemove(form)

		const domain = {
			...form,
			domainTechnologies: custom
				? []
				: domainTechnologies.filter((x) => x.dataType),
		} as DomainDto

		const postProcess = (dto: DomainDto | null) => {
			if (dto) {
				onSave()
				onClose()
			}
		}

		await onConfirmationDomain(domain, postProcess)
	}

	const handleTechnologyChange = (domainTechnology: DomainTechnologyDto) =>
		setDomainTechnologies([
			...domainTechnologies.filter(
				(x) => x.technologyId !== domainTechnology.technologyId,
			),
			domainTechnology,
		])

	return (
		<ModalForm<DomainDto>
			open
			onClose={onClose}
			contentStyle={{
				width: custom ? '40%' : '95%',
				maxWidth: '1400px',
				maxHeight: '90%',
				overflow: 'auto',
			}}
			initialValues={{
				id: domain?.id,
				name: domain?.name,
				code: domain?.code,
				description: domain?.description,
				custom: domain?.custom,
				hasTemplate: domain?.hasTemplate,
				template: domain?.template,
				templateValueFormat: domain?.templateValueFormat,
				templateValueRegex: domain?.templateValueRegex,
				notNullFlag: domain?.notNullFlag,
				textValueFlag: domain?.textValueFlag,
				...auditFieldsAdd(domain),
			}}
			header={domain ? t('UPDATE_DOMAIN') : t('CREATE_DOMAIN')}
			onSubmit={handleSubmit}
		>
			<Container>
				<LeftColumn>
					<TopAlignedRow>
						<Column width="50%">
							<TextFormField title={t('DOMAIN_NAME')} name="name" required />
							<TextFormField title={t('DOMAIN_CODE')} name="code" required />

							<TextAreaFormField
								textAreaHeight={textAreaHeight}
								title={t('DOMAIN_DESCRIPTION')}
								name="description"
								rows={3}
							/>
						</Column>

						<CheckboxesColumn width="50%">
							<CheckBoxFormField
								title={t('DOMAIN_NOT_NULL')}
								name="notNullFlag"
							/>
							<CheckBoxFormField
								title={t('DOMAIN_CUSTOM')}
								name="custom"
								onChange={(value: any) => setCustom(value as boolean)}
							/>
							<CheckBoxFormField
								title={t('DOMAIN_TEXT_VALUE_FLAG')}
								name="textValueFlag"
								onChange={(value: any) => setTextValueFlag(value as boolean)}
							/>
							<CheckBoxFormField
								title={t('DOMAIN_HAS_TEMPLATE')}
								name="hasTemplate"
								onChange={(value: any) => setHasTemplate(value as boolean)}
							/>

							{hasTemplate && (
								<>
									<TextFormField title={t('DOMAIN_TEMPLATE')} name="template" />
									<TextFormField
										title={t('DOMAIN_TEMPLATE_VALUE_FORMAT')}
										name="templateValueFormat"
									/>
									<TextFormField
										title={t('DOMAIN_TEMPLATE_VALUE_REGEX')}
										name="templateValueRegex"
									/>
								</>
							)}
						</CheckboxesColumn>
					</TopAlignedRow>

					<Column width="100%">
						<AuditForm $isUpdating={!!domain} />
					</Column>
				</LeftColumn>

				{!custom && (
					<RightColumn>
						{(systemTechnologies.data ?? []).map((x) => (
							<DomainTechnologyRow
								key={x.id}
								technology={x}
								domainTechnologies={domainTechnologies}
								onChange={handleTechnologyChange}
							/>
						))}
					</RightColumn>
				)}
			</Container>
		</ModalForm>
	)
}

const Container = styled.div`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
`

const LeftColumn = styled.div`
	flex: 1 400px;
	margin: 0 -20px;
`

const RightColumn = styled.div`
	flex: 1 400px;
	padding-left: 25px;
	margin: 0 -20px 0 25px;
	border-left: 5px #bbb solid;
`

const TopAlignedRow = styled(Row)`
	align-items: flex-start;
`

const CheckboxesColumn = styled(Column)`
	padding-top: 17px;
`
