import { StructureObjectDto } from '@/endpoints/models'
import { RootState } from '@/store'

export const TYPE_TO_SELECTOR: Record<
	any,
	(state: RootState) => Record<string, unknown>
> = {
	[StructureObjectDto.TypeEnum.API_COLLECTION]: (state) =>
		state.apiCollection.items,
	[StructureObjectDto.TypeEnum.API_NODE]: (state) => state.apiNode.nodes,
	[StructureObjectDto.TypeEnum.FOLDER]: (state) => state.folder.folders,
	[StructureObjectDto.TypeEnum.FOLDER_WORKFLOW]: () => ({}),
	[StructureObjectDto.TypeEnum.MAPPING]: (state) => state.mapping.mappings,
	[StructureObjectDto.TypeEnum.MAPPING_DEFAULT]: (state) =>
		state.mapping.mappings,
	[StructureObjectDto.TypeEnum.MAPPING_READ]: (state) =>
		state.readMapping.mappings,
	[StructureObjectDto.TypeEnum.QUEUE]: (state) => state.queue.queues,
	[StructureObjectDto.TypeEnum.ROOT]: () => ({}),
	[StructureObjectDto.TypeEnum.SYSTEM]: (state) => state.system.systems,
	[StructureObjectDto.TypeEnum.TABLE]: (state) => state.table.tables,
	[StructureObjectDto.TypeEnum.VIEW]: (state) => state.view.views,
	[StructureObjectDto.TypeEnum.WORKFLOW]: (state) => state.workflow.workflows,
	[StructureObjectDto.TypeEnum.MAPPING_WRITE]: (state) =>
		state.writeMapping.mappings,
}
