import { Button } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import React from 'react'

export interface DpCreationSelectOptionProps {
	systemNode: StructureDto | undefined
	inputValue: string
	isReleaseLoading: boolean
	setIsReleaseLoading: React.Dispatch<React.SetStateAction<boolean>>
	createRelease: (systemNode: StructureDto, name: string) => Promise<any>
}

const DpCreationSelectOption = ({
	systemNode,
	inputValue,
	isReleaseLoading,
	setIsReleaseLoading,
	createRelease,
}: DpCreationSelectOptionProps) => {
	const { t } = useAppContext()

	console.log(isReleaseLoading)

	return (
		<div className="flex gap-4 items-center ml-3 justify-between">
			{<span>{t('CREATE_DEPLOYMENT_PACKAGE', [inputValue])}</span>}
			<Button
				style={{ height: '24px', marginRight: '6px' }}
				isLoading={isReleaseLoading}
				onClick={() => {
					setIsReleaseLoading(true)

					createRelease(systemNode as StructureDto, inputValue)
				}}
			>
				{t('CREATE')}
			</Button>
		</div>
	)
}

export default DpCreationSelectOption
