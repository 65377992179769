import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: max(400px, 100%);
`

export const Controls = styled.div`
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;
`

export const DiagramContainer = styled.div`
	flex: 1;
	position: relative;
	box-sizing: border-box;
	border: 1px solid;
	display: flex;
	flex-wrap: wrap;

	.palette {
		flex: 0 0 200px;
		background-color: white;
		border-right: 1px solid;
	}

	.diagram {
		flex: 1 1 auto;
	}
`
