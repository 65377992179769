import React, { useCallback, useMemo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faArrowUp,
	faArrowDown,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { TableConstraintColumn, TableIndexColumn } from '@/endpoints/schemas'
import { SortIcon } from '@/pages/User/pages/Home/pages/TableDetail/components/SortIcon'
import {
	ColumnOption,
	ColumnsPickerItem,
	ColumnsPickerProps,
	ColumnsPickerValue,
} from './types'
import {
	Action,
	AddAction,
	CheckBoxField,
	Name,
	SelectField,
	Values,
	Column,
	Container,
} from './styles'
import { FormValue } from '@/types'

export const ColumnsPicker = ({
	field,
	columns,
	value,
	onChange,
	orders,
}: ColumnsPickerProps) => {
	useEffect(() => {
		// remove columns for other constraint types (Foreign key etc.)
		const valueFiltered = value.filter(
			(v: TableConstraintColumn) => !v.foreignColumnUuid,
		)

		onChange && onChange(valueFiltered, field)
	}, [])

	const handleColumnChange = useCallback(
		(v: FormValue) => {
			if (typeof v === 'string') {
				const newValue: ColumnsPickerValue = [
					...value,
					{
						...JSON.parse(v),
						...(orders && { sortType: TableIndexColumn.SortTypeEnum.ASC }),
					},
				]

				onChange && onChange(newValue, field)
			}
		},
		[field, value],
	)

	const handleUp = (item: ColumnsPickerItem) => {
		const newValue = [...value]
		const index = value.findIndex((v) => v.uuid === item.uuid)

		if (index > 0) {
			const b = value[index - 1]
			newValue[index] = b
			newValue[index - 1] = item

			onChange && onChange(newValue, field)
		}
	}

	const handleDown = (item: ColumnsPickerItem) => {
		const newValue = [...value]
		const index = value.findIndex((v) => v.uuid === item.uuid)

		if (index < value.length - 1) {
			const b = value[index + 1]
			newValue[index] = b
			newValue[index + 1] = item

			onChange && onChange(newValue, field)
		}
	}

	const handleRemove = (item: ColumnsPickerItem) => {
		onChange &&
			onChange(
				value.filter((v) => v.uuid !== item.uuid),
				field,
			)
	}

	const onChangeItemSort = (item: ColumnsPickerItem) => {
		onChange &&
			onChange(
				value.map((v) =>
					v.uuid !== item.uuid
						? v
						: {
								...v,
								sortType:
									v.sortType === TableIndexColumn.SortTypeEnum.DESC
										? TableIndexColumn.SortTypeEnum.ASC
										: TableIndexColumn.SortTypeEnum.DESC,
							},
				),
				field,
			)
	}

	const items = useMemo(
		() =>
			columns
				.filter((c) => !value.find((v) => v.uuid === c.uuid))
				.reduce((items, column) => {
					items.push({
						value: JSON.stringify({
							uuid: column.uuid,
							code: column.code,
						}),
						label: column.name,
					})

					return items
				}, [] as ColumnOption[]),
		[columns],
	)

	return (
		<Container>
			<Values>
				{value &&
					value
						.map((col) => ({
							item: col,
							col: columns.find((c) => c.uuid === col.uuid),
						}))
						.filter(({ col }) => col !== undefined)
						.map(
							({ col, item }, index) =>
								col && (
									<Column key={col.id}>
										{orders && (
											<SortIcon
												sortType={
													item.sortType ?? TableIndexColumn.SortTypeEnum.DESC
												}
											/>
										)}
										<Name>{col.name}</Name>
										<Action
											$isHidden={index === 0}
											onClick={() => handleUp(item)}
										>
											<FontAwesomeIcon icon={faArrowUp} />
										</Action>
										<Action
											$isHidden={index === value.length - 1}
											onClick={() => handleDown(item)}
										>
											<FontAwesomeIcon icon={faArrowDown} />
										</Action>
										<Action onClick={() => handleRemove(item)}>
											<FontAwesomeIcon icon={faTimes} />
										</Action>
										{orders && (
											<CheckBoxField
												name={'sort' + col.id}
												initialValue={
													item.sortType === TableIndexColumn.SortTypeEnum.DESC
												}
												onChange={() => onChangeItemSort(item)}
												title={TableIndexColumn.SortTypeEnum.DESC}
												showTitlePlaceholder={false}
											/>
										)}
									</Column>
								),
						)}
			</Values>

			<AddAction>
				<SelectField
					name="column"
					options={items}
					initialValue={null}
					onChange={handleColumnChange}
					formless
					hideTitle
					allowEmpty
				/>
			</AddAction>
		</Container>
	)
}
