import React, { useCallback, useMemo } from 'react'
import { TableAndProperties } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { PermissionsProperties } from './Properties'
import { UpdateDeepPartial } from '@/store/utils'
import { useAppContext } from '@/hooks'
import { createEmptyPermission } from '@/store/modules/table/helpers'
import { useSystemUsers } from '../../../../utils'
import { MappingPermission } from '@/endpoints/schemas'
import {
	MappingDataForm,
	OpenedMappingData,
} from '@/store/modules/mapping/types'

type Props = {
	node: StructureDto
	data: OpenedMappingData
	editMode: boolean
	onChange: (v: UpdateDeepPartial<MappingDataForm>) => void
	systemNodeId: number
}

export const Permissions = ({ node, data, onChange, systemNodeId }: Props) => {
	const { t } = useAppContext()
	const systemUsers = useSystemUsers(systemNodeId)

	const handleChange = useCallback(
		(update: UpdateDeepPartial<MappingDataForm>) => {
			Object.values(update.objectPermissions ?? {}).forEach((permission) => {
				if (permission.userId) {
					const user = systemUsers.data?.find(
						(user) => user.id === permission.userId,
					)

					permission.userCode = user?.code
					permission.userComment = user?.comment
					permission.userDescription = user?.description
					permission.userName = user?.name
				}
			})

			onChange(update)
		},
		[onChange, systemUsers.data],
	)

	const properties = useMemo(
		() =>
			PermissionsProperties(
				t,
				Object.values(MappingPermission.PermissionsEnum),
				systemUsers,
			),
		[systemUsers, t],
	)

	return (
		<TableAndProperties
			idKey="id"
			existsKey="userId"
			node={node}
			data={data.form}
			createEmpty={createEmptyPermission}
			itemsKey={'objectPermissions'}
			idCounterKey={'objectPermissionLastId'}
			onChange={handleChange}
			isRowOrderable
			properties={properties}
			tabKey="permissions"
		/>
	)
}
