import go from 'gojs'
import { Category } from './types'

// more shapes you can find here https://gojs.net/latest/samples/shapes.html
const shapes = {
	rectangle: 'Rectangle',
	lineH: 'LineH',
} as const

// more arrows you can find here https://gojs.net/latest/samples/arrowheads.html
const arrows = {
	none: 'None',
	openTriangle: 'OpenTriangle',
} as const

const colors = {
	background: '#ffffff',
	border: '#3d3d3d',
}

export const setupNodeTemplates = () => {
	const $ = go.GraphObject.make
	const nodeTemplates = new go.Map<Category, go.Part>()

	const defaultNode = $(
		go.Node,
		go.Panel.Auto,
		$(go.Shape, shapes.rectangle),
		$(go.TextBlock, { margin: 8 }, new go.Binding('text')),
	)

	nodeTemplates.add('', defaultNode)

	const tableNode = $(
		go.Node,
		'Auto',
		new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
			go.Point.stringify,
		),
		{
			locationSpot: go.Spot.Center,
			movable: true, // Here is the click event handler:
			click: function (e, obj) {
				const clickedNode = obj.part

				if (clickedNode !== null) {
					console.log('Clicked Node:', clickedNode.data)
					// You can access your node's data properties like this:
					console.log('Clicked Node Key:', clickedNode.data.key)
				}
			},
		},
		$(go.Shape, shapes.rectangle, {
			stroke: colors.border,
			fill: colors.background,
		}),
		$(
			go.Panel,
			go.Panel.Vertical,
			$(go.TextBlock, { margin: 4 }, new go.Binding('text')),
			$(go.Shape, shapes.lineH, { height: 1, stroke: colors.border }),
			$(
				go.Panel,
				go.Panel.Table,
				{
					stretch: go.GraphObject.Horizontal,
					defaultColumnSeparatorStroke: colors.border,
					defaultRowSeparatorStroke: colors.border,
				},
				$(go.TextBlock, '', { row: 0, column: 0 }),
				$(go.TextBlock, '', { row: 0, column: 1 }),
				$(go.TextBlock, '', { row: 1, column: 0 }),
				$(go.TextBlock, '', { row: 1, column: 1 }),
				$(go.TextBlock, '', { row: 2, column: 0 }),
				$(go.TextBlock, '', { row: 2, column: 1 }),
			),
		),
	)

	nodeTemplates.add('table', tableNode)

	return nodeTemplates
}

export const setupLinkTemplates = () => {
	const $ = go.GraphObject.make
	const linkTemplates = new go.Map<string, go.Link>()

	const defaultLink = $(
		go.Link,
		$(go.Shape),
		$(go.Shape, { toArrow: arrows.openTriangle }),
		$(go.TextBlock, { background: colors.background }, new go.Binding('text')),
	)

	linkTemplates.add('', defaultLink)

	return linkTemplates
}
