export const generateHeaderTitle = (
	t: any,
	drop?: boolean,
	preview?: boolean,
): string => {
	if (drop) {
		return t('DROP_TABLE')
	} else if (preview) {
		return t('PREVIEW_DLL')
	} else {
		return t('CREATE_DDL')
	}
}
