import React, { useEffect, useRef, useState } from 'react'
import { Portal } from '../Portal/Portal'
import { useWindowEvent } from '@/hooks'
import { TooltipContainer } from './styles'
import { Position } from '@/enums'

interface TooltipProps {
	content?: React.ReactNode
	showOnHover?: boolean
	shown?: boolean
	disableStyle?: boolean
	position?: Position
	children?: React.ReactNode
	className?: string
	styleTrigger?: React.CSSProperties
}

export const Tooltip = ({
	showOnHover = true,
	shown = true,
	position = Position.Top,
	disableStyle,
	content,
	children,
	className,
	styleTrigger,
}: TooltipProps) => {
	const [opened, setOpened] = useState(showOnHover ? false : shown)

	const [calculatedPosition, setCalculatedPosition] = useState({
		left: -1000 as number | undefined,
		top: -1000 as number | undefined,
		maxHeight: undefined as number | undefined,
	})

	const triggerRef = useRef<HTMLDivElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)

	const handleMouseEnter = () => {
		setOpened(true)
	}

	const handleMouseLeave = () => {
		setOpened(false)
	}

	const recalculate = () => {
		let top = undefined as number | undefined
		let left = undefined as number | undefined
		let maxHeight = undefined as number | undefined

		const viewHeight = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0,
		)

		if (triggerRef.current && contentRef.current) {
			const rect = triggerRef.current?.getBoundingClientRect()
			const contentRect = contentRef.current?.getBoundingClientRect()

			switch (position) {
				case Position.Top: {
					left = rect.left
					top = rect.top - 5 - contentRect.height
					break
				}

				case Position.Left: {
					left = rect.left - contentRect.width - 15
					top = rect.top - 15
					break
				}

				case Position.BottomLeft: {
					left = rect.right - contentRect.width
					top = rect.bottom
					break
				}

				case Position.Bottom: {
					left = rect.left
					top = rect.bottom + 5
					break
				}
			}

			maxHeight = viewHeight - top - 15

			setCalculatedPosition({ left, top, maxHeight })
		}
	}

	useEffect(() => {
		recalculate()
	}, [opened, shown])

	useWindowEvent('resize', () => recalculate())
	useWindowEvent('scroll', () => recalculate(), true)

	if (!content) {
		return <>{children}</>
	}

	return (
		<>
			<div
				onMouseEnter={showOnHover ? handleMouseEnter : undefined}
				onMouseLeave={showOnHover ? handleMouseLeave : undefined}
				ref={triggerRef}
				style={styleTrigger}
			>
				{children}
			</div>

			{(opened || (shown && !showOnHover)) && (
				<Portal>
					<TooltipContainer
						className={className}
						ref={contentRef}
						disableStyle={disableStyle}
						style={calculatedPosition}
					>
						{content}
					</TooltipContainer>
				</Portal>
			)}
		</>
	)
}

export default Tooltip
