import { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { ViewPermission } from '@/endpoints/schemas'
import capitalize from 'lodash/capitalize'
import { TablePermission, SystemUser } from '@/endpoints/models'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	systemUsers: SystemUser[] | undefined
}

const permissions = Object.values(ViewPermission.PermissionsEnum)

const mapPermissionToOption = (value: string) => ({
	value,
	label: capitalize(value),
})

export const usePermissionsProperties = ({ systemUsers }: HookOptions) => {
	const { t } = useAppContext()

	const properties = useMemo<TableColumnProps<TablePermission>[]>(
		() => [
			{
				field: 'userId',
				label: t('SYSTEM_USER'),
				type: ItemPropertyType.OPTION,
				options: systemUsers,
				optionValue: 'id',
				optionLabel: 'name',
				flex: 1,
				showWhenPanelOpened: true,
				isNumeric: true,
				width: 50,
			},
			{
				field: 'permissions',
				label: t('PERMISSIONS'),
				type: ItemPropertyType.OPTIONS,
				flex: 2,
				showWhenPanelOpened: true,
				options: permissions.map(mapPermissionToOption),
				width: 50,
			},
		],
		[systemUsers, t],
	)

	return properties
}
