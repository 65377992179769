import { combineReducers } from '@reduxjs/toolkit'

import { historyApiSlice } from '@/rtkQueries'

import miscSlice from '../slices/misc'
import acronyms from './acronyms'
import actionsTimestamps from './actionsTimestamps'
import apiCollection from './apiCollection'
import apiError from './apiError'
import apiNode from './apiNode'
import auth from './auth'
import businessDomain from './businessDomain'
import folder from './folder'
import formHighlights from './formHighlights'
import mapping from './mapping'
import node from './node'
import queue from './queue'
import readMapping from './readMapping'
import settings from './settings'
import stereotype from './stereotype'
import system from './system'
import tab from './tab'
import table from './table'
import technology from './technology'
import user from './user'
import view from './view'
import workflow from './workflow'
import writeMapping from './writeMapping'

const apiReducers = {
	[historyApiSlice.reducerPath]: historyApiSlice.reducer,
}
const reducers = {
	acronyms,
	actionsTimestamps,
	apiCollection,
	apiError,
	apiNode,
	auth,
	businessDomain,
	folder,
	formHighlights,
	mapping,
	miscSlice,
	node,
	queue,
	readMapping,
	settings,
	stereotype,
	system,
	tab,
	table,
	technology,
	user,
	view,
	workflow,
	writeMapping,
	...apiReducers,
}

export const rootReducer = combineReducers(reducers)

export * from '../slices/misc'
