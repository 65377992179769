import { importStructureCsvIntoNodeCheck } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { CsvImportRowDto } from '@/endpoints/models'
import { ImportStructureProps } from '../../../hooks/useImport'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { ActionTypeEnum } from '../context/types'
import { booleanFromString } from '../utils/booleanFromString'
import { getRowsWithoutDuplicates } from '../utils/getRowsWithoutDuplicates'

export const useOnCheckSubmit = () => {
	const request = useApiRequest()

	const {
		state: {
			hasHeaders,
			inputDataParsed,
			mapping,
			onMapped,
			nodeId,
			createAnywayTableNames,
		},
		dispatch,
	} = useCsvImporterContext()

	const onSubmit = async (values: ImportStructureProps) => {
		const formDataCheck = {
			...values,
			importAllColumns: !values.importAllColumns,
			generateCodes: false,
		}

		const rows = inputDataParsed?.data.slice(hasHeaders ? 1 : 0).map((row) => {
			const mapped = mapping as Record<string, number>

			return {
				column: {
					name: row[mapped['name']],
					code: row[mapped['code']],
					domainName: row[mapped['domainName']],
					dataType: row[mapped['dataType']],
					defaultValue: row[mapped['defaultValue']],
					mandatoryFlag: booleanFromString(row[mapped['mandatory']]),
					description: row[mapped['description']],
					comment: row[mapped['comment']],
					primaryKeyName: row[mapped['primaryKeyName']],
					indexName: row[mapped['indexName']],
					stereotypeCode: row[mapped['stereotypeCode']],
				},
				table: {
					name: row[mapped['tableName']],
					code: row[mapped['tableCode']],
					stereotypeCode: row[mapped['tableStereotype']],
					description: row[mapped['tableDescription']],
					comment: row[mapped['tableComment']],
					script: row[mapped['tableScript']],
					preScript: row[mapped['tablePreScript']],
					postScript: row[mapped['tablePostScript']],
				},
			} as CsvImportRowDto
		})

		if (rows) {
			const checkResult = await request(
				importStructureCsvIntoNodeCheck(nodeId, rows, formDataCheck),
			)

			dispatch({
				type: ActionTypeEnum.ROWS_SET,
				rows,
			})

			dispatch({
				type: ActionTypeEnum.CHECK_RESULT_SET,
				checkResult: checkResult.data || undefined,
			})

			if (checkResult) {
				if (checkResult.data?.errors?.length !== 0) {
					onMapped()

					return
				}

				const rowsWithoutDuplicates = getRowsWithoutDuplicates(
					createAnywayTableNames,
					rows,
					checkResult.data,
				)

				onMapped(rowsWithoutDuplicates, formDataCheck, true)
			}
		}
	}

	return onSubmit
}
