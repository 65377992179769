import { useCallback, useMemo } from 'react'
import {
	getAllSystemTechnologies,
	getAllWorkflowTechnologies,
	getSystemUsers,
} from 'src/endpoints'

import { Flex } from '@/components/Layout'
import {
	Form,
	ReadonlyFormField,
	SelectFormField,
	TextFormField,
} from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { useApi } from '@/endpoints/hooks'
import { StructureObjectDto } from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import {
	useAppContext,
	useAppDispatch,
	useCustomAttributesInitValues,
} from '@/hooks'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { MainContentWrap } from '@/styles'
import { GenerateCodeEnum } from '@/types'
import { booleanString } from '@/utils/booleans'
import { uniqueNameValidator } from '@/utils/validators'

import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'

type OverviewProps = {
	data: OpenedSystemData
	editMode: boolean
	node?: StructureObjectDto
	onChange: (v: Partial<SystemData>) => void
	/** Selected nodes for batch edit */
	selectedNodes?: StructureObjectDto[]
	systemNodeId: number
}

export const Overview = ({
	data,
	editMode,
	systemNodeId,
	onChange,
	selectedNodes,
}: OverviewProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const systemTechnologies = useApi(getAllSystemTechnologies())
	const workflowTechnologies = useApi(getAllWorkflowTechnologies())
	const formData = data.form

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId),
	)

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes,
	} = useCustomAttributesInitValues(
		formData,
		StructureObjectDto.TypeEnum.SYSTEM,
	)

	const handleChange = useCallback(
		(
			item: Partial<SystemDataForm>,
			source?: FormlessFormField<SystemDataForm>,
		) => {
			if (source) {
				const name = source.props.name

				if (name === 'ownerId') {
					const owner = systemUsers?.find((u) => u.id === item[name])

					onChange({
						[name]: owner?.id,
						ownerName: owner?.name,
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name],
						},
						formData.customAttributes,
						customAttributes,
					),
				)
			}
		},
		[formData.customAttributes, systemUsers],
	)

	const generateCodeOptions = useMemo(
		() =>
			Object.values(GenerateCodeEnum)
				.map((key) => ({
					value: key,
					label: t(key),
				}))
				.filter((opt) => opt.value !== GenerateCodeEnum.INHERIT),
		[t],
	)

	const caseToggleOptions = [
		{
			value: false,
			label: 'Uppercase',
		},
		{
			value: true,
			label: 'Lowercase',
		},
	]

	return (
		<MainContentWrap>
			<Form<SystemDataForm>
				defaultValues={{
					...formData,
					...initialValuesCustomAttributes,
				}}
				onChange={handleChange}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
			>
				<Flex>
					<TextFormField
						title={t('NAME')}
						name="name"
						validators={[uniqueNameValidator(dispatch, data.original.name)]}
						disabled={Boolean(selectedNodes)}
					/>
					<SelectFormField
						name="ownerId"
						title={t('NODE_OWNER')}
						options={systemUsers || []}
						fieldIsLoading={isSystemUserLoading}
						valueKey="id"
						labelKey="name"
						isNumeric
						allowEmpty
						clearable
					/>
				</Flex>
				<Flex>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateCode"
							title={t('GENERATE_ELEMENT_CODE')}
							options={generateCodeOptions}
							clearable={false}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateTableColumnCode"
							title={t('GENERATE_TABLE_COLUMN_CODE')}
							options={generateCodeOptions}
							clearable={false}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="letterCaseToggle"
							title={t('LETTER_CASE_TOGGLE')}
							options={caseToggleOptions}
							clearable={false}
						/>
					</div>
				</Flex>
				<ReadonlyFormField
					title={t('SYSTEM_TECHNOLOGY')}
					value={
						systemTechnologies.data?.find(
							(systemTechnology) =>
								systemTechnology.id === formData.systemTechnologyId,
						)?.name || t('NOTHING_HERE')
					}
				/>
				<ReadonlyFormField
					title={t('WORKFLOW_TECHNOLOGY')}
					value={
						workflowTechnologies.data?.find(
							(workflowTechnology) =>
								workflowTechnology.id === formData.workflowTechnologyId,
						)?.name || t('NOTHING_HERE')
					}
				/>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</MainContentWrap>
	)
}
