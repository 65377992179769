import { useMemo } from 'react'
import { ApiNodeColumn } from '@/endpoints/schemas/api-node'
import { useAppContext } from '@/hooks'
import { useTypeOptions } from './useTypeOptions'
import { useApi } from '@/endpoints/hooks'
import { getStereotypes } from 'src/endpoints'
import { StereotypeDto, DomainDto } from '@/endpoints/models'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	domains: DomainDto[]
}

const customDomainPredicate = (domains: DomainDto[]) => (item: ApiNodeColumn) =>
	!domains.find((x) => x.id === item.domainId && x.custom)

export const useColumnsProperties = ({ domains }: HookOptions) => {
	const { t } = useAppContext()
	const typeOptions = useTypeOptions()

	const { data: stereotypes, reloading } = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.API_NODE_ATTRIBUTE }),
	)

	const properties = useMemo<TableColumnProps<ApiNodeColumn>[]>(
		() => [
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				propertiesWidth: '50%',
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				flex: 1,
				propertiesWidth: '50%',
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'description',
				label: t('TABLE_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 200,
			},
			{
				field: 'comment',
				label: t('TABLE_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				flex: 1.5,
				width: 200,
			},
			{
				field: 'domainId',
				label: t('TABLE_COLUMNS_DOMAIN'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				optionValue: 'id',
				optionLabel: 'name',
				options: domains,
				isNumeric: true,
				propertiesWidth: '50%',
				width: 200,
			},
			{
				field: 'dataType',
				label: t('TABLE_COLUMNS_DATA_TYPE'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				disabled: customDomainPredicate(domains),
				propertiesWidth: '50%',
				width: 200,
			},
			{
				field: 'defaultValue',
				label: t('DOMAIN_DEFAULT_VALUE'),
				type: ItemPropertyType.TEXT,
				flex: 1,
				propertiesWidth: '50%',
				width: 200,
			},
			{
				field: 'stereotypeId',
				label: t('TABLE_COLUMNS_STEREOTYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				optionValue: 'id',
				optionLabel: 'name',
				options: stereotypes || [],
				isNumeric: true,
				isLoading: reloading,
				width: 200,
			},
			{
				field: 'type',
				label: t('API_NODE_COLUMNS_TYPE'),
				type: ItemPropertyType.OPTION,
				flex: 1,
				optionValue: 'value',
				optionLabel: 'label',
				options: typeOptions,
				isNumeric: true,
				width: 200,
			},
			{
				field: 'notNull',
				label: t('TABLE_COLUMNS_MANDATORY'),
				tableLabel: t('TABLE_COLUMNS_MANDATORY_SHORT'),
				type: ItemPropertyType.CHECKBOX,
				propertiesWidth: '33.3333%',
				width: 60,
			},
		],
		[domains, reloading, stereotypes, t, typeOptions],
	)

	return properties
}
