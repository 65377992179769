import React, { RefObject, useMemo } from 'react'
import { Tabs } from '@/components'
import { TabProps } from '@/components/Tabs'
import {
	OpenedTableData,
	TableColumnFlat,
	TableDataForm,
	TableMode,
	TableTab,
} from '@/store/modules/table/types'
import { Overview } from '@/pages/User/pages/Home/pages/TableDetail/pages/Overview/Overview'
import { Columns } from '@/pages/User/pages/Home/pages/TableDetail/pages/Columns/Columns'
import { Indexes } from '@/pages/User/pages/Home/pages/TableDetail/pages/Indexes/Indexes'
import { Constraints } from '@/pages/User/pages/Home/pages/TableDetail/pages/Constraints/Constraints'
import { PhysicalOptions } from '@/pages/User/pages/Home/pages/TableDetail/pages/PhysicalOptions/PhysicalOptions'
import { Permissions } from '@/pages/User/pages/Home/pages/TableDetail/pages/Permissions/Permissions'
import { Mappings } from '@/pages/User/pages/Home/pages/TableDetail/pages/Mappings/Mappings'
import { Preview } from '@/pages/User/pages/Home/components/Preview/Preview'
import { Deployments } from '@/pages/User/pages/Home/components/Deployments/Deployments'
import { Validation } from '@/pages/User/pages/Home/components/Validation/Validation'
import { Dependencies } from '@/pages/User/pages/Home/components/Dependencies/Dependencies'
import { useAppContext } from '@/hooks'
import { DomainDto, StructureDto } from '@/endpoints/models'
import { UpdateDeepPartial } from '@/store/utils'
import { HistoryTableData, IdCodeName, TableData } from '@/endpoints/schemas'
import { Form } from '@/components/UberForm'

interface TableDetailTabsProps {
	domains: DomainDto[]
	editMode: boolean
	handleChange: (data: UpdateDeepPartial<TableData>) => void
	handleSync: (field: keyof HistoryTableData) => void
	handleTabChange: (tab: TabProps) => void
	mode: TableMode
	modeForm: TableDataForm | undefined
	node: StructureDto
	objectTypes: IdCodeName[] | undefined
	refForm: RefObject<Form<TableData>>
	reloadTableData: () => void
	systemNodeId: number
	table: OpenedTableData
	technicalColumns: TableColumnFlat[]
	unsyncedFields: (keyof HistoryTableData)[]
}

export const TableDetailTabs = ({
	domains,
	editMode,
	handleChange,
	handleSync,
	handleTabChange,
	mode,
	modeForm,
	node,
	objectTypes,
	refForm,
	reloadTableData,
	systemNodeId,
	table,
	technicalColumns,
	unsyncedFields,
}: TableDetailTabsProps) => {
	const { t } = useAppContext()

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = table || {}

		if (!form || !table || !modeForm) {
			return []
		}

		return [
			{
				id: TableTab.General,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={table}
						systemNodeId={systemNodeId}
						editMode={editMode}
						mode={mode}
						onChange={handleChange}
						key={TableTab.General}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
						modeFormTable={table.form}
						refForm={refForm}
					/>
				),
			},
			{
				id: TableTab.Columns,
				title: t('TAB_COLUMNS'),
				content: (
					<Columns
						key={TableTab.Columns}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						editMode={editMode && mode === TableMode.TABLE}
						domains={domains ?? []}
						onChange={handleChange}
						technicalColumns={technicalColumns}
						reloadTableData={reloadTableData}
					/>
				),
			},
			{
				id: TableTab.Indexes,
				title: t('TAB_INDEXES'),
				content: (
					<Indexes
						key={TableTab.Indexes}
						data={table}
						node={node}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				),
			},
			{
				id: TableTab.Constraints,
				title: t('TAB_CONSTRAINTS'),
				content: (
					<Constraints
						key={TableTab.Constraints}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				),
			},
			{
				id: TableTab.PhysicalOptions,
				title: t('TAB_PHYSICAL_OPTIONS'),
				content: (
					<PhysicalOptions
						key={TableTab.PhysicalOptions}
						editMode={editMode}
						onChange={handleChange}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
					/>
				),
			},
			{
				id: TableTab.Permissions,
				title: t('PERMISSIONS'),
				content: (
					<Permissions
						key={TableTab.Permissions}
						node={node}
						onChange={handleChange}
						data={table}
						systemNodeId={systemNodeId}
					/>
				),
			},
			...(mode === TableMode.TABLE
				? [
						{
							id: TableTab.Mappings,
							title: t('TAB_MAPPINGS'),
							content: <Mappings node={node} key="mappings" />,
						},
					]
				: []),
			{
				id: TableTab.Preview,
				title: t('TAB_PREVIEW'),
				content: (
					<Preview
						key={TableTab.Preview}
						node={node}
						showObjectTypes
						objectTypes={objectTypes}
					/>
				),
			},
			{
				id: TableTab.Deployments,
				title: t('TAB_DEPLOYMENTS'),
				content: (
					<Deployments
						node={node}
						key={TableTab.Deployments}
						editMode={editMode}
					/>
				),
			},
			{
				id: TableTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={TableTab.Validation} node={node} />,
			},
			{
				id: TableTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={TableTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
		]
	}, [
		table,
		modeForm,
		t,
		node,
		systemNodeId,
		editMode,
		mode,
		handleChange,
		handleSync,
		unsyncedFields,
		domains,
		technicalColumns,
		reloadTableData,
		objectTypes,
		refForm,
	])

	return (
		<Tabs tabs={tabs} onChange={handleTabChange} selectedTabId={table.tab} />
	)
}
