import React from 'react'
import { useAppContext } from '@/hooks'
import {
	Container,
	DialogWrapper,
	Button,
	LightTable,
	Loader,
} from '@/components'
import { faList, faPlus } from '@fortawesome/free-solid-svg-icons'
import { CustomLovModal } from './components/CustomLovModal'
import { CustomLovDto } from '@/endpoints/models'
import { useApi } from '@/endpoints/hooks'
import { getAllCustomLovs } from '@/endpoints'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions,
} from '../../styles'
import { useCustomLovColumns } from './hooks/useCustomLovColumns'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

interface CustomLovProps{
	textAreaHeight?:string
}

export const CustomLov = ({textAreaHeight}:CustomLovProps) => {
	const { t } = useAppContext()
	const customLov = useApi(getAllCustomLovs())

	const handleSave = useSettingsPageSaveHandler(customLov)

	const columns = useCustomLovColumns(handleSave)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faList} />
				<TitleText>{t('CUSTOM_LISTS_OF_VALUES')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <CustomLovModal textAreaHeight={textAreaHeight} onSave={handleSave} onClose={onClose} />
						}
					>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<Loader loaded={!customLov.loading} $absolute />

				<LightTable<CustomLovDto>
					data={customLov.data ?? []}
					columns={columns}
					keyField={'id'}
					isLoading={customLov.loading}
				/>
			</Container>
		</>
	)
}
