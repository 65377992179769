import styled, { css } from 'styled-components'
import { Schema, Size } from '@/types'

export const Container = styled.button<{
	disabled: boolean
	$hasContent: boolean
	$schema: Schema
	size: Size
}>`
	transition: 0.2s;
	border-radius: 0;
	text-transform: uppercase;
	border: 0;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;

	${(props) => css`
		padding: ${props.size === 'sm' ? '3px 5px' : '10px 15px'};
		font-weight: ${props.theme.font.weight.medium};
		border-width: 1px;
		border-style: solid;
	`}

	${(props) =>
		!props.disabled &&
		css`
			background: ${props.theme.colors.button[props.$schema].background};
			border-color: ${props.theme.colors.button[props.$schema].borderColor};
			color: ${props.theme.colors.button[props.$schema].color};

			&:hover {
				background: ${props.theme.colors.button[props.$schema].hover
					.background};
				border-color: ${props.theme.colors.button[props.$schema].hover
					.borderColor};
				color: ${props.theme.colors.button[props.$schema].hover.color};
				& svg {
					color: ${props.theme.colors.button[props.$schema].hover.color};
				}
			}
		`}

	${(props) =>
		props.disabled &&
		css`
			cursor: not-allowed;
			background: ${props.theme.colors.button.disabledBackground} !important;
		`}
`

export const Icon = styled.span<{
	disabled: boolean
	$hasContent: boolean
	$coloredIcon?: boolean
	$schema: Schema
}>`
	margin-right: 8px;

	${(props) =>
		props.$coloredIcon &&
		!props.disabled &&
		css`
			color: ${props.theme.colors.button[props.$schema]?.background};
		`}

	${(props) =>
		!props.$hasContent &&
		css`
			margin-right: 0;
		`}
`
