import { ViewColumn } from '@/endpoints/schemas'
import { TranslationHelper } from '@/context/Locale/Locale'
import { PropertiesErrorProps } from '@/types'

/** Check if columns do not have same name or code */
export const duplication = (
	columns: ViewColumn[],
	t: TranslationHelper,
): PropertiesErrorProps => {
	return columns
		.filter(
			(data, index) =>
				!!columns.find(
					(d, i) =>
						index !== i &&
						data.name !== '' &&
						data.code !== '' &&
						d.name !== '' && // newly added row in table - do not count
						(d.name === data.name || d.code === data.code),
				),
		)
		.reduce(
			(acc, d) => ({
				...acc,
				[d.id]: {
					name: {
						message: t('VALIDATOR_DUPLICATE'),
					},
				},
			}),
			{},
		)
}
