import debounce from 'debounce'
import { ForwardedRef, forwardRef } from 'react'
import {
	createStereotype,
	getStereotypeCategories,
	getStereotypeTypes,
	updateStereotype,
} from 'src/endpoints'

import { CodeInput } from '@/components'
import { ForwardedModalForm } from '@/components/Modal/ForwardedModalForm'
import {
	Form,
	FormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	StereotypeCreateDto,
	StereotypeDto,
	StereotypeUpdateDto,
} from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'

import { onNameChange } from '../../../utils'

type StereoTypeModalProps = {
	onClose: () => void
	onSave: () => void
	stereoType?: Partial<StereotypeDto>
	textAreaHeight?: string
}

export const StereoTypeModal = forwardRef(
	(
		{ stereoType, onSave, onClose, textAreaHeight }: StereoTypeModalProps,
		ref: ForwardedRef<Form<StereotypeDto>>,
	) => {
		const { t } = useAppContext()
		const request = useApiRequest()
		const types = useApi(getStereotypeTypes())
		const categories = useApi(getStereotypeCategories())
		const dispatch = useAppDispatch()

		const handleSubmit = async (form: Partial<StereotypeDto>) => {
			const action =
				stereoType && stereoType.id
					? updateStereotype(stereoType.id, form as StereotypeUpdateDto)
					: createStereotype(form as StereotypeCreateDto)

			const result = await request(action)

			if (result) {
				onSave()
				onClose()
			}
		}

		const debouncedOnNameChange = debounce(onNameChange, 500)

		const initialValue = {
			...stereoType,
			category: stereoType?.category || 'USER_DEFINED',
			children: stereoType?.children || [],
		}

		return (
			<>
				<ForwardedModalForm<StereotypeDto>
					open={true}
					onClose={onClose}
					contentStyle={{
						width: '550px',
					}}
					initialValues={initialValue}
					header={stereoType ? t('UPDATE_STEREOTYPE') : t('CREATE_STEREOTYPE')}
					onSubmit={handleSubmit}
					ref={ref}
				>
					<TextFormField
						title={t('NAME')}
						name="name"
						required
						onChange={(v) => debouncedOnNameChange(dispatch, ref, v as string)}
					/>
					<FormField name="code" title={t('CODE')}>
						<CodeInput name="code" readonly />
					</FormField>
					<SelectFormField
						title={t('TYPE')}
						name="type"
						options={types.data || []}
						fieldIsLoading={types.loading}
						valueKey="code"
						required
						labelKey="code"
						clearable={false}
					/>
					<SelectFormField
						title={t('CATEGORY')}
						name="category"
						required
						disabled={true}
						options={categories.data || []}
						fieldIsLoading={categories.loading}
						valueKey="code"
						labelKey="code"
						clearable={false}
					/>
					<TextAreaFormField
						textAreaHeight={textAreaHeight}
						title={t('DESCRIPTION')}
						name="description"
					/>
				</ForwardedModalForm>
			</>
		)
	},
)
