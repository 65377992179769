import { Button } from '@/components'
import { TranslationHelper } from '@/context/Locale'
import { ReleaseEnvironmentDto } from '@/endpoints/models'
import { faServer, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import { DdlModalType } from '@/types'

interface ExportModalFooterProps {
	type: string | undefined
	env: ReleaseEnvironmentDto | undefined
	pushingGit: boolean
	objectSettings: { loading: boolean }
	selectedObjectSettings: number[]
	handleMassGitPush: () => void
	onClose: () => void
	exporting: boolean
	handleMassExport: () => void
	saveTitle?: string
	t: TranslationHelper
}

export const ExportModalFooter = ({
	type,
	env,
	pushingGit,
	objectSettings,
	selectedObjectSettings,
	handleMassGitPush,
	onClose,
	exporting,
	handleMassExport,
	saveTitle,
	t,
}: ExportModalFooterProps) => (
	<>
		{type === DdlModalType.PushToGit ? (
			<>
				{env && (
					<Button
						schema="primary"
						icon={faServer}
						disabled={
							pushingGit ||
							objectSettings.loading ||
							!selectedObjectSettings.length
						}
						isLoading={pushingGit || objectSettings.loading}
						onClick={handleMassGitPush}
					>
						{t('PUSH_TO_GIT')}
					</Button>
				)}
				<Button schema="transparent" onClick={onClose} icon={faTimes}>
					{t('CANCEL')}
				</Button>
			</>
		) : (
			<>
				<Button
					schema="primary"
					icon={faUpload}
					disabled={
						exporting ||
						objectSettings.loading ||
						!selectedObjectSettings.length
					}
					isLoading={exporting || objectSettings.loading}
					onClick={handleMassExport}
				>
					{saveTitle ?? t('MASS_DOWNLOAD_BUTTON')}
				</Button>
				<Button schema="transparent" onClick={onClose} icon={faTimes}>
					{t('CANCEL')}
				</Button>
			</>
		)}
	</>
)
