import { TableColumn } from '@/endpoints/models'
import { Col, Legend, Row } from '@/components/Layout'
import { useAppContext } from '@/hooks'
import React from 'react'
import { FieldSetGap } from '../FieldSet'
import { CustomAttributesSwitcher } from './CustomAttributesSwitcher'
import {
	CustomAttributesProps,
	PageType,
} from '@/pages/User/pages/Home/components/CustomAttributes/types'
import { CustomAttributesSwitcherWrap } from './styles'

export const CustomAttributes = <T extends TableColumn>({
	customAttributes,
	item,
	$pageType,
}: CustomAttributesProps<T>) => {
	const { t } = useAppContext()

	return (
		<>
			{customAttributes.length > 0 && (
				<FieldSetGap>
					<Legend>{t('CUSTOM_ATTRIBUTES')}</Legend>
					<Row style={{ alignItems: 'flex-start' }}>
						{customAttributes.map((customAttribute) => (
							<Col
								key={customAttribute.id}
								size={
									$pageType === PageType.OVERVIEW_PAGE ? 'medium' : 'custom'
								}
								width="100%"
							>
								<CustomAttributesSwitcherWrap $pageType={$pageType}>
									<CustomAttributesSwitcher
										item={item}
										customAttribute={customAttribute}
										$pageType={$pageType}
									/>
								</CustomAttributesSwitcherWrap>
							</Col>
						))}
					</Row>
				</FieldSetGap>
			)}
		</>
	)
}
