import React, {
	useContext,
	useReducer,
	createContext,
	useMemo,
	useEffect
} from 'react'
import {
	ActionTypeEnum,
	CsvImporterContextState,
	CsvImporterContextType,
	CsvImporterStepEnum,
	InputDataParsed
} from './types'
import { reducer } from './reducer'
import Papa from 'papaparse'

const CsvImporterContext = createContext<CsvImporterContextType | undefined>(
	undefined
)

export const useCsvImporterContext = () =>
	useContext(CsvImporterContext) as CsvImporterContextType

type Props = {
	children: React.ReactNode
	inputData: string
	initialState: Omit<
		CsvImporterContextState,
		'resetImport' | 'createAnywayTableNames' | 'step' | 'hasHeaders' | 'mapping'
	>
}

export const CsvImporterContextProvider = ({
	children,
	inputData,
	initialState
}: Props) => {
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		resetImport: () => null,
		createAnywayTableNames: [],
		mapping: {},
		hasHeaders: true,
		step: CsvImporterStepEnum.PREVIEW
	})

	const context = useMemo<CsvImporterContextType>(
		() => ({
			state: {
				...state,
				resetImport: () => {
					dispatch({
						type: ActionTypeEnum.CHECK_RESULT_SET,
						checkResult: undefined
					})

					state.onMapped()
				}
			},
			dispatch
		}),
		[state, dispatch]
	)

	useEffect(() => {
		const inputDataParsed = Papa.parse(inputData) as InputDataParsed

		const inputDataValidated = {
			...inputDataParsed,
			data: inputDataParsed.data.filter(row => row.length > 1)
		} as InputDataParsed

		dispatch({
			type: ActionTypeEnum.INPUT_DATA_PARSED_SET,
			inputDataParsed: inputDataValidated
		})
	}, [inputData])

	return (
		<CsvImporterContext.Provider value={context}>
			{children}
		</CsvImporterContext.Provider>
	)
}
