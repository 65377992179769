import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Submit } from '@/components/UberForm'

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 160%;
	border-bottom: 1px solid ${({ theme }) => theme.colors.layout.borderColor};
	background-color: #fafafa;
	padding-left: 20px;
`

export const TitleText = styled.div`
	margin: 10px 15px;
	flex: 1;
`

export const TitleIcon = styled(FontAwesomeIcon)<{ color?: string }>`
	color: ${(props) => props.color || '#333'};
`

export const TitleActions = styled.div`
	display: flex;
	flex: 0;
	button {
		margin: 0;
		white-space: nowrap;
		background-color: #f8f8f8;
		color: #333;
		border-radius: 0;
		border: none;
		border-left: 1px solid ${(props) => props.theme.colors.layout.borderColor};
		line-height: 22px;
	}
`
export const Wrapper = styled.div`
	display: flex;
	margin: 0 -20px;
	align-items: flex-end;
	flex-wrap: wrap;
`
export const Row = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-end;
`

export const Column = styled.div<{ width?: string }>`
	width: ${(props) => props.width ?? '25%'};
	padding: 0 20px;
	margin-bottom: 5px;
	box-sizing: border-box;
`

export const TreeContainer = styled.div`
	overflow: auto;
	height: 100%;
	display: flex;
	border-top: 1px solid #ddd;
`

export const ViewContainer = styled.div`
	flex-direction: column;
	overflow: auto;
	height: 100%;
	display: flex;
	background: white;
	border-top: 1px solid #ddd;
`

export const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`

export const CellValue = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`
export const AuditWrapper = styled.div`
	display: flex;
	font-size: 14px;
`
export const SubmitNaming = styled(Submit)`
	height: 62px;
	align-items: center;
	font-size: 1rem;
`
export const ColumnBig = styled.div`
	width: 40%;
	padding: 0 10px;
`
export const ColumnSmall = styled.div`
	width: 20%;
	padding: 0 10px;
`
