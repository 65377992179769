import { StructureObjectDto } from '@/endpoints/models'
import { RootState } from '@/store'
import { TableMode } from '@/store/modules/table/types'
import { AppDispatch } from '@/store/utils'
import { useMemo } from 'react'
import { getStructureTypeActions } from './getStructureTypeActions'

export type InitDataParams = {
	nodeId: number
	abortController?: AbortController
	version?: number
	editMode?: boolean
	mode?: TableMode
	force?: boolean
	envId?: number
}

export type StructureTypeActions = {
	initData: (params: InitDataParams) => (dispatch: AppDispatch) => Promise<void>
	updateData: (
		node: StructureObjectDto,
		update: Record<string, any>,
		mode?: TableMode,
	) => unknown
	saveData: (
		node: StructureObjectDto,
	) => (dispatch: AppDispatch, getState: () => RootState) => Promise<any>
}

/** Get redux actions for specific structure type */
export const useStructureTypeActions = (type: StructureObjectDto.TypeEnum) => {
	const structureTypeActions = useMemo<StructureTypeActions>(
		() => getStructureTypeActions(type),
		[type],
	)

	return structureTypeActions
}
