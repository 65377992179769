import { SET_API_ERROR } from './constants'

export type ErrorRedux = {
	title: string | null
	description: string | null
	message: string | null
}

interface SetApiError {
	type: typeof SET_API_ERROR
	payload: ErrorRedux
}

export const setApiError = (opts: ErrorRedux): SetApiError => ({
	type: SET_API_ERROR,
	payload: opts
})

export type Actions = SetApiError
