import {
	StructureDetailDto,
	StructureDto,
	TablePermission,
} from '@/endpoints/models'
import { HistoryTableData, TableColumn, TableData } from '@/endpoints/schemas'
import {
	TABLE_INIT,
	TABLE_SAVE,
	TABLE_SELECT_TAB,
	TABLE_SYNC_FIELD,
	TABLE_UPDATE,
	TABLE_UPDATE_HISTORY_COLUMNS,
	TABLE_UPDATE_STEREOTYPES_COLUMNS,
} from '@/store/modules/table/constants'
import { UpdateDeepPartial } from '@/store/utils'
import { Timestamp } from '@/store/modules/actionsTimestamps'

export enum TableMode {
	TABLE = 'TABLE',
	HISTORY = 'HISTORY',
	REFERENCE = 'REFERENCE',
}

export interface OpenedTableData {
	form: TableDataForm
	original: TableDataForm
	tab: TableTab
	stereotypeColumns: TableColumnFlat[]
	historyColumns: TableColumnFlat[]
	selected: {
		key: number | null
		column: number | null
		index: number | null
	}
	dirty: boolean
	mode: TableMode
	parsedEditMode: boolean
	referenceTableMode: boolean
}

export enum TableTab {
	General = 'General',
	Columns = 'Columns',
	Constraints = 'Constraints',
	Indexes = 'Indexes',
	Mappings = 'Mappings',
	PhysicalOptions = 'PhysicalOptions',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Permissions = 'Permissions',
	Dependencies = 'Dependencies',
}

export type TableColumnFlat = Omit<TableColumn, 'domain' | 'stereotype'> & {
	domainId?: number
	domainName?: string
	domainCode?: string

	stereotypeId?: number
	stereotypeName?: string
	stereotypeCode?: string
}

export type TablePermissionFlat = Omit<TablePermission, 'user'> & {
	userId?: number
	userName?: string
	userCode?: string
	userDescription?: string
	userComment?: string
}

export type TableDataForm = Omit<
	TableData,
	'columns' | 'historyTable' | 'referenceTable' | 'objectPermissions'
> & {
	columns: TableColumnFlat[]
	objectPermissions: TablePermissionFlat[]
	historyTable?: HistoryTableFormData
	referenceTable?: ReferenceTableFormData
	HISTORY?: HistoryTableData
}

export type HistoryTableFormData = Omit<HistoryTableData, 'columns'> & {
	columns?: TableColumnFlat[]
}

export type ReferenceTableFormData = Omit<HistoryTableData, 'columns'> & {
	columns?: TableColumnFlat[]
}

export interface InitTable {
	type: typeof TABLE_INIT
	node: StructureDetailDto
	editMode: boolean
	mode: TableMode
	force: boolean
}

interface UpdateTable {
	type: typeof TABLE_UPDATE
	node: StructureDto
	update: UpdateDeepPartial<TableData>
	mode: TableMode
}

export interface SaveTable {
	type: typeof TABLE_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

interface SelectTableTab {
	type: typeof TABLE_SELECT_TAB
	node: StructureDto
	tab: TableTab
}

interface TableSyncField {
	type: typeof TABLE_SYNC_FIELD
	node: StructureDto
	field: keyof HistoryTableData
	mode: TableMode
}

type TableUpdateStereotypeColumns = {
	type: typeof TABLE_UPDATE_STEREOTYPES_COLUMNS
	node: StructureDto
	stereotypeColumns: TableColumn[]
} & Timestamp

type TableUpdateHistoryColumns = {
	type: typeof TABLE_UPDATE_HISTORY_COLUMNS
	node: StructureDto
	historyColumns: TableColumn[]
	historyFormColumns: TableColumn[]
} & Timestamp

export type TableActions =
	| InitTable
	| SaveTable
	| UpdateTable
	| SelectTableTab
	| TableSyncField
	| TableUpdateStereotypeColumns
	| TableUpdateHistoryColumns
