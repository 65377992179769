import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/hooks'
import { UserDto } from '@/endpoints/models'
import { useApiRequest } from '@/endpoints/hooks'
import { resetUserPassword } from 'src/endpoints'

type Props = {
	user: UserDto
	onClose: () => void
}

export const ResetModal = ({ user, onClose }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async () => {
		await request(resetUserPassword({ body: { username: user.loginName } }))
		onClose()
	}

	return (
		<ModalForm<FormData>
			open={true}
			onClose={onClose}
			saveTitle={t('RESET_BUTTON')}
			saveSchema="danger"
			contentStyle={{
				width: '400px',
			}}
			header={t('RESET_PASSWORD')}
			onSubmit={handleSubmit}
		>
			{t('RESET_DESC', [user.loginName])}
		</ModalForm>
	)
}
