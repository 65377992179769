import { useAppContext } from '@/hooks'
import { range } from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { TRANSFER_KEY_CSV_COLUMN } from '../../../constants'
import { useCsvImporterContext } from '../../../context/CsvImporterContext'
import {useDrag} from "react-dnd";

type CsvColumnCardProps = {
	columnIndex: number
}

export const CsvColumnCard = ({ columnIndex }: CsvColumnCardProps) => {
	const { t } = useAppContext()

	const {
		state: { inputDataParsed, firstRow, hasHeaders, mapping },
	} = useCsvImporterContext()

	const isMapped = useMemo(
		() => Object.values(mapping).some((value) => value === columnIndex),
		[columnIndex, mapping],
	)

	const [{isDragging}, drag] = useDrag(() => ({
		type: TRANSFER_KEY_CSV_COLUMN,
		item: {columnIndex},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	}))

	return (
		<Wrapper
			ref={drag}
			isMapped={isMapped}
			isDragging={isDragging}
		>
			<ColumnIndex>
				{t('COLUMN')}: {columnIndex + 1}
			</ColumnIndex>
			<Cell isHeader={hasHeaders}>{firstRow?.[columnIndex]}</Cell>
			{range(1, 3).map((rowIndex) => (
				<Cell key={rowIndex}>
					{inputDataParsed?.data[rowIndex]?.[columnIndex]}
				</Cell>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.div<{ isMapped: boolean , isDragging: boolean}>`
	opacity: ${(props) => props.isDragging ? 0.5 : 1};
	display: flex;
	flex-direction: column;
	padding: 4px;
	border: 2px dashed #ddd;
	height: 64px;
	background: ${(props) =>
		props.isMapped ? props.theme.colors.primary.light : undefined};
`

const Cell = styled.div<{ isHeader?: boolean }>`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	${(props) => {
		const { isHeader } = props

		if (isHeader) {
			return css`
				text-align: center;
				font-style: italic;
				padding: 2px 0;
			`
		}
	}}
`

const ColumnIndex = styled.div`
	background: #f0f0f0;
	text-align: center;
	font-weight: 600;
`
