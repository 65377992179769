import React from 'react'
import { FullTextSearchDto } from '@/endpoints/models'
import styled, { css } from 'styled-components'
import { Loader } from '@/components'
import { Result } from './Result'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSadTear } from '@fortawesome/free-solid-svg-icons'

type Props = {
	results: FullTextSearchDto[] | undefined
	loading: boolean
}

export const Results = ({ results, loading }: Props) => {
	return (
		<C>
			<Loader loaded={!loading} />
			{!loading &&
				results &&
				results.map((r) => <Result key={r.id} item={r} />)}
			{!loading && results && results.length === 0 && (
				<NoResults>
					<NoResultsIcon>
						<FontAwesomeIcon color="#999" icon={faSadTear} />
					</NoResultsIcon>
					No results found
				</NoResults>
			)}
		</C>
	)
}

const C = styled.div`
	position: absolute;
	top: 31px;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 10;
	color: #000;
	max-height: 80vh;
	overflow: auto;

	${(props) => css`
		border: 1px solid ${props.theme.colors.layout.topBackgroundColor};
		border-top: 0;
	`}
`

const NoResults = styled.div`
	padding: 3rem 0;
	text-align: center;
`

const NoResultsIcon = styled.span`
	margin-right: 0.5rem;
`
