import { useEffect } from 'react'
import { useAppStore, useAppDispatch, useMemoObject } from '@/hooks'
import { initTable } from '@/store/modules/table/actions'

type HookOptions = {
	parentNodeId: number | undefined
	editMode: boolean
}

export const useParentColumns = ({ parentNodeId, editMode }: HookOptions) => {
	const dispatch = useAppDispatch()
	const tables = useAppStore((state) => state.table.tables)

	const parentTable = parentNodeId ? tables[parentNodeId] : undefined

	const formColumns = parentTable?.form.columns.filter((c) => c.name) || []
	const stereotypeColumns = parentTable?.stereotypeColumns || []
	const historyColumns = parentTable?.historyColumns || []

	const columns = useMemoObject([
		...formColumns,
		...stereotypeColumns,
		...historyColumns,
	])

	useEffect(() => {
		if (!parentTable && parentNodeId) {
			dispatch(initTable({ nodeId: parentNodeId, editMode }))
		}
	}, [dispatch, editMode, parentNodeId, parentTable])

	return columns
}
