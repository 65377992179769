import { CsvImporterContextState, Actions, ActionTypeEnum } from './types'

export const reducer = (
	state: CsvImporterContextState,
	action: Actions
): CsvImporterContextState => {
	switch (action.type) {
		case ActionTypeEnum.STEP_SET:
			return {
				...state,
				step: action.step
			}
		case ActionTypeEnum.FORM_DATA_CHECK_SET:
			return {
				...state,
				formDataCheck: action.formDataCheck
			}
		case ActionTypeEnum.ROWS_SET:
			return {
				...state,
				rows: action.rows
			}
		case ActionTypeEnum.CHECK_RESULT_SET:
			return {
				...state,
				checkResult: action.checkResult
			}
		case ActionTypeEnum.INPUT_DATA_PARSED_SET:
			return {
				...state,
				inputDataParsed: action.inputDataParsed,
				firstRow: action.inputDataParsed?.data?.[0]
			}
		case ActionTypeEnum.HAS_HEADERS_SET:
			return {
				...state,
				hasHeaders: action.hasHeaders
			}
		case ActionTypeEnum.CSV_MAPPING_UPDATE:
			return {
				...state,
				mapping: {
					...state.mapping,
					...action.mapping
				}
			}
		case ActionTypeEnum.CREATE_ANYWAY_TABLE_NAMES_TOGGLE:
			const isTableAlready = state.createAnywayTableNames.includes(
				action.createAnywayTableName
			)

			return {
				...state,
				createAnywayTableNames: isTableAlready
					? state.createAnywayTableNames.filter(
							name => name !== action.createAnywayTableName
					  )
					: [...state.createAnywayTableNames, action.createAnywayTableName]
			}
		case ActionTypeEnum.CREATE_ANYWAY_TABLE_NAMES_SET:
			return {
				...state,
				createAnywayTableNames: action.createAnywayTableNames
			}
		default:
			return state
	}
}
