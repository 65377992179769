// reproduces password strength calculation from http://www.passwordmeter.com/

import { MIN_PASSWORD_LENGTH } from '@/constants'

export enum PasswordStrength {
	VERY_WEAK = 0,
	WEAK,
	GOOD,
	STRONG,
	VERY_STRONG
}

type PasswordStrengthResult = {
	score: number
	complexity: PasswordStrength
}

const reverseString = (value: string) =>
	value
		.split('')
		.reverse()
		.join('')

const checkCharGroup = (regexp: RegExp, chars: string[]) => {
	const lastIndex = chars.length - 1

	let charCount = 0
	let consecutiveCount = 0
	let midcharCount = 0
	let lastMatchedIndex = -100

	chars.forEach((char, index) => {
		if (!char.match(regexp)) {
			return
		}

		if (lastMatchedIndex + 1 == index) {
			consecutiveCount++
		}

		if (index > 0 && index < lastIndex) {
			midcharCount++
		}

		charCount++
		lastMatchedIndex = index
	})

	return [charCount, consecutiveCount, midcharCount]
}

const checkChars = (chars: string[], length: number) => {
	const [upper, consecUpper] = checkCharGroup(/[A-Z]/g, chars)
	const [lower, consecLower] = checkCharGroup(/[a-z]/g, chars)
	const [number, consecNumber, numberMidchar] = checkCharGroup(/[0-9]/g, chars)
	const [symbol, , symbolMidchar] = checkCharGroup(/[^a-zA-Z0-9_]/g, chars)

	const hasUpper = upper > 0
	const hasLower = lower > 0
	const hasNumber = number > 0
	const hasSymbol = symbol > 0

	let score = 0

	if (hasUpper && upper < length) {
		score += 2 * (length - upper)
	}

	if (hasLower && lower < length) {
		score += 2 * (length - lower)
	}

	if (number < length) {
		score += 4 * number
	}

	score += 6 * symbol

	score -= 2 * consecUpper
	score -= 2 * consecLower
	score -= 2 * consecNumber

	score += 2 * (numberMidchar + symbolMidchar)

	// letters only
	if ((hasUpper || hasLower) && !hasNumber && !hasSymbol) {
		score -= length
	}

	// numbers only
	if (hasNumber && !hasUpper && !hasLower && !hasSymbol) {
		score -= length
	}

	return {
		score,
		data: [hasUpper, hasLower, hasNumber, hasSymbol]
	}
}

const checkRepeated = (chars: string[]) => {
	const length = chars.length

	let repeatedCount = 0
	let penalty = 0

	for (let i = 0; i < length; i++) {
		let isRepeated = false

		for (let j = 0; j < length; j++) {
			if (chars[i] == chars[j] && i != j) {
				/* 
				Calculate icrement deduction based on proximity to identical characters
				Deduction is incremented each time a new match is discovered
				Deduction amount is based on total password length divided by the
				difference of distance between currently selected match
				*/
				penalty += Math.abs(length / (j - i))
				isRepeated = true
			}
		}

		if (isRepeated) {
			const uniqueChars = length - ++repeatedCount

			penalty = Math.ceil(penalty / (uniqueChars || 1))
		}
	}

	return -1 * penalty
}

const checkSequences = (password: string, charset: string): number => {
	const sequenceLength = 3
	const passwordLower = password.toLowerCase()

	let sequencesCount = 0

	for (let i = 0; i < charset.length - sequenceLength; i++) {
		const forward = charset.substring(i, i + sequenceLength)
		const reverse = reverseString(forward)

		if (passwordLower.includes(forward) || passwordLower.includes(reverse)) {
			sequencesCount++
		}
	}

	return -3 * sequencesCount
}

const checkRequirements = (
	length: number,
	hasUpper: boolean,
	hasLower: boolean,
	hasNumber: boolean,
	hasSymbol: boolean
) => {
	const checks = [
		length >= MIN_PASSWORD_LENGTH,
		hasUpper,
		hasLower,
		hasNumber,
		hasSymbol
	]

	const satisfiedChecks = checks.filter(x => x === true).length
	const requiredChecks = length >= MIN_PASSWORD_LENGTH ? 3 : 4

	if (satisfiedChecks <= requiredChecks) {
		return 0
	}

	return 2 * satisfiedChecks
}

const getComplexity = (score: number): PasswordStrength => {
	if (score < 20) {
		return PasswordStrength.VERY_WEAK
	}

	if (score < 40) {
		return PasswordStrength.WEAK
	}

	if (score < 60) {
		return PasswordStrength.GOOD
	}

	if (score < 80) {
		return PasswordStrength.STRONG
	}

	return PasswordStrength.VERY_STRONG
}

const getResult = (rawScore: number): PasswordStrengthResult => {
	const score = Math.max(0, Math.min(100, rawScore))
	const complexity = getComplexity(score)

	return { score, complexity }
}

export const checkPasswordStrength = (
	password: string
): PasswordStrengthResult | null => {
	if (!password) {
		return null
	}

	const length = password.length
	const noSpaces = password.replace(/\s+/g, '')
	const chars = Array.from(noSpaces)

	let score = 4 * length

	const {
		score: charScore,
		data: [hasUpper, hasLower, hasNumber, hasSymbol]
	} = checkChars(chars, length)

	score += charScore

	score += checkRepeated(chars)

	score += checkSequences(password, 'abcdefghijklmnopqrstuvwxyz')
	score += checkSequences(password, '01234567890')
	score += checkSequences(password, ')!@#$%^&*()')

	score += checkRequirements(length, hasUpper, hasLower, hasNumber, hasSymbol)

	return getResult(score)
}
