import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ disabled?: boolean }>`
	position: relative;
	width: 100%;
	${(props) =>
		props.disabled &&
		css`
			.ace_content {
				background: ${(props) => props.theme.colors.input.disabled.background};
			}
		`}
`

export const Maximizer = styled.div`
	position: absolute;
	top: 4px;
	left: 4px;
	cursor: pointer;
	z-index: 10;
	font-size: 14px;
`
