import { Loader } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	MassExportConfigDto,
	ObjectSettingsDto,
	StructureObjectPair,
} from '@/endpoints/models'
import { DdlModalType, MassExportSortingEnum } from '@/types'
import { NativeMap } from '@/utils/collections'
import { useAppContext } from '@/hooks'
import FileSaver from 'file-saver'
import { useEffect, useMemo, useState } from 'react'
import {
	generateMassExportFile,
	generateMassExportPushGit,
	getAllObjectSettings,
	pushReleaseToGit,
} from 'src/endpoints'
import {
	ObjectTypesFieldSet,
	StructureTypeButton,
	StyledCheckbox,
	StyledLegend,
} from './styles'
import { ExportConfigFields } from './ExportConfigFields'
import { ExportModalFooter } from './ExportModalFooter'
import { MassSqlExportModalProps, StructureStatusType } from './types'

export const MassSqlExportModal = ({
	env,
	node,
	onClose,
	title,
	selectedNodes,
	onExport,
	open,
	enableExportStrategy = true,
	releaseId,
	type,
	saveTitle,
}: MassSqlExportModalProps) => {
	const { t } = useAppContext()

	const objectSettings = useApi(getAllObjectSettings())

	const request = useApiRequest()

	const [massExportStrategy, setMassExportStrategy] = useState(
		MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE,
	)

	const [massExportSorting, setMassExportSorting] = useState(
		MassExportSortingEnum.DEFAULT,
	)

	const [structureStatus, setStructureStatus] =
		useState<StructureStatusType[]>()

	const [selectedObjectSettings, setSelectedObjectSettings] = useState(
		[] as number[],
	)

	const [ignoreErrors, setIgnoreErrors] = useState(false)

	const [exporting, setExporting] = useState(false)
	const [pushingGit, setPushingToGit] = useState(false)

	const handleMassGitPush = async () => {
		setPushingToGit(true)

		const massExportConfigDto = {
			massExportStrategy: massExportStrategy,
			massExportSorting: massExportSorting,
			structureObjectPairs: getStructureObjectPairs(),
			structureIds: selectedNodes,
			environmentId: env?.id,
		} as MassExportConfigDto

		if (releaseId && node?.id && env?.id) {
			await request(
				pushReleaseToGit(node.id, releaseId, env.id, massExportConfigDto),
			)
		} else {
			await request(
				generateMassExportPushGit(node?.id as number, massExportConfigDto),
			)
		}

		setPushingToGit(false)
	}

	const getStructureObjectPairs = (): StructureObjectPair[] =>
		selectedObjectSettings.map((osId) => {
			const os = objectSettings?.data?.find((o) => o.id == osId)

			return {
				objectType: os,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				structureType: os?.structureType as any,
			}
		})

	const handleMassExport = async () => {
		const massExportConfigDto: MassExportConfigDto = {
			structureStatus: structureStatus,
			massExportStrategy: massExportStrategy,
			massExportSorting: massExportSorting,
			structureObjectPairs: getStructureObjectPairs(),
			structureIds:
				selectedNodes?.length ?? 0 > 0 ? selectedNodes : node ? [node.id] : [],
		}

		if (onExport) {
			onExport(massExportConfigDto)

			return
		}

		setExporting(true)

		const { data, error } = await request(
			generateMassExportFile(massExportConfigDto, { ignoreErrors }),
		)

		if (error) {
			setExporting(false)

			return
		}

		let fileName, type

		if (
			massExportStrategy ===
			MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE
		) {
			fileName = 'export.txt'
			type = 'application/text'
		} else {
			fileName = 'export.zip'
			type = 'application/zip'
		}

		const blob = new Blob([new Uint8Array(data as ArrayBuffer)], {
			type,
		})

		FileSaver.saveAs(blob, fileName)

		setExporting(false)
	}

	const massExportStrategies = useMemo(
		() => [
			{
				label: t('SINGLE_FILE'),
				value: MassExportConfigDto.MassExportStrategyEnum.SINGLE_FILE,
			},
			{
				label: t('ZIP_PER_STRUCTURE'),
				value: MassExportConfigDto.MassExportStrategyEnum.ZIP_PER_STRUCTURE,
			},
			{
				label: t('ZIP_OWNER_FOLDER_PER_OBJECT_TYPE'),
				value:
					MassExportConfigDto.MassExportStrategyEnum
						.ZIP_OWNER_FOLDER_PER_OBJECT_TYPE,
			},
			{
				label: t('ZIP_PER_OWNER_AND_OBJECT_TYPE'),
				value:
					MassExportConfigDto.MassExportStrategyEnum
						.ZIP_PER_OWNER_AND_OBJECT_TYPE,
			},
		],
		[t],
	)

	const massExportSortingOptions = useMemo(
		() => [
			{
				label: t('MASS_DOWNLOAD_SORTING_DEFAULT'),
				value: MassExportSortingEnum.DEFAULT,
			},
			{
				label: t('SORT_BY_NAME_ASC'),
				value: MassExportSortingEnum.SORT_BY_NAME_ASC,
			},
			{
				label: t('SORT_BY_TYPE_AND_NAME_ASC'),
				value: MassExportSortingEnum.SORT_BY_TYPE_AND_NAME_ASC,
			},
		],
		[t],
	)

	const objectSettingsMap = (objectSettings?.data || []).reduce(
		(pVal, cVal) => {
			const vKey = cVal.structureType?.toString() || ''

			if (pVal[vKey]) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				pVal[vKey]!.push(cVal)
			} else {
				pVal[vKey] = [cVal]
			}

			return pVal
		},
		{} as NativeMap<ObjectSettingsDto[]>,
	)

	useEffect(() => {
		if (
			type &&
			(type === DdlModalType.PushToGit || type === DdlModalType.CreateGit)
		) {
			const allOssIds = objectSettings?.data?.map((os) => os.id) || []
			setSelectedObjectSettings(allOssIds)
		}
	}, [objectSettings?.data, type])

	const handleGroupClick = (stKey: string) => {
		const ossIds =
			objectSettings?.data
				?.filter((os) => os.structureType === stKey)
				.map((os) => os.id) || []

		const isAllChecked = ossIds.every((ossId) =>
			selectedObjectSettings.some((oId) => oId === ossId),
		)

		if (isAllChecked) {
			setSelectedObjectSettings(
				selectedObjectSettings.filter((os) => !ossIds.some((o) => o === os)),
			)
		} else {
			setSelectedObjectSettings([...selectedObjectSettings, ...ossIds])
		}
	}

	const handleChecked = (isChecked: boolean, id: number) => {
		if (isChecked) {
			setSelectedObjectSettings([...selectedObjectSettings, id])
		} else {
			setSelectedObjectSettings(
				selectedObjectSettings.filter((osId) => osId !== id),
			)
		}
	}

	const isCheckBoxChecked = (os: ObjectSettingsDto): boolean => {
		return selectedObjectSettings.some((oId) => oId === os.id)
	}

	return (
		<Modal
			open={open ?? true}
			onClose={onClose}
			contentStyle={{ width: 600 }}
			header={title ? title : t('MASS_DOWNLOAD')}
			footer={
				<ExportModalFooter
					type={type}
					env={env}
					pushingGit={pushingGit}
					objectSettings={objectSettings}
					selectedObjectSettings={selectedObjectSettings}
					handleMassGitPush={handleMassGitPush}
					onClose={onClose}
					exporting={exporting}
					handleMassExport={handleMassExport}
					saveTitle={saveTitle}
					t={t}
				/>
			}
		>
			<Loader loaded={true} $absolute />

			<ObjectTypesFieldSet>
				<StyledLegend>{t('OBJECT_SETTINGS')}</StyledLegend>

				{Object.keys(objectSettingsMap).map((stKey) => (
					<div key={stKey}>
						<StructureTypeButton
							onClick={() => handleGroupClick(stKey)}
							schema="transparent"
						>
							{stKey}
						</StructureTypeButton>

						{objectSettingsMap[stKey]?.map((os) => {
							return (
								<StyledCheckbox
									name={`${stKey}--${os.code}`}
									key={os.id}
									title={os.code}
									value={isCheckBoxChecked(os)}
									onChange={(val) => handleChecked(val as boolean, os.id)}
								/>
							)
						})}
					</div>
				))}
			</ObjectTypesFieldSet>

			<ExportConfigFields
				enableExportStrategy={enableExportStrategy}
				type={type}
				massExportStrategies={massExportStrategies}
				massExportSortingOptions={massExportSortingOptions}
				setMassExportStrategy={setMassExportStrategy}
				setMassExportSorting={setMassExportSorting}
				setIgnoreErrors={setIgnoreErrors}
				t={t}
				massExportStrategy={massExportStrategy}
				massExportSorting={massExportSorting}
				setStructureStatus={setStructureStatus}
			/>
		</Modal>
	)
}
