import React, { useContext, useReducer, createContext, useMemo } from 'react'
import { BatchEditContextState, BatchEditContextType } from './types'
import { reducer } from './reducer'
import { useAppStore } from '@/hooks'
import { StructureObjectDto } from '@/endpoints/models'

const BatchEditContext = createContext<BatchEditContextType | undefined>(
	undefined,
)

export const useBatchEditContext = () =>
	useContext(BatchEditContext) as BatchEditContextType

type Props = {
	children: React.ReactNode
	initialState: Omit<BatchEditContextState, 'selectedStructures' | 'type'>
}

export const BatchEditContextProvider = ({ children, initialState }: Props) => {
	const [state, dispatch] = useReducer(reducer, {
		selectedStructures: [],
		type: StructureObjectDto.TypeEnum.SYSTEM,
		...initialState,
	})

	const nodes = useAppStore((state) => state.node.nodes)

	const selectedStructures = useMemo(
		() => state.selectedNodes.map((node) => nodes[node] as StructureObjectDto),
		[nodes, state.selectedNodes],
	)

	const type = useMemo(
		() => nodes[state.selectedNodes[0]]?.type,
		[nodes, state.selectedNodes],
	) as StructureObjectDto.TypeEnum

	const context = useMemo<BatchEditContextType>(
		() => ({
			state: {
				...state,
				selectedStructures,
				type,
			},
			dispatch,
		}),
		[selectedStructures, state, type],
	)

	return (
		<BatchEditContext.Provider value={context}>
			{children}
		</BatchEditContext.Provider>
	)
}
