import React, { useCallback } from 'react'
import { DomainDto, StructureDto } from '@/endpoints/models'
import { useConstantsProperties } from './Properties'
import { OpenedSystemData, SystemDataForm } from '@/store/modules/system/types'
import { createEmptySystemMapping } from '@/store/modules/system/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { TableAndProperties } from '@/components'
import { useDomainTechnology } from '@/utils/domain'

type LookupsProps = {
	node: StructureDto
	data: OpenedSystemData
	onChange: (v: UpdateDeepPartial<SystemDataForm>) => void
	domains: DomainDto[]
}

export const Lookups = ({ node, data, domains, onChange }: LookupsProps) => {
	const { getDomainData } = useDomainTechnology(node?.id)
	const canGenerateNaming = data.original.generateTableColumnCode
	const properties = useConstantsProperties(domains, !!canGenerateNaming)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<SystemDataForm>) => {
			if (v.lookups) {
				Object.values(v.lookups).forEach((l) => {
					if (l.domainId) {
						const d = domains.find((d) => d.id === l.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.lookups?.find((col) => col.id === l.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === l.domainId) {
							return
						}

						if (d) {
							l.domainId = d.id
							l.domainCode = d.code
							l.dataType = domainData?.dataType
						} else {
							l.domainId = undefined
							l.domainCode = undefined
							l.dataType = undefined
						}
					}
				})
			}

			onChange(v)
		},
		[domains, onChange, getDomainData],
	)

	return (
		<TableAndProperties
			node={node}
			data={data.form}
			createEmpty={createEmptySystemMapping}
			itemsKey={'lookups'}
			idCounterKey={'lookupsLastId'}
			onChange={handleChange}
			properties={properties}
			tabKey="lookup"
			maxWidth={1200}
		/>
	)
}
