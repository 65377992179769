import { executeNaming, executeNamingOverWorkingData } from '@/endpoints'
import { AdjustResultDto } from '@/endpoints/models'
import { apiCallAction } from '@/store/utils'
import { TABLE_NAMING_EXECUTE } from '../constants'

interface NamingResult {
	type: typeof TABLE_NAMING_EXECUTE
	payload: AdjustResultDto
}

export type CallNamingParams = Parameters<typeof executeNaming>
export type CallNamingWorkingDataParams = Parameters<
	typeof executeNamingOverWorkingData
>

export const callNaming = (params: CallNamingParams) =>
	apiCallAction<NamingResult>(
		() => executeNaming(...params),
		TABLE_NAMING_EXECUTE,
		{},
	)

export const callNamingWorkingData = (params: CallNamingWorkingDataParams) =>
	apiCallAction<NamingResult>(
		() => executeNamingOverWorkingData(...params),
		TABLE_NAMING_EXECUTE,
		{},
	)
