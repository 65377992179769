import React, { useMemo } from 'react'
import { Button, Container, DialogWrapper, LightTable } from '@/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEdit,
	faTimes,
	faIndustry,
	faPlus,
	faBomb,
} from '@fortawesome/free-solid-svg-icons'
import { Flex } from '@/components/Layout'
import { useAppContext } from '@/hooks'
import {
	getAllDomains,
	deleteDomain,
	getAllSystemTechnologies,
} from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { DomainDto } from '@/endpoints/models'
import { DomainModal } from './components/DomainModal'
import { RemoveModal } from '@/components/RemoveModal/RemoveModal'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions,
} from '../../styles'
import { DomainTechnologyModal } from './components/DomainTechnologyModal'
import styled from 'styled-components'
import { DomainImpactModal } from './components/DomainImpactModal'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'
import { LightTableColumn } from '@/types'

interface DomainsProps {
	textAreaHeight?:string
}

export const Domains = ({textAreaHeight}:DomainsProps) => {
	const { t } = useAppContext()
	const domains = useApi(getAllDomains())
	const systemTechnologies = useApi(getAllSystemTechnologies())

	const handleSave = useSettingsPageSaveHandler(domains)

	const columns = useMemo<LightTableColumn<DomainDto>[]>(
		() => [
			{
				field: 'name',
				title: t('NAME'),
				width: 50,
			},
			{
				field: 'code',
				title: t('DOMAIN_CODE'),
				width: 50,
			},
			...(systemTechnologies.data?.map<any>((technology) => ({
				field: technology.name,
				title: technology.name,
				width: 50,
				// eslint-disable-next-line react/display-name
				formatter: (_: unknown, domain: DomainDto) => {
					const domainTechnology = domain.domainTechnologies?.find(
						(dt) => dt.technologyId === technology.id,
					)

					return (
						<TechnologyWrapper>
							{domain.custom ? (
								'—'
							) : (
								<>
									<DialogWrapper
										dialog={(opened, onClose) =>
											opened && (
												<DomainTechnologyModal
													domain={domain}
													technology={technology}
													onSave={handleSave}
													onClose={onClose}
												/>
											)
										}
									>
										{(onClick) => (
											<TechnologyEditButton
												size="sm"
												schema="transparent"
												onClick={onClick}
											>
												<FontAwesomeIcon
													title={`${t('EDIT')} ${technology.name}`}
													icon={faEdit}
													color="#999"
												/>
											</TechnologyEditButton>
										)}
									</DialogWrapper>

									<TechnologyDataType>
										{domainTechnology?.dataType}
										{domainTechnology?.defaultValue && (
											<TechnologyDefaultValue>
												{` / ${domainTechnology?.defaultValue}`}
											</TechnologyDefaultValue>
										)}
									</TechnologyDataType>
								</>
							)}
						</TechnologyWrapper>
					)
				},
			})) ?? []),
			{
				field: '',
				width: 90,
				maxWidth: 90,
				// eslint-disable-next-line react/display-name
				formatter: (_, domain) => (
					<Flex>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<DomainImpactModal
										domain={domain}
										open={opened}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('SHOW_IMPACTED_TABLES_AND_COLUMNS')}
										icon={faBomb}
									/>
								</Button>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<DomainModal
										domain={domain}
										onSave={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon title={t('EDIT')} icon={faEdit} />
								</Button>
							)}
						</DialogWrapper>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<RemoveModal
										callback={deleteDomain}
										removeId={domain.id}
										textOnModal={t('REMOVE_DESC', [domain.name])}
										header={t('REMOVE_DOMAIN')}
										onRemove={handleSave}
										onClose={onClose}
									/>
								)
							}
						>
							{(onClick) => (
								<Button size="sm" schema="transparent" onClick={onClick}>
									<FontAwesomeIcon
										title={t('REMOVE')}
										color="#d00"
										icon={faTimes}
									/>
								</Button>
							)}
						</DialogWrapper>
					</Flex>
				),
			},
		],
		[handleSave, systemTechnologies.data, t],
	)

	return (
		<>
			<TitleContainer>
				<TitleIcon color="#dfb69d" icon={faIndustry} />
				<TitleText>{t('DOMAINS')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <DomainModal textAreaHeight={textAreaHeight} onSave={handleSave} onClose={onClose} />
						}
					>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>
			<Container>
				<LightTable<DomainDto>
					data={domains.data || []}
					columns={columns}
					keyField={'id'}
					isLoading={domains.loading}
				/>
			</Container>
		</>
	)
}

const TechnologyWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
`

const TechnologyDataType = styled.div`
	margin-right: auto;
	padding-left: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const TechnologyDefaultValue = styled.span`
	color: #888;
`

const TechnologyEditButton = styled(Button)`
	padding: 3px 0;
`
