import React, { useMemo, useState } from 'react'
import { NamingDto } from '@/endpoints/models'
import { faCheck, faTrademark } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/hooks'

import { Form, TextFormField } from '@/components/UberForm'

import NamingFormField from './components/NamingFormField'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { getNamings, updateNamings } from '@/endpoints'
import { Container, Loader, Tabs } from '@/components'
import {
	AuditWrapper,
	ColumnBig,
	ColumnSmall,
	SubmitNaming,
	TitleActions,
	TitleContainer,
	TitleIcon,
	TitleText,
} from '../../styles'
import { NamingTabEnum, useGetNamingTabContent } from './utils'
import { toLocaleString } from '@/utils/numbers'
import { NamingDtoTypeEnum } from '@/types'

export const Naming = () => {
	const { t } = useAppContext()
	const namings = useApi(getNamings())
	const request = useApiRequest()
	const [selectedTab, setSelectedTab] = useState(NamingTabEnum.ELEMENT_TYPES)
	const [dirty, setDirty] = useState(false)
	const [naming, setNaming] = useState<NamingDto>() //selected template

	const handleSubmit = async (
		values: Partial<Record<NamingDtoTypeEnum, string>>,
	) => {
		const data: NamingDto[] = []

		for (const v in values) {
			const n = namings.data?.filter((n) => n.type == v)[0]
			const template = values[v as keyof typeof NamingDtoTypeEnum]

			if (n?.template != template) {
				//send only changed templates
				data.push({
					type: v,
					template: template,
					version: n?.version,
				} as NamingDto)
			}
		}

		data.length != 0 && (await request(updateNamings(data)))

		setDirty(false)
		namings.invalidate()
	}

	const handleChange = () => {
		setDirty(true)
	}

	const handleFocus = (name: string | null) => {
		setNaming(namings.data?.filter((n) => n.type == name)[0])
	}

	const getNamingTabContent = useGetNamingTabContent()

	const initialValues = useMemo<Record<NamingDtoTypeEnum, string>>(() => {
		let result = {} as Record<NamingDtoTypeEnum, string>

		namings.data?.forEach((value) => {
			result = { ...result, [value.type as NamingDtoTypeEnum]: value.template }
		})

		return result
	}, [namings.data])

	if (namings.loading || !namings.data || namings.data.length === 0) {
		return <Loader loaded={false} />
	}

	return (
		<Form<Record<NamingDtoTypeEnum, string>>
			onSubmit={handleSubmit}
			initialValues={initialValues}
		>
			<TitleContainer>
				<TitleIcon icon={faTrademark} />
				<TitleText>{t('NAMING')}</TitleText>
				<AuditWrapper>
					<div className="flex">
						<ColumnBig>
							<TextFormField
								title={t('UPDATED_BY')}
								name="updatedBy"
								disabled
								initialValue={naming?.updatedBy}
							/>
						</ColumnBig>
						<ColumnBig>
							<TextFormField
								title={t('UPDATED_AT')}
								name="updatedAt"
								disabled
								formatter={(value) => toLocaleString(value)}
								initialValue={naming?.updatedAt}
							/>
						</ColumnBig>
						<ColumnSmall>
							<TextFormField
								title={t('VERSION')}
								name="version"
								disabled
								initialValue={naming?.version}
							/>
						</ColumnSmall>
					</div>
				</AuditWrapper>
				<TitleActions>
					<SubmitNaming
						size="md"
						icon={faCheck}
						schema="success"
						disabled={!dirty}
					>
						{t('PUSH')}
					</SubmitNaming>
				</TitleActions>
			</TitleContainer>
			<Container>
				<Tabs
					selectedTabId={selectedTab}
					onChange={(props) => setSelectedTab(props.id as NamingTabEnum)}
					tabs={[
						{
							id: NamingTabEnum.ELEMENT_TYPES,
							title: t('NAMING_TAB_ELEMENT_TYPES'),
							content: (
								<>
									{getNamingTabContent(NamingTabEnum.ELEMENT_TYPES).map(
										({ title, name, placeholders }) => (
											<NamingFormField
												key={name}
												title={title}
												name={name}
												required
												onChange={handleChange}
												onFocus={() => handleFocus(name)}
												onBlur={() => handleFocus(null)}
												placeholders={placeholders}
											/>
										),
									)}
								</>
							),
						},
						{
							id: NamingTabEnum.GENERATED_ELEMENTS,
							title: t('NAMING_TAB_GENERATED_ELEMENTS'),
							content: (
								<>
									{getNamingTabContent(NamingTabEnum.GENERATED_ELEMENTS).map(
										({ title, name, placeholders }) => (
											<NamingFormField
												key={name}
												title={title}
												name={name}
												required
												onChange={handleChange}
												onFocus={() => handleFocus(name)}
												onBlur={() => handleFocus(null)}
												placeholders={placeholders}
											/>
										),
									)}
								</>
							),
						},
						{
							id: NamingTabEnum.OTHERS,
							title: t('OTHERS'),
							content: (
								<>
									{getNamingTabContent(NamingTabEnum.OTHERS).map(
										({ title, name, placeholders }) => (
											<NamingFormField
												key={name}
												title={title}
												name={name}
												required
												onChange={handleChange}
												onFocus={() => handleFocus(name)}
												onBlur={() => handleFocus(null)}
												placeholders={placeholders}
											/>
										),
									)}
								</>
							),
						},
					]}
				/>
			</Container>
		</Form>
	)
}
