import Button from '../../Button/Button'
import { useAppContext, useFormFieldContext } from '@/hooks'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useMemo } from 'react'
import { AnyObject } from '../types'
import { ArrayFieldSwitcher } from './ArrayFieldSwitcher'
import { ArrayFormFieldProps } from './types'
import { withFormField } from '../FormFieldContext'

const ArrayFormFieldWrapperComponent = ({
	fields,
	disabled,
}: ArrayFormFieldProps) => {
	const { t } = useAppContext()
	const { onChange: onChangeFormField, value } = useFormFieldContext()

	const defaultValues = useMemo(
		() =>
			fields.reduce(
				(initValues, field) => ({ ...initValues, [field.name ?? '']: '' }),
				{} as AnyObject,
			),
		[fields],
	)

	/** Array of objects with values for different fields
	 *  Default value is empty string
	 */
	const values = (value ?? [defaultValues]) as any[]

	const onChange = useCallback(
		(fieldValue: any, fieldName: string, fieldIndex: number) => {
			const prevValue = values[fieldIndex]

			if (prevValue === undefined) {
				onChangeFormField?.([
					...values,
					{
						[fieldName]: fieldValue,
					},
				])

				return
			}

			const newValue = values.map((item, index) =>
				index === fieldIndex
					? {
							...item,
							[fieldName]: fieldValue,
						}
					: item,
			)

			onChangeFormField?.(newValue)
		},
		[onChangeFormField, values],
	)

	return (
		<>
			{values.map((value, index) => (
				<React.Fragment key={value.name}>
					{fields.map((field) => (
						<div key={index + (field.name ?? '')} style={{ marginTop: '4px' }}>
							<ArrayFieldSwitcher
								{...field}
								value={value[field.name ?? '']}
								onChange={(value) => {
									field.onChange?.(value)
									onChange(value, field.name ?? '', index)
								}}
								disabled={disabled}
							/>
						</div>
					))}
					{!disabled && (
						<Button
							onClick={() =>
								onChangeFormField?.(
									values.filter((value, valueIndex) => valueIndex !== index),
								)
							}
							style={{ margin: '4px 0 0 0' }}
							size="sm"
							icon={faTimes}
							schema="primaryOutlined"
						>
							{t('REMOVE')}
						</Button>
					)}
				</React.Fragment>
			))}

			<Button
				onClick={() => onChangeFormField?.([...values, defaultValues])}
				style={{ marginTop: '4px' }}
				icon={faPlus}
				disabled={disabled}
			>
				{t('ADD')}
			</Button>
		</>
	)
}

export const ArrayFormFieldWrapper = withFormField(
	ArrayFormFieldWrapperComponent,
)
