import React, { useState, useCallback } from 'react'

import { ItemMenuDialog } from '@/types'
import { StructureDto } from '@/endpoints/models'

interface DialogWrapperProps {
	className?: string
	dialog: ItemMenuDialog
	children: React.ReactNode | ((onClick: () => void) => React.ReactNode)
	node?: StructureDto
	selectedNodes?: number[]
	dispatch?: any
}

/**
 * Create openable dialog without having to add new state prop.
 */
export const DialogWrapper = ({
	children,
	className,
	dialog,
	dispatch,
	node,
	selectedNodes,
}: DialogWrapperProps) => {
	const [opened, setOpened] = useState(false)

	const onClose = useCallback(() => {
		setOpened(false)
	}, [])

	const onOpen = useCallback(() => {
		setOpened(true)
	}, [])

	return (
		<>
			{typeof children !== 'function' && (
				<div className={className} onClick={onOpen}>
					{children}
				</div>
			)}
			{typeof children === 'function' && children(onOpen)}
			{dialog(opened, onClose, node, dispatch, selectedNodes)}
		</>
	)
}
