import React from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import { useAppContext } from '@/hooks'
import { UserDto } from '@/endpoints/models'
import { useApiRequest } from '@/endpoints/hooks'
import { unlockUser } from 'src/endpoints'

type Props = {
	user: UserDto
	onClose: () => void
	onSave: () => void
}

export const UnblockModal = ({ user, onClose, onSave }: Props) => {
	const { t } = useAppContext()
	const request = useApiRequest()

	const handleSubmit = async () => {
		await request(unlockUser(user.id))
		onSave()
		onClose()
	}

	return (
		<ModalForm
			open={true}
			onClose={onClose}
			saveTitle={t('USER_STATUS_UNBLOCK')}
			contentStyle={{
				width: '400px',
			}}
			header={t('USER_STATUS_UNBLOCK')}
			onSubmit={handleSubmit}
		>
			{t('USER_STATUS_UNBLOCK_INFO', [user.loginName])}
		</ModalForm>
	)
}
