import { useMemo } from 'react'
import { useAppContext, useMemoObject } from '@/hooks'
import { TableConstraint, TableColumn } from '@/endpoints/schemas'
import { useConstraintOptions } from './useConstraintOptions'
import { useApi } from '@/endpoints/hooks'
import { getAllTablesForSystemNode } from 'src/endpoints'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnProps, PossibleForeignKeys } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	systemNodeId: number
	possibleForeignKeys: PossibleForeignKeys
	foreignKeyTableId: number | undefined
	primaryKeys: TableColumn[]
	columns: TableColumnFlat[]
	canGenerateNaming: boolean
}

export const useConstraintsProperties = ({
	systemNodeId,
	possibleForeignKeys,
	foreignKeyTableId,
	primaryKeys,
	columns,
	canGenerateNaming,
}: HookOptions) => {
	const { t } = useAppContext()
	const constraintOptions = useConstraintOptions()
	const cachedForeignKeys = useMemoObject(possibleForeignKeys)
	const cachedPrimaryKeys = useMemoObject(primaryKeys)
	const cachedColumns = useMemoObject(columns)

	const { data: referenceOptions = [], reloading: isReferenceLoading } = useApi(
		getAllTablesForSystemNode(systemNodeId),
	)

	const COLUMN_WIDTH = 150

	const properties = useMemo<TableColumnProps<TableConstraint>[]>(
		() => [
			{
				field: 'name',
				label: t('TABLE_CONSTRAINTS_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
				minWidth: 150,
			},
			{
				field: 'code',
				label: t('TABLE_CONSTRAINTS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				autoWidth: true,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
				minWidth: 150,
			},
			{
				field: 'constraintType',
				label: t('TABLE_CONSTRAINTS_CONSTRAINT_NAME'),
				type: ItemPropertyType.OPTION,
				options: constraintOptions,
				showWhenPanelOpened: true,
				width: COLUMN_WIDTH,
			},
			{
				field: 'foreignKeyTableId',
				label: t('TABLE_CONSTRAINTS_REFERENCE'),
				type: ItemPropertyType.OPTION,
				options: referenceOptions,
				optionValue: 'id',
				optionLabel: 'fullPath',
				isNumeric: true,
				isLoading: isReferenceLoading,
				condition: (item) =>
					item.constraintType ===
					TableConstraint.ConstraintTypeEnum.FOREIGN_KEY,
				hideInTable: true,
				width: COLUMN_WIDTH,
			},
			{
				field: 'columns',
				label: t('TABLE_CONSTRAINTS_COLUMNS'),
				type: ItemPropertyType.FOREIGN_KEY,
				condition: (item) =>
					item.constraintType ===
					TableConstraint.ConstraintTypeEnum.FOREIGN_KEY,
				hideInTable: true,
				possibleForeignKeys: cachedForeignKeys,
				foreignKeyTableId,
				primaryKeys: cachedPrimaryKeys,
				width: COLUMN_WIDTH,
			},
			{
				field: 'columns',
				label: t('TABLE_CONSTRAINTS_COLUMNS'),
				type: ItemPropertyType.COLUMNS,
				options: cachedColumns,
				condition: (item) =>
					item.constraintType ===
						TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
					item.constraintType === TableConstraint.ConstraintTypeEnum.UNIQUE ||
					item.constraintType === TableConstraint.ConstraintTypeEnum.CHECK ||
					item.constraintType === TableConstraint.ConstraintTypeEnum.CUSTOM,
				hideInTable: true,
				width: COLUMN_WIDTH,
			},
			{
				field: 'description',
				label: t('TABLE_CONSTRAINTS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: COLUMN_WIDTH,
			},
			{
				field: 'comment',
				label: t('TABLE_CONSTRAINTS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: COLUMN_WIDTH,
			},
			{
				field: 'physicalOptions',
				label: t('TABLE_CONSTRAINTS_PHYSICAL_OPTIONS'),
				type: ItemPropertyType.ACE_EDITOR,
				condition: (item) =>
					item.constraintType ===
						TableConstraint.ConstraintTypeEnum.PRIMARY_KEY ||
					item.constraintType ===
						TableConstraint.ConstraintTypeEnum.FOREIGN_KEY ||
					item.constraintType === TableConstraint.ConstraintTypeEnum.UNIQUE,
				hideInTable: true,
				width: COLUMN_WIDTH,
			},
			{
				field: 'script',
				label: t('TABLE_CONSTRAINTS_SCRIPT'),
				type: ItemPropertyType.ACE_EDITOR,
				condition: (item) =>
					item.constraintType === TableConstraint.ConstraintTypeEnum.CUSTOM,
				hideInTable: true,
			},
			{
				field: 'expression',
				label: t('TABLE_CONSTRAINTS_EXPRESSION'),
				type: ItemPropertyType.ACE_EDITOR,
				condition: (item) =>
					item.constraintType === TableConstraint.ConstraintTypeEnum.CHECK,
				hideInTable: true,
				width: COLUMN_WIDTH,
			},
			{
				field: 'enabled',
				label: t('TABLE_CONSTRAINTS_ENABLED'),
				type: ItemPropertyType.CHECKBOX,
				width: COLUMN_WIDTH,
			},
		],
		[
			t,
			canGenerateNaming,
			constraintOptions,
			referenceOptions,
			isReferenceLoading,
			cachedForeignKeys,
			foreignKeyTableId,
			cachedPrimaryKeys,
			cachedColumns,
		],
	)

	return properties
}
