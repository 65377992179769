import './LightTable.less'

import cn from 'classnames'
import React from 'react'

import LightTableBody from './components/Body'
import { LightTableHead } from './components/Head'
import { isVisible } from './helpers'
import { LightTableProps, Sort } from './types'

export class LightTable<T = any> extends React.PureComponent<
	LightTableProps<T>
> {
	/** Table header */
	head = React.createRef<LightTableHead<T>>()

	/** Table body */
	body = React.createRef<LightTableBody<T>>()

	state = {
		sort: this.props.defaultSort || { field: null, order: 'asc' },
	}

	componentDidUpdate() {
		this.props.onUpdate && this.props.onUpdate()
	}

	handleSortChange = (sort: Sort) => {
		const { onSortChange } = this.props

		this.setState({ sort }, () => {
			if (onSortChange) {
				onSortChange(sort)
			}
		})
	}

	render() {
		const {
			className,
			style,
			columns,
			keyField,
			data,
			trClassName,
			trStyle,
			hideHeader,
			tbodyClassName,
			theadClassName,
			isRowExpandable,
			expandedRowComponent,
			onExpand,
			expandedTrClassName,
			isLoading,
			sortFunction,
			defaultExpanded,
			striped,
			limit,
			width,
			onUpdate,
			onRowClick,
			windowedRendering,
			windowedRowHeight,
			scrollLeft,
			isRowOrderable,
			onRowOrderChanged,
			dragArrowVisible = true,
		} = this.props

		const { sort } = this.state

		let sorted: T[] = data

		if (sort && sortFunction) {
			sorted = sorted.slice()
			sorted.sort((a, b) => sortFunction(a, b, sort))
		}

		if (limit !== undefined) {
			sorted = sorted.slice(0, limit)
		}

		const actualWidth =
			width ||
			columns.filter((c) => isVisible(c)).reduce((w, c) => w + c.width, 0) +
				'px'

		return (
			<div
				role="table"
				className={cn('light-table', className, {
					'is-loading': isLoading,
					striped: striped,
				})}
				style={style}
			>
				{!hideHeader && (
					<LightTableHead<T>
						ref={this.head}
						columns={columns}
						className={theadClassName}
						sort={sort}
						onSortChanged={this.handleSortChange}
						width={actualWidth}
						scrollLeft={scrollLeft}
					/>
				)}
				<LightTableBody<T>
					dragArrowVisible={dragArrowVisible}
					ref={this.body}
					className={tbodyClassName}
					columns={columns}
					data={sorted}
					keyField={keyField}
					trClassName={trClassName}
					trStyle={trStyle}
					isRowExpandable={isRowExpandable}
					expandedRowComponent={expandedRowComponent}
					onExpand={onExpand}
					expandedTrClassName={expandedTrClassName}
					defaultExpanded={defaultExpanded}
					width={actualWidth}
					onUpdate={onUpdate}
					onRowClick={onRowClick}
					windowedRendering={windowedRendering}
					windowedRowHeight={windowedRowHeight}
					scrollLeft={scrollLeft}
					isRowOrderable={isRowOrderable}
					onRowOrderChanged={onRowOrderChanged}
				/>
			</div>
		)
	}
}
