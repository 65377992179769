import { StructureObjectDto } from '@/endpoints/models'
import {
	initApiCollection,
	saveApiCollection,
	updateApiCollection,
} from '@/store/modules/apiCollection/actions'
import {
	initApiNode,
	updateApiNode,
	saveApiNode,
} from '@/store/modules/apiNode/actions'
import {
	initFolder,
	updateFolder,
	saveFolder,
} from '@/store/modules/folder/actions'
import {
	initMapping,
	updateMapping,
	saveMapping,
} from '@/store/modules/mapping/actions'
import {
	initQueue,
	updateQueue,
	saveQueue,
} from '@/store/modules/queue/actions'
import {
	initReadMapping,
	updateReadMapping,
	saveReadMapping,
} from '@/store/modules/readMapping/actions'
import {
	initSystem,
	updateSystem,
	saveSystem,
} from '@/store/modules/system/actions'
import {
	initTable,
	updateTable,
	saveTable,
} from '@/store/modules/table/actions'
import { initView, updateView, saveView } from '@/store/modules/view/actions'
import {
	initWorkflow,
	updateWorkflow,
	saveWorkflow,
} from '@/store/modules/workflow/actions'
import {
	initWriteMapping,
	saveWriteMapping,
	updateWriteMapping,
} from '@/store/modules/writeMapping/actions'

export const getStructureTypeActions = (type: StructureObjectDto.TypeEnum) => {
	switch (type) {
		case StructureObjectDto.TypeEnum.API_NODE:
			return {
				initData: initApiNode,
				updateData: updateApiNode,
				saveData: saveApiNode,
			}
		case StructureObjectDto.TypeEnum.API_COLLECTION:
			return {
				initData: initApiCollection,
				updateData: updateApiCollection,
				saveData: saveApiCollection,
			}
		case StructureObjectDto.TypeEnum.FOLDER:
			return {
				initData: initFolder,
				updateData: updateFolder,
				saveData: saveFolder,
			}
		case StructureObjectDto.TypeEnum.MAPPING:
		case StructureObjectDto.TypeEnum.MAPPING_DEFAULT:
			return {
				initData: initMapping,
				updateData: updateMapping,
				saveData: saveMapping,
			}
		case StructureObjectDto.TypeEnum.MAPPING_READ:
			return {
				initData: initReadMapping,
				updateData: updateReadMapping,
				saveData: saveReadMapping,
			}
		case StructureObjectDto.TypeEnum.MAPPING_WRITE:
			return {
				initData: initWriteMapping,
				updateData: updateWriteMapping,
				saveData: saveWriteMapping,
			}
		case StructureObjectDto.TypeEnum.QUEUE:
			return {
				initData: initQueue,
				updateData: updateQueue,
				saveData: saveQueue,
			}
		case StructureObjectDto.TypeEnum.SYSTEM:
			return {
				initData: initSystem,
				updateData: updateSystem,
				saveData: saveSystem,
			}
		case StructureObjectDto.TypeEnum.TABLE:
			return {
				initData: initTable,
				updateData: updateTable,
				saveData: saveTable,
			}

		case StructureObjectDto.TypeEnum.VIEW:
			return {
				initData: initView,
				updateData: updateView,
				saveData: saveView,
			}
		case StructureObjectDto.TypeEnum.WORKFLOW:
			return {
				initData: initWorkflow,
				updateData: updateWorkflow,
				saveData: saveWorkflow,
			}
		case StructureObjectDto.TypeEnum.FOLDER_WORKFLOW:
			return {
				initData: initWorkflow,
				updateData: updateWorkflow,
				saveData: saveWorkflow,
			}

		default:
			console.error('Unknown structure type')

			return {
				initData: initWorkflow,
				updateData: updateWorkflow,
				saveData: saveWorkflow,
			}
	}
}
