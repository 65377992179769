import { StructureObjectDto } from '@/endpoints/models'
import { ModalForm } from '@/components/Modal/ModalForm'
import { SelectFormField } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { uniqBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { BatchEditUnlock } from './BatchEditUnlock'
import { useBatchEditContext } from './context/BatchEditContext'
import { ActionTypeEnum } from './context/types'

type FormData = {
	type: string
}

export const BatchEditSelectType = () => {
	const { t } = useAppContext()

	const {
		state: { selectedStructures, onClose, node },
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const types = useMemo(
		() =>
			uniqBy(
				selectedStructures
					.map((structure) => ({
						value: structure.type,
						label: t(structure.type as 'ROOT'),
					}))
					.filter(
						(type) =>
							![
								StructureObjectDto.TypeEnum.ROOT,
								StructureObjectDto.TypeEnum.FOLDER_WORKFLOW,
							].includes(type.value),
					),
				(item) => item.value,
			),
		[selectedStructures, t],
	)

	const isAllSameType = useMemo(
		() =>
			selectedStructures.every(
				(structure) => structure.type === selectedStructures[0].type,
			),
		[selectedStructures],
	)

	const onSubmit = useCallback(
		(values: FormData) => {
			const selectedStructuresUpdate = selectedStructures.filter(
				(structure) => structure.type === values.type,
			)

			const selectedNodes = selectedStructuresUpdate.map(
				(structure) => structure.id,
			)

			dispatchContext({
				type: ActionTypeEnum.SET,
				state: { selectedStructures: selectedStructuresUpdate, selectedNodes },
			})
		},
		[dispatchContext, selectedStructures],
	)

	return (
		<>
			{isAllSameType ? (
				<BatchEditUnlock />
			) : (
				<ModalForm<FormData>
					open={true}
					header={t('SELECT_ELEMENT_TYPE_FOR_EDITING')}
					onClose={() => onClose()}
					onSubmit={onSubmit}
					saveTitle={t('SELECT')}
					initialValues={{
						type: types.find((type) => type.value === node.type)
							? node.type
							: undefined,
					}}
				>
					<SelectFormField
						name="type"
						title={t('ELEMENT_TYPE')}
						options={types}
						clearable
						required
					/>
				</ModalForm>
			)}
		</>
	)
}
