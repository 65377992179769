import { Button, DialogWrapper, Loader } from '@/components'
import { useAppContext, useTableColumns } from '@/hooks'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { AddEnvironment } from './AddEnvironment'
import { HeaderContainer } from '../../styles'
import { DeploymentPackageEnvironmentsProps } from '../../types'
import { EnvTable } from './EnvTable'
import { useEnvTableColumns } from './useEnvTableColumns'

export const DeploymentPackageEnvironments = ({
	node,
	deploymentPackage,
	editMode,
	dpEnvironments,
	invalidate,
	loading,
}: DeploymentPackageEnvironmentsProps) => {
	const { t } = useAppContext()

	const sortedEnvData = useMemo(
		() => sortBy(dpEnvironments, (env) => env.id),
		[dpEnvironments],
	)

	const reloadEnvs = useCallback(() => invalidate(), [invalidate])

	const columns = useTableColumns({
		tableData: [sortedEnvData ?? []],
		properties: useEnvTableColumns(
			deploymentPackage,
			editMode,
			node,
			reloadEnvs,
		),
	})

	return (
		<section>
			<HeaderContainer>
				<h1>{t('ENVIRONMENTS_IN_RELEASE')}</h1>
			</HeaderContainer>

			<div className="my-4">
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<>
								<AddEnvironment
									node={node}
									deploymentPackage={deploymentPackage}
									envs={sortedEnvData}
									onClose={() => {
										reloadEnvs()
										onClose()
									}}
								/>
							</>
						)
					}
				>
					{(onClick) => (
						<Button disabled={!editMode} onClick={onClick}>
							{t('ADD_ENVIRONMENT')}
						</Button>
					)}
				</DialogWrapper>
			</div>

			{loading ? (
				<Loader loaded={!loading} $absolute />
			) : (
				<EnvTable
					columns={columns}
					hasLastRowEdit={false}
					editMode={editMode}
					node={node}
					reloadEnvs={reloadEnvs}
					sortedEnvData={sortedEnvData}
					deploymentPackage={deploymentPackage}
				/>
			)}
		</section>
	)
}
