import styled, { css } from 'styled-components'
import { Cell } from '@/components/Layout'
import { ItemPropertyType } from '@/enums'

interface EditCellWrapProps {
	$readonly: boolean
	$focused: boolean
	type: ItemPropertyType
	editing: boolean
}

export const EditCellWrap = styled(Cell)<EditCellWrapProps>`
	padding: 0;

	> div {
		display: flex;
		align-items: center;
		flex: 1;
		${(props) =>
			props.type === ItemPropertyType.CHECKBOX &&
			css`
				display: flex;
				justify-content: center;
			`}
	}

	${(props) =>
		!props.$readonly &&
		css`
			> div {
				padding: 0;
			}
		`}

	${(props) =>
		props.$focused &&
		css`
			z-index: 1;
			outline: 2px solid ${props.theme.colors.primary.base};
		`}

		${(props) =>
		props.editing &&
		css`
			z-index: 1;
			outline: 2px solid ${props.theme.colors.input.focused.boxShadow};
		`}

	textarea,
	input[type='text'] {
		padding: 6px;
		border: 0;
	}

	textarea {
		background: #fff;
		z-index: 5;
		transition:
			height 0s,
			box-shadow 0.25s,
			border-color 0.25s,
			z-index 0.25s;
		padding: 0 6px;
		line-height: 1.25;
		resize: none;
		overflow: hidden;
		:focus {
			position: absolute;
			padding: 6px;
			height: 100px;
			overflow: auto;
			border: 1px solid #ddd;
			border-radius: 0;
			width: 400px;
			margin-top: -1px;
			margin-left: -1px;
			overflow-y: scroll;
		}
	}
`
