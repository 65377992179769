import { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { ApiNodeColumnType } from '@/endpoints/schemas/api-node'

export const useTypeOptions = () => {
	const { t } = useAppContext()

	const typeOptions = useMemo(
		() => [
			{
				label: t('API_NODE_COLUMN_TYPE_IN'),
				value: ApiNodeColumnType.IN,
			},
			{
				label: t('API_NODE_COLUMN_TYPE_OUT'),
				value: ApiNodeColumnType.OUT,
			},
			{
				label: t('API_NODE_COLUMN_TYPE_IN_OUT'),
				value: ApiNodeColumnType.IN_OUT,
			},
			{
				label: t('API_NODE_COLUMN_TYPE_INTERNAL'),
				value: ApiNodeColumnType.INTERNAL,
			},
		],
		[t],
	)

	return typeOptions
}
