import React from 'react'
import {SettingsItem} from '../types'
import {Acronyms} from '../pages/Acronyms/Acronyms'
import {Domains} from '../pages/Domains/Domains'
import {
	BusinessDomains,
	CustomAttributes,
	CustomLov,
	Naming,
	ObjectSettings,
	ScriptEditor,
	StereoTypes,
	TechnicalColumns,
	Templates,
	Users,
} from '../pages'
import {useAuthContext} from '@/hooks'

export const View = ({ item }: { item: SettingsItem | null }) => {
	const auth = useAuthContext()

	 const textAreaHeight = "1.875rem"

	return (
		<>
			{auth.canManageUsers() && item === SettingsItem.Users && <Users />}
			{auth.canManageLOVs() && item === SettingsItem.Acronyms && <Acronyms />}
			{auth.canManageLOVs() && item === SettingsItem.Templates && <Templates textAreaHeight={textAreaHeight} />}
			{auth.canManageLOVs() && item === SettingsItem.Naming && <Naming />}
			{auth.canManageLOVs() && item === SettingsItem.BusinessDomains && (
				<BusinessDomains textAreaHeight={textAreaHeight} />
			)}
			{auth.canManageLOVs() && item === SettingsItem.Domains && <Domains textAreaHeight={textAreaHeight} />}
			{auth.canManageLOVs() && item === SettingsItem.TechnicalColumns && (
				<TechnicalColumns textAreaHeight={textAreaHeight} />
			)}
			{auth.canManageLOVs() && item === SettingsItem.ObjectSettings && (
				<ObjectSettings textAreaHeight={textAreaHeight} />
			)}
			{auth.canManageLOVs() && item === SettingsItem.StereoTypes && (
				<StereoTypes textAreaHeight={textAreaHeight}  />
			)}
			{auth.canManageLOVs() && item === SettingsItem.CustomAttributes && (
				<CustomAttributes textAreaHeight={textAreaHeight} />
			)}
			{auth.canManageLOVs() && item === SettingsItem.CustomLovs && (
				<CustomLov textAreaHeight={textAreaHeight} />
			)}
			{item === SettingsItem.ScriptEditor && <ScriptEditor />}
		</>
	)
}
