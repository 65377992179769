import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'

import { useAppContext } from '@/hooks'

import { useTablePropertiesContext } from '../context/TablePropertiesContext'
import { ActionTypeEnum } from '../context/types'
import { ITEM_HEIGHT } from './constants'
import { TablePropertiesRowOrHeader } from './TablePropertiesRowOrHeader'

const MAX_HEIGHT = window.innerHeight - 250

export const calculateTablePropertiesHeight = <T,>(items?: T[], buffer = 0) =>
	((items?.length ?? 1) + 2 + buffer) * ITEM_HEIGHT

type TablePropertiesWrapperProps = {
	children:
		| React.ReactNode
		| ((
				propertiesHidden: boolean | undefined,
				columnWidths: Record<string, number>,
		  ) => React.ReactNode)
}

export const TablePropertiesWrapper = ({
	children,
}: TablePropertiesWrapperProps) => {
	const { t } = useAppContext()

	const {
		state: {
			refContainer,
			readonly,
			columnWidths,
			items,
			isDeletable,
			dragOverRowIndex,
			propertiesHidden,
		},
		dispatch,
	} = useTablePropertiesContext()

	return (
		<>
			<Container
				role="table"
				style={{
					height: Math.min(calculateTablePropertiesHeight(items), MAX_HEIGHT),
				}}
			>
				<div
					ref={refContainer}
					onDragLeave={() => {
						if (dragOverRowIndex !== null) {
							dispatch({
								type: ActionTypeEnum.DRAG_OVER_INDEX_SET,
								payload: { dragOverRowIndex: null },
							})
						}
					}}
					style={{
						flex: '1',
					}}
				>
					<AutoSizer
						style={{
							width: '100%',
						}}
					>
						{({ height }: { height: number }) => (
							<FixedSizeList
								height={Math.min(MAX_HEIGHT, Number(height))}
								itemCount={items.length + 1}
								itemSize={ITEM_HEIGHT}
								width="100%"
								overscanCount={6}
								onItemsRendered={(props) =>
									dispatch({
										type: ActionTypeEnum.SYNC_CONTEXT_AND_PROPS,
										payload: {
											firstRenderedItemIndex: props.visibleStartIndex,
										},
									})
								}
							>
								{TablePropertiesRowOrHeader}
							</FixedSizeList>
						)}
					</AutoSizer>
				</div>
			</Container>
			{items.filter(
				(item, index) => !readonly || !isDeletable || isDeletable(item, index),
			).length === 0 && <Empty>{t('NOTHING_HERE')}</Empty>}
			{typeof children !== 'function' && children}
			{typeof children === 'function' &&
				children(propertiesHidden, columnWidths)}
		</>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const Empty = styled.div`
	padding: 0 10px 0 10px;
	text-align: center;
`
