import React from 'react'
import { Table } from '@/components'
import { TablePropertiesContextProvider } from '@/components/Properties'
import { StereotypeTitle } from '@/components/TableAndProperties/styles'
import { useAppContext } from '@/hooks'
import { Column } from '@tanstack/react-table'
import { PropertiesChangeCallback } from '@/components/Properties/types'
import { Item } from '@/components/Properties/context/types'
import { LastEditedCellProps } from '@/components/TableAndProperties/types'
import { TableColumnProps } from '@/types'

interface MainTableProps {
	columns: Column<any>[]
	handleChange: PropertiesChangeCallback
	handleDelete: (deletedItem: Item) => void
	items: Item[]
	lastEditedCell: LastEditedCellProps
	maxWidth?: number
	onSaveData: (newItems: Item[]) => void
	technicalColumns: Item[] | undefined
	isEditMode: boolean
	isReadonly: ((item: Item) => boolean) | undefined
	properties: TableColumnProps<Item, any>[]
}

export const MainTable = ({
	columns,
	handleChange,
	handleDelete,
	isEditMode,
	isReadonly,
	items,
	lastEditedCell,
	maxWidth,
	onSaveData,
	properties,
	technicalColumns,
}: MainTableProps) => {
	const { t } = useAppContext()

	return (
		<div>
			<TablePropertiesContextProvider
				properties={properties}
				items={items}
				readonly={!isEditMode}
				isReadonly={isReadonly}
			>
				<Table
					columns={columns}
					data={items}
					isEditMode={isEditMode}
					hasLastRowEdit={false}
					handleChange={handleChange}
					onDelete={handleDelete}
					onSaveData={onSaveData}
					lastEditedCell={lastEditedCell}
					maxWidth={maxWidth}
				/>
			</TablePropertiesContextProvider>
			{technicalColumns && technicalColumns.length > 0 && (
				<TablePropertiesContextProvider
					properties={properties}
					items={items}
					readonly
				>
					<StereotypeTitle>{t('TECHNICAL_COLUMNS_TITLE')}</StereotypeTitle>
					<Table
						columns={columns}
						data={technicalColumns}
						hasLastRowEdit={false}
						isEditMode={false}
						handleChange={handleChange}
						onDelete={handleDelete}
						onSaveData={onSaveData}
					/>
				</TablePropertiesContextProvider>
			)}
		</div>
	)
}
