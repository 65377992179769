import React from 'react'
import Select from 'react-select'
import { withFormField } from '../../FormFieldContext'
import { EnhancedPureComponentWithContext } from '@/components/EnhancedComponents'
import { compareFlatArrays, getCustomComponents, nextFrame } from '@/utils'
import { customStyles, StyledSelect } from './styles'
import { SelectProps } from './types'
import { FormInput } from '@/components/UberForm/types'

const SELECT_CLASSNAME_PREFIX = 'react-select'
export const SELECT_MENU_CLASSNAME = SELECT_CLASSNAME_PREFIX + '__menu'

export class SelectWithoutForm<
	T extends Record<string, any>,
> extends EnhancedPureComponentWithContext<SelectProps<T>> {
	static defaultProps = {
		labelKey: 'label',
		valueKey: 'value',
		clearable: true,
		allowEmpty: true,
	}
	input = React.createRef<Select>()
	async componentDidMount() {
		const { register, refInput } = this.props

		if (refInput && refInput !== this.input) {
			this.input = refInput
		}

		if (register) {
			register(this as FormInput)
		}

		await nextFrame()
	}

	getValueKey = () => this.props.valueKey || 'value'
	getLabelKey = () => this.props.labelKey || 'label'

	componentDidUpdate(prevProps: SelectProps<T>) {
		const { options, isMulti, allowEmpty, loading, onChange, value } =
			this.props

		// Skip value checks if we're still loading
		if (loading) {
			return
		}

		if (
			loading !== prevProps.loading ||
			options !== prevProps.options ||
			value !== prevProps.value ||
			(!value && !allowEmpty)
		) {
			let updatedValue = value

			if (isMulti) {
				if (value && Array.isArray(value)) {
					updatedValue = this.props.options
						? value.filter((item) =>
								(this.props.options as T[]).find(
									(option) => this.getOptionValue(option) === item,
								),
							)
						: []
				}
			} else {
				updatedValue =
					value === null || value === undefined
						? value
						: this.props.options &&
							(this.props.options.some(
								(option) => this.getOptionValue(option) === value,
							)
								? value
								: allowEmpty
									? undefined
									: this.props.options[0] &&
										this.getOptionValue(this.props.options[0]))
			}

			if (
				updatedValue !== value &&
				(!isMulti ||
					!Array.isArray(updatedValue) ||
					!Array.isArray(value) ||
					!compareFlatArrays(updatedValue as string[], value as string[]))
			) {
				onChange && onChange(updatedValue)
			}
		}
	}

	onChange = (newValue: readonly T[] | T | undefined | null) => {
		const { isMulti, options, allowEmpty, onChange, onCoerseValue, value } =
			this.props

		if (onCoerseValue && options) {
			const oldValue = isMulti
				? value
					? (value as [string]).map(
							(val) =>
								options.filter(
									(option) => this.getOptionValue(option) === val,
								)[0],
						)
					: []
				: value
					? options.filter(
							(option) => this.getOptionValue(option) === (value as string),
						)
					: undefined

			newValue = onCoerseValue(oldValue, newValue)
		}

		onChange &&
			onChange(
				isMulti
					? newValue
						? (newValue as T[]).map((option) => this.getOptionValue(option))
						: []
					: newValue
						? this.getOptionValue(newValue as T)
						: allowEmpty
							? undefined
							: options && options[0] && this.getOptionValue(options[0]),
			)
	}

	getOptionValue = (opt: T): string => {
		const value =
			opt[this.getValueKey()] !== undefined && opt[this.getValueKey()] !== null
				? opt[this.getValueKey()]
				: null

		return !value || this.props.isNumeric ? value : value.toString()
	}

	getOptionLabel = (opt: T) => {
		let label = opt[this.getLabelKey()]

		if (opt.wasDeleted) {
			label += ' !'
		}

		return label
	}

	getNoOptionsMessage = () =>
		this.props.noResultText
			? this.props.noResultText
			: this.context.t('FILTER_NO_RESULT_TEXT')

	render() {
		const { t } = this.context

		const {
			placeholder,
			className,
			name,
			options,
			value,
			id,
			isMulti,
			disabled,
			clearable,
			formatOptionLabel,
			openMenuOnFocus,
			onMenuClose,
			onMenuOpen,
			onFocus,
			onBlur,
			refInput,
			onInputChange,
			customWidth,
			components,
		} = this.props

		const selectedValue = isMulti
			? options
					?.filter((opt) => {
						const values = value ? (Array.isArray(value) ? value : [value]) : []

						return values.includes(this.getOptionValue(opt))
					})
					.filter((v) => !!v) ?? null
			: options?.find((opt) => this.getOptionValue(opt) === value) ?? null

		return (
			<div
				role="listbox"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.stopPropagation()
						e.preventDefault()
					}
				}}
			>
				<StyledSelect<any>
					ref={refInput ?? this.input}
					className={className}
					styles={customStyles}
					classNamePrefix={SELECT_CLASSNAME_PREFIX}
					inputId={id}
					name={name}
					options={options}
					closeMenuOnSelect={!isMulti}
					isDisabled={disabled}
					isClearable={clearable}
					placeholder={
						!disabled
							? placeholder !== undefined
								? placeholder
								: isMulti
									? t('FILTER_ALL_PLACEHOLDER')
									: ''
							: ''
					}
					noOptionsMessage={this.getNoOptionsMessage}
					hideSelectedOptions={false}
					onChange={this.onChange}
					value={selectedValue}
					components={getCustomComponents(components)}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					menuPortalTarget={document.body}
					menuPlacement="auto"
					formatOptionLabel={formatOptionLabel}
					openMenuOnFocus={openMenuOnFocus}
					onMenuClose={onMenuClose}
					onMenuOpen={onMenuOpen}
					onFocus={onFocus}
					onBlur={onBlur}
					onInputChange={onInputChange}
					customWidth={customWidth}
					isMulti={isMulti}
				/>
			</div>
		)
	}
}

export default withFormField(SelectWithoutForm)
