import React, { useCallback } from 'react'
import {
	selectTab,
	openTab,
	closeTab,
	selectReleaseTab,
} from '@/store/modules/tab/actions'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppDispatch, useAppContext } from '@/hooks'
import { OpenedTab } from '@/store/modules/tab/types'
import { ContextMenu } from '@/components'
import {
	ContextMenuItems,
	ContextMenuItem,
} from '@/components/ContextMenu/ContextMenu'
import { TabHeading } from './TabHeading'
import { TabProps } from '../types'

export const Tab = ({
	node,
	tab,
	isSelected,
	isTemporary,
	onConfirm,
	onCloseOther,
	onCloseAll,
	isVertical,
}: TabProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const closeItem = useCallback((tab: OpenedTab, node: StructureObjectDto) => {
		tab.editMode ? onConfirm([node]) : dispatch(closeTab(node.id))
	}, [])

	const isReleaseTab = tab.releaseTabProps?.isReleaseTab
	const releaseTabId = isReleaseTab && tab.nodeId

	const handleClick = useCallback(() => {
		if (isReleaseTab) {
			return dispatch(selectReleaseTab(releaseTabId as number))
		}

		return node && dispatch(selectTab(node))
	}, [node, tab])

	const handleDblClick = useCallback(() => {
		node && dispatch(openTab(node, false))
	}, [node])

	const handleMouseUp = useCallback(
		(e: React.MouseEvent) => {
			if (e.button === 1) {
				e.stopPropagation()
				node && closeItem(tab, node)
			}
		},
		[node, tab, closeItem],
	)

	const handleCloseClick = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation()

			if (isReleaseTab) {
				return dispatch(closeTab(releaseTabId as number))
			}

			node && closeItem(tab, node)
		},
		[node, tab, closeItem],
	)

	const handleCloseOtherClick = useCallback(() => {
		onCloseOther(node as StructureObjectDto)
	}, [onCloseOther, node])

	const contextMenu = useCallback(
		(opened: boolean, x: number, y: number) => (
			<ContextMenu opened={opened} x={x} y={y}>
				<ContextMenuItems $minWidth={150}>
					<ContextMenuItem onClick={handleCloseClick}>
						{t('CLOSE_TAB')}
					</ContextMenuItem>
					<ContextMenuItem onClick={handleCloseOtherClick}>
						{t('CLOSE_OTHER_TABS')}
					</ContextMenuItem>
					<ContextMenuItem onClick={onCloseAll}>
						{t('CLOSE_ALL_TABS')}
					</ContextMenuItem>
				</ContextMenuItems>
			</ContextMenu>
		),
		[handleCloseClick, onCloseAll],
	)

	return (
		<TabHeading
			contextMenu={contextMenu}
			node={node}
			isSelected={isSelected}
			isTemporary={isTemporary}
			isVertical={!!isVertical}
			handleClick={handleClick}
			handleDblClick={handleDblClick}
			handleMouseUp={handleMouseUp}
			tab={tab}
			handleCloseClick={handleCloseClick}
		/>
	)
}
