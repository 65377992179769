import * as React from 'react'
import { Modal } from '@/components/Modal/Modal'
import { Button } from '@/components'
import { faHistory, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '@/hooks'
import { PopupText } from '../styles'
import { ConfirmationDialogProps } from '../types'

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	title,
	description,
	onSubmit,
	onClose,
}) => {
	const { t } = useAppContext()

	const footer = (close: () => void) => (
		<>
			<Button schema="warn" onClick={() => onSubmit(true)} icon={faHistory}>
				{t('CANCEL')}
			</Button>
			<Button icon={faTrash} schema="danger" onClick={() => onSubmit()}>
				{t('SAVE_AND_UPDATE_ELEMENTS')}
			</Button>
			<Button schema="transparent" onClick={close} icon={faTimes}>
				{t('SAVE_AND_DO_NOT_UPDATE_ELEMENTS')}
			</Button>
		</>
	)

	return (
		<Modal
			onClose={onClose}
			open={open}
			header={title}
			zIndex={9999}
			stickyFooter
			contentStyle={{
				width: 700,
			}}
			footer={footer}
		>
			<PopupText>
				<span>{description}</span>
			</PopupText>
		</Modal>
	)
}
