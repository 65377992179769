import styled, { css } from 'styled-components'

export const Cell = styled.div<{ flex?: string | number; $readonly?: boolean }>`
	border-width: 1px;
	border-style: solid;
	border-top-width: 0;
	border-left-width: 0;
	box-sizing: border-box;

	padding: 6px;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: 0px;
	white-space: nowrap;

	display: flex;
	justify-content: center;
	flex-direction: column;

	&:first-child {
		border-left-width: 1px;
	}

	${(props) => css`
		flex: ${props.flex || 1};
		border-color: ${props.theme.colors.form.$compact.header.borderColor};
	`}
`
