import React, { useEffect, useRef } from 'react'
import { exportStructure } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import FileSaver from 'file-saver'
import { Loader } from '@/components'

type Props = {
	nodeId: number
	onDownloadFinish: () => void
}

export const ExportStructure = ({ nodeId, onDownloadFinish }: Props) => {
	const refDownloading = useRef(false)
	const request = useApiRequest()

	useEffect(() => {
		if (refDownloading.current) {
			return
		}

		const call = async () => {
			refDownloading.current = true
			const file = await request(exportStructure(nodeId))

			if (file.data?.exportFileName && file.data?.content) {
				const blob = new Blob([JSON.stringify(file.data.content)], {
					type: 'application/json;charset=uff-8',
				})

				FileSaver.saveAs(blob, file.data.exportFileName)
			}

			refDownloading.current = false
			onDownloadFinish()
		}

		call()
	}, [nodeId, onDownloadFinish, request])

	return <Loader loaded={false} />
}
