import { StructureObjectDto } from '@/endpoints/models'
import mainColors from './mainColors'
import { darken, lighten } from 'polished'

export default {
	[StructureObjectDto.TypeEnum.ROOT]: '#f5c338',
	[StructureObjectDto.TypeEnum.SYSTEM]: mainColors.danger,
	[StructureObjectDto.TypeEnum.FOLDER]: mainColors.warn,
	[StructureObjectDto.TypeEnum.FOLDER_WORKFLOW]: '#4287f5',
	[StructureObjectDto.TypeEnum.TABLE]: lighten(0.1, mainColors.primary),
	[StructureObjectDto.TypeEnum.TABLE_COLUMN]: lighten(0.1, mainColors.primary),
	[StructureObjectDto.TypeEnum.VIEW]: lighten(0.3, mainColors.primary),
	[StructureObjectDto.TypeEnum.MAPPING]: mainColors.success,
	[StructureObjectDto.TypeEnum.MAPPING_READ]: mainColors.primary,
	[StructureObjectDto.TypeEnum.MAPPING_DEFAULT]: mainColors.success,
	[StructureObjectDto.TypeEnum.WORKFLOW]: '#6ba0db',
	[StructureObjectDto.TypeEnum.API_NODE]: '#0086C6',
	[StructureObjectDto.TypeEnum.API_COLLECTION]: '#006DA2',
	[StructureObjectDto.TypeEnum.QUEUE]: darken(0.1, mainColors.primary),
	[StructureObjectDto.TypeEnum.MAPPING_WRITE]: '#FFC0CB',
	[StructureObjectDto.TypeEnum.CONSTANT_DOMAIN]: '#FFC0CB',
}
