import { StructureDto, StructureObjectDto } from '@/endpoints/models'

type BatchEditErrors = Record<string | number, string>
export interface BatchEditContextState {
	formChanged: Record<string, unknown>
	onClose: () => void
	selectedNodes: number[]
	selectedStructures: StructureDto[]
	locking: boolean
	submitting: boolean
	errors: BatchEditErrors
	/** Right-clicked node in tree panel */
	node: StructureDto
	type: StructureObjectDto.TypeEnum
}

export interface BatchEditContextType {
	state: BatchEditContextState
	dispatch: React.Dispatch<Actions>
}

export enum ActionTypeEnum {
	SET,
	FORM_CHANGED_UPDATE,
}

type ActionSet = {
	type: ActionTypeEnum.SET
	state: Partial<BatchEditContextState>
}

type ActionFormChangedUpdate = {
	type: ActionTypeEnum.FORM_CHANGED_UPDATE
	formChanged: Record<string, unknown>
}

export type Actions = ActionSet | ActionFormChangedUpdate
