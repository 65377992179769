import { Actions } from './actions'
import {
	DeploymentDto,
	StructureObjectDto,
	StructureUserPermissionDto,
} from '@/endpoints/models'
import { NativeMap, keyMap } from '@/utils/collections'
import {
	NODE_LOAD_SYSTEM,
	NODE_LOAD_CHILDREN,
	NODE_EXPAND,
	NODE_COLLAPSE,
	NODE_ADD,
	NODE_ADD_SYSTEM,
	NODE_DELETE,
	NODE_LOCK,
	NODE_UNLOCK,
	NODE_IMPORT_STATE,
	NODE_LOAD,
	NODE_LOAD_PERMISSIONS,
	NODE_SELECT,
	NODE_DESELECT,
	NODE_SELECT_MODE_CHANGE,
	NODE_LOAD_NESTED_CHILDREN,
	NODE_LOAD_HISTORY_VERSION,
} from './constants'
import { removeDeletedNodes } from './helpers'
import { uniq } from 'lodash'

export type State = Readonly<typeof initialState>
export type NodeSession = {
	expanded: State['expanded']
}

export const getNodeSession = (state: State) => ({
	expanded: state.expanded,
})

export type StructureDtoRedux = StructureObjectDto & {
	data?: string
	workingData?: string
	deploymentDto?: DeploymentDto
}

const initialState = {
	nodes: {} as NativeMap<StructureDtoRedux>,
	roots: [] as number[],
	children: {} as NativeMap<number[]>,
	expanded: [] as number[],
	permissions: {} as NativeMap<StructureUserPermissionDto[]>,
	selectedNodes: [] as number[],
	selectMode: false as boolean,
}

export default (state = initialState, action: Actions): State => {
	switch (action.type) {
		case NODE_LOAD_SYSTEM: {
			const nodes = keyMap(action.payload, 'id', { ...state.nodes })
			const roots = action.payload.map((x) => x.id)

			return {
				...state,
				nodes,
				roots,
			}
		}

		case NODE_LOAD_CHILDREN: {
			const nodes = keyMap(action.payload, 'id', { ...state.nodes })

			return {
				...state,
				nodes,
				children: {
					...state.children,
					[action.metadata.parentId]: action.payload.map((x) => x.id),
				},
			}
		}

		case NODE_LOAD_NESTED_CHILDREN: {
			const nodes = keyMap(action.payload, 'id', { ...state.nodes })

			const children = action.payload.reduce((children, node) => {
				if (node.parentStructureId === undefined) {
					return children
				}

				return {
					...children,
					[node.parentStructureId]: uniq([
						...(children?.[node.parentStructureId] ?? []),
						node.id,
					]),
				}
			}, state.children)

			return {
				...state,
				nodes,
				children,
			}
		}

		case NODE_LOAD: {
			return {
				...state,
				nodes: { ...state.nodes, [action.payload.id]: action.payload },
			}
		}

		case NODE_LOAD_HISTORY_VERSION: {
			return {
				...state,
				nodes: {
					...state.nodes,
					[action.payload.structureId]: {
						...(state.nodes[action.payload.structureId] ?? {}),
						...action.payload,
						id: action.payload.structureId,
					},
				},
			}
		}

		case NODE_ADD: {
			const id = action.payload.id
			const parentId = action.payload.parentStructureId as number
			const parent = state.nodes[parentId]

			return {
				...state,
				nodes: {
					...state.nodes,
					[id]: action.payload,
					[parentId]: { ...parent, hasChildren: true },
				},
				children: {
					...state.children,
					[parentId]: (state.children[parentId] || []).concat(id),
				},
			}
		}

		case NODE_ADD_SYSTEM: {
			const id = action.payload.id

			return {
				...state,
				nodes: {
					...state.nodes,
					[id]: action.payload,
				},
				roots: state.roots.concat(id),
			}
		}

		case NODE_EXPAND: {
			const expanded = state.expanded.includes(action.key)
				? state.expanded
				: state.expanded.concat([action.key])

			return {
				...state,
				expanded,
			}
		}

		case NODE_COLLAPSE: {
			const expanded = state.expanded.filter((id) => id !== action.key)

			return {
				...state,
				expanded,
			}
		}

		case NODE_DELETE: {
			const { nodes, children } = removeDeletedNodes(
				action.metadata.node,
				state,
			)

			return {
				...state,
				nodes,
				roots: state.roots.filter((x) => x !== action.metadata.node.id),
				children,
			}
		}

		case NODE_LOCK: {
			const { node, userId, userName } = action.metadata

			return {
				...state,
				nodes: {
					...state.nodes,
					[node.id]: {
						...node,
						lockTime: new Date().toISOString(),
						lockUserId: userId,
						lockUserName: userName,
					},
				},
			}
		}

		case NODE_UNLOCK: {
			const id = action.metadata.node.id

			return {
				...state,
				nodes: {
					...state.nodes,
					[id]: {
						...action.metadata.node,
						lockTime: null,
						lockUserName: null,
					},
				},
			}
		}

		case NODE_IMPORT_STATE: {
			const saved = action.session

			return {
				...state,
				expanded: Array.isArray(saved.expanded) ? saved.expanded : [],
			}
		}

		case NODE_LOAD_PERMISSIONS: {
			return {
				...state,
				permissions: {
					...state.permissions,
					[action.metadata.nodeId]: action.payload,
				},
			}
		}

		case NODE_SELECT: {
			return {
				...state,
				selectedNodes: [
					...state.selectedNodes,
					...action.metadata.selectedNodes,
				].filter((v, i, a) => a.indexOf(v) === i),
			}
		}

		case NODE_DESELECT: {
			return {
				...state,
				selectedNodes: [
					...state.selectedNodes.filter(
						(nodeId) => !action.metadata.deselectedNodes.includes(nodeId),
					),
				],
			}
		}

		case NODE_SELECT_MODE_CHANGE: {
			return {
				...state,
				selectMode: action.metadata.selectMode,
				selectedNodes: [],
			}
		}

		default:
			return state
	}
}
