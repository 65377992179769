import { updateDataNode } from 'src/endpoints'
import {
	StructureDetailDto,
	StructureDto,
	StructureObjectDto,
} from '@/endpoints/models'
import { ApiCollectionData } from '@/endpoints/schemas/api-collection'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { RootState } from '@/store'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { loadNodeOrHistoryVersion } from '../node/utils'
import {
	API_COLLECTION_INIT,
	API_COLLECTION_SAVE,
	API_COLLECTION_UPDATE,
} from './constants'

type InitApiCollection = ReturnType<typeof initApiCollectionAction>
type UpdateApiCollection = ReturnType<typeof updateApiCollection>

interface SaveApiCollection {
	type: typeof API_COLLECTION_SAVE
	payload: void
	metadata: {
		node: StructureDto
	}
}

export const initApiCollection =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch(initApiCollectionAction(node, editMode, force))
	}

export const saveApiCollection =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const collection = getState().apiCollection.items[node.id]

		if (!collection) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const nonEmptyColumns = collection.form.columns.filter((x) => x.name)

		const formData: ApiCollectionData = {
			...collection.form,
			columns: nonEmptyColumns,
		}

		await dispatch(
			apiCallAction<SaveApiCollection>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(formData),
					}),
				API_COLLECTION_SAVE,
				{ node },
			),
		)
	}

const initApiCollectionAction = (
	node: StructureDetailDto,
	editMode: boolean,
	force: boolean,
) =>
	({
		type: API_COLLECTION_INIT,
		node,
		editMode,
		force,
	}) as const

export const updateApiCollection = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<ApiCollectionData>,
) =>
	({
		type: API_COLLECTION_UPDATE,
		node,
		update,
	}) as const

export type Actions =
	| InitApiCollection
	| SaveApiCollection
	| UpdateApiCollection
