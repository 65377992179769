import React from 'react'

interface FormWrapProps {
	children: React.ReactNode
	className?: string
}

export const FormWrap = ({ children, className }: FormWrapProps) => {
	return (
		<div
			className={`${className} border border-solid border-neutral-300 my-10 mx-auto max-w-screen-xl w-full p-10`}
		>
			{children}
		</div>
	)
}
