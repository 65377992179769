import { components, OptionProps, SingleValueProps } from 'react-select'
// @ts-expect-error
import { SelectComponents } from 'react-select/dist/declarations/src/components'
const { Option, SingleValue } = components

const DefaultSingleValue = ({ children, ...props }: SingleValueProps<any>) => {
	return <SingleValue {...props}>{children}</SingleValue>
}

const DefaultNoOptionMessage = ({
	children,
	...props
}: SingleValueProps<any>) => {
	return <SingleValue {...props}>{children}</SingleValue>
}

const DefaultOption = ({ children, ...props }: OptionProps<any, any>) => {
	return <Option {...props}>{children}</Option>
}

/**
 * This object represents the main logic for react-select customization,
 * for more information please visit https://react-select.com/components
 */

export const getCustomComponents = (components: SelectComponents) => {
	const { SingleValue, NoOptionsMessage, Option } = components || {}

	return {
		SingleValue: SingleValue || DefaultSingleValue,
		Option: Option || DefaultOption,
		NoOptionsMessage: NoOptionsMessage || DefaultNoOptionMessage,
	}
}
