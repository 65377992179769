import { useMemo } from 'react'
import { TablePermission } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useSystemUsers } from '../../../../utils'
import capitalize from 'lodash/capitalize'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	systemNodeId: number
}

const mapStringToOption = (value: string) => ({
	value,
	label: capitalize(value),
})

export const usePermissionsProperties = ({ systemNodeId }: HookOptions) => {
	const { t } = useAppContext()
	const { data: users } = useSystemUsers(systemNodeId)
	const permissions = Object.values(TablePermission.PermissionsEnum)

	const properties = useMemo<TableColumnProps<TablePermission>[]>(
		() => [
			{
				field: 'userId',
				label: t('SYSTEM_USER'),
				type: ItemPropertyType.OPTION,
				options: users ?? [],
				optionValue: 'id',
				optionLabel: 'name',
				flex: 1,
				showWhenPanelOpened: true,
				isNumeric: true,
				width: 50,
			},
			{
				field: 'permissions',
				label: t('PERMISSIONS'),
				type: ItemPropertyType.OPTIONS,
				flex: 2,
				showWhenPanelOpened: true,
				options: permissions.map(mapStringToOption),
				width: 100,
			},
		],
		[permissions, t, users],
	)

	return properties
}
