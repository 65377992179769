import styled from 'styled-components'
import { SyncFormField } from '@/pages/User/pages/Home/pages/TableDetail/components/SyncFormField'
import { TextFormField } from '@/components/UberForm'

export const Switcher = styled.div`
	display: flex;

	> button {
		&:not(:hover) {
			background-color: ${(props) =>
				props.theme.colors.button.default.background};
			color: ${(props) => props.theme.colors.button.default.color};
			border-color: #ddd;
			& svg {
				color: ${(props) => props.theme.colors.button.primary.background};
			}
		}
		border-top: none;
		border-bottom: none;
		border-radius: 0;
	}
`
export const RightButtons = styled.div`
	display: flex;
	height: 100%;
	position: relative;
`
export const SyncFormFieldNoPadding = styled(SyncFormField)`
	padding: 0 !important;
`
export const TextFormFieldNoPadding = styled(TextFormField)`
	padding: 0 !important;
`
