import { Loader } from '@/components'
import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { useTabContext } from '@/context/TabContext/TabContext'
import {
	initApiNode,
	saveApiNode,
	selectApiNodeTab,
	updateApiNode,
} from '@/store/modules/apiNode/actions'
import { ApiNodeDataForm, ApiNodeTab } from '@/store/modules/apiNode/types'
import { UpdateDeepPartial } from '@/store/utils'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
} from '@/hooks'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { Columns } from './pages/Columns/Columns'
import { Mappings } from './pages/Mappings/Mappings'
import { Overview } from './pages/Overview/Overview'
import { Collections } from './pages/Collections/Collections'
import { Validation } from '../../components/Validation/Validation'
import { Preview } from '../../components/Preview/Preview'
import { Deployments } from '../../components/Deployments/Deployments'
import { Permissions } from './pages/Permissions/Permissions'
import { useDeletedDomains } from '@/utils/domain'
import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { useNodeInit } from '../../hooks/useNodeInit'
import { Dependencies } from '../../components/Dependencies/Dependencies'

const ApiNodeComponent = () => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const dispatch = useAppDispatch()

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const apiNodes = useAppStore((state) => state.apiNode.nodes)
	const apiNode = apiNodes[node.id]
	const domains = useDeletedDomains(systemNodeId, apiNode)

	const objectTypes = apiNode?.form.objectSettings

	useNodeInit()

	const handleSave = useDebounceCallback(async () => {
		if (!apiNode || !editMode) {
			return
		}

		try {
			await dispatch(saveApiNode(node))
		} catch (e) {
			onSaveError(e)
		}
	}, 1000)

	const handleEdit = async () => {
		await dispatch(initApiNode({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initApiNode({ nodeId: node.id, editMode: false }))
	}

	const handleChange = useCallback(
		(data: UpdateDeepPartial<ApiNodeDataForm>) => {
			if (!editMode) {
				return
			}

			dispatch(updateApiNode(node, data))
			handleSave()
		},
		[editMode, dispatch, node, handleSave],
	)

	const handleTabChange = (tab: TabProps) => {
		dispatch(selectApiNodeTab(node, tab.id as ApiNodeTab))
	}

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = apiNode || {}

		if (!form || !apiNode) {
			return []
		}

		return [
			{
				id: ApiNodeTab.General,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={apiNode}
						editMode={editMode}
						systemNodeId={systemNodeId}
						onChange={handleChange}
						key="overview"
					/>
				),
			},
			{
				id: ApiNodeTab.Columns,
				title: t('TAB_STRUCTURE'),
				content: (
					<Columns
						key="columns"
						data={apiNode}
						node={node}
						systemNodeId={systemNodeId}
						domains={domains}
						onChange={handleChange}
					/>
				),
			},
			{
				id: ApiNodeTab.ApiCollection,
				title: t('TAB_MAPPINGS_API_COLLECTION'),
				content: <Collections node={node} key="apiCollection" />,
			},
			{
				id: ApiNodeTab.ReadMappings,
				title: t('API_MAPPINGS'),
				content: <Mappings node={node} key="mappings" />,
			},
			{
				id: ApiNodeTab.Permissions,
				title: t('PERMISSIONS'),
				content: (
					<Permissions
						key={ApiNodeTab.Permissions}
						node={node}
						data={apiNode}
						systemNodeId={systemNodeId}
						editMode={editMode}
						onChange={handleChange}
					/>
				),
			},
			{
				id: ApiNodeTab.Preview,
				title: t('TAB_PREVIEW'),
				content: (
					<Preview
						key={ApiNodeTab.Preview}
						node={node}
						showObjectTypes
						objectTypes={objectTypes}
					/>
				),
			},
			{
				id: ApiNodeTab.Deployments,
				title: t('TAB_DEPLOYMENTS'),
				content: (
					<Deployments
						node={node}
						key={ApiNodeTab.Deployments}
						editMode={editMode}
					/>
				),
			},
			{
				id: ApiNodeTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={ApiNodeTab.Validation} node={node} />,
			},
			{
				id: ApiNodeTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={ApiNodeTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
		]
	}, [
		apiNode,
		t,
		node,
		editMode,
		systemNodeId,
		handleChange,
		domains,
		objectTypes,
	])

	if (!apiNode) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<RightButtons>
						<EditableNodeActions
							node={node}
							editMode={editMode}
							dirty={apiNode.dirty}
							onEdit={handleEdit}
							onCancel={handleCancel}
						/>
					</RightButtons>
				}
			/>

			<Tabs
				tabs={tabs}
				onChange={handleTabChange}
				selectedTabId={apiNode.tab}
			/>
		</>
	)
}

const RightButtons = styled.div`
	display: flex;
	height: 100%;
	position: relative;
`

export const ApiNode = React.memo(ApiNodeComponent)
