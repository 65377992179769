import React, { ComponentType, CSSProperties, Dispatch } from 'react'
import { IAceEditorProps } from 'react-ace'
import { OptionProps } from 'react-select'

import { AutocompleteConfig } from '@/components/AceEditor/types'
import { ColumnsPickerValue } from '@/components/ColumnsPicker'
import { ColumnCallback } from '@/components/LightTable'
import { Predicate } from '@/components/Properties/types'
import { RegExpProps } from '@/components/RegExpValidatedInput/RegExpValidatedInput'
import { FormFieldContext } from '@/components/UberForm/FormFieldContext'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { TableColumn } from '@/endpoints/schemas'
import { ItemPropertyType } from '@/enums'
import { CellProps } from '@/types'

export enum NamingTypeEnum {
	GENERATE_CODE = 'GENERATE_CODE',
	GENERATE_TABLE_COLUMN_CODE = 'GENERATE_TABLE_COLUMN_CODE',
}

// primaryKeyUUID matches data type for column data type
export type PossibleForeignKeys = { [primaryKeyUUID: string]: TableColumn[] }

export type Schema =
	| 'default'
	| 'primary'
	| 'secondary'
	| 'success'
	| 'info'
	| 'danger'
	| 'warn'
	| 'transparent'
	| 'primaryOutlined'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

export type FormValue =
	| boolean
	| string
	| { from: string | undefined; to: string | undefined }
	| null
	| undefined
	| string[]
	| number
	| Date
	| Date[]

export type AceEditorProps = Omit<IAceEditorProps, 'height'> &
	FormFieldContext & {
		autoFocus?: boolean
		autocompleteConfig?: AutocompleteConfig
		height?: number | string
		isInModal?: boolean
	}

export interface TableColumnProps<T, O = any> {
	Cell?: (props: CellProps) => React.JSX.Element
	Header?: (props: CellProps) => React.JSX.Element
	aceEditorProps?: AceEditorProps
	autoWidth?: boolean
	canFilter?: boolean
	canGenerateCodeFieldName?: boolean
	clearable?: boolean
	codeSource?: any
	condition?: Predicate<T>
	customOption?: ComponentType<OptionProps<any, any, any>>
	disabled?: boolean | Predicate<T>
	field?: any
	flex?: number
	foreignKeyTableId?: number
	formatter?: React.FC<{
		fromProps?: boolean
		item: T
		readonly: boolean
		systemNode?: StructureObjectDto
	}>
	hideInTable?: boolean
	id?: string | number
	isLoading?: boolean
	isNumeric?: boolean
	label?: string
	maxWidth?: number
	minWidth?: number
	optionLabel?: string
	optionValue?: string
	options?: O[] | ((item: T | undefined) => O[])
	possibleForeignKeys?: PossibleForeignKeys
	primaryKeys?: TableColumn[]
	propertiesWidth?: string
	regExp?: (item: T) => RegExpProps | undefined
	showWhenPanelOpened?: boolean
	sticky?: boolean
	tableLabel?: string
	type?: ItemPropertyType
	value?: (item: T) => FormValue | ColumnsPickerValue
	width?: number | string
}

export interface LightTableColumn<
	T = any,
	K extends Extract<keyof T, string> = Extract<keyof T, string>,
> {
	/** Contents align */
	align?: 'left' | 'center' | 'right'
	/** Can this column be used to toggle expansion */
	expandable?: boolean

	/** Field used to get column value */
	field: K | '' | null
	/** Formatter used to get contents of the column */
	formatter?: ColumnCallback<T, React.ReactNode, K>
	/** Align of header */
	headerAlign?: 'left' | 'center' | 'right'

	/** Is the content stringified HTML */
	htmlInString?: boolean
	/** Key to be used when field is not unique */
	key?: string

	/** Maximum column width */
	maxWidth?: number
	/** Can this column be used for sorting */
	sortable?: boolean
	/** Should this column stick to the left part of the table */
	sticky?: boolean
	/** Align of subheader */
	subHeaderAlign?: 'left' | 'center' | 'right'

	/** Subheader title */
	subTitle?: React.ReactNode
	/** Classname applied to td of this specific column */
	tdClassName?: string | ColumnCallback<T, string>

	/** Style applied to td of this specific column */
	tdStyle?: React.CSSProperties | ColumnCallback<T, React.CSSProperties>
	/** Classname applied to header of this specific column */
	thClassName?: string

	/** Style applied to header of this specific column */
	thStyle?: React.CSSProperties
	/** Header title */
	title?: React.ReactNode

	/** Is this column visible */
	visible?: boolean

	/** Predefined column width */
	width: number
}

export interface ModalProps {
	bodyStyle?: CSSProperties
	children: React.ReactNode | ((close: () => void) => React.ReactNode)
	closeOnEscape?: boolean
	contentStyle?: React.CSSProperties
	dialogId?: string
	disablePortal?: boolean
	footer?: React.ReactNode | ((close: () => void) => React.ReactNode)
	footerStyle?: CSSProperties
	header?: React.ReactNode | ((close: () => void) => React.ReactNode)
	headerStyle?: CSSProperties
	height?: number
	maximizeButtonVisible?: boolean
	maximized?: boolean
	onClose?: () => void
	open?: boolean
	resizable?: boolean
	stickyFooter?: boolean
	stretchFooterButtons?: boolean
	zIndex?: number
}

export enum DdlModalType {
	CreateGit = 'CREATE_GIT_MODAL',
	PushToGit = 'PUSH_TO_GIT_MODAL',
}

export interface PropertiesErrorProps {
	[key: number]: {
		[column: string]: {
			message: string
		}
	}
}

export type ItemMenuDialog = (
	opened: boolean,
	onClose: () => void,
	node?: StructureDto,
	dispatch?: Dispatch<any>,
	selectedNodes?: number[],
) => React.JSX.Element | boolean
