import { ApiCollectionData } from '@/endpoints/schemas/api-collection'

export interface OpenedApiCollectionData {
	form: ApiCollectionData
	original: ApiCollectionData
	selected: {
		column: number | null
	}
	dirty: boolean
	parsedEditMode: boolean
}

export enum ApiCollectionTab {
	General = 'General',
	Columns = 'Columns',
	Validation = 'Validation',
	Dependencies = 'Dependencies',
}
