import React, { useContext } from 'react'
import { OpenedTab } from '@/store/modules/tab/types'

export interface TabContext {
	tab: OpenedTab
	onSaveError: (e: unknown) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TabContext = React.createContext<TabContext>({} as any)

export const useTabContext = () => useContext(TabContext)
TabContext.displayName = 'TabContext'
