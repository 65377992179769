import { PasswordStrength } from '@/utils/passwords'
import MainColor from './mainColors'
import { lighten, darken } from 'polished'

export default {
	text: {
		base: '#333',
	},
	input: {
		border: '#CCC',
		placeholder: '#bbb',
		error: {
			border: '#eb4034',
			background: '#ffeeed',
		},
		$compact: {
			verticalBorder: '#E0E0E0',
			horizontalBorder: '#DDD',
		},
		disabled: {
			border: '#EEE',
			background: '#F2F2F2',
		},
		focused: {
			boxShadow: '#9999ff',
		},
	},

	form: {
		error: {
			background: '#eb4034',
			color: '#fff',
		},
		$compact: {
			header: {
				color: '#555',
				background: '#f3f3f3',
				borderColor: '#ddd',
			},
		},
	},

	button: {
		disabledBackground: '#eee',
		primary: {
			background: MainColor.primary,
			borderColor: MainColor.primary,
			color: '#fff',
			hover: {
				background: lighten(0.2, MainColor.primary),
				borderColor: lighten(0.2, MainColor.primary),
				color: '#fff',
			},
		},
		secondary: {
			background: MainColor.secondary,
			borderColor: MainColor.secondary,
			color: '#fff',
			hover: {
				background: lighten(0.2, MainColor.primary),
				borderColor: lighten(0.2, MainColor.primary),
				color: '#fff',
			},
		},
		default: {
			background: '#f8f8f8',
			borderColor: '#ddd',
			color: '#555',
			hover: {
				background: '#fff',
				borderColor: '#ccc',
				color: '#333',
			},
		},
		success: {
			background: MainColor.success,
			borderColor: MainColor.success,
			color: '#fff',
			hover: {
				background: darken(0.1, MainColor.success),
				borderColor: darken(0.1, MainColor.success),
				color: '#fff',
			},
		},
		info: {
			background: MainColor.info,
			borderColor: MainColor.info,
			color: '#fff',
			hover: {
				background: lighten(0.1, MainColor.info),
				borderColor: lighten(0.1, MainColor.info),
				color: '#fff',
			},
		},
		danger: {
			background: MainColor.danger,
			borderColor: MainColor.danger,
			color: '#fff',
			hover: {
				background: darken(0.1, MainColor.danger),
				borderColor: darken(0.1, MainColor.danger),
				color: '#fff',
			},
		},
		warn: {
			background: MainColor.warn,
			borderColor: MainColor.warn,
			color: '#fff',
			hover: {
				background: darken(0.2, MainColor.warn),
				borderColor: darken(0.2, MainColor.warn),
				color: '#fff',
			},
		},
		transparent: {
			background: 'none',
			borderColor: 'transparent',
			color: '#000',
			hover: {
				background: 'none',
				borderColor: 'transparent',
				color: '#666',
			},
		},
		primaryOutlined: {
			background: 'transparent',
			borderColor: MainColor.primary,
			color: MainColor.primary,
			hover: {
				background: lighten(0.2, MainColor.primary),
				borderColor: lighten(0.2, MainColor.primary),
				color: '#fff',
			},
		},
	},

	layout: {
		borderColor: '#ddd',
		appBackgroundColor: lighten(0.71, MainColor.primary),
		topBackgroundColor: MainColor.primary,
		footerBackgroundColor: '#f0f0f0',
		tabContentBackgroundColor: '#fff',
		mainMenu: {
			linkBackgroundColor: lighten(0.05, MainColor.primary),
			linkActiveBackgroundColor: lighten(0.2, MainColor.primary),
			linkActiveHoverBackgroundColor: lighten(0.2, MainColor.primary),
			linkHoverBackgroundColor: lighten(0.1, MainColor.primary),
		},
		treePanel: {
			backgroundColor: '#fcfcfc',
			headerBackgroundColor: '#f3f3f3',
		},
	},

	table: {
		thead: {
			borderColor: '#ddd',
			th: {
				borderColor: '#ddd',
				backgroundColor: '#f3f3f3',
			},
		},
	},

	success: {
		light: lighten(0.52, MainColor.success),
		base: MainColor.success,
		shadowColor: MainColor.success,
	},

	primary: {
		base: MainColor.primary,
		light: lighten(0.52, MainColor.primary),
		shadowColor: 'rgba(0,123,255,.4)',
	},

	secondary: {
		base: MainColor.secondary,
		light: lighten(0.52, MainColor.secondary),
		shadowColor: 'rgba(0,123,255,.4)',
	},

	warn: {
		base: MainColor.warn,
		light: lighten(0.3, MainColor.warn),
	},

	danger: {
		base: MainColor.danger,
		light: lighten(0.15, MainColor.danger),
	},

	info: {
		base: MainColor.info,
		light: lighten(0.15, MainColor.info),
	},

	graph: {
		selectedBorder: 'rgb(0, 192, 255)',
	},

	message: {
		info: {
			background: MainColor.info,
			color: '#fff',
		},
		warn: {
			background: MainColor.warn,
			color: '#fff',
		},
		error: {
			background: MainColor.danger,
			color: '#fff',
		},
		success: {
			background: MainColor.success,
			color: '#fff',
		},
	},

	passwordStrength: {
		[PasswordStrength.VERY_WEAK]: '#cf000f',
		[PasswordStrength.WEAK]: 'orange',
		[PasswordStrength.GOOD]: '#87d37c',
		[PasswordStrength.STRONG]: '#26c281',
		[PasswordStrength.VERY_STRONG]: '#26a65b',
	},
}
