import { OptionProps, SingleValueProps } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { mainColors } from '@/styles'
import { components } from 'react-select'
import React from 'react'
const { Option, SingleValue } = components

interface FrozenIconProps {
	isFrozen: boolean
}

const FrozenIcon = ({ isFrozen }: FrozenIconProps) => {
	if (isFrozen) {
		return (
			<FontAwesomeIcon
				icon={faBan}
				size="sm"
				color={mainColors.frozen}
				title="FROZEN"
				style={{ marginRight: '5px' }}
			/>
		)
	} else {
		return null
	}
}

export const StatusCustomOption = ({
	children,
	...props
}: OptionProps<any, any>) => {
	return (
		<Option {...props}>
			<FrozenIcon isFrozen={props.data.value === 'frozen'} />
			{children}
		</Option>
	)
}

export const StatusCustomSingleValue = ({
	children,
	...props
}: SingleValueProps<any>) => {
	return (
		<SingleValue {...props}>
			<FrozenIcon isFrozen={props.data.value === 'frozen'} />
			{children}
		</SingleValue>
	)
}
