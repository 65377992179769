import React, { useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { generateSqlPreview } from 'src/endpoints'
import styled from 'styled-components'

import { Button, Loader, Message } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { Flex } from '@/components/Layout'
import { SelectFormField } from '@/components/UberForm'
import { StructureObjectDto } from '@/endpoints/models'
import { IdCodeName } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { MainContentWrap } from '@/styles'
import { useGeneratedSql } from '@/utils/sql'

interface Props {
	node: StructureObjectDto
	objectTypes?: IdCodeName[]
	showObjectTypes?: boolean
}

export const Preview = ({ node, showObjectTypes, objectTypes }: Props) => {
	const [isRawView, setIsRawView] = useState(false)

	const [objectTypeId, setObjectTypeId] = useState(objectTypes?.[0]?.id || null)

	const { t } = useAppContext()

	const [sql, loading, error] = useGeneratedSql(
		generateSqlPreview(
			node.id,
			objectTypeId ? { objectSettingsId: objectTypeId } : undefined,
		),
		[node.id, objectTypeId],
	)

	const handleSwitchView = () => {
		setIsRawView(!isRawView)
	}

	const isObjectTypeValid = !showObjectTypes || objectTypeId

	return loading ? (
		<Loader loaded={false} />
	) : (
		<>
			<ActionBarContainer>
				<SwitchButton>
					<Button
						onClick={handleSwitchView}
						schema={!isRawView ? 'primary' : 'primaryOutlined'}
					>
						{t('SCRIPT')}
					</Button>
					<Button
						onClick={handleSwitchView}
						schema={isRawView ? 'primary' : 'primaryOutlined'}
					>
						{t('RAW_JSON')}
					</Button>
				</SwitchButton>
				{showObjectTypes && (
					<ObjectTypeSelect
						formless={true}
						name="objectType"
						options={objectTypes || []}
						valueKey="id"
						isNumeric
						allowEmpty={false}
						clearable={false}
						onChange={(e: any) => {
							setObjectTypeId(e ? e : null)
						}}
						initialValue={objectTypeId}
						labelKey="name"
					/>
				)}
			</ActionBarContainer>

			<MainContentWrap>
				{error && (
					<Message
						type="error"
						header={t('DDL_CREATION_FAILED')}
						message={error}
					/>
				)}
				{!isObjectTypeValid && (
					<Message
						type="error"
						header={t('DDL_CREATION_FAILED')}
						message={t('PREVIEW_ERROR_OBJECT_TYPE')}
					/>
				)}
				{sql?.errors?.map((error) => (
					<Message
						key={error}
						type="error"
						header={t('ERROR')}
						message={error}
					/>
				))}
				{isObjectTypeValid && !error && (
					<FullscreenSql>
						<AutoSizer>
							{({ height, width }: { height: number; width: number }) => (
								<div style={{ height, width }}>
									{isRawView ? (
										<AceEditorField
											name="script"
											value={JSON.stringify(sql?.templateDataMap, null, 2)}
											disabled
											mode="json"
											height={height}
										/>
									) : (
										<AceEditorField
											name="script"
											value={sql?.sql}
											disabled
											mode="sql"
											height={height}
										/>
									)}
								</div>
							)}
						</AutoSizer>
					</FullscreenSql>
				)}
			</MainContentWrap>
		</>
	)
}

const FullscreenSql = styled.div`
	height: 90%;
	width: 100%;
`

const ActionBarContainer = styled(Flex)`
	margin: 1rem 0 0 0;
`

const ObjectTypeSelect = styled(SelectFormField)`
	margin: 1px;
	max-width: 250px;
`

const SwitchButton = styled.div`
	flex: 0;
	border-radius: 1.25rem;
	display: flex;
	margin: 0 1.25rem;

	button {
		&:first-child {
			border-radius: 1.25rem 0 0 1.25rem;
		}
		&:last-child {
			border-radius: 0 1.25rem 1.25rem 0;
		}
	}
`
