import { CustomAttributeComboDto } from '@/endpoints/models'

export type CustomAttributesSwitcherProps<T> = {
	customAttribute: CustomAttributeComboDto
	item?: T
	$pageType?: PageType | undefined
}

export type CustomAttributesProps<T> = {
	customAttributes: CustomAttributeComboDto[]
	item?: T
	$pageType?: PageType | undefined
}

export enum PageType {
	OVERVIEW_PAGE = 'OVERVIEW_PAGE',
	COLUMN_PAGE = 'COLUMN_PAGE',
}
