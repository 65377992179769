export interface SourceItem {
	id: number
	alias?: string
	stereotype?: SourceEnums.StereotypeEnum
	objectType?: SourceEnums.ObjectTypeEnum
	expression?: string
	sourceContext?: string
	ownerName?: string
	ownerId?: number
	objectId?: number
	objectCode?: string
	objectUuid?: string
	objectCustom?: string
}

export namespace SourceEnums {
	export enum StereotypeEnum {
		FROM = 'from',
		JOIN = 'join',
		LEFT_JOIN = 'left join',
		RIGHT_JOIN = 'right join',
		FULL_OUTER_JOIN = 'full outer join',
	}
	export enum ObjectTypeEnum {
		TABLE = 'TABLE',
		VIEW = 'VIEW',
		SUB_SELECT = 'SUB_SELECT',
		DUMMY = 'DUMMY',
	}
}
