import React, { useState } from 'react'
import { Flex, Cell } from '@/components/Layout'
import { useAppDispatch, useAppContext, useAuthContext } from '@/hooks'
import {
	setNodePermission,
	removeNodePermission,
} from '@/store/modules/node/actions'
import { Loader, Message, Button } from '@/components'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { NewStructureUserPermissionDto } from '@/endpoints/models'
import { useApi } from '@/endpoints/hooks'
import { getPermissions, getStructurePermissions } from 'src/endpoints'
import { Modal } from '@/components/Modal/Modal'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FormlessText } from '@/components/UberForm/Input/Text/Text'
import styled from 'styled-components'
import { loadNode } from '@/store/modules/node/general-actions'
import { ThCell } from '@/styles'

export const Permissions = ({
	nodeId,
	isReadOnly,
	opened,
	onClose,
}: {
	nodeId: number
	isReadOnly: boolean
	opened?: boolean
	onClose?: () => void
}) => {
	const { t } = useAppContext()
	const auth = useAuthContext()
	const dispatch = useAppDispatch()
	const users = useApi(getStructurePermissions(nodeId))
	const [permissions, permissionsLoading] = useApi(getPermissions('NODE'))
	const [saving, setSaving] = useState(false)
	const [search, setSearch] = useState(undefined as string | undefined)

	const handleChange = (userId: number, code: string) => {
		const load = async () => {
			setSaving(true)

			await dispatch(
				code
					? setNodePermission(
							nodeId,
							userId,
							code as NewStructureUserPermissionDto.PermissionCodeEnum,
						)
					: removeNodePermission(nodeId, userId),
			)

			users.invalidate()
			// TODO: Only load when userId === logged user id
			await dispatch(loadNode(nodeId))
			setSaving(false)
		}

		load()
	}

	return (
		<Modal
			open={opened}
			onClose={onClose}
			header={t('PERMISSIONS_TITLE')}
			footer={
				<Button icon={faCheck} onClick={onClose}>
					{t('CLOSE')}
				</Button>
			}
		>
			<Loader loaded={!saving && !users.loading} $absolute />

			{users.error && (
				<Message
					type="error"
					header={t('NETWORK_ERROR')}
					message={t('NETWORK_ERROR_MESSAGE', [users.error.message])}
				/>
			)}

			{users.data && (
				<>
					<SearchText
						value={search}
						onChange={(v: string) => setSearch(v)}
						placeholder={t('SEARCH_PLACEHOLDER')}
					/>

					<ScrollContainer>
						<Flex>
							<ThCell>{t('PERMISSIONS_USER')}</ThCell>
							<ThCell>{t('INHERITED_RIGHTS')}</ThCell>
							<ThCell>{t('EXPLICIT_RIGHTS')}</ThCell>
						</Flex>
						{users.data
							.filter(
								(u) =>
									!search ||
									search.length === 0 ||
									u.userLoginName.toLowerCase().indexOf(search.toLowerCase()) >=
										0,
							)
							.map((p) => (
								<Flex key={p.userId} $align="stretch">
									<Cell>{p.userLoginName}</Cell>
									<Cell>{p.inheritedPermissionName}</Cell>
									<Cell>
										{!auth.canManageUsers() || isReadOnly ? (
											p.structurePermissionName
										) : (
											<SelectWithoutForm
												options={permissions || []}
												disabled={permissionsLoading}
												isCompact
												valueKey="code"
												labelKey="name"
												allowEmpty
												onChange={(value) =>
													handleChange(p.userId, value as string)
												}
												value={p.structurePermissionCode}
												clearable
												loading={permissionsLoading}
											/>
										)}
									</Cell>
								</Flex>
							))}
					</ScrollContainer>
				</>
			)}
		</Modal>
	)
}

const SearchText = styled(FormlessText)`
	margin-bottom: 10px;
`

const ScrollContainer = styled.div`
	max-height: 400px;
	overflow: auto;
`
