import { AUTH_INIT, AUTH_LOGOUT, AUTH_TOKEN, AUTH_USER } from './constants'
import { UserDto } from '@/endpoints/models'

interface TokenAction {
	type: typeof AUTH_TOKEN
	token: string
}

interface LogoutAction {
	type: typeof AUTH_LOGOUT
}

interface UserAction {
	type: typeof AUTH_USER
	user: UserDto
}

export const setToken = (token: string): TokenAction => ({
	type: AUTH_TOKEN,
	token
})

export const setUser = (user: UserDto): UserAction => ({
	type: AUTH_USER,
	user
})

export const logout = () => ({
	type: AUTH_LOGOUT
})

export const initAuth = () => ({
	type: AUTH_INIT
})

export type Actions = TokenAction | LogoutAction | UserAction
