import { NativeMap } from '@/utils/collections'
import { OpenedViewData, ViewPermissionFlat, ViewTab } from './types'
import { Actions } from './actions'
import { ViewData } from '@/endpoints/schemas'
import { TAB_CLOSE } from '../tab/constants'
import { VIEW_INIT, VIEW_SAVE, VIEW_UPDATE, VIEW_SELECT_TAB } from './constants'
import { updateTabData } from '@/store/utils'
import { getInitialViewData, defaultViewData } from './helpers'
import { ensureValidData } from '../helpers'
import { flatTablePermission } from '../table/helpers'
import { GeneralNodeActions } from '@/store/modules/tab/types'

type State = Readonly<typeof initialState>

const initialState = {
	views: {} as NativeMap<OpenedViewData>,
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions,
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const views = { ...state.views }
			delete views[action.nodeId]

			return {
				...state,
				views,
			}
		}

		case VIEW_INIT: {
			const { node, editMode } = action

			const previous = state.views[node.id]

			if (previous && editMode && previous.parsedEditMode) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: ViewData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultViewData)
				: getInitialViewData(node.name)

			if (!data.columns) {
				data.columns = []
			}

			if (typeof data.columnsLastId !== 'number') {
				data.columnsLastId = Math.max(0, ...data.columns.map((c) => c.id))
			}

			const objectPermissions =
				data.objectPermissions
					?.filter((x) => x.user)
					.map(flatTablePermission) ?? []

			data.objectPermissions = objectPermissions as ViewPermissionFlat[]

			if (typeof data.objectPermissionLastId !== 'number') {
				data.objectPermissionLastId = Math.max(
					0,
					...(data.objectPermissions?.map((c) => c.id ?? 0) ?? [0]),
				)
			}

			return {
				...state,
				views: {
					...state.views,
					[node.id]: {
						form: data,
						original: data,
						tab: previous ? previous.tab : ViewTab.Overview,
						dirty: false,
						parsedEditMode: editMode,
					} as OpenedViewData,
				},
			}
		}

		case VIEW_SAVE: {
			const { node } = action.metadata

			return {
				...state,
				views: updateTabData(state.views, node.id, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		case VIEW_UPDATE: {
			const { node, update } = action

			return {
				...state,
				views: updateTabData(state.views, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...update,
					},
					dirty: true,
				})),
			}
		}

		case VIEW_SELECT_TAB: {
			return {
				...state,
				views: updateTabData(state.views, action.node.id, {
					tab: action.tab,
				}),
			}
		}

		default:
			return state
	}
}
