import { unlockNode } from '@/store/modules/node/actions'
import { useAppDispatch } from '@/hooks'
import { useCallback } from 'react'
import { useBatchEditContext } from '../context/BatchEditContext'
import { ActionTypeEnum } from '../context/types'

/** Remove locks from selected structures */
export const useOnUnlock = () => {
	const dispatch = useAppDispatch()

	const {
		state: { selectedStructures, locking },
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const onUnlock = useCallback(async () => {
		if (locking) {
			return
		}

		dispatchContext({ type: ActionTypeEnum.SET, state: { locking: true } })

		const unlocks = selectedStructures.map(async (node) => {
			if (node.lockTime) {
				await dispatch(unlockNode(node))
			}
		})

		await Promise.allSettled(unlocks)
		dispatchContext({ type: ActionTypeEnum.SET, state: { locking: false } })
	}, [dispatch, dispatchContext, selectedStructures, locking])

	return onUnlock
}
