export const TAB_OPEN = 'TAB_OPEN'
export const TAB_SELECT = 'TAB_SELECT'
export const TAB_SELECT_RELEASE = 'TAB_SELECT_RELEASE'
export const TAB_CLOSE = 'TAB_CLOSE'
export const TAB_EDIT = 'TAB_EDIT'
export const TAB_CANCEL_EDIT = 'TAB_CANCEL_EDIT'
export const TAB_SET_PERMANENT = 'TAB_SET_PERMANENT'
export const TAB_SET_PROPERTIES_SHOWN = 'TAB_SET_PROPERTIES_SHOWN'
export const TAB_SET_PROPERTIES_WIDTH = 'TAB_SET_PROPERTIES_WIDTH'
export const TAB_SET_PROPERTY_ITEM = 'TAB_SET_PROPERTY_ITEM'
export const TAB_CLOSE_RELATED = 'TAB_CLOSE_RELATED'
export const TAB_IMPORT_STATE = 'TAB_IMPORT_STATE'
export const TAB_SELECT_TAB = 'TAB_SELECT_TAB'
export const TAB_CHANGE_NAME = 'TAB_CHANGE_NAME'
