import { CustomAttributeForm } from '@/hooks'
import { SourceItem } from '.'

export interface WriteMappingData {
	code?: string
	targetTableId?: number
	targetTableFullPath?: string
	columns?: WriteMappingColumn[]
	columnsLastId?: number
	criteria?: string
	criteriaDescription?: string
	description?: string
	comment?: string
	distinct?: boolean
	name?: string
	outWhere?: string
	outWhereDescription?: string
	postMappingScript?: string
	postMappingScriptDescription?: string
	preMappingScript?: string
	preMappingScriptDescription?: string
	sourceHints?: string
	sourceHintsDescription?: string
	sources?: string
	sourcesDescription?: string
	status?: WriteMappingDataEnums.WriteMappingStatusEnum
	stereotypeId?: number
	targetHints?: string
	targetHintsDescription?: string
	truncate?: boolean
	responsiblePersonId?: number
	responsiblePersonName?: string
	responsibleAnalystId?: number
	responsibleAnalystName?: string
	ownerId?: number
	ownerName?: string
	condition?: string
	rowsetCode?: string
	orderPriority?: number
	sourceItems?: SourceItem[]
	sourceItemsLastId?: number
	customAttributes?: CustomAttributeForm[]
}

export interface WriteMappingColumn {
	id: number
	order: number
	uuid: string
	columnCode: string
	columnSourceStructureId?: number
	columnSourceStructureCode?: string
	columnSourceColumnUuid?: string
	columnSourceType?: WriteMappingColumn.WriteMappingColumnSourceType
	keyFlag?: boolean
	mapping: string
	mappingDescription?: string
	mappingComment?: string
	updateFlag?: boolean
	groupByFlag?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WriteMappingDataEnums {
	export enum WriteMappingStatusEnum {
		LOGICAL_IN_PROGRESS = 'logical_in_progress',
		LOGICAL_COMPLETED = 'logical_completed',
		PHYSICAL_IN_PROGRESS = 'physical_in_progress',
		PHYSICAL_COMPLETED = 'physical_completed',
		FROZEN = 'frozen',
	}
}

export namespace WriteMappingColumn {
	export enum WriteMappingColumnSourceType {
		API_NODE = 'API_NODE',
		ROW_SET = 'ROW_SET',
	}
}
