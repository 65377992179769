import { StructureObjectDto, StructureDetailDto } from '@/endpoints/models'
import { ReadMappingData } from '@/endpoints/schemas'
import { ReadMappingTab, ReadMappingDataForm } from './types'
import {
	MAPPING_READ_INIT,
	MAPPING_READ_SAVE,
	MAPPING_READ_UPDATE,
	MAPPING_READ_SELECT_TAB,
} from './constants'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { updateDataNode } from '@/endpoints'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { loadNodeOrHistoryVersion } from '../node/utils'

interface InitReadMapping {
	type: typeof MAPPING_READ_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface SaveReadMapping {
	type: typeof MAPPING_READ_SAVE
	payload: void
	metadata: {
		node: StructureObjectDto
	}
}

interface UpdateReadMapping {
	type: typeof MAPPING_READ_UPDATE
	node: StructureObjectDto
	update: UpdateDeepPartial<ReadMappingDataForm>
}

interface SelectReadMappingTab {
	type: typeof MAPPING_READ_SELECT_TAB
	node: StructureObjectDto
	tab: ReadMappingTab
}

export const initReadMapping =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: MAPPING_READ_INIT,
			node,
			editMode,
			force,
		} as InitReadMapping)
	}

export const updateReadMapping = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<ReadMappingDataForm>,
): Actions => ({
	type: MAPPING_READ_UPDATE,
	node,
	update,
})

export const saveReadMapping = (node: StructureObjectDto) =>
	apiCallAction(
		(getState) => {
			const mapping = getState().readMapping.mappings[node.id]

			if (!mapping) {
				throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
			}

			const formData: ReadMappingData = {
				...mapping.form,
				columns: (mapping.form.columns || []).filter((c) => !!c.columnCode),
			}

			return updateDataNode(node.id, {
				data: JSON.stringify(formData),
			})
		},
		MAPPING_READ_SAVE,
		{ node },
	)

export const selectReadMappingTab = (
	node: StructureObjectDto,
	tab: ReadMappingTab,
): Actions => ({
	type: MAPPING_READ_SELECT_TAB,
	node,
	tab,
})

export type Actions =
	| InitReadMapping
	| SaveReadMapping
	| UpdateReadMapping
	| SelectReadMappingTab
