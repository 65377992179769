import { useMemo, useCallback } from 'react'
import { ReadMappingColumn } from '@/endpoints/schemas'
import { useAppContext, useMemoObject } from '@/hooks'
import { ApiNodeColumn } from '@/endpoints/schemas/api-node'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	apiNodeColumns: ApiNodeColumn[]
	availableColumns: ApiNodeColumn[]
}

export const useColumnsProperties = ({
	apiNodeColumns,
	availableColumns,
}: HookOptions) => {
	const { t } = useAppContext()

	const allOptions = useMemoObject(apiNodeColumns)
	const availableOptions = useMemoObject(availableColumns)

	const getColumnOptions = useCallback(
		(item: ReadMappingColumn | undefined) => {
			if (item && item.columnCode) {
				const selectedColumn = allOptions.find(
					({ code }) => code === item.columnCode,
				)

				return selectedColumn
					? [selectedColumn, ...availableOptions]
					: availableOptions
			}

			return availableOptions
		},
		[allOptions, availableOptions],
	)

	const properties = useMemo<TableColumnProps<ReadMappingColumn>[]>(
		() => [
			{
				field: 'columnCode',
				label: t('MAPPING_COLUMNS_COLUMN'),
				type: ItemPropertyType.OPTION,
				options: getColumnOptions,
				optionValue: 'code',
				optionLabel: 'name',
				width: 200,
				isNumeric: true,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'mapping',
				label: t('MAPPING_COLUMNS_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				width: 200,
			},
			{
				field: 'mappingDescription',
				label: t('MAPPING_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'mappingComment',
				label: t('MAPPING_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'keyFlag',
				label: t('MAPPING_COLUMNS_KEY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 60,
			},
			{
				field: 'updateFlag',
				label: t('MAPPING_COLUMNS_UPDATE_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 100,
			},
			{
				field: 'groupByFlag',
				label: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 100,
			},
		],
		[t, getColumnOptions],
	)

	return properties
}
