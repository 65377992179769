import { updateDataNode } from 'src/endpoints'
import { StructureDetailDto, StructureObjectDto } from '@/endpoints/models'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'
import { RootState } from '@/store'
import { apiCallAction, AppDispatch, UpdateDeepPartial } from '@/store/utils'
import { inflateMappingPermission } from '../mapping/helpers'
import { loadNodeOrHistoryVersion } from '../node/utils'
import {
	API_NODE_INIT,
	API_NODE_SAVE,
	API_NODE_SELECT_TAB,
	API_NODE_UPDATE,
	API_NODE_UPDATE_GRAPH_DATA,
} from './constants'
import { ApiNodeTab, ApiNodeGraphData, ApiNodeDataForm } from './types'

interface InitApiNode {
	type: typeof API_NODE_INIT
	node: StructureDetailDto
	editMode: boolean
	force: boolean
}

interface UpdateApiNode {
	type: typeof API_NODE_UPDATE
	node: StructureObjectDto
	update: UpdateDeepPartial<ApiNodeDataForm>
}

interface SaveApiNode {
	type: typeof API_NODE_SAVE
	payload: void
	metadata: {
		node: StructureObjectDto
	}
}

interface SelectApiNodeTab {
	type: typeof API_NODE_SELECT_TAB
	node: StructureObjectDto
	tab: ApiNodeTab
}

interface UpdateApiNodeGraphData {
	type: typeof API_NODE_UPDATE_GRAPH_DATA
	node: StructureObjectDto
	update: UpdateDeepPartial<ApiNodeGraphData>
}

export const initApiNode =
	({ nodeId, version, editMode = false, force, envId }: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: API_NODE_INIT,
			node,
			editMode,
			force,
		} as InitApiNode)
	}

export const saveApiNode =
	(node: StructureObjectDto) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const apiNode = getState().apiNode.nodes[node.id]

		if (!apiNode) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const nonEmptyColumns = apiNode.form?.columns?.filter((x) => x.name) ?? []

		const formData = {
			...apiNode.form,
			columns: nonEmptyColumns,
			objectPermissions: (apiNode.form.objectPermissions || [])
				.filter((c) => !!c.userName)
				.map(inflateMappingPermission),
		}

		await dispatch(
			apiCallAction<SaveApiNode>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(formData),
					}),
				API_NODE_SAVE,
				{ node },
			),
		)
	}

export const updateApiNode = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<ApiNodeDataForm>,
): Actions => ({
	type: API_NODE_UPDATE,
	node,
	update,
})

export const selectApiNodeTab = (
	node: StructureObjectDto,
	tab: ApiNodeTab,
): Actions => ({
	type: API_NODE_SELECT_TAB,
	node,
	tab,
})

export type Actions =
	| InitApiNode
	| SaveApiNode
	| UpdateApiNode
	| UpdateApiNodeGraphData
	| SelectApiNodeTab
