import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faSlash, faTimes } from '@fortawesome/free-solid-svg-icons'
import { HistoryTableData } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { mainColors } from '@/styles'

export type UnsyncedFields = (keyof HistoryTableData)[]

export const SyncFormField = <K extends keyof HistoryTableData>({
	children,
	onSync,
	unsyncedFields,
	field,
	disabled = false,
}: {
	children: React.ReactNode
	onSync: (key: K) => void
	unsyncedFields: UnsyncedFields
	field: K
	disabled?: boolean
}) => {
	const { t } = useAppContext()

	return (
		<Container>
			{children}
			{unsyncedFields.includes(field) && (
				<SyncIcon>
					<span className="fa-layers fa-fw">
						<FontAwesomeIcon icon={faSync} color={mainColors.warn} />
						<FontAwesomeIcon icon={faSlash} color={mainColors.warn} />
					</span>
					{t('UNSYNCHRONIZED')}
					{!disabled && (
						<Resync onClick={() => onSync(field)}>
							<FontAwesomeIcon icon={faTimes} />
						</Resync>
					)}
				</SyncIcon>
			)}
		</Container>
	)
}

const Container = styled.div`
	flex: 1;
`

const SyncIcon = styled.div`
	display: flex;
	align-content: center;
	color: ${(props) => props.theme.colors.warn.base};
`

const Resync = styled.div`
	margin-left: 10px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.text.base};
`
