import styled from 'styled-components'

export const FilterContainer = styled.div`
	display: flex;
	align-items: stretch;
	box-sizing: border-box;
	margin: 0 1px;
`

export const FilterCell = styled.div<{ width?: number; $maxWidth?: number }>`
	display: flex;
	flex-grow: 1;
	padding: 0 5px;
	margin-right: 1px;
	box-sizing: border-box;
	width: ${(props) => (props.width ? `${props.width}px` : `80px`)};
	min-width: ${(props) => (props.width ? `${props.width}px` : `80px`)};
	max-width: ${(props) =>
		props.$maxWidth ? `${props.$maxWidth}px` : undefined};
`
