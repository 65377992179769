import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'

import { MoveDir } from '../types'
import { PropertyField } from './PropertyField'
import { NativeMap } from '@/utils/collections'
import { nextFrame } from '@/utils/async'
import { ColumnsPickerValue } from '@/components/ColumnsPicker/types'
import { EditCellWrap } from './styles'
import { FormValue, TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

interface EditCellProps<T> {
	index: number
	prop: TableColumnProps<T>
	item: T
	listOfValues: T[]
	readonly: boolean
	onChange?: (
		item: T,
		key: string,
		value: FormValue | ColumnsPickerValue,
	) => void
	value: any | null | undefined
	error?: string
	isFocused: boolean

	onFocus: (field: number) => void
	onMove: (dir: MoveDir) => void
	columnWidth?: number
}

const keyToDir = {
	ArrowUp: MoveDir.Up,
	ArrowDown: MoveDir.Down,
	ArrowLeft: MoveDir.Left,
	ArrowRight: MoveDir.Right,
} as NativeMap<MoveDir>

export const EditCell = <T,>({
	index,
	prop,
	item,
	listOfValues,
	value,
	readonly,
	onChange,
	error,
	isFocused,
	onFocus,
	onMove,
	columnWidth,
}: EditCellProps<T>) => {
	const refCheckbox = useRef<HTMLInputElement>(null)
	const [focused, setFocused] = useState(false)
	const [editing, setEditing] = useState(false)
	const container = useRef<HTMLDivElement>(null)

	// callback for textarea because it edits input in popup
	const onDisableEditing = useCallback(async () => {
		setEditing(false)
		await nextFrame()

		if (
			document.activeElement === null ||
			document.activeElement.tagName === 'BODY'
		) {
			container.current?.focus()
			setFocused(true)
		}
	}, [])

	const isEditable = !(typeof prop.disabled === 'function'
		? prop.disabled(item, listOfValues)
		: typeof prop.disabled === 'boolean'
			? prop.disabled
			: false)

	useEffect(() => {
		if (isFocused && !focused) {
			container.current?.focus()
		}

		if (!isFocused && focused) {
			setFocused(false)
			setEditing(false)
		}
	}, [focused, isFocused])

	useEffect(() => {
		if (editing && !isEditable) {
			setEditing(false)
		}
	}, [isEditable, editing])

	useEffect(() => {
		// disable editing mode when cell loses focus
		if (!isFocused) {
			setEditing(false)
		}
	}, [isFocused])

	const handleClick = useCallback(() => {
		// checkbox has no editing mode
		if (prop.type === ItemPropertyType.CHECKBOX || readonly) {
			return
		}

		setEditing(true)
	}, [prop.type, readonly])

	const handleFocus = useCallback(
		(e: React.FocusEvent<HTMLDivElement>) => {
			// TODO: HotFix - prevent nested focusing
			if (e.target.tagName.toLowerCase() === 'div') {
				setFocused(true)
				onFocus(index)
			}
		},
		[index, onFocus],
	)

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (!focused) {
				return
			}

			// change value of checkbox on Enter without focus / editing state
			if (
				(e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') &&
				prop.type === ItemPropertyType.CHECKBOX &&
				refCheckbox.current &&
				onChange
			) {
				const { name, checked } = refCheckbox.current
				onChange(item, name, !checked)

				return
			}

			if (editing) {
				if (
					(e.key === 'Enter' &&
						![ItemPropertyType.ACE_EDITOR, ItemPropertyType.TEXTAREA].includes(
							prop.type,
						)) ||
					e.key === 'Escape' ||
					e.key === 'Tab'
				) {
					setEditing(false)

					if (e.key !== 'Tab') {
						container.current?.focus()
					}
				}
			} else {
				const isWriting = e.key.match(/^[a-z0-9]$/i)

				if (isEditable && (e.key === 'Enter' || isWriting)) {
					setEditing(true)

					if (
						prop.type === ItemPropertyType.GENERATED_CODE &&
						onChange &&
						prop.field &&
						isWriting
					) {
						onChange(item, prop.field, (value ?? '') + e.key)
					}
				}

				const move = keyToDir[e.key]

				if (move !== undefined) {
					e.stopPropagation()
					e.preventDefault()
					onMove(move)
				}
			}
		},
		[
			editing,
			focused,
			isEditable,
			item,
			onChange,
			onMove,
			prop.field,
			prop.type,
			value,
		],
	)

	const style = useMemo<React.CSSProperties>(
		() => ({
			width: columnWidth || prop.width,
			minWidth: columnWidth || prop.width,
			flexGrow: prop.flex,
			...(prop.sticky
				? {
						position: 'sticky',
						right: '0px',
						top: '0px',
						zIndex: 2,
						background: 'white',
					}
				: {}),
		}),
		[columnWidth, prop.width, prop.flex, prop.sticky],
	)

	return (
		<EditCellWrap
			ref={container}
			$readonly={!focused || !editing || readonly}
			$focused={focused}
			editing={editing}
			tabIndex={readonly ? undefined : 0}
			style={style}
			onFocus={handleFocus}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			type={prop.type}
		>
			{prop.formatter ? (
				<prop.formatter item={item} readonly={!!readonly} />
			) : (
				<div>
					<PropertyField
						prop={prop}
						compact={true}
						item={item}
						listOfValues={listOfValues}
						value={value}
						readonly={!focused || !editing || readonly}
						onChange={onChange}
						error={error}
						autoFocus={true}
						refCheckbox={refCheckbox}
						onDisableEditing={onDisableEditing}
					/>
				</div>
			)}
		</EditCellWrap>
	)
}
