import React from 'react'
import { StructureObjectDto } from '@/endpoints/models'
import styled from 'styled-components'
import { Flex } from '@/components/Layout/Flex'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogWrapper } from '@/components'
import { DeleteModal } from '@/pages/User/pages/Home/components/DeleteModal'
import { Cell, ActionsCell } from '@/components/Layout'

interface WorkflowProps {
	node: StructureObjectDto
	onEdit: (node: StructureObjectDto) => void
}

export const Workflow = ({ node, onEdit }: WorkflowProps) => (
	<Flex $align="stretch">
		<Cell>{node.name}</Cell>
		<Cell>{node.code}</Cell>

		<ActionsCell>
			<Action onClick={() => onEdit(node)}>
				<FontAwesomeIcon icon={faEdit} />
			</Action>
			{node.canEdit && (
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && <DeleteModal node={node} onClose={onClose} />
					}
				>
					{(onClick) => (
						<Action onClick={onClick}>
							<FontAwesomeIcon icon={faTimes} />
						</Action>
					)}
				</DialogWrapper>
			)}
		</ActionsCell>
	</Flex>
)

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`
