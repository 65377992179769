import React from 'react'
import { TableActionCell } from '@/components/Table/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '@/components/Button/Button'
import { RowProps } from '@/components/Table/types'
import { Item } from '../Properties/context/types'
import { CoreCell, flexRender } from '@tanstack/react-table'

export const TableRowContent = ({
	hasLeftAction,
	hasLastRowEdit,
	isEditMode,
	isLastRow,
	onEdit,
	onDelete,
	row,
	dragRef,
}: RowProps<Item>) => {
	const editHandler = () => {
		return onEdit && onEdit(row.original)
	}

	const deleteHandler = () => {
		return onDelete && onDelete(row.original)
	}

	return (
		<>
			{hasLeftAction && (
				<TableActionCell
					$hasLastRowEdit={hasLastRowEdit}
					$isEditMode={isEditMode}
					$isLastRow={isLastRow}
					ref={dragRef}
				>
					<Button className="translate-x-4" size="sm" schema="transparent">
						<FontAwesomeIcon icon={faEllipsisV} color="grey" />
					</Button>
				</TableActionCell>
			)}

			{row.getVisibleCells().map((cell: CoreCell<any, any>) => {
				return (
					<td key={cell.id}>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</td>
				)
			})}

			<TableActionCell
				$hasLastRowEdit={hasLastRowEdit}
				$isEditMode={isEditMode}
				$isLastRow={isLastRow}
			>
				{hasLastRowEdit && (
					<Button size="sm" schema="transparent" onClick={editHandler}>
						<FontAwesomeIcon icon={faEdit} />
					</Button>
				)}

				<Button
					className={!hasLeftAction ? '' : '-translate-x-4'}
					size="sm"
					schema="transparent"
					onClick={deleteHandler}
				>
					<FontAwesomeIcon icon={faTimes} color="red" />
				</Button>
			</TableActionCell>
		</>
	)
}
