/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames'
import React from 'react'
import { NativeMap } from '@/utils/collections'
import { getRowValue, iterateColumns } from '../helpers'
import { RowCallback } from '../types'
import Row from './Row'
import memoizeOne from 'memoize-one'
import debounce from 'debounce'
import { LightTableColumn } from '@/types'

interface Props<T, K1 extends keyof T = keyof T> {
	data: T[]
	columns: LightTableColumn<T>[]
	keyField: K1
	trClassName?: string | RowCallback<T, string>
	trStyle?: React.CSSProperties | RowCallback<T, React.CSSProperties>
	className?: string
	width?: string

	onUpdate?: () => void
	isRowExpandable?: (row: T, index: number) => boolean
	expandedRowComponent?: (row: T, index: number) => React.ReactNode
	onExpand?: (row: T, index: number, isExpanded: boolean) => void
	expandedTrClassName?: string | RowCallback<T, string>
	defaultExpanded?: string[]

	onRowClick?: (row: T) => void

	windowedRendering?: boolean
	windowedRowHeight?: number
	scrollLeft?: number

	isRowOrderable?: boolean
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	dragArrowVisible?: boolean
}

interface State {
	expanded: NativeMap<boolean>
	viewTop: number
	viewHeight: number
	positionTop: number
	draggingRowIndex: number | null
	dragOverRowIndex: number | null
}

export default class LightTableBody<T> extends React.PureComponent<
	Props<T>,
	State
> {
	state: State = {
		expanded: {} as NativeMap<boolean>,
		viewTop: 0,
		viewHeight: 0,
		positionTop: 0,
		draggingRowIndex: null,
		dragOverRowIndex: null,
	}

	container = React.createRef<HTMLDivElement>()
	scroller = React.createRef<HTMLTableElement>()

	eventsRegistered = false

	componentDidMount() {
		const { defaultExpanded, data, expandedRowComponent } = this.props

		if (defaultExpanded && expandedRowComponent && data) {
			this.expand(defaultExpanded, data)
		}

		if (this.props.windowedRendering) {
			this.registerEvents()
		}
	}

	componentWillUnmount() {
		if (this.eventsRegistered) {
			this.eventsRegistered = false

			window.removeEventListener('scroll', this.updateScroll)
			window.removeEventListener('resize', this.updateSize)
		}
	}

	componentDidUpdate() {
		this.props.onUpdate && this.props.onUpdate()

		if (!this.eventsRegistered && this.props.windowedRendering) {
			this.registerEvents()
		}
	}

	updateSize = () => {
		this.setState({
			viewHeight: window.innerHeight,
			positionTop:
				window.scrollY +
				(this.scroller.current
					? this.scroller.current.getBoundingClientRect().top
					: 0),
		})
	}

	updateScroll = debounce(() => {
		this.setState({ viewTop: window.scrollY })
	}, 100)

	registerEvents = () => {
		this.eventsRegistered = true

		window.addEventListener('scroll', this.updateScroll)
		window.addEventListener('resize', this.updateSize)

		this.updateSize()
		this.updateScroll()
	}

	handleRowClick = (row: T, index: number) => {
		const { keyField, onExpand } = this.props
		const { expanded } = this.state

		const newExpanded = { ...expanded }
		const rowKey = row[keyField] as any
		const isExpanded = expanded[rowKey]

		if (isExpanded) {
			delete newExpanded[rowKey]
		} else {
			newExpanded[rowKey] = true
		}

		if (onExpand) {
			onExpand(row, index, !isExpanded)
		}

		this.setState({ expanded: newExpanded })
	}

	// tslint:disable-next-line: function-name
	UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
		const { defaultExpanded } = nextProps

		if (
			defaultExpanded &&
			(nextProps.data !== this.props.data ||
				defaultExpanded !== this.props.defaultExpanded)
		) {
			this.expand(defaultExpanded, nextProps.data)
		}
	}

	expand = (defaultExpanded: string[], data: T[]) => {
		const { keyField } = this.props
		const expanded = { ...this.state.expanded }
		let changed = false

		data.forEach((row) => {
			const key = ((row[keyField] || '') as any).toString()

			if (defaultExpanded.includes(key) && !expanded[key]) {
				expanded[key] = true
				changed = true
			}
		})

		if (changed) {
			this.setState({
				expanded,
			})
		}
	}

	isExpanded = (row: T) => {
		const { keyField } = this.props
		const { expanded } = this.state

		return expanded[row[keyField] as any]
	}

	resolveIsRowOrderable = (itemIndex: number) => {
		const { isRowOrderable, data } = this.props

		if (isRowOrderable) {
			return {
				up: itemIndex != 0,
				down:
					itemIndex + 1 < data.length &&
					data[itemIndex + 1] &&
					data.length - 1 > itemIndex,
				enabled: true,
			}
		}

		return { enabled: !!isRowOrderable }
	}

	handleRowDrop = (dropIndex: number) => {
		const { onRowOrderChanged } = this.props

		if (onRowOrderChanged) {
			onRowOrderChanged(this.state.draggingRowIndex as number, dropIndex)
		}
	}

	renderRow = (
		row: T,
		index: number,
		columns: { column: LightTableColumn<T>; style: React.CSSProperties }[],
		key?: string,
	) => {
		const {
			keyField,
			trClassName,
			trStyle,
			isRowExpandable,
			onRowClick,
			width,
			onRowOrderChanged,
			dragArrowVisible = true,
		} = this.props

		const rowExpandable = isRowExpandable && isRowExpandable(row, index)

		return (
			<Row<T>
				dragArrowVisible={dragArrowVisible}
				isRowOrderable={this.resolveIsRowOrderable(index)}
				onRowOrderChanged={onRowOrderChanged}
				onDragChanged={(isDrag: boolean) => {
					this.setState({ draggingRowIndex: isDrag ? index : null })

					if (!isDrag) {
						this.setState({ draggingRowIndex: null })
					}
				}}
				onDragOver={(index: number) =>
					this.setState({ dragOverRowIndex: index })
				}
				onRowDrop={this.handleRowDrop}
				$isDragging={this.state.draggingRowIndex !== null}
				isDragOver={this.state.dragOverRowIndex === index}
				key={`${key || row[keyField]}`}
				row={row}
				rowIndex={index}
				columns={columns}
				isExpandable={rowExpandable}
				isExpanded={rowExpandable && this.isExpanded(row)}
				trClassName={trClassName}
				onRowClick={onRowClick}
				onRowExpand={this.handleRowClick}
				trStyle={trStyle}
				width={width}
			/>
		)
	}

	getStyledColumns = memoizeOne((columns: LightTableColumn<T>[]) =>
		iterateColumns(columns),
	)

	render() {
		const {
			data,
			columns,
			keyField,
			className,
			expandedTrClassName,
			expandedRowComponent,
			width,
			windowedRendering,
			windowedRowHeight,
			scrollLeft,
			isRowExpandable,
		} = this.props

		const { expanded, positionTop, viewTop, viewHeight } = this.state

		const items = data || []
		const rows = []
		const styledColumns = this.getStyledColumns(columns)

		const rowHeight = windowedRowHeight || 20

		if (windowedRendering) {
			const bufferSize = viewHeight

			const from = Math.max(
				0,
				Math.floor((viewTop - bufferSize - positionTop) / rowHeight),
			)

			const to = Math.min(
				items.length,
				Math.ceil(
					(viewTop + viewHeight + bufferSize - positionTop) / rowHeight,
				),
			)

			rows.push(<div key={'ts'} style={{ height: from * rowHeight }} />)

			for (let index = from; index < to; index++) {
				const row = items[index]

				rows.push(this.renderRow(row, index, styledColumns))
			}

			rows.push(
				<div key={'tf'} style={{ height: (items.length - to) * rowHeight }} />,
			)
		} else {
			items.forEach((row, index) => {
				rows.push(this.renderRow(row, index, styledColumns))

				const expandedComponent = expanded[row[keyField] as any]

				if (
					expandedComponent &&
					expandedRowComponent &&
					(!isRowExpandable || isRowExpandable(row, index))
				) {
					const exp = expandedRowComponent(row, index)

					rows.push(
						<div
							key={`${row[keyField]}_expanded`}
							className={cn(
								'div-tr',
								'light-table-expanded-content',
								getRowValue(expandedTrClassName, row, index),
							)}
						>
							<div className="div-td">{exp}</div>
						</div>,
					)
				}
			})
		}

		return (
			<div
				className={cn(
					'light-table-body',
					className,
					windowedRendering && 'is-windowed',
				)}
				style={{
					marginLeft: scrollLeft !== undefined ? -scrollLeft : undefined,
				}}
				ref={this.container}
			>
				<div
					className={cn('light-table-body-table')}
					style={{
						minWidth: width,
						/*height: windowedRendering ? items.length * rowHeight : undefined,*/
					}}
					ref={this.scroller}
				>
					{rows}
				</div>
			</div>
		)
	}
}
