import React from 'react'
import { TableHeaderContentProps } from '@/components/Table/types'
import { TableHeaderContentWrap } from '@/components/Table/styles'
import en from '../../i18n/en'
import Button from '../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import {
	ColumnDefTemplate,
	flexRender,
	HeaderContext,
	SortDirection,
} from '@tanstack/react-table'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ColumnFilter } from '@/components/Table/ColumnFilter'

export const TableHeaderContent = ({
	header,
	table,
}: TableHeaderContentProps) => {
	const getHeaderName = (
		headerName: ColumnDefTemplate<HeaderContext<any, any>> | undefined,
	) => {
		switch (headerName) {
			case en.TABLE_COLUMNS_PRIMARY_KEY_ORDER:
				return en.TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT

			case en.TABLE_COLUMNS_MANDATORY:
				return en.TABLE_COLUMNS_MANDATORY_SHORT

			case en.TABLE_COLUMNS_FOREIGN_KEY:
				return en.TABLE_COLUMNS_FOREIGN_KEY_SHORT

			default:
				return headerName
		}
	}

	const headerName = header.column.columnDef.header

	const getSortingIndicator = (sortOrder: SortDirection): IconProp => {
		const sortIndicators = {
			asc: faArrowUp,
			desc: faArrowDown,
		}

		return sortIndicators[sortOrder] ?? faArrowUp
	}

	return (
		<th
			key={header.id}
			colSpan={header.colSpan}
			style={{ width: header.getSize() }}
		>
			<TableHeaderContentWrap
				className={header.column.getCanSort() ? 'cursor-pointer' : ''}
				onClick={header.column.getToggleSortingHandler()}
			>
				{flexRender(getHeaderName(headerName), header.getContext())}
				<Button size="sm">
					<FontAwesomeIcon
						icon={getSortingIndicator(
							header.column.getIsSorted() as SortDirection,
						)}
						opacity={header.column.getIsSorted() ? 1 : 0.3}
					/>
				</Button>
			</TableHeaderContentWrap>

			{header.column.getCanFilter() && (
				<ColumnFilter column={header.column} table={table} />
			)}
		</th>
	)
}
