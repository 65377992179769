import React, { useMemo, useState } from 'react'
import { SystemData, SystemEnvironment } from '@/endpoints/schemas'
import { useAppContext, useAppStore } from '@/hooks'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { getNodeDetail, resetVersion, takeOverVersion } from '@/endpoints'
import { Flex } from '@/components/Layout'
import { Button, DialogWrapper, Loader, MessageModal } from '@/components'
import { ModalForm } from '@/components/Modal/ModalForm'
import { SelectFormField } from '@/components/UberForm'
import styled from 'styled-components'

type FormDataTakeover = {
	sourceEnvId: string
}

interface ActionsColumnProps {
	item: SystemEnvironment
	readonly: boolean
	fromProps?: boolean
}

export const ActionsColumn: React.FC<ActionsColumnProps> = ({
	item,
	readonly,
	fromProps,
}) => {
	const { t } = useAppContext()
	const apiError = useAppStore((state) => state.apiError.title)

	const systemId = useAppStore(
		(state) =>
			state.tab.openedTabs[state.tab.selectedTab as number]?.systemNodeId,
	)

	const { data: systemDetail } = useApi(getNodeDetail(systemId))

	const envsOptions = useMemo(() => {
		if (!systemDetail?.data) {
			return []
		}

		const pushedData = JSON.parse(systemDetail.data) as SystemData

		const envsFiltered = pushedData.environments.filter(
			(systemEnv) => systemEnv.id !== item.id,
		)

		return envsFiltered
	}, [item.id, systemDetail])

	const request = useApiRequest()

	const [status, setStatus] = useState({
		loading: false,
		message: '',
	})

	const onReset = async () => {
		setStatus({
			loading: true,
			message: '',
		})

		const { error } = await request(resetVersion(systemId, item.id))

		if (error) {
			setStatus({
				loading: false,
				message: '',
			})

			return
		}

		setStatus({
			loading: false,
			message: t('VERSIONS_RESET'),
		})
	}

	const onTakeover = async (data: FormDataTakeover) => {
		setStatus({
			loading: true,
			message: '',
		})

		const { error } = await request(
			takeOverVersion(systemId, item.id, parseFloat(data.sourceEnvId)),
		)

		if (error) {
			setStatus({
				loading: false,
				message: '',
			})

			return
		}

		setStatus({
			loading: false,
			message: t('VERSIONS_TOOK_OVER'),
		})
	}

	const onClose = () => {
		setStatus({
			loading: false,
			message: '',
		})
	}

	return (
		<Flex
			style={
				fromProps
					? {
							marginBottom: '10px',
							padding: '0 10px',
							width: '100%',
						}
					: { justifyContent: 'center' }
			}
		>
			<Loader loaded={!status.loading} $absolute />
			<DialogWrapper
				dialog={(opened, onClose) => (
					<ModalForm
						onClose={() => {
							onClose()
						}}
						header={t('RESET_VERSIONS')}
						saveTitle={t('RESET_VERSIONS')}
						onSubmit={async () => {
							await onReset()
							onClose()
						}}
						open={opened}
					>
						<></>
					</ModalForm>
				)}
			>
				{(onClick) => (
					<ButtonReset
						disabled={readonly}
						isLoading={status.loading}
						size="sm"
						onClick={onClick}
					>
						{t('RESET_VERSIONS')}
					</ButtonReset>
				)}
			</DialogWrapper>
			<DialogWrapper
				dialog={(opened, onClose) => (
					<ModalForm<FormDataTakeover>
						onClose={() => {
							onClose()
						}}
						header={t('TAKE_OVER_VERSIONS')}
						saveTitle={t('TAKE_OVER_VERSIONS')}
						onSubmit={async (data) => {
							await onTakeover(data)
							onClose()
						}}
						open={opened}
					>
						<Warning>{t('ONLY_PUSHED_ENVIRONMENTS_CAN_BE_ADDED')}</Warning>
						<SelectFormField
							name="sourceEnvId"
							title={t('SOURCE_ENVIRONMENT')}
							options={envsOptions}
							valueKey="id"
							labelKey="name"
							required
							placeholder=""
						/>
					</ModalForm>
				)}
			>
				{(onClick) => (
					<Button disabled={readonly} size="sm" onClick={onClick}>
						{t('TAKE_OVER_VERSIONS')}
					</Button>
				)}
			</DialogWrapper>

			{status.message && !apiError && (
				<MessageModal
					title={t('DONE')}
					message={status.message}
					type="info"
					onClose={onClose}
				/>
			)}
		</Flex>
	)
}

const Warning = styled.div`
	margin-bottom: 10px;
`

const ButtonReset = styled(Button)`
	margin-right: 10px;
`
