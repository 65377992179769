/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnCallback, RowCallback } from './types'
import { LightTableColumn } from '@/types'

export const getCellValue = <T, R>(
	value: R | ColumnCallback<T, R>,
	cell: any,
	row: T,
	index: number,
): R =>
	typeof value === 'function'
		? (value as ColumnCallback<T, R>)(cell, row, index)
		: value

export const getRowValue = <T, R>(
	value: R | RowCallback<T, R>,
	row: T,
	index: number,
): R =>
	typeof value === 'function' ? (value as RowCallback<T, R>)(row, index) : value

export const isVisible = <T>(column: LightTableColumn<T>) =>
	column.visible === undefined || column.visible

/**
 * Inserts filler columns for sticky columns
 * @param result where to insert fillers
 * @param column next column
 * @param left current left position in table
 * @param stickyOffset offset from last unsticky header
 */
const insertFiller = <T>(
	result: { column: LightTableColumn<T>; style: React.CSSProperties }[],
	column: LightTableColumn<T>,
	left: number,
	stickyOffset: number,
) => {
	result.push({
		column: {
			field: null,
			key: `separator_${column.key || column.field}`,
			formatter: () => '',
			tdClassName: 'sticky-column sticky-column-separator',
			thClassName: 'sticky-column sticky-column-separator',
			width: 0,
			sticky: true,
		},
		style: {
			left: `${left}px`,
		},
	})

	result.push({
		column: {
			field: null,
			key: `filler_${column.key || column.field}`,
			formatter: () => '-',
			tdClassName: 'sticky-column-filler',
			thClassName: 'sticky-column-filler',
			width: stickyOffset,
		},
		style: {
			width: `${stickyOffset}px`,
			minWidth: `${stickyOffset}px`,
			maxWidth: `${stickyOffset}px`,
		},
	})
}

/**
 * Returns columns and their generated styles (mostly related to sticky columns).
 * @param columns
 */
export const iterateColumns = <T>(columns: LightTableColumn<T>[]) => {
	let stickyOffset = 0
	let totalOffset = 0

	const styledColumns = columns
		.filter((col) => isVisible(col))
		.reduce(
			(result, column) => {
				const width = column.width
				const style = {} as React.CSSProperties
				const left = totalOffset

				if (column.sticky) {
					stickyOffset += width
				} else if (stickyOffset > 0) {
					insertFiller(result, column, left, stickyOffset)
					stickyOffset = 0
				}

				if (column.width) {
					style.width = `${column.width}px`
					style.minWidth = `${column.width}px`
				}

				if (column.maxWidth) {
					style.maxWidth = `${column.maxWidth}px`
				}

				if (column.sticky) {
					style.left = `${left}px`
					style.width = `${width}px`
				}

				totalOffset += width

				result.push({ column, style })

				return result
			},
			[] as { column: LightTableColumn<T>; style: React.CSSProperties }[],
		)

	// In case there's no non-sticky header
	if (stickyOffset > 0) {
		insertFiller<T>(
			styledColumns,
			{ field: 'empty' as any, width: 0 },
			totalOffset,
			stickyOffset,
		)
	}

	return styledColumns
}
