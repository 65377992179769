import React, { useState } from 'react'
import {
	getAvailableStructureForSourceObject,
	getExistingStructuresForSourceMapping,
	getSystemUsers,
} from 'src/endpoints'

import { Button, TableAndProperties } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { calculateTablePropertiesHeight } from '@/components/Properties/tableProperties/TablePropertiesWrapper'
import { AnyObject } from '@/components/UberForm/types'
import { useApi } from '@/endpoints/hooks'
import { StructureExportDto, StructureObjectDto } from '@/endpoints/models'
import { SourceItem } from '@/endpoints/schemas'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { OpenedMappingData } from '@/store/modules/mapping/types'
import { createEmptySourceItem } from '@/utils/source/createEmptySourceItem'
import { useSourceItemProperties } from '@/utils/source/useSourceItemProperties'
import { getStructureTypeActions } from '@/utils/structureType/getStructureTypeActions'

import { useDetailTabContext } from '../DetailTab/context/DetailTabContext'
import { ListEditTabWrapper, SwitchButton } from './styles'

type SourceProps = {
	data: OpenedMappingData
	onChange: (v: AnyObject) => void
	sourceFieldName?: string
}

type SourceValues = {
	sourceItems: SourceItem[]
}

export const SourceComponent = ({
	data,
	onChange,
	sourceFieldName = 'sources',
}: SourceProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const sources = data?.form?.sources
	const sourceItems = data?.form?.sourceItems

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const [isRawView, setIsRawView] = useState(!sources)

	const { tables } = useAppStore((state) => state.table)
	const { views } = useAppStore((state) => state.view)

	const handleSwitchView = () => {
		setIsRawView(!isRawView)
	}

	const properties = useSourceItemProperties()

	const systemTables = useApi(
		editMode
			? getAvailableStructureForSourceObject(node.id, 'SYSTEM')
			: getExistingStructuresForSourceMapping(node.id, 'SYSTEM'),
	)

	const [systemUsers] = useApi(getSystemUsers(systemNodeId))

	const handleChange = async (v: Partial<SourceValues>, keyChange?: string) => {
		if (v.sourceItems) {
			const rows = Object.values(v.sourceItems)

			for (const row of rows) {
				if (keyChange === 'objectType') {
					row.objectCustom = undefined
					row.objectId = undefined
					row.objectCode = undefined
				}

				if (keyChange === 'objectCode') {
					const sourceStructure = systemTables?.data?.find(
						(st) => st.code === row.objectCode,
					)

					if (sourceStructure) {
						// load source node to get owner from form
						const { initData } = getStructureTypeActions(
							sourceStructure.type as unknown as StructureObjectDto.TypeEnum,
						)

						const sourceStructureId = sourceStructure.structureId ?? -1

						if (
							(sourceStructure.type === StructureExportDto.TypeEnum.TABLE &&
								tables[sourceStructureId] === undefined) ||
							(sourceStructure.type === StructureExportDto.TypeEnum.VIEW &&
								views[sourceStructureId] === undefined)
						) {
							await dispatch(
								initData({
									nodeId: sourceStructure.structureId as number,
									editMode: false,
								}),
							)
						}

						const structureForm =
							sourceStructure.type === StructureExportDto.TypeEnum.TABLE
								? tables
								: views

						row.ownerId = structureForm[sourceStructureId]?.form.ownerId
						row.ownerName = structureForm[sourceStructureId]?.form.ownerName
						row.objectId = sourceStructure.structureId
					}
				}

				if (keyChange === 'ownerId') {
					const owner = systemUsers?.find((u) => u.id === row.ownerId)

					if (owner) {
						row.ownerName = owner?.name
					}
				}
			}
		}

		onChange(v as AnyObject)
	}

	return (
		<>
			<SwitchButton>
				<Button
					onClick={handleSwitchView}
					schema={!isRawView ? 'primary' : 'primaryOutlined'}
				>
					{t('TEXT_SOURCES')}
				</Button>
				<Button
					onClick={handleSwitchView}
					schema={isRawView ? 'primary' : 'primaryOutlined'}
				>
					{t('STRUCTURED_SOURCES')}
				</Button>
			</SwitchButton>

			{isRawView ? (
				<ListEditTabWrapper
					className="mb-4"
					style={{
						height: calculateTablePropertiesHeight(sourceItems, 2),
						maxHeight: 400,
					}}
				>
					<TableAndProperties
						node={node}
						data={data.form}
						createEmpty={createEmptySourceItem}
						itemsKey={'sourceItems'}
						idCounterKey={'sourceItemsLastId'}
						onChange={handleChange}
						properties={properties}
						disablePanelProperties
						tabKey="object"
						existsKey="stereotype"
						style={{ padding: '10px 0' }}
					/>
				</ListEditTabWrapper>
			) : (
				<AceEditorField
					name={sourceFieldName}
					title={t('MAPPING_SOURCES')}
					initialValue={sources}
					autocompleteConfig={{
						disableColumnsMasterElement: true,
					}}
				/>
			)}
		</>
	)
}

export const Source = React.memo(SourceComponent)
