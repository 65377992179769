import React from 'react'
import styled from 'styled-components'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { FormFieldContext, withFormField } from '../../FormFieldContext'
import { nextFrame } from '@/utils/async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import enGb from 'date-fns/locale/en-GB'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

export interface DatePickerProps
	extends FormFieldContext,
		Omit<ReactDatePickerProps, 'onFocus' | 'onChange' | 'onBlur' | 'value'> {
	placeholder?: string
	className?: string
	value?: Date
	autoFocus?: boolean
	clearable?: boolean
	inputIcon?: IconDefinition
	refInput?: React.RefObject<HTMLInputElement>
}

export class DatePicker extends React.PureComponent<DatePickerProps> {
	input = React.createRef<HTMLInputElement>()

	async componentDidMount() {
		const { refInput, autoFocus } = this.props
		registerLocale('en-GB', enGb)
		setDefaultLocale('en-GB')

		await nextFrame()

		if (this.input.current && autoFocus) {
			this.input.current.focus()
		}

		if (refInput && autoFocus) {
			refInput.current?.focus()
		}
	}

	onChange = (date: Date | [Date, Date] | /* for selectsRange */ null) => {
		if (this.props.onChange) {
			this.props.onChange(date || undefined)
		}
	}

	onFocus = (e: React.FocusEvent) => {
		const { onFocus } = this.props

		if (onFocus) {
			onFocus(e)
		}
	}

	onBlur = (e: React.FocusEvent) => {
		const { onBlur } = this.props

		if (onBlur) {
			onBlur(e)
		}
	}

	handleClear = () => {
		if (this.props.onChange) {
			this.props.onChange(undefined)
		}
	}

	render() {
		const {
			id,
			value,
			placeholder,
			className,
			name,
			disabled,
			clearable,
			inputIcon,
			refInput,
			...reactDateProps
		} = this.props

		return (
			<Container>
				{inputIcon && (
					<InputIcon>
						<FontAwesomeIcon icon={inputIcon} />
					</InputIcon>
				)}
				<ReactDatePicker
					customInputRef={(refInput as any) ?? (this.input as any)}
					id={id}
					name={name}
					className={className}
					disabled={disabled}
					placeholderText={placeholder}
					selected={value}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					locale="en-GB"
					dateFormat="dd/MM/yyyy"
					autoComplete="off"
					{...reactDateProps}
				/>
				{clearable && value && (
					<Clear onClick={this.handleClear}>
						<FontAwesomeIcon icon={faTimes} />
					</Clear>
				)}
			</Container>
		)
	}
}

const Container = styled.div`
	position: relative;
	flex: 1;
`

const Clear = styled.div`
	position: absolute;
	opacity: 0.5;
	right: 12px;
	top: 0;
	transform: translate(50%, 50%);
	cursor: pointer;
`

const InputIcon = styled.div`
	position: absolute;
	left: 0;
	opacity: 0.5;
	transform: translate(50%, 50%);
	& ~ input {
		padding-left: 26px !important;
	}
`

export default withFormField(DatePicker)
