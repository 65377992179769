import omit from 'lodash/omit'

import { getMappingsOfSystemNodes, updateDataNode } from '@/endpoints'
import { StructureDetailDto, StructureObjectDto } from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import {
	apiCallAction,
	AppDispatch,
	thunkAction,
	UpdateDeepPartial,
} from '@/store/utils'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

import { loadNodeChildren } from '../node/actions'
import { loadNodeOrHistoryVersion } from '../node/utils'
import {
	ENVIRONMENT_WAS_BLURRED,
	SYSTEM_INIT,
	SYSTEM_LOAD_MAPPINGS,
	SYSTEM_SAVE,
	SYSTEM_SELECT_TAB,
	SYSTEM_UPDATE,
} from './constants'
import { SystemDataForm, SystemTab } from './types'

interface InitSystem {
	editMode: boolean
	force: boolean
	node: StructureDetailDto
	type: typeof SYSTEM_INIT
}

interface SaveSystem {
	metadata: {
		node: StructureObjectDto
	}
	payload: void
	type: typeof SYSTEM_SAVE
}

interface UpdateSystem {
	node: StructureObjectDto
	type: typeof SYSTEM_UPDATE
	update: UpdateDeepPartial<SystemDataForm>
}

interface SelectSystemTab {
	node: StructureObjectDto
	tab: SystemTab
	type: typeof SYSTEM_SELECT_TAB
}
interface SetTokenVisibility {
	payload: { isBlurred: boolean; nodeId?: number; onBlurItemId: number }
	type: typeof ENVIRONMENT_WAS_BLURRED
}
interface SystemLoadMappings {
	metadata: {
		systemId: number
	}
	payload: StructureObjectDto[]
	type: typeof SYSTEM_LOAD_MAPPINGS
}

export const initSystem =
	({
		nodeId,
		editMode = false,
		force = false,
		version,
		envId,
	}: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: SYSTEM_INIT,
			node,
			editMode,
			force,
		} as InitSystem)
	}

export const updateSystem = (
	node: StructureObjectDto,
	update: UpdateDeepPartial<SystemDataForm>,
): Actions => ({
	type: SYSTEM_UPDATE,
	node,
	update,
})

export const saveSystem = (node: StructureObjectDto) =>
	thunkAction(async (dispatch, getState) => {
		const system = getState().system.systems[node.id]

		if (!system) {
			throw new Error(`Saving unopened state ${JSON.stringify(node)}`)
		}

		const {
			name,
			code,
			constants,
			constantsLastId,
			lookups,
			lookupsLastId,
			environments,
			environmentsLastId,
			systemUsers,
			generateCode,
			generateTableColumnCode,
		} = system.form

		const formData = omit(system.form, [
			'generateCode',
			'generateTableColumnCode',
		])

		const systemData: SystemData = {
			...formData,
			name: name as string,
			code: code as string,
			constants: (constants || []).filter((c) => !!c.name),
			constantsLastId: constantsLastId as number,
			lookups: (lookups || []).filter((c) => !!c.name),
			lookupsLastId: lookupsLastId as number,
			environments: (environments || []).filter((c) => !!c.name),
			environmentsLastId: environmentsLastId as number,
			systemUsers: (systemUsers || []).filter((c) => !!c.name),
			namingConfig: {
				generateCode,
				generateTableColumnCode,
			},
		}

		await dispatch(
			apiCallAction<SaveSystem>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(systemData),
					}),
				SYSTEM_SAVE,
				{ node },
			),
		)

		await dispatch(loadNodeChildren(node.parentStructureId as number))
	})

export const selectSystemTab = (
	node: StructureObjectDto,
	tab: SystemTab,
): Actions => ({
	type: SYSTEM_SELECT_TAB,
	node,
	tab,
})
export const setTokenVisibility = (payload: {
	isBlurred: boolean
	nodeId: number | undefined
	onBlurItemId: number
}) => ({
	type: ENVIRONMENT_WAS_BLURRED,
	payload,
})

export const loadSystemMappings = (systemId: number) =>
	apiCallAction<SystemLoadMappings>(
		() => getMappingsOfSystemNodes(systemId),
		SYSTEM_LOAD_MAPPINGS,
		{ systemId },
	)

export type Actions =
	| InitSystem
	| SaveSystem
	| UpdateSystem
	| SelectSystemTab
	| SystemLoadMappings
	| SetTokenVisibility
