import { getColumnMappingsOptions, getFilteredColumns } from '../../../../utils'
import { ApiNodeColumn } from '@/endpoints/schemas/api-node'
import { useMemo, useCallback } from 'react'
import { WriteMappingColumn } from '@/endpoints/schemas'
import { useAppContext, useAppStore, useMemoObject } from '@/hooks'
import { OpenedApiNodeData } from '@/store/modules/apiNode/types'
import { ApiNodeColumnDto } from '@/endpoints/models'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	nodeId: number
	parentNode: OpenedApiNodeData | undefined
	items: WriteMappingColumn[]
	rowSetCode: string | undefined
	writeColumns: ApiNodeColumnDto[] | undefined
}

export const useColumnsProperties = ({
	nodeId,
	parentNode,
	items,
	rowSetCode,
	writeColumns,
}: HookOptions) => {
	const { t } = useAppContext()

	const writeMappings = useAppStore((state) => state.writeMapping.mappings)

	const targetTableFullPath = writeMappings[nodeId]?.form.targetTableFullPath

	const mappingColumns = getColumnMappingsOptions(
		getFilteredColumns(parentNode, writeColumns),
		rowSetCode,
		!!targetTableFullPath,
	).map<ApiNodeColumn>((column) => ({
		id: -1,
		order: 0,
		name: column.columnName,
		code: column.columnCode,
		mandatory: false,
	}))

	const cachedItems = useMemoObject(items)
	const cachedMappingColumns = useMemoObject(mappingColumns)

	const availableColumns = useMemo(
		() =>
			cachedMappingColumns.filter(
				(c) => !cachedItems.find((item) => item?.columnCode == c.code),
			),
		[cachedItems, cachedMappingColumns],
	)

	const getColumnOptions = useCallback(
		(item: WriteMappingColumn | undefined) => {
			if (item && item.columnCode) {
				return [
					cachedMappingColumns.find((c) => c.code == item.columnCode),
					...availableColumns,
				].filter((c) => !!c)
			}

			return availableColumns
		},
		[availableColumns, cachedMappingColumns],
	)

	const properties = useMemo<TableColumnProps<WriteMappingColumn>[]>(
		() => [
			{
				field: 'columnCode',
				label: t('MAPPING_COLUMNS_COLUMN'),
				type: ItemPropertyType.OPTION,
				options: getColumnOptions,
				optionValue: 'code',
				optionLabel: 'name',
				width: 200,
				isNumeric: true,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'mapping',
				label: t('MAPPING_COLUMNS_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				width: 200,
			},
			{
				field: 'mappingDescription',
				label: t('MAPPING_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'mappingComment',
				label: t('MAPPING_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'keyFlag',
				label: t('MAPPING_COLUMNS_KEY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 60,
			},
			{
				field: 'updateFlag',
				label: t('MAPPING_COLUMNS_UPDATE_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 100,
			},
			{
				field: 'groupByFlag',
				label: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 100,
			},
		],
		[t, getColumnOptions],
	)

	return properties
}
