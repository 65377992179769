import { FormField } from '@/components/UberForm'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { FormFieldProps } from '@/components/UberForm/types'
import { AceEditorProps } from '@/types'

import { AceEditor } from './AceEditor'
import { AceEditorLabel } from './AceEditorLabel'

type AceEditorFieldProps = AceEditorProps &
	FormFieldProps & { formless?: boolean }

export const AceEditorField = (props: AceEditorFieldProps) => {
	const { formless, ...aceEditorProps } = props

	const FF = formless ? FormlessFormField : FormField

	return (
		<FF
			{...aceEditorProps}
			customLabel={(title: string, inputId: string, isHighlighted: boolean) => (
				<AceEditorLabel htmlFor={inputId} $isHighlighted={isHighlighted}>
					{title}
				</AceEditorLabel>
			)}
		>
			<AceEditor {...aceEditorProps} />
		</FF>
	)
}
