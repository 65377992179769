import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { CsvImporterStepEnum } from '../context/types'
import { CsvFields } from '../types/CsvField'

type Fields = Record<CsvImporterStepEnum.COLUMNS, CsvFields>

export const useFields = () => {
	const { t } = useAppContext()

	const fields = useMemo<Fields>(
		() => ({
			[CsvImporterStepEnum.COLUMNS]: [
				{
					name: 'name',
					label: t('NAME'),
					required: true,
				},
				{
					name: 'code',
					label: t('CODE'),
				},
				{
					name: 'domainName',
					label: t('DOMAIN_NAME'),
				},
				{
					name: 'dataType',
					label: t('DOMAIN_DATA_TYPE'),
				},
				{
					name: 'stereotypeCode',
					label: t('STEREOTYPE_CODE'),
				},
				{
					name: 'defaultValue',
					label: t('DOMAIN_DEFAULT_VALUE'),
				},
				{
					name: 'mandatory',
					label: t('DOMAIN_MANDATORY'),
				},
				{
					name: 'description',
					label: t('DESCRIPTION'),
				},
				{
					name: 'comment',
					label: t('COMMENT'),
				},
				{
					name: 'indexName',
					label: t('INDEX_NAME'),
				},
				{
					name: 'primaryKeyName',
					label: t('PRIMARY_KEY_NAME'),
				},
			],
		}),
		[t],
	)

	return fields
}
