import React from 'react'
import { CsvImporterContextProvider } from './context/CsvImporterContext'
import { Wrapper } from './components/Wrapper'
import { CsvOnMapped } from './context/types'

interface Props {
	inputData: string
	nodeId: number
	onMapped: CsvOnMapped
}

export default function CsvImporter({ nodeId, inputData, onMapped }: Props) {
	return (
		<CsvImporterContextProvider
			inputData={inputData}
			initialState={{
				onMapped,
				nodeId
			}}
		>
			<Wrapper />
		</CsvImporterContextProvider>
	)
}
