import { FolderData, FolderDataForm } from './types'
import { ensureValidData } from '../helpers'
import omit from 'lodash/omit'

export const defaultFolderData: Readonly<FolderData> = {
	name: '',
	diagram: {
		nodes: [],
		links: [],
		zoom: 0,
		offset: {
			x: 0,
			y: 0,
		},
		autoLayoutFlag: true,
	},
	newDiagram: {
		class: '',
		linkKeyProperty: '',
		nodeDataArray: [],
		linkDataArray: [],
	},
	diagramSvg: '',
}

export const getInitialFolderData = (name: string) =>
	ensureValidData(
		{
			name,
		},
		defaultFolderData,
	)

export const getFolderDataForm = (data: FolderData) => {
	const folderDataForm: FolderDataForm = {
		...omit(data, 'namingConfig'),
		generateCode: data.namingConfig?.generateCode,
		generateTableColumnCode: data.namingConfig?.generateTableColumnCode,
	}

	return folderDataForm
}
