import { ReadMappingData } from '@/endpoints/schemas'

export interface OpenedReadMappingData {
	form: ReadMappingDataForm
	original: ReadMappingDataForm
	stereotypeId: number
	tab: ReadMappingTab
	dirty: boolean
	parsedEditMode: boolean
}

export type ReadMappingDataForm = Partial<ReadMappingData>

export enum ReadMappingTab {
	Overview = 'Overview',
	Columns = 'Columns',
	Sources = 'Sources',
	Others = 'Others',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Dependencies = 'Dependencies',
}
