import { DeleteNodesModal } from '@/pages/User/pages/Home/components/TreePanel/components/DeleteNodesModal'
import { DeleteModal } from '@/pages/User/pages/Home/components/DeleteModal'
import { BatchEdit } from '@/pages/User/pages/Home/components/TreePanel/components/BatchEdit/BatchEdit'
import { ImportStructureModal } from '@/pages/User/pages/Home/components/TreePanel/components/ImportStructureModal'
import { MassSqlExportModal } from '@/pages/User/pages/Home/components/TreePanel/components/MassSqlExportModal'
import { UnlockModal } from '@/pages/User/pages/Home/components/UnlockModal'
import { cancelEditingTab } from '@/store/modules/tab/actions'
import { DuplicateNodeModal } from '@/pages/User/pages/Home/components/TreePanel/components/DuplicateNodeModal'
import React from 'react'
import { StructureDto } from '@/endpoints/models'

import { ItemMenuDialog } from '@/types'

export const deleteNodesModalDialog: ItemMenuDialog = (opened, onClose) =>
	opened && <DeleteNodesModal onClose={onClose} />

export const deleteNodeDialog: ItemMenuDialog = (opened, onClose, node) =>
	opened && <DeleteModal node={node as StructureDto} onClose={onClose} />

export const batchEditDialog: ItemMenuDialog = (opened, onClose, node) =>
	opened && <BatchEdit onClose={onClose} node={node as StructureDto} />

export const importStructureModalDialog: ItemMenuDialog = (
	opened,
	onClose,
	node,
) => opened && <ImportStructureModal node={node} onClose={onClose} />

export const duplicateNodeModalDialog: ItemMenuDialog = (
	opened,
	onClose,
	node,
) => opened && <DuplicateNodeModal node={node} onClose={onClose} />

export const massSqlExportModalDialog: ItemMenuDialog = (
	opened,
	onClose,
	node,
	undefined,
	selectedNodes,
) =>
	opened && (
		<MassSqlExportModal
			node={node}
			onClose={onClose}
			selectedNodes={selectedNodes}
		/>
	)

export const unlockNodeModalDialog: ItemMenuDialog = (
	opened,
	onClose,
	node,
	dispatch,
) =>
	opened && (
		<UnlockModal
			node={node as StructureDto}
			onConfirm={() =>
				dispatch && dispatch(cancelEditingTab(node as StructureDto))
			}
			onClose={onClose}
		/>
	)
