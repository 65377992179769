import { TechnicalColumnDto } from '@/endpoints/models'
import { hasObjectAllValues } from '@/utils/collections'
import omit from 'lodash/omit'
import { useMemo } from 'react'
import Fuse from 'fuse.js'
import { TechnicalColumnsFilterValues } from '../components/TechnicalColumnsFilter'

export const useTechnicalColumnsFilteredData = (
	filter: TechnicalColumnsFilterValues,
	data: TechnicalColumnDto[] = [],
) => {
	return useMemo(() => {
		let dataValues = data.filter((item) => {
			return hasObjectAllValues(item, omit(filter, 'updatedBy'))
		})

		if (filter.updatedBy) {
			const options = {
				keys: ['updatedBy'],
			}

			const fuse = new Fuse(dataValues, options)

			const search = fuse.search(filter.updatedBy)

			dataValues = search.map((fuseItem) => fuseItem.item)
		}

		return dataValues
	}, [data, filter])
}
