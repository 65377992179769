export default {
	fontSize: '14px',
	borderRadius: '0',
	padding: '6px 8px;',
	width: '100%',
	compact: {
		padding: '4px',
		height: '26px'
	}
}
