import styled, { css } from 'styled-components'

export const TooltipContainer = styled.div<{ disableStyle?: boolean }>`
	position: absolute;

	${(props) =>
		!props.disableStyle &&
		css`
			z-index: 1000;
			background: #000;
			color: #ddd;
			padding: 10px;

			&::before {
				content: ' ';
				position: absolute;
				bottom: -10px;
				left: 50%;
				margin-left: -5px;
				border: 5px solid transparent;
				border-top-color: #000;
			}
		`}
`
