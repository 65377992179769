import React from 'react'
import { FormlessFormField } from './FormField'
import { AnyObject } from './types'
import { FormValue } from '@/types'

export interface FormContext<T = AnyObject> {
	onFieldChange: (field: FormlessFormField<T>, value: FormValue) => void
	onFieldValidating: (field: string) => void
	onFieldValidated: (field: string, valid: boolean) => void
	getValues: () => Partial<T>
	reset: (values?: Partial<T>) => void
	register: (field: FormlessFormField<T>) => Promise<void>
	unregister: (field: FormlessFormField<T>) => void
	id: string
	valid: boolean
	validating: boolean
	submitting: boolean
	loading: boolean
	isHorizontal: boolean
	withQueryString: boolean
	disabled: boolean
	enableFieldHighlight?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormContext = React.createContext<FormContext>(null as any)

FormContext.displayName = 'FormContext'

export const withForm = (Component: typeof React.Component) => {
	return class WithForm extends React.Component {
		render() {
			return (
				<FormContext.Consumer>
					{(context) => <Component form={context} {...this.props} />}
				</FormContext.Consumer>
			)
		}
	}
}
