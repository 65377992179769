import styled, { css } from 'styled-components'

import { cssError } from '@/styles/errorValidation'

export const Container = styled.div`
	height: 100%;
	position: relative;
	flex: 1;
`

export const Clear = styled.div`
	position: absolute;
	opacity: 0.5;
	right: 12px;
	top: 0;
	transform: translate(50%, 50%);
	cursor: pointer;
`

export const InputIcon = styled.div`
	position: absolute;
	left: 0;
	opacity: 0.5;
	transform: translate(50%, 50%);
	& ~ input {
		padding-left: 26px !important;
	}
`

interface InputProps {
	$compact: boolean
	$error?: string | null
	$hasIcon?: boolean
	disabled?: boolean
}

export const Input = styled.input<InputProps>`
	background-color: ${(props) =>
		props.disabled ? props.theme.colors.input.disabled : 'none'};
	height: 100%;
	${cssError}

	&& {
		${(props) => css`
			border-color: ${props.$compact
				? `${props.theme.colors.input.$compact.horizontalBorder} ${props.theme.colors.input.$compact.verticalBorder}`
				: props.theme.colors.input.border};
			border-radius: ${props.$compact ? 0 : props.theme.input.borderRadius};
			padding: ${props.$compact
				? props.theme.input.compact.padding
				: props.theme.input.padding};
			:focus {
				border-color: ${props.theme.colors.primary.base};
				box-shadow: 0 0 3px ${props.theme.colors.primary.shadowColor};
			}
			:hover {
				box-shadow: ${props.disabled
					? 'none'
					: `0 0 3px ${props.theme.colors.primary.shadowColor}`};
			}
		`}

		${(props) =>
			props.$hasIcon &&
			css`
				padding-left: 24px;
			`}

		${(props) =>
			props.$compact &&
			css`
				height: 26px;
			`}
	}
`
