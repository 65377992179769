import React from 'react'
import { useAppContext, useAppStore } from '@/hooks'
import { Search } from './Search/Search'
import { UserMenu } from './UserMenu'
import { NavBarMenu } from './NavBarMenu'
import { NavContainer, UserCompany } from './styles'

export const Navbar = () => {
	const { t } = useAppContext()
	const user = useAppStore((store) => store.auth.user)

	return (
		<NavContainer>
			<h1 className="font-semibold">{t('APPLICATION_NAME')}</h1>
			{user && <UserCompany>{user.companyName}</UserCompany>}
			<NavBarMenu className="ml-6" />
			<Search />
			<UserMenu />
		</NavContainer>
	)
}
