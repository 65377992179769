import React, { useEffect, useMemo, useRef } from 'react'
import { ModalForm } from '@/components/Modal/ModalForm'
import {
	CustomAttributeCreateDto,
	CustomAttributeUpdateDto,
	CustomLovDto,
} from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { Form, TextAreaFormField, TextFormField } from '@/components/UberForm'
import { useApiRequest, useApi } from '@/endpoints/hooks'
import { updateCustomLov, createCustomLov, getCustomLov } from '@/endpoints'
import { Flex } from '@/components/Layout'
import ArrayFormField from '@/components/UberForm/arrayField/ArrayFormField'
import { InputTypeEnum } from '@/components/UberForm/arrayField/types'

type CustomLovModalProps = {
	customLov?: Partial<CustomLovDto>
	onSave: () => void
	onClose: () => void
	textAreaHeight?:string
}

export const CustomLovModal = ({ customLov, onSave, onClose,textAreaHeight }: CustomLovModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const refForm = useRef<Form<CustomLovDto>>(null)

	const customLovDetail = useApi(getCustomLov(customLov?.id!))

	const handleSubmit = async (form: Partial<CustomLovDto>) => {
		const action =
			customLov && customLov.id
				? updateCustomLov(customLov.id, form as CustomAttributeUpdateDto)
				: createCustomLov(form as CustomAttributeCreateDto)

		const result = await request(action)

		if (result) {
			customLovDetail.invalidate()
			onSave()
			onClose()
		}
	}

	const formData = useMemo(
		() => customLovDetail?.data || ({} as CustomLovDto),
		[customLovDetail],
	)

	const initialValues = useMemo(
		() => ({
			name: formData.name,
			description: formData.description,
			lovValues: formData.lovValues,
		}),
		[formData],
	)

	useEffect(() => {
		refForm.current?.setValues(initialValues)
	}, [initialValues])

	return (
		<>
			<ModalForm<CustomLovDto>
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				maximizeButtonVisible
				initialValues={initialValues}
				formRef={refForm}
				header={() => (
					<>{customLov ? t('CUSTOM_LOV_UPDATE') : t('CUSTOM_LOV_NEW')}</>
				)}
				onSubmit={handleSubmit}
			>
				<Flex style={{ alignItems: 'flex-start' }}>
					<TextFormField name="name" title={t('NAME')} required />
					<TextAreaFormField textAreaHeight={textAreaHeight} name="description" title={t('DESCRIPTION')} />
				</Flex>
				<ArrayFormField
					name="lovValues"
					title="Values"
					fields={[
						{
							inputType: InputTypeEnum.TEXT,
							name: 'value',
							placeholder: t('VALUE'),
						},
						{
							inputType: InputTypeEnum.TEXT,
							name: 'label',
							placeholder: t('LABEL'),
						},
					]}
				/>
			</ModalForm>
		</>
	)
}
