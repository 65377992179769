import { StructureObjectDto, StructureExportDto } from '@/endpoints/models'
import { SourceItem, SourceEnums } from '@/endpoints/schemas'

export const getAvailableStructures = (
	item?: StructureObjectDto[],
	column?: SourceItem
) => {
	if (!item) {
		return []
	}

	const objectType = column?.objectType

	switch (objectType) {
		case SourceEnums.ObjectTypeEnum.TABLE:
			return item.filter(c => c?.type === StructureExportDto.TypeEnum.TABLE)
		case SourceEnums.ObjectTypeEnum.VIEW:
			return item.filter(c => c?.type === StructureExportDto.TypeEnum.VIEW)
		case SourceEnums.ObjectTypeEnum.DUMMY:
			return item.filter(
				c =>
					c?.type === StructureExportDto.TypeEnum.TABLE ||
					c?.type === StructureExportDto.TypeEnum.VIEW
			)
		default:
			return []
	}
}
