import React, { useMemo } from 'react'

import { Locale } from '../Locale'
import AppContext from './AppContext'
import { useAppStore } from '@/hooks'

export const AppContextWrapper = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const locale = useAppStore((state) => state.auth.locale)

	const ctx = useMemo(() => {
		const localeInstance = new Locale()

		if (locale !== undefined) {
			localeInstance.setLocale(locale)
		}

		return {
			locale: localeInstance,
			t: localeInstance.translate.bind(locale),
		}
	}, [locale])

	return <AppContext.Provider value={ctx}>{children}</AppContext.Provider>
}
