import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { TableConstraint } from '@/endpoints/schemas'

export const useConstraintOptions = () => {
	const { t } = useAppContext()

	const options = useMemo(
		() => [
			{
				label: t('CONSTRAINT_TYPE_PRIMARY_KEY'),
				value: TableConstraint.ConstraintTypeEnum.PRIMARY_KEY,
			},
			{
				label: t('CONSTRAINT_TYPE_FOREIGN_KEY'),
				value: TableConstraint.ConstraintTypeEnum.FOREIGN_KEY,
			},
			{
				label: t('CONSTRAINT_TYPE_CUSTOM'),
				value: TableConstraint.ConstraintTypeEnum.CUSTOM,
			},
			{
				label: t('CONSTRAINT_TYPE_UNIQUE'),
				value: TableConstraint.ConstraintTypeEnum.UNIQUE,
			},
			{
				label: t('CONSTRAINT_TYPE_CHECK'),
				value: TableConstraint.ConstraintTypeEnum.CHECK,
			},
		],
		[t],
	)

	return options
}
