import React, { useCallback } from 'react'
import { Form } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { TableDataForm } from '@/store/modules/table/types'
import { TableData, HistoryTableData } from '@/endpoints/schemas'
import { SyncFormField, UnsyncedFields } from '../../components/SyncFormField'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { MainContentWrap } from '@/styles'

interface Props {
	editMode: boolean
	onChange: (v: Partial<TableData>) => void
	unsyncedFields: UnsyncedFields
	onSync: (field: keyof HistoryTableData) => void
	modeForm: TableDataForm
}

export const PhysicalOptions = ({
	onChange,
	editMode,
	onSync,
	unsyncedFields,
	modeForm,
}: Props) => {
	const { t } = useAppContext()

	const handleChange = useCallback(
		(item: Partial<TableData>, source?: FormlessFormField<TableData>) => {
			if (source) {
				const name = source.props.name

				onChange({
					[name]: item[name],
				})
			}
		},
		[onChange],
	)

	return (
		<MainContentWrap>
			<Form<TableData>
				onChange={handleChange}
				defaultValues={modeForm}
				disabled={!editMode}
				enableFieldHighlight
			>
				<SyncFormField
					unsyncedFields={unsyncedFields}
					disabled={!editMode}
					field="script"
					onSync={onSync}
				>
					<AceEditorField
						name="script"
						title={t('TABLE_SCRIPT')}
						initialValue={modeForm.script}
					/>
				</SyncFormField>
				<SyncFormField
					unsyncedFields={unsyncedFields}
					disabled={!editMode}
					field="preTableScript"
					onSync={onSync}
				>
					<AceEditorField
						name="preTableScript"
						title={t('TABLE_PRE_TABLE_SCRIPT')}
						initialValue={modeForm.preTableScript}
					/>
				</SyncFormField>
				<SyncFormField
					unsyncedFields={unsyncedFields}
					disabled={!editMode}
					field="postTableScript"
					onSync={onSync}
				>
					<AceEditorField
						name="postTableScript"
						title={t('TABLE_POST_TABLE_SCRIPT')}
						initialValue={modeForm.postTableScript}
					/>
				</SyncFormField>
			</Form>
		</MainContentWrap>
	)
}
