import React, { useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import {
	Form,
	TextAreaFormField,
	CheckBoxFormField,
	SelectFormField,
	TextFormField,
	NamingInputFormField,
	useGetNamingConfig,
} from '@/components/UberForm'
import NumberFormField from '@/components/UberForm/Helpers/NumberFormField'
import { OpenedReadMappingData } from '@/store/modules/readMapping/types'
import {
	useAppContext,
	useAppDispatch,
	useCustomAttributesInitValues,
} from '@/hooks'
import { ReadMappingData, ReadMappingDataEnums } from '@/endpoints/schemas'
import { booleanString } from '@/utils/booleans'
import { uniqueNameValidator } from '@/utils/validators'
import { useApi } from '@/endpoints/hooks'
import {
	getStereotypes,
	getUsersForCombo,
	getSystemUsers,
	getNodesOfSpecificParent,
} from 'src/endpoints'
import { StereotypeDto, StructureObjectDto } from '@/endpoints/models'
import { FieldSet, Legend, Row, Col, Flex } from '@/components/Layout'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'
import { MainContentWrap } from '@/styles'

interface Props {
	data: OpenedReadMappingData
	editMode: boolean
	systemNodeId: number
	onChange: (v: Partial<ReadMappingData>) => void
	node?: StructureObjectDto
	/** Selected nodes for batch edit */
	selectedNodes?: StructureObjectDto[]
}

export const Overview = ({
	onChange,
	data,
	editMode,
	systemNodeId,
	node,
	selectedNodes,
}: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const refForm = useRef<Form<ReadMappingData>>(null)

	const stereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.MAPPING_READ }),
	)

	const [users, isUserLoading] = useApi(getUsersForCombo())

	const [systemUsers] = useApi(getSystemUsers(systemNodeId))

	// TODO: Enable for batch edit (check if all selected nodes have same parent)
	const nodeChildren = useApi(
		getNodesOfSpecificParent(node?.parentStructureId ?? 1),
	)

	const getRowSetOptions = () => {
		if (nodeChildren.data) {
			return nodeChildren.data?.filter(
				(node) => node.type === StructureObjectDto.TypeEnum.API_COLLECTION,
			)
		}

		return []
	}

	const {
		initialValuesCustomAttributes,
		getCustomAttributesReset,
		parseCustomAttribute,
		customAttributes,
	} = useCustomAttributesInitValues(
		data.form,
		StructureObjectDto.TypeEnum.MAPPING_READ,
	)

	//FIXME - Make sure ordering works in other ways too (drag and drop)
	// if (data?.form?.orderPriority != node?.orderPriority) {
	// 	order mismatch...
	// }

	const readMappingStates = useMemo(
		() => [
			{
				label: t('MAPPING_STATUS_LOGICAL_IN_PROGRESS'),
				value: ReadMappingDataEnums.ReadMappingStatusEnum.LOGICAL_IN_PROGRESS,
			},
			{
				label: t('MAPPING_STATUS_LOGICAL_COMPLETED'),
				value: ReadMappingDataEnums.ReadMappingStatusEnum.LOGICAL_COMPLETED,
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_IN_PROGRESS'),
				value: ReadMappingDataEnums.ReadMappingStatusEnum.PHYSICAL_IN_PROGRESS,
			},
			{
				label: t('MAPPING_STATUS_PHYSICAL_COMPLETED'),
				value: ReadMappingDataEnums.ReadMappingStatusEnum.PHYSICAL_COMPLETED,
			},
			{
				label: t('MAPPING_STATUS_FROZEN'),
				value: ReadMappingDataEnums.ReadMappingStatusEnum.FROZEN,
			},
		],
		[t],
	)

	const handleChange = useCallback(
		(
			item: Partial<ReadMappingData>,
			source?: FormlessFormField<ReadMappingData>,
		) => {
			if (source) {
				const { name } = source.props

				if (name === 'responsiblePersonId' || name === 'responsibleAnalystId') {
					const user = users?.find((u) => u.id === item[name])

					const userName =
						name === 'responsiblePersonId'
							? 'responsiblePersonName'
							: 'responsibleAnalystName'

					onChange({
						[name]: user?.id,
						[userName]: user?.value,
					})

					return
				}

				if (name === 'ownerId') {
					const owner = systemUsers?.find((u) => u.id === item[name])

					onChange({
						ownerId: owner?.id,
						ownerName: owner?.name,
					})

					return
				}

				if (name === 'orderPriority') {
					onChange({
						orderPriority: item[name],
					})

					return
				}

				if (name === 'stereotypeId') {
					const stereotype = stereotypes.data?.find((u) => u.id === item[name])

					const { customAttributesForm, customAttributesRedux } =
						getCustomAttributesReset(stereotype?.id)

					refForm.current?.setValues(customAttributesForm)

					onChange({
						[name]: stereotype?.id,
						customAttributes: customAttributesRedux,
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name],
						},
						data.form.customAttributes,
						customAttributes,
					),
				)
			}
		},
		[
			onChange,
			parseCustomAttribute,
			data.form.customAttributes,
			users,
			systemUsers,
			stereotypes,
			getCustomAttributesReset,
		],
	)

	const namingConfig = useGetNamingConfig(data.form, node)

	return (
		<MainContentWrap>
			<Form<ReadMappingData>
				onChange={handleChange}
				defaultValues={{ ...data.form, ...initialValuesCustomAttributes }}
				disabled={!editMode}
				key={booleanString(editMode)}
				enableFieldHighlight
			>
				<Row $alignItems="flex-end">
					<Col size="medium">
						<TextFormField
							title={t('MAPPING_NAME')}
							name="name"
							initialValue={data.form.name}
							validators={[uniqueNameValidator(dispatch, data.original.name)]}
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<NamingInputFormField
							node={node}
							name="code"
							title={t('MAPPING_CODE')}
							callNamingWorkingDataParams={namingConfig}
							initialValue={data.form.code}
							disabled={Boolean(selectedNodes)}
						/>
					</Col>
					<Col size="medium">
						<SelectFormField
							name="stereotypeId"
							title={t('MAPPING_STEREOTYPE')}
							initialValue={data.form.stereotypeId}
							fieldIsLoading={stereotypes.reloading}
							options={stereotypes.data || []}
							valueKey="id"
							labelKey="name"
							isNumeric
							allowEmpty
						/>
					</Col>
					<Col size="small">
						<Flex>
							<CheckBoxFormField
								name="distinct"
								title={t('MAPPING_DISTINCT')}
								initialValue={data.form.distinct}
							/>
						</Flex>
					</Col>
				</Row>
				<Row>
					<Col size="big">
						<TextAreaFormField
							name="description"
							title={t('MAPPING_DESCRIPTION')}
							initialValue={data.form.description}
						/>
					</Col>
					<Col size="big">
						<TextAreaFormField
							name="comment"
							title={t('MAPPING_COMMENT')}
							initialValue={data.form.comment}
						/>
					</Col>
				</Row>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_PROJECT_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="status"
								title={t('MAPPING_STATUS')}
								initialValue={data.form.status}
								options={readMappingStates}
								valueKey="value"
								labelKey="label"
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsiblePersonId"
								title={t('RESPONSIBLE_PERSON')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsiblePersonId}
								isNumeric
								allowEmpty
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="responsibleAnalystId"
								title={t('RESPONSIBLE_ANALYST')}
								options={users || []}
								fieldIsLoading={isUserLoading}
								valueKey="id"
								labelKey="value"
								initialValue={data.form.responsibleAnalystId}
								isNumeric
								allowEmpty
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<FieldSetGap>
					<Legend>{t('TABLE_GENERAL_TECHNICAL_METADATA')}</Legend>
					<Row>
						<Col size="medium">
							<SelectFormField
								name="ownerId"
								title={t('NODE_OWNER')}
								options={systemUsers || []}
								fieldIsLoading={isUserLoading}
								initialValue={data.form.ownerId}
								valueKey="id"
								labelKey="name"
								isNumeric
								allowEmpty
								clearable
							/>
						</Col>
						<Col size="big">
							<TextAreaFormField
								name="condition"
								title={t('MAPPING_CONDITION')}
								initialValue={data.form.condition}
							/>
						</Col>
						<Col size="medium">
							<SelectFormField
								name="rowsetCode"
								title={t('MAPPING_ROWSET')}
								initialValue={data.form.rowsetCode}
								options={getRowSetOptions() || []}
								valueKey="code"
								labelKey="name"
								disabled={Boolean(selectedNodes)}
								fieldIsLoading={nodeChildren?.loading}
							/>
						</Col>
						<Col size="medium">
							<NumberFormField
								name="orderPriority"
								title={t('ORDER_OF_EXECUTION')}
								initialValue={data.form.orderPriority}
								decimals={2}
							/>
						</Col>
					</Row>
				</FieldSetGap>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</MainContentWrap>
	)
}

const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
