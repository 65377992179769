import { css } from 'styled-components'
import React from 'react'
interface CssErrorProps extends React.InputHTMLAttributes<HTMLInputElement> {
	$error?: string | null
}

export const cssError = css<CssErrorProps>`
	${(props) =>
		!!props.$error &&
		css`
			background: ${props.theme.colors.input.error.background};
			border-color: ${props.theme.colors.input.error.border};
		`}
`
