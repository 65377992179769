import styled from 'styled-components'

export const StyledLabel = styled.label<{
	disabled?: boolean
	checked?: boolean
}>`
	display: grid;
	grid-template-columns: 1rem auto;
	gap: 0.5em;
	max-width: 80%;
	color: ${(props) =>
		props.disabled ? props.theme.mainColors.frozen : 'inherit'};

	input[type='checkbox'] {
		appearance: none;
		background-color: white;
		margin: 0;
		font: inherit;
		color: currentColor;
		width: 1.15rem;
		height: 1.15rem;
		border: 1px solid ${(props) => props.theme.mainColors.primary};
		border-radius: 0.15rem;
		transform: translateY(-0.075rem);
		display: grid;
		place-content: center;

		&::before {
			content: '';
			width: 0.65em;
			height: 0.65em;
			transform: scale(0);
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1em 1em
				${(props) =>
					props.disabled
						? props.theme.mainColors.frozen
						: props.theme.mainColors.primary};
			transform-origin: bottom left;
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
			/* Windows High Contrast Mode */
			background-color: CanvasText;
		}

		&:checked::before {
			transform: scale(1);
		}

		&:disabled {
			color: ${(props) => props.theme.colors.input.disabled};
			cursor: not-allowed;
		}
	}
`
