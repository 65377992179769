import React, { useCallback, useRef, useState } from 'react'
import SimpleBarReact from 'simplebar-react'
import {
	faCaretSquareDown,
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	useAppDispatch,
	useAppStore,
	useDocumentEvent,
	useWindowEvent,
} from '@/hooks'
import { StructureDto } from '@/endpoints/models'
import { Tab } from './Tab'
import { Tooltip } from '@/components'
import { isNotUndefined } from '@/utils/collections'
import { closeTab } from '@/store/modules/tab/actions'
import {
	TabsControl,
	TabsControls,
	TabsNavigationWrap,
	TabsList,
	TabsMenu,
} from '../styles'
import { Position } from '@/enums'

type Props = {
	onConfirm: (nodes: StructureDto[]) => void
}

export const TabNavigation = ({ onConfirm }: Props) => {
	const dispatch = useAppDispatch()
	const nodes = useAppStore((state) => state.node.nodes)
	const openedTabs = useAppStore((state) => state.tab.openedTabs)
	const selectedTab = useAppStore((state) => state.tab.selectedTab)
	const temporaryTab = useAppStore((state) => state.tab.temporaryTab)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const scrollbarRef = useRef<any>(null)
	const scrollingInterval = useRef<ReturnType<typeof setTimeout>>()

	const [menuShown, setMenuShown] = useState(false)

	const handleCloseAll = useCallback(() => {
		handleCloseMultiple(openedTabs)
	}, [openedTabs, nodes])

	const handleCloseOther = useCallback(
		(node: StructureDto) => {
			handleCloseMultiple(openedTabs.filter((tab) => tab.nodeId !== node.id))
		},
		[openedTabs, nodes],
	)

	const handleCloseMultiple = useCallback(
		(tabs: any) => {
			onConfirm(
				tabs
					.filter((tab: any) => tab.editMode)
					.map((tab: any) => nodes[tab.nodeId])
					.filter(isNotUndefined),
			)

			tabs
				.filter((tab: any) => !tab.editMode)
				.forEach((tab: any) => dispatch(closeTab(tab.nodeId)))
		},
		[nodes],
	)

	// Starts button-invoked scrolling
	const startScrolling = useCallback((offset: number) => {
		stopScrolling()

		scrollingInterval.current = setInterval(() => {
			const el: HTMLDivElement = scrollbarRef.current?.getScrollElement()
			el.scrollLeft = el.scrollLeft + offset
		}, 10)
	}, [])

	const stopScrolling = useCallback(() => {
		if (scrollingInterval.current) {
			clearInterval(scrollingInterval.current)
			scrollingInterval.current = undefined
		}
	}, [])

	/*
		Stop button-invoked scrolling when user releases mouse
			- having mouseup on the button won't work when user moves mouse outside of button
	*/
	useWindowEvent('mouseup', () => {
		stopScrolling()
	})

	const handleScrollLeft = useCallback(() => {
		startScrolling(-5)
	}, [])

	const handleScrollRight = useCallback(() => {
		startScrolling(+5)
	}, [])

	// Hide tabs menu when user clicks outside
	useDocumentEvent('click', () => {
		setMenuShown(false)
	})

	return (
		<TabsNavigationWrap>
			<SimpleBarReact ref={scrollbarRef} style={{ overflowY: 'hidden' }}>
				<TabsList role="tablist">
					{openedTabs.map((tab, index) => (
						<Tab
							key={index}
							node={nodes[tab.nodeId]}
							tab={tab}
							isSelected={index === selectedTab}
							isTemporary={index === temporaryTab}
							onConfirm={onConfirm}
							onCloseOther={handleCloseOther}
							onCloseAll={handleCloseAll}
						/>
					))}
				</TabsList>
			</SimpleBarReact>
			<TabsControls>
				<TabsControl onMouseDown={handleScrollLeft}>
					<FontAwesomeIcon icon={faChevronLeft} />
				</TabsControl>
				<TabsControl onMouseDown={handleScrollRight}>
					<FontAwesomeIcon icon={faChevronRight} />
				</TabsControl>
				<TabsControl
					onClick={(e) => {
						e.nativeEvent.stopImmediatePropagation()
						setMenuShown(!menuShown)
					}}
				>
					<Tooltip
						showOnHover={false}
						disableStyle
						shown={menuShown}
						position={Position.BottomLeft}
						content={
							<TabsMenu>
								{openedTabs.map((tab, index) => (
									<Tab
										key={index}
										node={nodes[tab.nodeId]}
										tab={tab}
										isSelected={index === selectedTab}
										isTemporary={index === temporaryTab}
										isVertical
										onConfirm={onConfirm}
										onCloseOther={handleCloseOther}
										onCloseAll={handleCloseAll}
									/>
								))}
							</TabsMenu>
						}
					>
						<FontAwesomeIcon icon={faCaretSquareDown} />
					</Tooltip>
				</TabsControl>
			</TabsControls>
		</TabsNavigationWrap>
	)
}
