import React, { useState } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Root } from './Root'
import keycloak from '@/endpoints/keycloak'

export const Keycloak = () => {
	const [token, setToken] = useState<undefined | string>(undefined)

	return (
		<ReactKeycloakProvider
			initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
			authClient={keycloak}
			onTokens={(tokens) =>
				setToken((prev) => (prev === tokens.token ? prev : tokens.token))
			}
		>
			<Root token={token} />
		</ReactKeycloakProvider>
	)
}
