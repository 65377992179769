import {
	CsvImportDomainDto,
	CsvImportExistingDomainDto,
} from '@/endpoints/models'
import { CheckBoxPure } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { LightTableColumn } from '@/types'

export const useCsvImporterColumns = () => {
	const { t } = useAppContext()

	const columns = useMemo<LightTableColumn<CsvImportDomainDto>[]>(
		() => [
			{
				field: null,
				title: t('EXISTING'),
				expandable: true,
				maxWidth: 100,
				width: 40,
				tdStyle: { justifyContent: 'center' },
				// eslint-disable-next-line react/display-name
				formatter: (_, os) => (
					<CheckboxCompact
						disabled
						name="default"
						value={os.matchedDomain !== null}
					/>
				),
			},
			{
				field: 'domainName',
				title: t('DOMAIN_NAME'),
				width: 50,
				maxWidth: 180,
			},
			{
				field: 'dataType',
				title: t('DOMAIN_DATA_TYPE'),
				width: 50,
				maxWidth: 180,
			},
			{
				field: 'defaultValue',
				title: t('DOMAIN_DEFAULT_VALUE'),
				width: 50,
			},
			{
				field: 'mandatoryFlag',
				title: t('DOMAIN_MANDATORY'),
				maxWidth: 100,
				width: 40,
				tdStyle: { justifyContent: 'center' },
				// eslint-disable-next-line react/display-name
				formatter: (_, os) => (
					<CheckboxCompact disabled name="default" value={os.mandatoryFlag} />
				),
			},
		],
		[t],
	)

	const subColumns = useMemo<LightTableColumn<CsvImportExistingDomainDto>[]>(
		() => [
			{
				field: 'domainName',
				title: t('DOMAIN_NAME'),
				width: 50,
				maxWidth: 180,
			},
			{
				field: 'code',
				title: t('DOMAIN_CODE'),
				maxWidth: 100,
				width: 40,
			},
			{
				field: 'dataType',
				title: t('DOMAIN_DATA_TYPE'),
				width: 50,
				maxWidth: 180,
			},
			{
				field: 'defaultValue',
				title: t('DOMAIN_DEFAULT_VALUE'),
				width: 50,
			},
			{
				field: 'notNullFlag',
				title: t('DOMAIN_MANDATORY'),
				maxWidth: 100,
				width: 40,
				tdStyle: { justifyContent: 'center', display: 'flex' },
				// eslint-disable-next-line react/display-name
				formatter: (_, os) => (
					<CheckboxCompact disabled name="default" value={os.notNullFlag} />
				),
			},
		],
		[t],
	)

	const csvImporterColumns = useMemo(
		() => ({ columns, subColumns }),
		[columns, subColumns],
	)

	return csvImporterColumns
}

export const CheckboxCompact = styled(CheckBoxPure)`
	flex: 0;
	margin: 0;
`
