/**
 * Insert string into another string at specified index range
 * @param source original string
 * @param insert string to be inserted
 * @param indexStart index to start insert
 * @param indexEnd index to end insert
 */
export const replaceAt = (
	source: string,
	insert: string,
	indexStart: number,
	indexEnd?: number
) =>
	`${source.slice(0, indexStart)}${insert}${source.slice(
		indexEnd || indexStart
	)}`
