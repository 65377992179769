import React, { useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Row } from './styles'
import { DragObject, DragProps, DropProps, RowProps } from './types'
import { TableRowContent } from '@/components/Table/TableRowContent'
import { TableSubRowContent } from './TableSubRowContent'
import { useAppDispatch } from '@/hooks'
import { tableRowSelected } from '@/store'

const TABLE_ROW = 'row'

export const TableRow = <Item extends object>({
	actionCellWidth,
	hasLastRowEdit,
	hasLeftAction,
	isEditMode,
	isLastRow,
	lastRowIndex,
	onEdit,
	onDelete,
	onMoveRow,
	row,
}: RowProps<Item>) => {
	const dispatch = useAppDispatch()

	const [dropProps, dropRef] = useDrop<DragObject, unknown, DropProps>({
		accept: TABLE_ROW,
		collect: (monitor) => ({
			$isDragOver: monitor.isOver(),
			$yDiff: monitor.getDifferenceFromInitialOffset()?.y || 0,
			$canDrop: monitor.canDrop(),
		}),
		canDrop: (item) => row.index !== lastRowIndex && item.index !== row.index,
		drop: (item) => {
			onMoveRow && onMoveRow(item.index, row.index)
			item.index = row.index
		},
	})

	const [dragProps, dragRef] = useDrag<DragObject, unknown, DragProps>({
		type: TABLE_ROW,
		item: { index: row.index },
		collect: (monitor) => ({
			$isDragging: monitor.isDragging(),
		}),
	})

	const handleSelect = useCallback((selectedIndex: number) => {
		return dispatch(tableRowSelected(selectedIndex))
	}, [])

	return (
		<>
			<Row
				ref={dropRef}
				onClick={() => handleSelect(row.index)}
				{...dragProps}
				{...dropProps}
			>
				<TableRowContent
					actionCellWidth={actionCellWidth}
					hasLeftAction={hasLeftAction}
					hasLastRowEdit={hasLastRowEdit}
					isEditMode={isEditMode}
					isLastRow={isLastRow}
					onEdit={onEdit}
					onDelete={onDelete}
					row={row}
					dragRef={dragRef}
				/>
			</Row>
			{row.getIsExpanded() && (
				<tr className="expandedRow">
					<TableSubRowContent row={row} />
				</tr>
			)}
		</>
	)
}
