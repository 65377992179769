import { PrePushValidationDto } from '@/endpoints/models'
import React, { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { CheckboxSimpleTable } from '@/pages/User/pages/Settings/pages/TechnicalColumns/Columns'
import { LightTableColumn } from '@/types'
import {CellValue} from "@/pages/User/pages/Settings/styles";

export const useValidationsColumns = () => {
	const { t } = useAppContext()

	return useMemo<LightTableColumn<PrePushValidationDto>[]>(
		() => [
			{
				field: 'path',
				title: t('ELEMENT'),
				width: 140,
			},
			{
				field: 'type',
				title: t('TYPE'),
				width: 50,
			},
			{
				field: 'structureType',
				title: t('ELEMENT_TYPE'),
				width: 50,
			},
			{
				field: 'message',
				title: t('MESSAGE'),
				width: 50,
				formatter: (value, row) => <div className="overflow-hidden" title={`${value}`}>
					<CellValue>{value}</CellValue>
				</div>
			},
			{
				field: 'allowedIgnore',
				title: t('ALLOWED_IGNORE'),
				width: 50,
				formatter: (_, row) => (
					<CheckboxSimpleTable
						name="allowedIgnore"
						disabled
						initialValue={row.allowedIgnore ?? false}
					/>
				),
			},
			{
				field: 'editableByActualUser',
				title: t('EDITABLE_BY_ACTUAL_USER'),
				width: 50,
				formatter: (_, row) => (
					<CheckboxSimpleTable
						name="editableByActualUser"
						disabled
						initialValue={row.allowedIgnore ?? false}
					/>
				),
			},
		],
		[t],
	)
}
