import { TemplateListDto } from '@/endpoints/models'
import { hasObjectAllValues } from '@/utils/collections'
import omit from 'lodash/omit'
import { useMemo } from 'react'
import Fuse from 'fuse.js'
import { TemplateFilterValues } from '../components/types'

export const useTemplatesFilteredData = (
	filter: TemplateFilterValues,
	data: TemplateListDto[] = [],
) => {
	const filteredData = useMemo(() => {
		let dataValues = data.filter((item) => {
			const valuesEqual = hasObjectAllValues(item, omit(filter, 'authorName'))

			return valuesEqual
		})

		if (filter.authorName) {
			const options = {
				keys: ['authorName'],
			}

			const fuse = new Fuse(dataValues, options)

			const search = fuse.search(filter.authorName)

			dataValues = search.map((fuseItem) => fuseItem.item)
		}

		return dataValues
	}, [data, filter])

	return filteredData
}
