import { DomainDto } from '@/endpoints/models'
import { SystemMapping } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { useMemo } from 'react'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const useConstantsProperties = (
	domains: DomainDto[],
	canGenerateNaming: boolean,
) => {
	const { t } = useAppContext()

	const properties = useMemo<TableColumnProps<SystemMapping>[]>(
		() => [
			{
				field: 'name',
				label: t('FIELD_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('FIELD_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				autoWidth: true,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'domainId',
				label: t('FIELD_DOMAIN'),
				type: ItemPropertyType.OPTION,
				options: domains,
				optionValue: 'id',
				optionLabel: 'name',
				isNumeric: true,
				width: 200,
			},
			{
				field: 'dataType',
				label: t('DOMAIN_DATA_TYPE'),
				type: ItemPropertyType.TEXT,
				disabled: true,
				width: 150,
			},
			{
				field: 'value',
				label: t('FIELD_SCRIPT'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
			{
				field: 'description',
				label: t('FIELD_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
		],
		[canGenerateNaming, domains, t],
	)

	return properties
}
