import { createApi } from '@reduxjs/toolkit/query/react'

import { HistoryDto, TemplateDto } from '@/endpoints/models'
import { baseQueryWithAuth } from '@/rtkQueries/utils'

interface HistoryQueryParams {
	structureId: number
	version?: number
}

export const historyApiSlice = createApi({
	reducerPath: 'historyApiSlice',
	baseQuery: baseQueryWithAuth,
	endpoints: (builder) => ({
		getVersion: builder.query<TemplateDto, HistoryQueryParams>({
			query: ({ structureId, version }) =>
				`structure/${structureId}/history/version/${version}`,
		}),
		getHistoryList: builder.query<HistoryDto[], HistoryQueryParams>({
			query: ({ structureId }) => `structure/${structureId}/history`,
		}),
	}),
})

export const { useGetHistoryListQuery, useGetVersionQuery } = historyApiSlice
