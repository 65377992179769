import { useAppStore } from '@/hooks'
import { isEqual, isNil } from 'lodash'
import { useMemo } from 'react'

export const useIsFieldHighlighted = (fieldName: string, value: any) => {
	const { active, prevVersionValues } = useAppStore(
		(state) => state.formHighlights,
	)

	const isFieldHighlighted = useMemo(() => {
		if (!active) {
			return false
		}

		const originalValue = prevVersionValues?.[fieldName]

		if (isNil(originalValue) && isNil(value)) {
			return false
		}

		if (!isEqual(originalValue, value)) {
			return true
		}

		return false
	}, [active, fieldName, prevVersionValues, value])

	return isFieldHighlighted
}
