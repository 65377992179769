export enum HeadlineBitType {
	Text,
	Match,
	NewLine,
}

export type HeadlineMatchBit = {
	type: HeadlineBitType.Match
	text: string
}

export type HeadlineBit =
	| HeadlineMatchBit
	| {
			type: HeadlineBitType.Text
			text: string
	  }
	| {
			type: HeadlineBitType.NewLine
	  }

enum HeadlineTokenType {
	Text,
	MatchStart,
	MatchEnd,
	NewLine,
}

type HeadlineToken = {
	type: HeadlineTokenType
	contents: string
	position: number
}

const MATCH_START = '<b>'
const MATCH_END = '</b>'
const NEWLINE = '\n'

const tokenizeHeadline = (headline: string) => {
	const tokens = [] as HeadlineToken[]

	let pos = 0
	let buffer = undefined as undefined | HeadlineToken

	const pushToken = (token: HeadlineToken) => {
		if (buffer !== undefined) {
			buffer = undefined
		}

		tokens.push(token)
	}

	while (pos < headline.length) {
		// TODO: Use keyword array?
		if (headline.substring(pos, MATCH_START.length) === MATCH_START) {
			pushToken({
				type: HeadlineTokenType.MatchStart,
				position: pos,
				contents: MATCH_START,
			})

			pos += MATCH_START.length
		} else if (headline.substring(pos, MATCH_END.length) === MATCH_END) {
			pushToken({
				type: HeadlineTokenType.MatchEnd,
				position: pos,
				contents: MATCH_END,
			})

			pos += MATCH_END.length
		} else if (headline.substring(pos, NEWLINE.length) === NEWLINE) {
			pushToken({
				type: HeadlineTokenType.NewLine,
				position: pos,
				contents: NEWLINE,
			})

			pos += NEWLINE.length
		} else {
			if (buffer === undefined) {
				buffer = {
					type: HeadlineTokenType.Text,
					contents: '',
					position: pos,
				}

				tokens.push(buffer)
			}

			// TODO: This optimization is hardcoded
			const nextTokenPos = Math.min(
				...[headline.indexOf('<', pos), headline.indexOf('\n', pos)].filter(
					(i) => i > -1,
				),
			)

			if (!isNaN(nextTokenPos) && nextTokenPos > pos) {
				buffer.contents = headline.substring(pos, nextTokenPos)
				pos = nextTokenPos
			} else {
				buffer.contents = headline.substring(pos, headline.length)
				pos = headline.length
			}
		}
	}

	return tokens
}

export const parseHeadline = (headline: string) => {
	const tokens = tokenizeHeadline(headline)
	const bits = [] as HeadlineBit[]

	let match = undefined as HeadlineMatchBit | undefined

	for (const token of tokens) {
		switch (token.type) {
			case HeadlineTokenType.MatchStart: {
				if (match) {
					throw new Error(
						`At ${token.position}: Unexpected ${token.contents}: Matches cannot be nested`,
					)
				}

				match = {
					type: HeadlineBitType.Match,
					text: '',
				}

				bits.push(match)

				break
			}

			case HeadlineTokenType.MatchEnd: {
				if (!match) {
					throw new Error(
						`At ${token.position}: Unexpected ${token.contents}: Match was not started`,
					)
				}

				match = undefined
				break
			}

			case HeadlineTokenType.Text: {
				if (match) {
					match.text += token.contents
				} else {
					bits.push({
						type: HeadlineBitType.Text,
						text: token.contents,
					})
				}

				break
			}

			case HeadlineTokenType.NewLine: {
				if (match) {
					// TODO: This is not an ideal solution
					match.text += token.contents
				} else {
					bits.push({ type: HeadlineBitType.NewLine })
				}

				break
			}
		}
	}

	return bits
}
