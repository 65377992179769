import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'

import Button from '@/components/Button/Button'
import { Loader } from '@/components/Loader/Loader'
import { Modal } from '@/components/Modal/Modal'
import { useTabContext } from '@/context/TabContext/TabContext'
import { useAppContext } from '@/hooks'

import { DpStatusModalProps } from '../types'

export const DpStatusModal = ({
	node,
	onConfirm,
	onClose,
	type,
}: DpStatusModalProps) => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const [loading, setLoading] = useState(false)

	const handleConfirm = async () => {
		setLoading(true)

		try {
			await onConfirm(node)
		} catch (e) {
			onSaveError(e)
		}

		setLoading(false)
		onClose()
	}

	const customFooter = (
		close:
			| ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
			| undefined,
	) => (
		<>
			<Button
				icon={faTrash}
				schema="danger"
				onClick={handleConfirm}
				isLoading={loading}
			>
				{t('YES')}
			</Button>
			<Button schema="transparent" onClick={close} icon={faTimes}>
				{t('NO')}
			</Button>
		</>
	)

	return (
		<Modal
			open={true}
			header={t('STATUS_MODAL_HEADER', [node.name])}
			footer={customFooter}
			onClose={onClose}
		>
			<div>
				<Loader loaded={!loading} $absolute />

				<p className="pb-4">
					{t('STATUS_MODAL_MESSAGE_1')}
					<span className="text-amber-500">{type}</span>
				</p>
				<p className="leading-normal">{t('STATUS_MODAL_MESSAGE_2')}</p>
			</div>
		</Modal>
	)
}
