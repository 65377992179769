/* eslint-disable @typescript-eslint/no-namespace */
/**
 * This file is autogenerated by swagger script. Do not modify manually!
 */

export interface AcronymCreateDto {
	acronym: string
	text: string
}
export interface AcronymDto {
	id?: number // int64
	acronym: string
	text: string
	deleted?: boolean
	version?: number // int32
	createdBy?: string
	createdAt?: string // date-time
	updatedBy?: string
	updatedAt?: string // date-time
}
export interface AcronymUpdateDto {
	acronym: string
	text: string
}
export interface AdjustResultDto {
	result: string
}
export interface ApiNodeColumnDto {
	columnName?: string
	columnCode?: string
	columnUuid?: string // uuid
	sourceType?: ApiNodeColumnDto.SourceTypeEnum
	sourceStructureId?: number // int64
	sourceStructureCode?: string
}
export interface AutocompleteResultDto {
	name?: string
	code?: string
}
export interface BusinessDomainComboDto {
	id?: number // int64
	name: string
	description?: string
	companyName: string
}
export interface BusinessDomainDto {
	id?: number // int64
	name: string
	description?: string
	companyName: string
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	version?: number // int32
	deleted?: boolean
}
export interface CheckResultDto {
	result: boolean
	errors?: string[]
}
export interface Company {
	id?: number // int64
	name: string
	users?: User[]
}
export interface CsvImportColumnDto {
	name?: string
	code?: string
	comment?: string
	description?: string
	domainCode?: string
	domainId?: number // int64
	stereotypeCode?: string
	stereoTypeId?: number // int64
	dataType?: string
	defaultValue?: string
	notNullFlag?: boolean
	primaryKeyName?: string
	indexName?: string
}
export interface CsvImportDomainDto {
	domainName?: string
	dataType?: string
	defaultValue?: string
	mandatoryFlag?: boolean
	matchedDomain?: CsvImportExistingDomainDto
	similarDomain?: CsvImportExistingDomainDto[]
}
export interface CsvImportExistingDomainDto {
	id?: number // int64
	domainName?: string
	dataType?: string
	defaultValue?: string
	code?: string
	customFlag?: boolean
	notNullFlag?: boolean
}
export interface CsvImportReturnDto {
	domainDtoList?: CsvImportDomainDto[]
	tableDtos?: CsvImportTableDto[]
	technicalColumnMap?: {
		[name: string]: TechnicalColumnStereotypeDto[]
	}
	errors?: ImportReturnMessage[]
	warnings?: ImportReturnMessage[]
}
export interface CsvImportRowDto {
	column: ImportColumn
	table: ImportTable
}
export interface CsvImportTableDto {
	name: string
	code?: string
	comment?: string
	description?: string
	stereotypeCode?: string
	script?: string
	preScript?: string
	postScript?: string
	stereotypeId?: number // int64
	columnDtoList?: CsvImportColumnDto[]
}
export interface CustomAttribute {
	id?: string
	name?: string
	version?: string
	source?: string
	value?: {}
}
export interface CustomAttributeComboDto {
	id?: number // int64
	name: string
	description?: string
	type: CustomAttributeComboDto.TypeEnum
	regex?: string
	lovValues?: CustomLovValueComboDto[]
	structureTypes?: CustomAttributeComboDto.StructureTypesEnum[]
	stereotypeIds?: number /* int64 */[]
	version?: number // int32
	ordering?: number // int32
}
export interface CustomAttributeCreateDto {
	id?: number // int64
	name: string
	description?: string
	type: CustomAttributeCreateDto.TypeEnum
	regex?: string
	customLovId?: number // int64
	structureTypes?: CustomAttributeCreateDto.StructureTypesEnum[]
	stereotypeIds?: number /* int64 */[]
	version?: number // int32
	ordering?: number // int32
}
export interface CustomAttributeDto {
	id?: number // int64
	name: string
	description?: string
	type: CustomAttributeDto.TypeEnum
	regex?: string
	customLovId?: number // int64
	structureTypes?: CustomAttributeDto.StructureTypesEnum[]
	stereotypeIds?: number /* int64 */[]
	version?: number // int32
	ordering?: number // int32
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	deleted?: boolean
}
export interface CustomAttributeUpdateDto {
	id?: number // int64
	name: string
	description?: string
	type: CustomAttributeUpdateDto.TypeEnum
	regex?: string
	customLovId?: number // int64
	structureTypes?: CustomAttributeUpdateDto.StructureTypesEnum[]
	stereotypeIds?: number /* int64 */[]
	version?: number // int32
	ordering?: number // int32
}
export interface CustomLovComboDto {
	id?: number // int64
	name: string
	description?: string
	lovValues?: CustomLovValueComboDto[]
	version?: number // int32
}
export interface CustomLovCreateDto {
	id?: number // int64
	name: string
	description?: string
	lovValues?: CustomLovValueComboDto[]
	version?: number // int32
}
export interface CustomLovDto {
	id?: number // int64
	name: string
	description?: string
	lovValues?: CustomLovValueComboDto[]
	version?: number // int32
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	deleted?: boolean
}
export interface CustomLovUpdateDto {
	id?: number // int64
	name: string
	description?: string
	lovValues?: CustomLovValueComboDto[]
	version?: number // int32
}
export interface CustomLovValueComboDto {
	value?: string
	label?: string
	ordering?: number // int32
}
export interface DeploymentDetailDto {
	id: number // int64
	templateId: number // int64
	structureHistoryId: number // int64
	structureId: number // int64
	environmentId: number // int64
	sqlType: DeploymentDetailDto.SqlTypeEnum
	version: number // int64
	description: string
	authorId: number // int64
	authorName: string
	created: string // date-time
	data: string
}
export interface DeploymentDto {
	id: number // int64
	templateId: number // int64
	structureHistoryId: number // int64
	structureId: number // int64
	environmentId: number // int64
	sqlType: DeploymentDto.SqlTypeEnum
	version: number // int64
	description: string
	authorId: number // int64
	authorName: string
	created: string // date-time
}
export interface DeploymentInfo {
	id?: string
	objectTypeCode?: string
	deployedHistoryId?: number // int64
	deployedVersion?: number // int32
	pushedHistoryId?: number // int64
	pushedVersion?: number // int32
}
export interface DeploymentNewDto {
	description: string
	data: string
	templateId: number // int64
	structureHistoryId: number // int64
	sqlType?: DeploymentNewDto.SqlTypeEnum
}
export interface DomainComboDto {
	id: number // int64
	name: string
	code: string
	description?: string
	length?: string
	companyName: string
	custom: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	notNullFlag?: boolean
	textValueFlag?: boolean
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	version?: number // int32
	deleted?: boolean
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate: boolean
}
export interface DomainCreateDto {
	name: string
	code: string
	description?: string
	length?: string
	companyName: string
	custom: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	notNullFlag?: boolean
	textValueFlag?: boolean
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate: boolean
}
export interface DomainDto {
	id: number // int64
	name: string
	code: string
	description?: string
	length?: string
	companyName: string
	custom: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	notNullFlag?: boolean
	textValueFlag?: boolean
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	version?: number // int32
	deleted?: boolean
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate: boolean
}
export interface DomainTechnologyDto {
	id: number // int64
	dataType: string
	technologyCode: string
	technologyId?: number // int64
	defaultValue?: string
}
export interface DomainUpdateDto {
	id: number // int64
	name: string
	code: string
	description?: string
	length?: string
	companyName: string
	custom: boolean
	template?: string
	templateValueFormat?: string
	templateValueRegex?: string
	notNullFlag?: boolean
	textValueFlag?: boolean
	domainTechnologies?: DomainTechnologyDto[]
	hasTemplate: boolean
}
export interface EditUserInfoDto {
	email: string
	loginName: string
	name: string
	surname: string
	role?: EditUserInfoDto.RoleEnum
	department?: string
	description?: string
}
export interface EditUserPasswordDto {
	password: string
	oldPassword: string
}
export interface EnvInfo {
	envCode?: string
	frontendId?: number // int32
	deploymentInfo?: DeploymentInfo[]
}
export interface EnvironmentDeploymentDto {
	id: number // int64
	frontendId: number // int32
	name: string
	code: string
	description?: string
	connection?: string
	gitUrl?: string
	gitLoginName?: string
	gitPasswordToken?: string
	directDeployment?: boolean
	rememberPassword?: boolean
	production?: boolean
	lastRelease?: ReleaseDto
	key?: number // int64
	objectType?: ObjectSettingsDto
	lastDeploymentAuthorId?: number // int64
	lastDeploymentAuthorCompositeName?: string
	lastDeploymentStructureHistoryVersion?: number // int64
	lastDeploymentStructureHistoryId?: number // int64
}
export interface EnvironmentDto {
	id: number // int64
	frontendId: number // int32
	name: string
	code: string
	description?: string
	connection?: string
	gitUrl?: string
	gitLoginName?: string
	gitPasswordToken?: string
	directDeployment?: boolean
	rememberPassword?: boolean
	production?: boolean
	lastRelease?: ReleaseDto
}
export interface FullTextSearchDto {
	id?: number // int64
	name?: string
	code?: string
	rank?: number
	type?: FullTextSearchDto.TypeEnum
	created?: string // date-time
	headline?: string
}
export interface GeneratedSqlDto {
	sql: string
	templateId: number // int64
	sqlType:
		| 'TABLE_HISTORY'
		| 'TABLE_REFERENCE'
		| 'TABLE'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
	structureHistoryId: number // int64
	structurePath?: string
	templateDataMap?: {
		[name: string]: {}
	}
	fileName?: string
	errors?: string[]
	objectSettingsCode?: string
	formatType?: GeneratedSqlDto.FormatTypeEnum
}
export interface HistoryDetailDto {
	id: number // int64
	version: number // int64
	description?: string
	structureId: number // int64
	authorId: number // int64
	authorName: string
	created: string // date-time
	data: string
	name?: string
	code?: string
	deploymentDto?: DeploymentDto
}
export interface HistoryDto {
	id: number // int64
	version: number // int64
	description?: string
	structureId: number // int64
	authorId: number // int64
	authorName: string
	created: string // date-time
}
export interface HistoryTable {
	uuid?: string // uuid
	name?: string
	code?: string
	comment?: string
	description?: string
	stereotypeId?: number // int64
	stereotypeCode?: string
	subStereotypeId?: number // int64
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	businessDomainId?: number // int64
	businessDomainName?: string
	entityType?: HistoryTable.EntityTypeEnum
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	responsibleAnalystId?: number // int64
	responsibleAnalystName?: string
	responsiblePersonId?: number // int64
	responsiblePersonName?: string
	retentionStrategy?: HistoryTable.RetentionStrategyEnum
	retentionStrategyFullHistoryValue?: number // int64
	retentionStrategyFullHistoryUnit?: HistoryTable.RetentionStrategyFullHistoryUnitEnum
	retentionStrategyEomValue?: number // int64
	retentionStrategyEomUnit?: HistoryTable.RetentionStrategyEomUnitEnum
	columnsLastId?: number // int64
	columns?: TableColumn[]
	indexesLastId?: number // int64
	indexes?: TableIndex[]
	constraintsLastId?: number // int64
	constraints?: TableConstraint[]
	g_templateVersion?: number // int64
	g_templateStereotype?: string
	partitioningStrategy?: HistoryTable.PartitioningStrategyEnum
	/**
	 * If any of the key partitioning strategies is chosen then a id of key column must be provided.
	 */
	partitioningColumnId?: number // int64
	partitioningColumnCode?: string
	/**
	 * Sequence related to the table (only 1 is allowed)
	 */
	sequenceCode?: string
	/**
	 * Column used with the Sequence
	 */
	sequenceColumnCode?: string
	nonSyncFields?: HistoryTable.NonSyncFieldsEnum[]
	g_isHistoryTable?: boolean
	g_partitioningColumnCode?: string
	ownerId?: number // int64
	script?: string
	preTableScript?: string
	postTableScript?: string
	ownerName?: string
	objectSettings?: ObjectSettings[]
	originalTable?: Parent
	objectPermissions?: TablePermission[]
	objectPermissionLastId?: number // int64
	customAttributes?: CustomAttribute[]
}
export interface ImpactChangeCodeListDto {
	affectedElements?: {
		[name: string]: number // int64
	}
}
export interface ImpactedColumnDto {
	tableId?: number // int64
	tableName?: string
	tableCode?: string
	columnName?: string
	columnCode?: string
	columnUuid?: string // uuid
}
export interface ImportColumn {
	name: string
	code?: string
	comment?: string
	description?: string
	stereotypeCode?: string
	domainName?: string
	dataType?: string
	defaultValue?: string
	primaryKeyName?: string
	indexName?: string
	mandatoryFlag?: boolean
}
export interface ImportReturnMessage {
	messageType?: ImportReturnMessage.MessageTypeEnum
	object?: string
}
export interface ImportTable {
	name: string
	code?: string
	comment?: string
	description?: string
	stereotypeCode?: string
	script?: string
	preScript?: string
	postScript?: string
}
export interface JsonPatch {}
export interface MassExportConfigDto {
	massExportStrategy?: MassExportConfigDto.MassExportStrategyEnum
	massExportSorting?:
		| 'DEFAULT'
		| 'SORT_BY_NAME_ASC'
		| 'SORT_BY_TYPE_AND_NAME_ASC'
	structureObjectPairs?: StructureObjectPair[]
	structureStatus?: MassExportConfigDto.StructureStatusEnum[]
	structureIds?: number /* int64 */[]
	environmentId?: number // int64
}
export interface MassUpdateDto {
	structureIds?: number /* int64 */[]
	commitMessage?: string
	stereotypeType?: MassUpdateDto.StereotypeTypeEnum
	patch?: JsonPatch
}
export interface MddError {
	errorCode?: string
	errorDescription?: string
	errorMessage?: string
}
export interface NamingDto {
	type?:
		| 'TABLE_CODE'
		| 'MAPPING_CODE'
		| 'INTERFACE_CODE'
		| 'VIEW_CODE'
		| 'API_NODE_CODE'
		| 'API_COLLECTION_CODE'
		| 'FOLDER_CODE'
		| 'QUEUE_CODE'
		| 'SYSTEM_CODE'
		| 'WORKFLOW_CODE'
		| 'HISTORICAL_TABLE_NAME'
		| 'REFERENCE_TABLE_NAME'
		| 'HISTORICAL_TABLE_CODE'
		| 'REFERENCE_TABLE_CODE'
		| 'HISTORICAL_DEFAULT_MAPPING_NAME'
		| 'REFERENCE_DEFAULT_MAPPING_NAME'
		| 'INTERFACE_DEFAULT_MAPPING_NAME'
		| 'HISTORICAL_DEFAULT_MAPPING_CODE'
		| 'REFERENCE_DEFAULT_MAPPING_CODE'
		| 'INTERFACE_DEFAULT_MAPPING_CODE'
		| 'DEFAULT_MAPPING_NAME'
		| 'DEFAULT_MAPPING_CODE'
		| 'DDL_SCRIPT_NAME'
		| 'EXPORT_NAME'
		| 'PRIMARY_KEY_NAME'
		| 'SEQUENCE_NAME'
	template?: string
	defaultTemplate?: string
	companyName?: string
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	version?: number // int32
	deleted?: boolean
}
export interface NewHistoryDto {
	description: string
}
export interface NewStructureDto {
	type: NewStructureDto.TypeEnum
	data: string
}
export interface NewStructureUserPermissionBatchDto {
	permissionCodes: (
		| 'NO_ACCESS'
		| 'READ_ACCESS'
		| 'FULL_ACCESS'
		| 'MANAGE_USERS'
		| 'MANAGE_LOV'
		| 'CREATE_DELETE_SYSTEM'
		| 'FORCE_UNLOCK'
	)[]
	userId: number // int64
}
export interface NewStructureUserPermissionDto {
	permissionCode: NewStructureUserPermissionDto.PermissionCodeEnum
}
export interface NewUserDto {
	email: string
	loginName: string
	name?: string
	surname?: string
	password: string
	department?: string
	description?: string
}
export interface Number {}
export interface ObjectSettings {
	id?: number // int64
	name?: string
	code?: string
}
export interface ObjectSettingsComboDto {
	id: number // int64
	name: string
	code: string
	comment?: string
	description?: string
	structureType?: ObjectSettingsComboDto.StructureTypeEnum
	defaultFlag?: boolean
	deleted?: boolean
	version?: number // int32
	createdBy?: string
	createdAt?: string // date-time
	updatedBy?: string
	updatedAt?: string // date-time
}
export interface ObjectSettingsCreateDto {
	id: number // int64
	name: string
	code: string
	comment?: string
	description?: string
	structureType?: ObjectSettingsCreateDto.StructureTypeEnum
}
export interface ObjectSettingsDto {
	id: number // int64
	name: string
	code: string
	comment?: string
	description?: string
	structureType?: ObjectSettingsUpdateDto.StructureTypeEnum
	defaultFlag?: boolean
	deleted?: boolean
	version?: number // int32
	createdBy?: string
	createdAt?: string // date-time
	updatedBy?: string
	updatedAt?: string // date-time
}
export interface ObjectSettingsUpdateDto {
	id: number // int64
	name: string
	code: string
	comment?: string
	description?: string
	structureType?: PrePushValidationDto.StructureTypeEnum
}
export interface Page {
	totalPages?: number // int32
	totalElements?: number // int64
	first?: boolean
	last?: boolean
	size?: number // int32
	content?: {}[]
	number?: number // int32
	sort?: Sort
	numberOfElements?: number // int32
	pageable?: Pageable
	empty?: boolean
}
export interface PageStructureReleaseInfoDto {
	totalPages?: number // int32
	totalElements?: number // int64
	first?: boolean
	last?: boolean
	size?: number // int32
	content?: StructureReleaseInfoDto[]
	number?: number // int32
	sort?: Sort
	numberOfElements?: number // int32
	pageable?: Pageable
	empty?: boolean
}
export interface Pageable {
	offset?: number // int64
	sort?: Sort
	paged?: boolean
	unpaged?: boolean
	pageNumber?: number // int32
	pageSize?: number // int32
}
export interface PageableDto {
	page?: number // int32
	size?: number // int32
	totalPages?: number // int32
	totalElements?: number // int64
	acronyms?: {}[]
}
export interface PageableDtoAcronymDto {
	page?: number // int32
	size?: number // int32
	totalPages?: number // int32
	totalElements?: number // int64
	acronyms?: AcronymDto[]
}
export interface Parent {
	name?: string
	code?: string
}
export interface Permission {
	code: Permission.CodeEnum
	name: string
	description?: string
	permissionGroup: 'NONE' | 'NODE_ACCESS' | 'SINGLE'
	type: Permission.TypeEnum
}
export interface PrePushValidationDto {
	allowedIgnore?: boolean
	message?: string
	path?: string
	structureId?: number // int64
	editableByActualUser?: boolean
	structureType?: TemplateDto.StructureTypeEnum
	type?: 'BROKEN_LINK' | 'BROKEN_FK' | 'MISSING_REQ_FIELD'
}
export interface ReferenceTable {
	uuid?: string // uuid
	name?: string
	code?: string
	comment?: string
	description?: string
	stereotypeId?: number // int64
	stereotypeCode?: string
	subStereotypeId?: number // int64
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	businessDomainId?: number // int64
	businessDomainName?: string
	entityType?: ReferenceTable.EntityTypeEnum
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	responsibleAnalystId?: number // int64
	responsibleAnalystName?: string
	responsiblePersonId?: number // int64
	responsiblePersonName?: string
	retentionStrategy?: ReferenceTable.RetentionStrategyEnum
	retentionStrategyFullHistoryValue?: number // int64
	retentionStrategyFullHistoryUnit?: ReferenceTable.RetentionStrategyFullHistoryUnitEnum
	retentionStrategyEomValue?: number // int64
	retentionStrategyEomUnit?: ReferenceTable.RetentionStrategyEomUnitEnum
	columnsLastId?: number // int64
	columns?: TableColumn[]
	indexesLastId?: number // int64
	indexes?: TableIndex[]
	constraintsLastId?: number // int64
	constraints?: TableConstraint[]
	g_templateVersion?: number // int64
	g_templateStereotype?: string
	partitioningStrategy?: ReferenceTable.PartitioningStrategyEnum
	/**
	 * If any of the key partitioning strategies is chosen then a id of key column must be provided.
	 */
	partitioningColumnId?: number // int64
	partitioningColumnCode?: string
	/**
	 * Sequence related to the table (only 1 is allowed)
	 */
	sequenceCode?: string
	/**
	 * Column used with the Sequence
	 */
	sequenceColumnCode?: string
	nonSyncFields?: ReferenceTable.NonSyncFieldsEnum[]
	g_isReferenceTable?: boolean
	g_partitioningColumnCode?: string
	ownerId?: number // int64
	ownerName?: string
	script?: string
	preTableScript?: string
	postTableScript?: string
	objectSettings?: ObjectSettings[]
	originalTable?: Parent
	objectPermissions?: TablePermission[]
	objectPermissionLastId?: number // int64
	customAttributes?: CustomAttribute[]
}
export interface RelationColumnDto {
	uuid?: string // uuid
	code?: string
}
export interface RelationCreateDto {
	rightSideStructure?: RelationStructureDto
	name?: string
	code?: string
	description?: string
}
export interface RelationDto {
	id?: number // int64
	relationType?:
		| 'PK_FK_TABLE'
		| 'PK_FK_COLUMN'
		| 'MAPPING_WRITE'
		| 'LINK'
		| 'LINK_COLUMN'
		| 'CUSTOM_LINK'
	leftSideStructure?: RelationStructureDto
	rightSideStructure?: RelationStructureDto
	leftSideColumn?: RelationColumnDto
	rightSideColumn?: RelationColumnDto
	name?: string
	code?: string
	description?: string
	parentRelationId?: number // int64
	hasChildren?: boolean
	children?: RelationDto[]
	status?: RelationDto.StatusEnum
}
export interface RelationStructureDto {
	id?: number // int64
	code?: string
	name?: string
	type?: RelationStructureDto.TypeEnum
	fullPath?: string
}
export interface ReleaseDto {
	id?: number // int64
	description?: string
	name?: string
	status?: 'OPEN' | 'CANCEL' | 'COMPLETED'
	responsibleUserId?: number // int64
	responsibleUser?: UserDto
}
export interface ReleaseEnvironmentDto {
	id?: number // int32
	code?: string
	name?: string
	releaseDate?: string // date
	releasedTime?: string // date-time
	released?: boolean
}
export interface ReorderStructureDto {
	enterBeforeStructureId?: number // int64
	targetParentStructureId?: number // int64
	reorderStrategy?: ReorderStructureDto.ReorderStrategyEnum
}
export interface ResetPasswordDto {
	username: string
}
export interface SessionContextDto {
	context?: string
	user?: UserDto
}
export interface Sort {
	empty?: boolean
	sorted?: boolean
	unsorted?: boolean
}
export interface StereotypeCategoryDto {
	code: string
}
export interface StereotypeCreateDto {
	name: string
	code: string
	type: StereotypeCreateDto.TypeEnum
	category: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	description?: string
	hasSubtypes: boolean
	children?: StereotypeCreateDto[]
}
export interface StereotypeDto {
	id: number // int64
	name: string
	code: string
	type: StereotypeDto.TypeEnum
	category: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	description?: string
	internal?: boolean
	hasSubtypes: boolean
	children?: StereotypeDto[]
}
export interface StereotypeTypeDto {
	code: string
}
export interface StereotypeUpdateDto {
	id: number // int64
	name: string
	code: string
	type: StereotypeUpdateDto.TypeEnum
	category: 'BEHAVIORAL' | 'DEFAULT' | 'USER_DEFINED'
	description?: string
	internal?: boolean
	hasSubtypes: boolean
	children?: StereotypeUpdateDto[]
}
export interface StructureAudit {
	id?: number // int64
	entityId: number // int64
	entityType: StructureAudit.EntityTypeEnum
	operationType: StructureAudit.OperationTypeEnum
	description: string
	created: string // date-time
	author: User
	relatedPerson?: User
	plainDataBefore?: string
	plainDataAfter?: string
}
export interface StructureDataDto {
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	data?: string
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	workingData?: string
}
export interface StructureDetailDto {
	id: number // int64
	type: StructureDetailDto.TypeEnum
	name: string
	code: string
	uuid?: string // uuid
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	data?: string
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	workingData?: string
	parentStructureId?: number // int64
	hasChildren: boolean
	/**
	 * Required for TABLE/MAPPING types.
	 */
	stereotypeName?: string
	/**
	 * Required for TABLE/MAPPING types.
	 */
	stereotypeId?: number // int64
	/**
	 * Required for MAPPING type and optional for Table.
	 */
	subStereotypeName?: string
	/**
	 * Required for MAPPING type and optional for Table.
	 */
	subStereotypeId?: number // int64
	/**
	 * Required for SYSTEM type.
	 */
	systemTechnologyName?: string
	/**
	 * Required for SYSTEM type.
	 */
	systemTechnologyId?: number // int64
	/**
	 * Required for SYSTEM type.
	 */
	workflowTechnologyName?: string
	/**
	 * Required for SYSTEM type.
	 */
	workflowTechnologyId?: number // int64
	companyId: number // int64
	authorId?: number // int64
	authorName?: string
	lockUserId?: number // int64
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	lockUserName?: string
	/**
	 * Required for SYSTEM/TABLE/MAPPING/WORKFLOW types.
	 */
	lockTime?: string // date-time
	canEdit: boolean
	orderPriority?: number // int64
	status?: string
	usersPermissions?: StructureUserPermissionDto[]
}
export interface StructureDetailExtDto {
	id?: number // int64
	parentId?: number // int64
	name?: string
	code?: string
	uuid?: string // uuid
	type?: StructureDetailExtDto.TypeEnum
	data?: string
	lastUpdatedAt?: string // date-time
}
export interface StructureDto {
	id: number // int64
	type: StructureSearchedDto.TypeEnum
	name: string
	code: string
	uuid?: string // uuid
	parentStructureId?: number // int64
	hasChildren: boolean
	companyId: number // int64
	authorId?: number // int64
	authorName?: string
	lockUserId?: number // int64
	lockUserName?: string
	lockTime?: string // date-time
	fullPath?: string
	canEdit: boolean
	orderPriority?: number // int64
	description?: string
	status?: string
}
export interface StructureExport {
	exportFileName?: string
	content?: StructureExportDto
}
export interface StructureExportDto {
	code?: string
	name?: string
	type?: StructureExportDto.TypeEnum
	actualData?: string
	children?: StructureExportDto[]
}
export interface StructureInfo {
	name?: string
	id?: number // int64
	type?: StructureObjectDto.TypeEnum
	depth?: number // int32
	code?: string
	uuid?: string
	parentId?: number // int64
	lastUpdatedAt?: string // date-time
}
export interface StructureObjectDto {
	structureId?: number // int64
	type?: StructureReleaseInfoDto.TypeEnum
	name?: string
	code?: string
	parentStructureId?: number // int64
	fullPath?: string
}
export interface StructureObjectPair {
	objectType?: ObjectSettingsDto
	structureType?: TemplateListDto.StructureTypeEnum
}
export interface StructureReleaseInfoDto {
	structureId?: number // int64
	type?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
	name?: string
	code?: string
	parentStructureId?: number // int64
	fullPath?: string
	environmentInfo?: EnvInfo[]
}
export interface StructureSearchedDto {
	id: number // int64
	type:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
	name: string
	code?: string
	parentStructureId?: number // int64
	hasChildren?: boolean
	lockUserName?: string
	lockTime?: string // date-time
	children: StructureSearchedDto[]
	uuid?: string // uuid
	lockUserId?: number // int64
	orderPriority?: number // int64
	searchedItem?: boolean
}
export interface StructureTypeDto {
	code: string
}
export interface StructureUserPermissionDto {
	structurePermissionCode?: string
	structurePermissionName?: string
	inheritedPermissionCode: string
	inheritedPermissionName: string
	userId: number // int64
	userLoginName: string
}
export interface StructureWorkingDataDto {
	data: string
}
export interface SystemUser {
	id?: number // int64
	code?: string
	name?: string
	description?: string
	comment?: string
}
export interface Table {
	uuid?: string // uuid
	name?: string
	code?: string
	comment?: string
	description?: string
	stereotypeId?: number // int64
	stereotypeCode?: string
	subStereotypeId?: number // int64
	hasHistoryTable?: boolean
	hasReferenceTable?: boolean
	businessDomainId?: number // int64
	businessDomainName?: string
	entityType?: Table.EntityTypeEnum
	status?:
		| 'LOGICAL_IN_PROGRESS'
		| 'LOGICAL_COMPLETED'
		| 'PHYSICAL_IN_PROGRESS'
		| 'PHYSICAL_COMPLETED'
		| 'FROZEN'
	responsibleAnalystId?: number // int64
	responsibleAnalystName?: string
	responsiblePersonId?: number // int64
	responsiblePersonName?: string
	retentionStrategy?: Table.RetentionStrategyEnum
	retentionStrategyFullHistoryValue?: number // int64
	retentionStrategyFullHistoryUnit?: Table.NtionStrategyFullHistoryUnitEnum
	retentionStrategyEomValue?: number // int64
	retentionStrategyEomUnit?: Table.RetentionStrategyEomUnitEnum
	columnsLastId?: number // int64
	columns?: TableColumn[]
	indexesLastId?: number // int64
	indexes?: TableIndex[]
	constraintsLastId?: number // int64
	constraints?: TableConstraint[]
	referenceTable?: ReferenceTable
	historyTable?: HistoryTable
	script?: string
	preTableScript?: string
	postTableScript?: string
	partitioningStrategy?: Table.PartitioningStrategyEnum
	/**
	 * If any of the key partitioning strategies is chosen then a id of key column must be provided.
	 */
	partitioningColumnId?: number // int64
	partitioningColumnCode?: string
	/**
	 * Sequence related to the table (only 1 is allowed)
	 */
	sequenceCode?: string
	/**
	 * Column used with the Sequence
	 */
	sequenceColumnCode?: string
	g_templateVersion?: number // int64
	g_templateStereotype?: string
	g_partitioningColumnCode?: string
	ownerId?: number // int64
	ownerName?: string
	objectSettings?: ObjectSettings[]
	objectPermissions?: TablePermission[]
	objectPermissionLastId?: number // int64
	customAttributes?: CustomAttribute[]
}
export interface TableColumn {
	/**
	 * Is unique across table columns.
	 */
	id?: number // int64
	name?: string
	code?: string
	comment?: string
	description?: string
	dataType?: string
	defaultValue?: string
	notNullFlag?: boolean
	/**
	 * true if the column is created automatically based on table stereotype.
	 */
	stereotypeColumn?: boolean
	/**
	 * id of the technical column from which it was created
	 */
	technicalColumnId?: number // int64
	/**
	 * true if the column is created automatically for history table.
	 */
	historyColumn?: boolean
	mandatory?: boolean
	primaryKey?: boolean
	foreignKey?: boolean
	g_hasTemplate?: boolean
	g_template?: string
	g_isTextValue?: boolean
	domain?: TableColumnDomain
	stereotype?: TableColumnStereotype
	uuid?: string // uuid
	virtualColumnScript?: string
	customAttributes?: CustomAttribute[]
}
export interface TableColumnDomain {
	id?: number // int64
	name?: string
	code?: string
}
export interface TableColumnStereotype {
	id?: number // int64
	name?: string
	code?: string
}
export interface TableConstraint {
	/**
	 * Is unique across table indexes.
	 */
	id?: number // int64
	enabled?: boolean
	physicalOptions?: string
	name?: string
	code?: string
	comment?: string
	description?: string
	foreignKeyTableId?: number // int64
	g_foreignKeyTableCode?: string
	g_foreignKeyTableOwner?: string
	script?: string
	expression?: string
	constraintType?: TableConstraint.ConstraintTypeEnum
	columns?: TableConstraintColumn[]
}
export interface TableConstraintColumn {
	name?: string
	/**
	 * The reference to the table column.
	 */
	code?: string
	uuid?: string // uuid
	/**
	 * The reference to the referenced table column.
	 */
	foreignColumnCode?: string
	foreignColumnUuid?: string // uuid
}
export interface TableDiagramDto {
	nodes?: TableDiagramNodeDto[]
	tableDirectIds?: number /* int64 */[]
	tableReferencedIds?: number /* int64 */[]
	links?: TableDiagramLinkDto[]
}
export interface TableDiagramLinkDto {
	constraintName?: string
	constraintCode?: string
	foreignKeyTableId?: number // int64
	foreignKeyColumnCode?: string
	foreignKeyColumnCodes?: TableConstraintColumn[]
	primaryKeyTableId?: number // int64
	primaryKeyColumnCodes?: TableConstraintColumn[]
}
export interface TableDiagramNodeDto {
	id?: number // int64
	code?: string
	name?: string
	relationship?: TableDiagramNodeDto.RelationshipEnum
}
export interface TableIndex {
	/**
	 * Is unique across table indexes.
	 */
	id?: number // int64
	name?: string
	code?: string
	isUnique?: boolean
	physicalOptions?: string
	comment?: string
	description?: string
	columns?: TableIndexColumn[]
}
export interface TableIndexColumn {
	code?: string
	sortType?: TableIndexColumn.SortTypeEnum
	uuid?: string // uuid
}
export interface TablePermission {
	/**
	 * Is unique across table users.
	 */
	id?: number // int64
	user?: SystemUser
	permissions?: TablePermission.PermissionsEnum[]
	order?: number // int64
}
export interface TechnicalColumnCreateDto {
	name: string
	code: string
	description?: string
	virtualColumnScript?: string
	comment?: string
	domainId: number // int64
	stereotypeIds?: number /* int64 */[]
	stereotypeColumnId?: number // int64
	ordering?: number // int32
}
export interface TechnicalColumnDto {
	id?: number // int64
	name: string
	code: string
	description?: string
	comment?: string
	virtualColumnScript?: string
	domainId: number // int64
	stereotypeIds?: number /* int64 */[]
	stereotypeColumnId?: number // int64
	notNull?: boolean
	ordering?: number // int32
	createdBy?: string
	updatedBy?: string
	createdAt?: string // date-time
	updatedAt?: string // date-time
	version?: number // int32
	deleted?: boolean
}
export interface TechnicalColumnStereotypeDto {
	id: number // int64
	ordering: number // int32
	name: string
	code: string
	domainId: number // int64
	domainName: string
	domainCode: string
	dataType: string
	notNull: boolean
	defaultValue: string
	columnStereotypeId: number // int64
	columnStereotypeName: string
	columnStereotypeCode: string
	description?: string
	comment?: string
	virtualColumnScript?: string
}
export interface TechnicalColumnUpdateDto {
	id?: number // int64
	name: string
	code: string
	description?: string
	virtualColumnScript?: string
	comment?: string
	domainId: number // int64
	notNull?: boolean
	stereotypeIds?: number /* int64 */[]
	stereotypeColumnId?: number // int64
	ordering?: number // int32
}
export interface TechnologyDto {
	id: number // int64
	name: string
	code: string
	description: string
}
export interface TemplateDto {
	id?: number // int64
	data: string
	description?: string
	technologyId: number // int64
	stereotypeId?: number // int64
	technologyName?: string
	stereotypeName?: string
	version?: number // int64
	authorName?: string
	comment?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
	objectSettingsId?: number // int64
	objectSettingsName?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	fileExtension?: string
	created?: string // date-time
}
export interface TemplateListDto {
	id?: number // int64
	description?: string
	technologyId: number // int64
	stereotypeId?: number // int64
	technologyName?: string
	stereotypeName?: string
	version?: number // int64
	authorName?: string
	comment?: string
	structureType?:
		| 'ROOT'
		| 'SYSTEM'
		| 'FOLDER'
		| 'FOLDER_WORKFLOW'
		| 'TABLE'
		| 'MAPPING_DEFAULT'
		| 'MAPPING'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'WORKFLOW'
		| 'VIEW'
		| 'API_NODE'
		| 'API_COLLECTION'
		| 'QUEUE'
		| 'CONSTANT_DOMAIN'
		| 'TABLE_COLUMN'
	objectSettingsId?: number // int64
	objectSettingsName?: string
	formatType?: 'PYTHON' | 'SQL' | 'PLAIN_TEXT'
	fileExtension?: string
	created?: string // date-time
}
export interface User {
	id?: number // int64
	email: string
	loginName: string
	name: string
	surname: string
	department?: string
	description?: string
	sessionContext?: string
	extId?: string
	company?: number // int64
}
export interface UserComboDto {
	id: number // int64
	value: string
}
export interface UserDto {
	id: number // int64
	email: string
	loginName: string
	deleted: boolean
	name: string
	surname: string
	department?: string
	description?: string
	sessionContext?: string
	canBeEdited: boolean
	compositeName: string
	companyName?: string
	hardLock?: boolean
}
export interface UserMessageDto {
	message?: string
}
export interface UserRolesDto {
	roles: UserRolesDto.RolesEnum[]
}
export interface UtilsNameRequestDto {
	name: string
	systemFolderId?: number // int64
}
export interface ValidationMessageDto {
	id: number // int32
	ruleName?: string
	nodeName: string
	elementName: string
	message: string
	description: string
	path?: string
	elementType: ValidationMessageDto.ElementTypeEnum
	nodeType:
		| 'SYSTEM_NODE'
		| 'FOLDER'
		| 'WORKFLOW'
		| 'TABLE'
		| 'MAPPING'
		| 'VIEW'
		| 'API'
		| 'QUEUE'
	severity: ValidationMessageDto.SeverityEnum
	uuid?: string
}

export namespace ApiNodeColumnDto {
	export enum SourceTypeEnum {
		API_NODE = 'API_NODE',
		ROW_SET = 'ROW_SET',
		TABLE = 'TABLE',
	}
}
export namespace CustomAttributeComboDto {
	export enum TypeEnum {
		STRING = 'STRING',
		SCRIPT = 'SCRIPT',
		NUMBER = 'NUMBER',
		BOOLEAN = 'BOOLEAN',
		LOV = 'LOV',
		ARRAY = 'ARRAY',
	}
	export enum StructureTypesEnum {
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		TABLE_COLUMN = 'TABLE_COLUMN',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace CustomAttributeCreateDto {
	export enum TypeEnum {
		STRING = 'STRING',
		SCRIPT = 'SCRIPT',
		NUMBER = 'NUMBER',
		BOOLEAN = 'BOOLEAN',
		LOV = 'LOV',
		ARRAY = 'ARRAY',
	}
	export enum StructureTypesEnum {
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		TABLE_COLUMN = 'TABLE_COLUMN',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace CustomAttributeDto {
	export enum TypeEnum {
		STRING = 'STRING',
		SCRIPT = 'SCRIPT',
		NUMBER = 'NUMBER',
		BOOLEAN = 'BOOLEAN',
		LOV = 'LOV',
		ARRAY = 'ARRAY',
	}
	export enum StructureTypesEnum {
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		TABLE_COLUMN = 'TABLE_COLUMN',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace CustomAttributeUpdateDto {
	export enum TypeEnum {
		STRING = 'STRING',
		SCRIPT = 'SCRIPT',
		NUMBER = 'NUMBER',
		BOOLEAN = 'BOOLEAN',
		LOV = 'LOV',
		ARRAY = 'ARRAY',
	}
	export enum StructureTypesEnum {
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		TABLE_COLUMN = 'TABLE_COLUMN',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace DeploymentDetailDto {
	export enum SqlTypeEnum {
		TABLE_HISTORY = 'TABLE_HISTORY',
		TABLE_REFERENCE = 'TABLE_REFERENCE',
		TABLE = 'TABLE',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace DeploymentDto {
	export enum SqlTypeEnum {
		TABLE_HISTORY = 'TABLE_HISTORY',
		TABLE_REFERENCE = 'TABLE_REFERENCE',
		TABLE = 'TABLE',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace DeploymentNewDto {
	export enum SqlTypeEnum {
		TABLE_HISTORY = 'TABLE_HISTORY',
		TABLE_REFERENCE = 'TABLE_REFERENCE',
		TABLE = 'TABLE',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
	}
}
export namespace EditUserInfoDto {
	export enum RoleEnum {
		ADMIN = 'ADMIN',
		LEADER = 'LEADER',
	}
}
export namespace FullTextSearchDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace GeneratedSqlDto {
	export enum FormatTypeEnum {
		PYTHON = 'PYTHON',
		SQL = 'SQL',
		PLAIN_TEXT = 'PLAIN_TEXT',
	}
}
export namespace HistoryTable {
	export enum EntityTypeEnum {
		AGGREGATE = 'AGGREGATE',
		AUXILIARY = 'AUXILIARY',
		COMPOSITION = 'COMPOSITION',
		FUNDAMENTAL = 'FUNDAMENTAL',
		METADATA = 'METADATA',
		RELATION = 'RELATION',
		SNAPSHOT = 'SNAPSHOT',
		SPECIALISATION = 'SPECIALISATION',
		TRANSACTION = 'TRANSACTION',
	}
	export enum RetentionStrategyEnum {
		INFINITE = 'INFINITE',
		DELETE_AFTER = 'DELETE_AFTER',
		MOVE_AFTER = 'MOVE_AFTER',
		KEEP_FULL_HISTORY = 'KEEP_FULL_HISTORY',
		KEEP_FULL_HISTORY_AND_EOM = 'KEEP_FULL_HISTORY_AND_EOM',
	}
	export enum RetentionStrategyFullHistoryUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum RetentionStrategyEomUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum PartitioningStrategyEnum {
		NO_PARTITION = 'NO_PARTITION',
		KEY = 'KEY',
		SOURCE_SYSTEM = 'SOURCE_SYSTEM',
		SNAPSHOT_DATA = 'SNAPSHOT_DATA',
		SOURCE_AND_SNAPSHOT = 'SOURCE_AND_SNAPSHOT',
		KEY_AND_SNAPSHOT = 'KEY_AND_SNAPSHOT',
		SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
		KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
		SOURCE_SYSTEM_AND_VALID_TO = 'SOURCE_SYSTEM_AND_VALID_TO',
	}
	export enum NonSyncFieldsEnum {
		UUID = 'UUID',
		NAME = 'NAME',
		CODE = 'CODE',
		COMMENT = 'COMMENT',
		DESCRIPTION = 'DESCRIPTION',
		STEREOTYPE_ID = 'STEREOTYPE_ID',
		STEREOTYPE_CODE = 'STEREOTYPE_CODE',
		SUB_STEREOTYPE_ID = 'SUB_STEREOTYPE_ID',
		HAS_HISTORY_TABLE = 'HAS_HISTORY_TABLE',
		HAS_REFERENCE_TABLE = 'HAS_REFERENCE_TABLE',
		BUSINESS_DOMAIN_ID = 'BUSINESS_DOMAIN_ID',
		BUSINESS_DOMAIN_NAME = 'BUSINESS_DOMAIN_NAME',
		ENTITY_TYPE = 'ENTITY_TYPE',
		STATUS = 'STATUS',
		RESPONSIBLE_ANALYST_ID = 'RESPONSIBLE_ANALYST_ID',
		RESPONSIBLE_ANALYST_NAME = 'RESPONSIBLE_ANALYST_NAME',
		RESPONSIBLE_PERSON_ID = 'RESPONSIBLE_PERSON_ID',
		RESPONSIBLE_PERSON_NAME = 'RESPONSIBLE_PERSON_NAME',
		RETENTION_STRATEGY = 'RETENTION_STRATEGY',
		RETENTION_STRATEGY_FULL_HISTORY_VALUE = 'RETENTION_STRATEGY_FULL_HISTORY_VALUE',
		RETENTION_STRATEGY_FULL_HISTORY_UNIT = 'RETENTION_STRATEGY_FULL_HISTORY_UNIT',
		RETENTION_STRATEGY_EOM_VALUE = 'RETENTION_STRATEGY_EOM_VALUE',
		RETENTION_STRATEGY_EOM_UNIT = 'RETENTION_STRATEGY_EOM_UNIT',
		COLUMNS = 'COLUMNS',
		COLUMNS_LAST_ID = 'COLUMNS_LAST_ID',
		INDEXES = 'INDEXES',
		INDEXES_LAST_ID = 'INDEXES_LAST_ID',
		CONSTRAINTS = 'CONSTRAINTS',
		CONSTRAINTS_LAST_ID = 'CONSTRAINTS_LAST_ID',
		HISTORY_TABLE = 'HISTORY_TABLE',
		REFERENCE_TABLE = 'REFERENCE_TABLE',
		SCRIPT = 'SCRIPT',
		PRE_TABLE_SCRIPT = 'PRE_TABLE_SCRIPT',
		POST_TABLE_SCRIPT = 'POST_TABLE_SCRIPT',
		PARTITIONING_STRATEGY = 'PARTITIONING_STRATEGY',
		PARTITIONING_COLUMN_ID = 'PARTITIONING_COLUMN_ID',
		PARTITIONING_COLUMN_CODE = 'PARTITIONING_COLUMN_CODE',
		SEQUENCE_CODE = 'SEQUENCE_CODE',
		SEQUENCE_COLUMN_CODE = 'SEQUENCE_COLUMN_CODE',
		OWNER_ID = 'OWNER_ID',
		OWNER_NAME = 'OWNER_NAME',
		OBJECT_SETTINGS = 'OBJECT_SETTINGS',
		CUSTOM_ATTRIBUTES = 'CUSTOM_ATTRIBUTES',
	}
}
export namespace ImportReturnMessage {
	export enum MessageTypeEnum {
		INCOMPATIBLE_TARGET = 'INCOMPATIBLE_TARGET',
		DOMAIN_NOT_FOUND = 'DOMAIN_NOT_FOUND',
		STEREOTYPE_NOT_FOUND = 'STEREOTYPE_NOT_FOUND',
		TABLE_STEREOTYPE_NOT_FOUND = 'TABLE_STEREOTYPE_NOT_FOUND',
		TABLE_ALREADY_EXISTS_IN_FOLDER = 'TABLE_ALREADY_EXISTS_IN_FOLDER',
		TABLE_ALREADY_EXISTS_IN_SYSTEM = 'TABLE_ALREADY_EXISTS_IN_SYSTEM',
		INCONSISTENT_TABLES = 'INCONSISTENT_TABLES',
		INCONSISTENT_DOMAINS = 'INCONSISTENT_DOMAINS',
		COLUMN_WITH_SAME_NAME_ALREADY_EXISTS = 'COLUMN_WITH_SAME_NAME_ALREADY_EXISTS',
		COLUMN_WITH_SAME_CODE_ALREADY_EXISTS = 'COLUMN_WITH_SAME_CODE_ALREADY_EXISTS',
		DUPLICATE_COLUMN_NAME = 'DUPLICATE_COLUMN_NAME',
		DUPLICATE_COLUMN_CODE = 'DUPLICATE_COLUMN_CODE',
	}
}
export namespace MassExportConfigDto {
	export enum MassExportStrategyEnum {
		ZIP_PER_STRUCTURE = 'ZIP_PER_STRUCTURE',
		ZIP_OWNER_FOLDER_PER_OBJECT_TYPE = 'ZIP_OWNER_FOLDER_PER_OBJECT_TYPE',
		ZIP_PER_OWNER_AND_OBJECT_TYPE = 'ZIP_PER_OWNER_AND_OBJECT_TYPE',
		SINGLE_FILE = 'SINGLE_FILE',
	}
	export enum StructureStatusEnum {
		LOGICAL_IN_PROGRESS = 'LOGICAL_IN_PROGRESS',
		LOGICAL_COMPLETED = 'LOGICAL_COMPLETED',
		PHYSICAL_IN_PROGRESS = 'PHYSICAL_IN_PROGRESS',
		PHYSICAL_COMPLETED = 'PHYSICAL_COMPLETED',
		FROZEN = 'FROZEN',
	}
}
export namespace MassUpdateDto {
	export enum StereotypeTypeEnum {
		FOLDER = 'FOLDER',
		TABLE = 'TABLE',
		TABLE_INTERFACE = 'TABLE_INTERFACE',
		TABLE_HISTORY = 'TABLE_HISTORY',
		COLUMN = 'COLUMN',
		MAPPING = 'MAPPING',
		MAPPING_TYPE = 'MAPPING_TYPE',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		VIEW = 'VIEW',
		WORKFLOW = 'WORKFLOW',
		API_NODE = 'API_NODE',
		API_NODE_ATTRIBUTE = 'API_NODE_ATTRIBUTE',
		API_ROWSET = 'API_ROWSET',
		QUEUE = 'QUEUE',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace NewStructureDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace NewStructureUserPermissionDto {
	export enum PermissionCodeEnum {
		NO_ACCESS = 'NO_ACCESS',
		READ_ACCESS = 'READ_ACCESS',
		FULL_ACCESS = 'FULL_ACCESS',
		MANAGE_USERS = 'MANAGE_USERS',
		MANAGE_LOV = 'MANAGE_LOV',
		CREATE_DELETE_SYSTEM = 'CREATE_DELETE_SYSTEM',
		FORCE_UNLOCK = 'FORCE_UNLOCK',
	}
}
export namespace ObjectSettingsComboDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace ObjectSettingsCreateDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace ObjectSettingsUpdateDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace Permission {
	export enum CodeEnum {
		NO_ACCESS = 'NO_ACCESS',
		READ_ACCESS = 'READ_ACCESS',
		FULL_ACCESS = 'FULL_ACCESS',
		MANAGE_USERS = 'MANAGE_USERS',
		MANAGE_LOV = 'MANAGE_LOV',
		CREATE_DELETE_SYSTEM = 'CREATE_DELETE_SYSTEM',
		FORCE_UNLOCK = 'FORCE_UNLOCK',
	}
	export enum TypeEnum {
		NODE = 'NODE',
		GLOBAL = 'GLOBAL',
	}
}
export namespace PrePushValidationDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace ReferenceTable {
	export enum EntityTypeEnum {
		AGGREGATE = 'AGGREGATE',
		AUXILIARY = 'AUXILIARY',
		COMPOSITION = 'COMPOSITION',
		FUNDAMENTAL = 'FUNDAMENTAL',
		METADATA = 'METADATA',
		RELATION = 'RELATION',
		SNAPSHOT = 'SNAPSHOT',
		SPECIALISATION = 'SPECIALISATION',
		TRANSACTION = 'TRANSACTION',
	}
	export enum RetentionStrategyEnum {
		INFINITE = 'INFINITE',
		DELETE_AFTER = 'DELETE_AFTER',
		MOVE_AFTER = 'MOVE_AFTER',
		KEEP_FULL_HISTORY = 'KEEP_FULL_HISTORY',
		KEEP_FULL_HISTORY_AND_EOM = 'KEEP_FULL_HISTORY_AND_EOM',
	}
	export enum RetentionStrategyFullHistoryUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum RetentionStrategyEomUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum PartitioningStrategyEnum {
		NO_PARTITION = 'NO_PARTITION',
		KEY = 'KEY',
		SOURCE_SYSTEM = 'SOURCE_SYSTEM',
		SNAPSHOT_DATA = 'SNAPSHOT_DATA',
		SOURCE_AND_SNAPSHOT = 'SOURCE_AND_SNAPSHOT',
		KEY_AND_SNAPSHOT = 'KEY_AND_SNAPSHOT',
		SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
		KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
	}
	export enum NonSyncFieldsEnum {
		UUID = 'UUID',
		NAME = 'NAME',
		CODE = 'CODE',
		COMMENT = 'COMMENT',
		DESCRIPTION = 'DESCRIPTION',
		STEREOTYPE_ID = 'STEREOTYPE_ID',
		STEREOTYPE_CODE = 'STEREOTYPE_CODE',
		SUB_STEREOTYPE_ID = 'SUB_STEREOTYPE_ID',
		HAS_HISTORY_TABLE = 'HAS_HISTORY_TABLE',
		HAS_REFERENCE_TABLE = 'HAS_REFERENCE_TABLE',
		BUSINESS_DOMAIN_ID = 'BUSINESS_DOMAIN_ID',
		BUSINESS_DOMAIN_NAME = 'BUSINESS_DOMAIN_NAME',
		ENTITY_TYPE = 'ENTITY_TYPE',
		STATUS = 'STATUS',
		RESPONSIBLE_ANALYST_ID = 'RESPONSIBLE_ANALYST_ID',
		RESPONSIBLE_ANALYST_NAME = 'RESPONSIBLE_ANALYST_NAME',
		RESPONSIBLE_PERSON_ID = 'RESPONSIBLE_PERSON_ID',
		RESPONSIBLE_PERSON_NAME = 'RESPONSIBLE_PERSON_NAME',
		RETENTION_STRATEGY = 'RETENTION_STRATEGY',
		RETENTION_STRATEGY_FULL_HISTORY_VALUE = 'RETENTION_STRATEGY_FULL_HISTORY_VALUE',
		RETENTION_STRATEGY_FULL_HISTORY_UNIT = 'RETENTION_STRATEGY_FULL_HISTORY_UNIT',
		RETENTION_STRATEGY_EOM_VALUE = 'RETENTION_STRATEGY_EOM_VALUE',
		RETENTION_STRATEGY_EOM_UNIT = 'RETENTION_STRATEGY_EOM_UNIT',
		COLUMNS = 'COLUMNS',
		COLUMNS_LAST_ID = 'COLUMNS_LAST_ID',
		INDEXES = 'INDEXES',
		INDEXES_LAST_ID = 'INDEXES_LAST_ID',
		CONSTRAINTS = 'CONSTRAINTS',
		CONSTRAINTS_LAST_ID = 'CONSTRAINTS_LAST_ID',
		HISTORY_TABLE = 'HISTORY_TABLE',
		REFERENCE_TABLE = 'REFERENCE_TABLE',
		SCRIPT = 'SCRIPT',
		PRE_TABLE_SCRIPT = 'PRE_TABLE_SCRIPT',
		POST_TABLE_SCRIPT = 'POST_TABLE_SCRIPT',
		PARTITIONING_STRATEGY = 'PARTITIONING_STRATEGY',
		PARTITIONING_COLUMN_ID = 'PARTITIONING_COLUMN_ID',
		PARTITIONING_COLUMN_CODE = 'PARTITIONING_COLUMN_CODE',
		SEQUENCE_CODE = 'SEQUENCE_CODE',
		SEQUENCE_COLUMN_CODE = 'SEQUENCE_COLUMN_CODE',
		OWNER_ID = 'OWNER_ID',
		OWNER_NAME = 'OWNER_NAME',
		OBJECT_SETTINGS = 'OBJECT_SETTINGS',
		CUSTOM_ATTRIBUTES = 'CUSTOM_ATTRIBUTES',
	}
}
export namespace RelationDto {
	export enum StatusEnum {
		OK = 'OK',
		PK_SIDE_REMOVED = 'PK_SIDE_REMOVED',
		FK_COL_NOT_SET = 'FK_COL_NOT_SET',
		PK_COL_REMOVED = 'PK_COL_REMOVED',
		BROKEN = 'BROKEN',
	}
}
export namespace RelationStructureDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace ReorderStructureDto {
	export enum ReorderStrategyEnum {
		ALPHABETICAL = 'ALPHABETICAL',
		CUSTOM = 'CUSTOM',
		TYPE = 'TYPE',
	}
}
export namespace StereotypeCreateDto {
	export enum TypeEnum {
		FOLDER = 'FOLDER',
		TABLE = 'TABLE',
		TABLE_INTERFACE = 'TABLE_INTERFACE',
		TABLE_HISTORY = 'TABLE_HISTORY',
		COLUMN = 'COLUMN',
		MAPPING = 'MAPPING',
		MAPPING_TYPE = 'MAPPING_TYPE',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		VIEW = 'VIEW',
		WORKFLOW = 'WORKFLOW',
		API_NODE = 'API_NODE',
		API_NODE_ATTRIBUTE = 'API_NODE_ATTRIBUTE',
		API_ROWSET = 'API_ROWSET',
		QUEUE = 'QUEUE',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StereotypeDto {
	export enum TypeEnum {
		FOLDER = 'FOLDER',
		TABLE = 'TABLE',
		TABLE_INTERFACE = 'TABLE_INTERFACE',
		TABLE_HISTORY = 'TABLE_HISTORY',
		COLUMN = 'COLUMN',
		MAPPING = 'MAPPING',
		MAPPING_TYPE = 'MAPPING_TYPE',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		VIEW = 'VIEW',
		WORKFLOW = 'WORKFLOW',
		API_NODE = 'API_NODE',
		API_NODE_ATTRIBUTE = 'API_NODE_ATTRIBUTE',
		API_ROWSET = 'API_ROWSET',
		QUEUE = 'QUEUE',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StereotypeUpdateDto {
	export enum TypeEnum {
		FOLDER = 'FOLDER',
		TABLE = 'TABLE',
		TABLE_INTERFACE = 'TABLE_INTERFACE',
		TABLE_HISTORY = 'TABLE_HISTORY',
		COLUMN = 'COLUMN',
		MAPPING = 'MAPPING',
		MAPPING_TYPE = 'MAPPING_TYPE',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		VIEW = 'VIEW',
		WORKFLOW = 'WORKFLOW',
		API_NODE = 'API_NODE',
		API_NODE_ATTRIBUTE = 'API_NODE_ATTRIBUTE',
		API_ROWSET = 'API_ROWSET',
		QUEUE = 'QUEUE',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureAudit {
	export enum EntityTypeEnum {
		STRUCTURE = 'STRUCTURE',
		DOMAIN = 'DOMAIN',
	}
	export enum OperationTypeEnum {
		LOCK = 'LOCK',
		UNLOCK = 'UNLOCK',
		FORCE_UNLOCK = 'FORCE_UNLOCK',
		UPDATE_DATA = 'UPDATE_DATA',
		UPDATE = 'UPDATE',
		CREATE = 'CREATE',
		DELETE = 'DELETE',
	}
}
export namespace StructureDetailDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureDetailExtDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureExportDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureObjectDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureReleaseInfoDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace StructureSearchedDto {
	export enum TypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace Table {
	export enum EntityTypeEnum {
		AGGREGATE = 'AGGREGATE',
		AUXILIARY = 'AUXILIARY',
		COMPOSITION = 'COMPOSITION',
		FUNDAMENTAL = 'FUNDAMENTAL',
		METADATA = 'METADATA',
		RELATION = 'RELATION',
		SNAPSHOT = 'SNAPSHOT',
		SPECIALISATION = 'SPECIALISATION',
		TRANSACTION = 'TRANSACTION',
	}
	export enum RetentionStrategyEnum {
		INFINITE = 'INFINITE',
		DELETE_AFTER = 'DELETE_AFTER',
		MOVE_AFTER = 'MOVE_AFTER',
		KEEP_FULL_HISTORY = 'KEEP_FULL_HISTORY',
		KEEP_FULL_HISTORY_AND_EOM = 'KEEP_FULL_HISTORY_AND_EOM',
	}
	export enum NtionStrategyFullHistoryUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum RetentionStrategyEomUnitEnum {
		DAY = 'DAY',
		MONTH = 'MONTH',
		YEAR = 'YEAR',
	}
	export enum PartitioningStrategyEnum {
		NO_PARTITION = 'NO_PARTITION',
		KEY = 'KEY',
		SOURCE_SYSTEM = 'SOURCE_SYSTEM',
		SNAPSHOT_DATA = 'SNAPSHOT_DATA',
		SOURCE_AND_SNAPSHOT = 'SOURCE_AND_SNAPSHOT',
		KEY_AND_SNAPSHOT = 'KEY_AND_SNAPSHOT',
		SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
		KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE = 'KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE',
	}
}
export namespace TableConstraint {
	export enum ConstraintTypeEnum {
		PRIMARY_KEY = 'PRIMARY_KEY',
		FOREIGN_KEY = 'FOREIGN_KEY',
		CUSTOM = 'CUSTOM',
		UNIQUE = 'UNIQUE',
		CHECK = 'CHECK',
	}
}
export namespace TableDiagramNodeDto {
	export enum RelationshipEnum {
		DIRECT = 'DIRECT',
		REFERENCED = 'REFERENCED',
	}
}
export namespace TableIndexColumn {
	export enum SortTypeEnum {
		ASC = 'ASC',
		DESC = 'DESC',
	}
}
export namespace TablePermission {
	export enum PermissionsEnum {
		ALTER = 'ALTER',
		SELECT = 'SELECT',
		DELETE = 'DELETE',
		UPDATE = 'UPDATE',
		INSERT = 'INSERT',
		REFERENCE = 'REFERENCE',
		SEQUENCE = 'SEQUENCE',
	}
}
export namespace TemplateDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace TemplateListDto {
	export enum StructureTypeEnum {
		ROOT = 'ROOT',
		SYSTEM = 'SYSTEM',
		FOLDER = 'FOLDER',
		FOLDER_WORKFLOW = 'FOLDER_WORKFLOW',
		TABLE = 'TABLE',
		MAPPING_DEFAULT = 'MAPPING_DEFAULT',
		MAPPING = 'MAPPING',
		MAPPING_READ = 'MAPPING_READ',
		MAPPING_WRITE = 'MAPPING_WRITE',
		WORKFLOW = 'WORKFLOW',
		VIEW = 'VIEW',
		API_NODE = 'API_NODE',
		API_COLLECTION = 'API_COLLECTION',
		QUEUE = 'QUEUE',
		CONSTANT_DOMAIN = 'CONSTANT_DOMAIN',
		TABLE_COLUMN = 'TABLE_COLUMN',
	}
}
export namespace UserRolesDto {
	export enum RolesEnum {
		SUPER_ADMIN = 'SUPER_ADMIN',
		MANAGE_USERS = 'MANAGE_USERS',
		MANAGE_LOV = 'MANAGE_LOV',
		CREATE_DELETE_SYSTEM = 'CREATE_DELETE_SYSTEM',
		FORCE_UNLOCK = 'FORCE_UNLOCK',
		EXY_SYSTEM = 'EXY_SYSTEM',
	}
}
export namespace ValidationMessageDto {
	export enum ElementTypeEnum {
		TABLE = 'TABLE',
		MAPPING = 'MAPPING',
		COLUMN = 'COLUMN',
		VIEW = 'VIEW',
		FOLDER = 'FOLDER',
		SYSTEM_FOLDER = 'SYSTEM_FOLDER',
		RELATION = 'RELATION',
		OVERVIEW = 'OVERVIEW',
		CONSTRAINT = 'CONSTRAINT',
		STEREOTYPE = 'STEREOTYPE',
	}
	export enum SeverityEnum {
		ERROR = 'ERROR',
		WARN = 'WARN',
		INFO = 'INFO',
	}
}
