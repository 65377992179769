import React from 'react'
import {
	faAnglesDown,
	faAnglesRight,
	faBan,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RowExpanderProps } from './types'

export const RowExpander = ({ row, envCode }: RowExpanderProps) => {
	return (
		<span className="flex justify-center items-center">
			{row.getCanExpand() && envCode === row.original.subRows[0].envCode ? (
				<button
					onClick={row.getToggleExpandedHandler()}
					className="cursor-pointer"
				>
					{row.getIsExpanded() ? (
						<FontAwesomeIcon icon={faAnglesDown} color="#FAA94B" />
					) : (
						<FontAwesomeIcon icon={faAnglesRight} color="#FAA94B" />
					)}
				</button>
			) : (
				<FontAwesomeIcon icon={faBan} color="#FAA94B" />
			)}
		</span>
	)
}
