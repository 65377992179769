import styled from 'styled-components'
import { colors } from '@/styles'
import { DragProps, DropProps } from './types'
import { SelectFormField } from '@/components/UberForm'

export const TableWrap = styled.div<{
	$maxWidth?: number
	$actionCellWidth?: string
}>`
	/* This is required to make the table full-width */
	display: block;
	max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '100%')};
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 4.5rem;

	th {
		padding: 0.313rem 0.5rem;
		background: ${(props) => props.theme.colors.primary.base};
		color: #fff;
	}

	table {
		/* Make sure the inner table is always as wide as needed */
		width: 100%;
		border-spacing: 0;
		table-layout: fixed;

		td {
			padding: 0;
		}

		/* Fixes the issue with blank row below expandedRow */
		.expandedRow + tr {
			display: none !important;
		}

		.expandedRow {
			td {
				border-top: none;
				border-bottom: none;
				border-right: none;
			}
		}

		th,
		td {
			margin: 0;
			border: 1px solid ${colors.table.thead.borderColor};
			vertical-align: middle;
		}

		/* Fixes the issue with checkboxes => not centered */
		input {
			width: 100%;
		}

		input[type='text'] {
			border: none;
		}

		.table__closeBtn {
			position: sticky;
			top: 0;
			right: 0;
		}
	}

	.pagination {
		padding: 0.5rem;
	}

	.textInputWithoutBorder {
		border: none;
		min-height: 32px;
	}
`

export const TableHeaderContentWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const TableActionTh = styled.th`
	width: 2.5rem;
	padding: 0 !important;
	visibility: hidden;
	border: none !important;
	flex-shrink: 0;
`

export const TableActionCell = styled.td<{
	$hasLastRowEdit: boolean
	$isEditMode: boolean
	$isLastRow: boolean
}>`
	padding: 0 !important;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none !important;
	visibility: ${({ $isEditMode, $isLastRow, $hasLastRowEdit }) => {
		if (!$hasLastRowEdit && $isLastRow) {
			return 'hidden'
		}

		return $isEditMode ? 'visible' : 'hidden'
	}};
	flex-shrink: 0;
`

export const Row = styled.tr<DragProps & DropProps>`
	opacity: ${({ $isDragging }) => ($isDragging ? 0.3 : 1)};
	border-bottom: ${({ $isDragOver, $canDrop, $yDiff }) => {
		if ($canDrop && $isDragOver && $yDiff > 0) {
			return '0.125rem solid'
		}

		return 'none'
	}};
	border-top: ${({ $isDragOver, $canDrop, $yDiff }) => {
		if ($canDrop && $isDragOver && $yDiff < 0) {
			return '0.125rem solid'
		}

		return 'none'
	}};
`

export interface SelectProps {
	$error?: string
	$focused?: boolean
	variant?: 'textarea'
	rows?: number
}

export const TableSelect = styled(SelectFormField)<SelectProps>`
	height: 100%;
	width: 100%;
	margin-bottom: 1px;
	margin-right: 0;

	.react-select__control {
		border: none;
	}
`
