import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { appConfig } from '@/config'
import { RootState } from '@/store'

type prepareHeaders = (
	headers: Headers,
	api: {
		endpoint: string
		extra: unknown
		forced?: boolean
		getState: () => unknown
		type: 'query' | 'mutation'
	},
) => Headers | void

export const prepareHeaders: prepareHeaders = (headers, { getState }) => {
	const token = (getState() as RootState).auth.token
	if (token) {
		headers.set('authorization', `Bearer ${token}`)
	}
	return headers
}

export const baseQueryWithAuth = fetchBaseQuery({
	baseUrl: appConfig.apiUrl,
	prepareHeaders,
})
