import React from 'react'
import styled from 'styled-components'

interface Props {
	children?: React.ReactNode
	className?: string
}

const Toolbar = (props: Props) => {
	return <Container className={props.className}>{props.children}</Container>
}

const Container = styled.div`
	background: #fafafa;
	button {
		margin: 0;
		background-color: #f9f9f9;
		color: #333;
		border-radius: 0;
		border: none;
		padding: 8px;
		border-right: 1px solid ${(props) => props.theme.colors.layout.borderColor};
	}
`

export default Toolbar
