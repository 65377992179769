import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type MiscSliceState = {
	selectedRowTable: number
}

const initialState: MiscSliceState = {
	selectedRowTable: 0,
}

const miscSlice = createSlice({
	name: 'misc',
	initialState,
	reducers: {
		tableRowSelected: (state, action: PayloadAction<number>) => {
			state.selectedRowTable = action.payload
		},
	},
})

export const { tableRowSelected } = miscSlice.actions

export default miscSlice.reducer
