import React, { useCallback } from 'react'
import { Button, Loader, DialogWrapper, TabsActionsBar } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useAppContext, useTableColumns } from '@/hooks'
import { getSystemReleases } from '@/endpoints'
import { MainContentWrap } from '@/styles'
import { DeploymentPackagesProps } from './types'
import { useDpTableColumns } from './tableColumns'
import { AddRelease, DpTable } from './components'
import { useApi } from '@/endpoints/hooks'
import { sortBy } from 'lodash'

export const DeploymentPackages = ({
	node,
	editMode,
}: DeploymentPackagesProps) => {
	const { t } = useAppContext()

	const {
		data: deploymentPackages,
		loading,
		invalidate,
	} = useApi(getSystemReleases(node.id))

	const sortedDeploymentPackages = sortBy(deploymentPackages, 'name')

	const columns = useTableColumns({
		tableData: [sortedDeploymentPackages],
		properties: useDpTableColumns(),
	})

	const reloadReleases = useCallback(() => {
		invalidate()
	}, [invalidate])

	return (
		<>
			<TabsActionsBar>
				<DialogWrapper
					dialog={(opened, onClose) =>
						opened && (
							<AddRelease
								onClose={() => {
									reloadReleases()
									onClose()
								}}
								node={node}
								editMode={editMode}
							/>
						)
					}
				>
					{(onClick) => (
						<Button
							icon={faPlus}
							onClick={onClick}
							schema="success"
							coloredIcon={true}
							disabled={!editMode}
						>
							{t('ADD_NEW_RELEASE')}
						</Button>
					)}
				</DialogWrapper>
			</TabsActionsBar>

			<MainContentWrap>
				{loading ? (
					<Loader loaded={!loading} $absolute />
				) : (
					<DpTable
						node={node}
						columns={columns}
						deploymentPackages={sortedDeploymentPackages}
						editMode={editMode}
						reloadReleases={reloadReleases}
						hasLastRowEdit
					/>
				)}
			</MainContentWrap>
		</>
	)
}
