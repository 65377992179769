import { useMemo, useCallback } from 'react'
import { MappingColumn } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	parentColumns: TableColumnFlat[]
	availableColumns: TableColumnFlat[]
	editMode: boolean
}

export const useColumnsProperties = ({
	parentColumns,
	availableColumns,
	editMode,
}: HookOptions) => {
	const { t } = useAppContext()

	const getColumnOptions = useCallback(
		(item: MappingColumn | undefined) => {
			if (!item?.columnCode) {
				return availableColumns
			}

			const selectedColumn = parentColumns.find(
				(c) => c.code === item.columnCode,
			)

			return selectedColumn
				? [selectedColumn, ...availableColumns]
				: availableColumns
		},
		[parentColumns, availableColumns],
	)

	const properties = useMemo<TableColumnProps<MappingColumn>[]>(
		() => [
			{
				field: 'columnCode',
				label: t('MAPPING_COLUMNS_COLUMN'),
				type: ItemPropertyType.OPTION,
				options: getColumnOptions,
				optionValue: 'code',
				optionLabel: 'name',
				flex: 1.5,
				isNumeric: true,
				showWhenPanelOpened: true,
				width: 350,
			},
			{
				field: 'mapping',
				label: t('MAPPING_COLUMNS_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				width: 200,
			},
			{
				field: 'outerMapping',
				label: t('MAPPING_COLUMNS_OUTER_MAPPING'),
				type: ItemPropertyType.ACE_EDITOR,
				width: 200,
			},
			{
				field: 'mappingDescription',
				label: t('MAPPING_COLUMNS_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'mappingComment',
				label: t('MAPPING_COLUMNS_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
			},
			{
				field: 'keyFlag',
				label: t('MAPPING_COLUMNS_KEY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 90,
				disabled: !editMode,
			},
			{
				field: 'updateFlag',
				label: t('MAPPING_COLUMNS_UPDATE_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 90,
				disabled: !editMode,
			},
			{
				field: 'groupByFlag',
				label: t('MAPPING_COLUMNS_GROUP_BY_FLAG'),
				type: ItemPropertyType.CHECKBOX,
				width: 90,
				disabled: !editMode,
			},
		],
		[t, getColumnOptions, editMode],
	)

	return properties
}
