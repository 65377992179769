import debounce from 'debounce'
import { ForwardedRef, forwardRef, useMemo, useState } from 'react'

import { CodeInput, Loader } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import { ForwardedModalForm } from '@/components/Modal/ForwardedModalForm'
import {
	CheckBoxFormField,
	Form,
	FormField,
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { columnStereotypeValue } from '@/constants'
import { getAllDomainsForCombo, getStereotypes } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StereotypeDto, TechnicalColumnDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import { FormValue } from '@/types'
import { excludeCustomDomains } from '@/utils/domain'

import { AuditForm } from '../../../components/AuditForm'
import { auditFieldsAdd, auditFieldsRemove, onNameChange } from '../../../utils'
import { SplitFields, Stereotypes } from '../styles'
import { DomainInheritDataState, TechColumnModalProps } from '../types'
import { useConfirmationTechnicalColumn } from './hooks/useConfirmationTechnicalColumn'

export const TechColumnModal = forwardRef(
	(
		{ techCol, onClose, onSave, position, stereotypes }: TechColumnModalProps,
		ref: ForwardedRef<Form<TechnicalColumnDto>>,
	) => {
		const { t } = useAppContext()
		const dispatch = useAppDispatch()

		const stereotypesColumn = useApi(
			getStereotypes({ type: StereotypeDto.TypeEnum.COLUMN }),
		)

		const domains = useApi(getAllDomainsForCombo())

		const domainsData = useMemo(
			() => excludeCustomDomains(domains.data),
			[domains.data],
		)

		const getDomain = (id: number | undefined) =>
			domainsData.find((dom) => dom.id === id)

		const initLocalValues = () => ({
			domainId: techCol?.domainId,
			notNull: getDomain(techCol?.domainId)?.notNullFlag,
			nameValue: techCol?.name,
		})

		const [domainInheritData, setDomainInheritData] =
			useState<DomainInheritDataState>(initLocalValues())

		const [selectedStereotypeValue, setSelectedStereotypeValue] = useState<
			undefined | number
		>(techCol?.stereotypeColumnId)

		const { onConfirmationTechnicalColumn } = useConfirmationTechnicalColumn()

		const handleSubmit = async (form: Partial<TechnicalColumnDto>) => {
			auditFieldsRemove(form)

			if (!form.ordering && typeof position === 'number') {
				form.ordering = position + 1
			}

			const postProcess = (dto: TechnicalColumnDto | null) => {
				if (dto) {
					onSave()
					onClose()
				}
			}

			await onConfirmationTechnicalColumn(
				form as TechnicalColumnDto,
				postProcess,
			)
		}

		const onDomainChange = (id: FormValue) => {
			const domain = getDomain(id as number)

			setDomainInheritData({
				...domainInheritData,
				notNull: domain?.notNullFlag || undefined,
				domainId: id as number,
			})
		}

		const debouncedOnNameChange = debounce(onNameChange, 500)

		const onChange = (value: FormValue, field: string) => {
			setDomainInheritData({
				...domainInheritData,
				[field]: value,
			})
		}

		if (stereotypesColumn.loading) {
			return <Loader loaded={false} $absolute />
		}

		return (
			<>
				<ForwardedModalForm<TechnicalColumnDto>
					open={true}
					onClose={onClose}
					contentStyle={{
						width: '40%',
						maxHeight: '85%',
						overflow: 'auto',
					}}
					initialValues={{
						...techCol,
						...auditFieldsAdd(techCol),
					}}
					header={techCol ? t('UPDATE_TECH_COLUMN') : t('CREATE_TECH_COLUMN')}
					onSubmit={handleSubmit}
					ref={ref}
				>
					<SplitFields>
						<TextFormField
							title={t('TECH_COLUMN_NAME')}
							onChange={(v) =>
								debouncedOnNameChange(dispatch, ref, v as string)
							}
							name="name"
							required
						/>
						<FormField name="code" title={t('TECH_COLUMN_CODE')}>
							<CodeInput name={domainInheritData.nameValue} readonly />
						</FormField>
					</SplitFields>
					<SplitFields>
						<TextAreaFormField
							title={t('TECH_COLUMN_DESCRIPTION')}
							name="description"
						/>
						<TextAreaFormField
							title={t('TECH_COLUMN_COMMENT')}
							name="comment"
						/>
					</SplitFields>

					<SelectFormField
						isNumeric
						valueKey={'id'}
						labelKey={'name'}
						required
						title={t('TECH_COLUMN_DOMAIN')}
						name="domainId"
						onChange={(v) => onDomainChange(v)}
						options={domainsData}
					/>
					<CheckBoxFormField
						disabled={true}
						title={t('TECH_COLUMN_NOT_NULL')}
						name="notNull"
						formless={true}
						onChange={onChange}
						initialValue={domainInheritData.notNull}
					/>
					<SelectFormField
						isNumeric
						valueKey={'id'}
						labelKey={'name'}
						title={t('TECH_COLUMN_STEREOTYPE_COLUMN')}
						name="stereotypeColumnId"
						options={stereotypesColumn.data || []}
						required
						onChange={(v) => setSelectedStereotypeValue(v as number)}
					/>
					{selectedStereotypeValue === columnStereotypeValue.VIRTUAL_COLUMN && (
						<AceEditorField
							name="virtualColumnScript"
							title={t('TECH_COLUMN_SCRIPT')}
						/>
					)}

					<Stereotypes>
						<SelectFormField
							isNumeric
							valueKey={'id'}
							labelKey={'name'}
							isMulti
							title={t('TECH_COLUMN_STEREOTYPE')}
							name="stereotypeIds"
							options={stereotypes}
						/>
					</Stereotypes>
					<AuditForm $isUpdating={!!techCol} />
				</ForwardedModalForm>
			</>
		)
	},
)
