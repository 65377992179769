import { ViewData, ViewPermission } from '@/endpoints/schemas'

export interface OpenedViewData {
	form: ViewDataForm
	original: ViewDataForm
	tab: ViewTab
	dirty: boolean
	parsedEditMode: boolean
}

export type ViewPermissionFlat = Omit<ViewPermission, 'user'> & {
	userId?: number
	userName?: string
	userCode?: string
	userDescription?: string
	userComment?: string
}

export type ViewDataForm = Omit<Partial<ViewData>, 'objectPermissions'> & {
	objectPermissions: ViewPermissionFlat[]
}

export enum ViewTab {
	Overview = 'Overview',
	Columns = 'Columns',
	Sources = 'Sources',
	Permissions = 'Permissions',
	Preview = 'Preview',
	Deployments = 'Deployments',
	Validation = 'Validation',
	Dependencies = 'Dependencies',
}
