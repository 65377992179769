import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { App } from './pages/App/App'
import { store } from './store'
import { AppContextWrapper } from './context/AppContext/AppContextWrapper'
import { AuthContextProvider } from './context/AuthContext/AuthContext'
import * as theme from '@/styles'
import 'jsondiffpatch/formatters/styles/html.css'
import 'split-pane-react/esm/themes/default.css'
import './index.css'

type RootProps = {
	token?: string
}

export const Root = ({ token }: RootProps) => {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<AuthContextProvider>
					<AppContextWrapper>
						<App token={token} />
					</AppContextWrapper>
				</AuthContextProvider>
			</ThemeProvider>
		</Provider>
	)
}
