import React from 'react'

import { splitProps } from '@/utils/collections'
import FormField, { FormFieldProps, FormlessFormField } from '../FormField'
import Number, { NumberProps } from '../Input/Number/Number'

/**
 * Form field with text input.
 */
export default class NumberFormField extends React.PureComponent<
	Omit<NumberProps, 'onChange'> & FormFieldProps & { formless?: boolean }
> {
	render() {
		const fieldProps = { ...this.props }

		const inputProps = splitProps(fieldProps, [
			'placeholder',
			'className',
			'onEnter',
			'decimals',
			'step',
			'numberFormatProps'
		])

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<Number {...inputProps} />
			</FF>
		)
	}
}
