import React from 'react'
import { Loader, Button } from '@/components'
import { FileImport } from '@/components/FileImport/FileImport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useAppContext } from '@/hooks'

type Props = {
	loading: boolean
	file: File | null
	handleFile: (files: File[]) => void
	onRemoveFile: () => void
}

export const UploadFile = ({
	loading,
	file,
	handleFile,
	onRemoveFile,
}: Props) => {
	const { t } = useAppContext()

	return (
		<>
			<Loader loaded={!loading} $absolute />
			{!file && (
				<FileImportWrapper>
					<FileImport onFiles={handleFile} />
				</FileImportWrapper>
			)}
			{file && (
				<FileInfo>
					<FontAwesomeIcon icon={faFileAlt} />
					<span>{file.name}</span>
					<Button
						schema="transparent"
						icon={faTimes}
						onClick={() => onRemoveFile()}
					>
						{t('REMOVE_FILE')}
					</Button>
				</FileInfo>
			)}
		</>
	)
}

const FileImportWrapper = styled.div`
	margin-bottom: 17px;
`

const FileInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px;

	span {
		margin-left: 5px;
	}
`
