import { TablePropertiesContextState, Actions, ActionTypeEnum } from './types'

export const reducer = (
	state: TablePropertiesContextState,
	action: Actions
): TablePropertiesContextState => {
	switch (action.type) {
		case ActionTypeEnum.FOCUSED_SET:
		case ActionTypeEnum.DIR_LAST_SET:
		case ActionTypeEnum.COLUMNS_WIDTHS_SET:
		case ActionTypeEnum.DRAG_OVER_INDEX_SET:
		case ActionTypeEnum.DRAGGING_ROW_INDEX_SET:
		case ActionTypeEnum.SCROLLBAR_WIDTH_SET:
		case ActionTypeEnum.SYNC_CONTEXT_AND_PROPS:
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}
