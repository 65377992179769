import { useRef, useEffect, useState } from 'react'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'

export const useMemoObject = <Data extends object>(data: Data) => {
	const [object, setObject] = useState(data)
	const cachedData = useRef<Data>(data)

	useEffect(() => {
		if (isObject(data) && isObject(cachedData.current)) {
			if (!isEqual(data, cachedData.current)) {
				cachedData.current = data
				setObject(data)
			}
		}

		if (Array.isArray(data) && Array.isArray(cachedData.current)) {
			if (!isEqual(data, cachedData.current)) {
				cachedData.current = data
				setObject(data)
			}
		}
	}, [data])

	return object
}
