import React, { ChangeEventHandler } from 'react'
import { StyledLabel } from './styles'

interface NewCheckboxProps {
	checked?: boolean
	disabled?: boolean
	label?: string
	name?: string
	onChange?: ChangeEventHandler<HTMLInputElement>
}

export const NewCheckBox = ({
	checked,
	disabled,
	label,
	name,
	onChange,
}: NewCheckboxProps) => {
	return (
		<div className={`flex items-center justify-center w-full h-full`}>
			<StyledLabel className="form-control" disabled={disabled}>
				<input
					type="checkbox"
					name={name}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
				/>
				{label}
			</StyledLabel>
		</div>
	)
}
