import React from 'react'
import styled, { css } from 'styled-components'
import { range } from '@/utils/collections'
import { stopEvent } from '@/utils/events'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { LightTable, Loader } from '@/components'
import { LightTableProps } from '@/components/LightTable'

type PaginatedTableProps<T> = LightTableProps<T> & {
	onPage: (page: number) => void
	currentPage: number
	pages: number
}

const PAGE_RANGE = 3

export const PaginatedTable = <T extends object>({
	onPage,
	currentPage,
	pages,
	...lightTableProps
}: PaginatedTableProps<T>) => {
	return (
		<LoaderContainer>
			<Loader loaded={!lightTableProps.isLoading} $absolute />

			<LightTable<T> {...lightTableProps} />

			{!!pages && (
				<Pages>
					<Page
						onClick={stopEvent(
							() => currentPage > 1 && onPage(currentPage - 1),
						)}
						$isDisabled={currentPage === 1}
					>
						<FontAwesomeIcon icon={faChevronLeft} />
					</Page>
					{currentPage > PAGE_RANGE + 1 && (
						<Page
							$isCurrent={currentPage === 0}
							onClick={stopEvent(() => onPage(1))}
						>
							1
						</Page>
					)}
					{currentPage > PAGE_RANGE + 1 && <Separator>...</Separator>}
					{range(
						Math.max(1, currentPage - PAGE_RANGE),
						Math.min(currentPage + PAGE_RANGE, pages),
					).map((page) => (
						<Page
							$isCurrent={currentPage === page}
							key={page}
							onClick={stopEvent(() => onPage(page))}
						>
							{page}
						</Page>
					))}
					{currentPage < pages - PAGE_RANGE && <Separator>...</Separator>}
					{currentPage < pages - PAGE_RANGE && (
						<Page
							$isCurrent={currentPage === pages}
							onClick={stopEvent(() => onPage(pages))}
						>
							{pages}
						</Page>
					)}

					<Page
						onClick={stopEvent(
							() => currentPage < pages && onPage(currentPage + 1),
						)}
						$isDisabled={currentPage === pages}
					>
						<FontAwesomeIcon icon={faChevronRight} />
					</Page>
				</Pages>
			)}
		</LoaderContainer>
	)
}

const Pages = styled.div`
	display: flex;
	justify-content: flex-end;
`

const Page = styled.div<{ $isCurrent?: boolean; $isDisabled?: boolean }>`
	padding: 10px 5px;
	cursor: pointer;
	min-width: 20px;
	text-align: center;
	user-select: none;

	${(props) =>
		props.$isCurrent &&
		css`
			font-weight: bold;
		`}

	${(props) =>
		props.$isDisabled &&
		css`
			color: #aaa;
		`}
`

const Separator = styled.div`
	padding: 10px;
`

const LoaderContainer = styled.div`
	position: relative;
`
