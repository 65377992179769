import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { TextAreaInput, FormInput } from '../PropertyFieldStyles'
import { ValueWrapper } from '../ValueWrapper'
import { useClickOutside } from '@/hooks'
import { Portal } from '@/components/Portal/Portal'
import { TableColumnProps } from '@/types'

type Props<T> = {
	value: string
	disabled: boolean
	onFocus: () => void
	onBlur: () => void
	isFocused: boolean
	onChange: (name: string, value: string | boolean) => void
	prop: TableColumnProps<T>
	fromProps?: boolean
	autoFocus?: boolean
	onDisableEditing?: () => void
}

export const CLASSNAME_TEXTAREA_POPUP = 'className__textarea--popup'

export const TextArea = <T,>({
	value,
	disabled,
	onFocus,
	onBlur,
	isFocused,
	onChange,
	fromProps,
	prop,
	autoFocus,
	onDisableEditing,
}: Props<T>) => {
	// focus textarea popup in table view
	useEffect(() => {
		if (!isFocused && !fromProps) {
			onFocus()
		}
	}, [isFocused, onFocus, fromProps])

	return (
		<>
			{fromProps && (
				<FormInput
					as="textarea"
					value={
						typeof value === 'string' || typeof value === 'number' ? value : ''
					}
					name={prop.field}
					autoComplete="off"
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
						onChange(e.target.name, e.target.value)
					}
					disabled={disabled}
					onFocus={onFocus}
					onBlur={onBlur}
					$isFocused={isFocused}
					autoFocus={autoFocus}
					$fromProps={fromProps}
					rows={5}
				/>
			)}
			{!fromProps && isFocused && (
				<TextAreaTable
					value={value}
					disabled={disabled}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={onChange}
					fromProps={fromProps}
					prop={prop}
					isFocused={true}
					onDisableEditing={onDisableEditing}
				/>
			)}
		</>
	)
}

const TextAreaTable = <T,>({
	value,
	disabled,
	onFocus,
	onBlur,
	onChange,
	prop,
	onDisableEditing,
}: Props<T>) => {
	const refInput = useRef<HTMLTextAreaElement>(null)
	const refChangeFirst = useRef(true)

	const [referenceElement, setReferenceElement] =
		useState<HTMLDivElement | null>(null)

	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null,
	)

	const { styles, attributes } = usePopper(referenceElement, popperElement)

	useEffect(() => {
		refInput.current?.focus()
	}, [])

	useClickOutside(
		() => {
			onDisableEditing?.()
			onBlur()
		},
		refInput?.current,
	)

	return (
		<>
			<ValueWrapper setRefWrapper={setReferenceElement}>{value}</ValueWrapper>
			<Portal>
				<div
					ref={setPopperElement}
					style={styles.popper}
					{...attributes.popper}
				>
					<TextAreaInput
						variant="textarea"
						value={typeof value === 'string' ? value : ''}
						rows={14}
						name={prop.field}
						onChange={(e: any) => {
							const isFirstCharNewLine =
								e.target.value.length === 1 &&
								e.target.value.match(/\r\n|\r|\n/g)

							// do not add newline on enter
							if (refChangeFirst.current && isFirstCharNewLine) {
								refChangeFirst.current = false

								return
							}

							onChange(prop.field as string, e.target.value)
						}}
						disabled={disabled}
						style={{
							zIndex: 6,
							padding: '6px',
						}}
						onBlur={() => {
							onDisableEditing?.()
							onBlur()
						}}
						onFocus={onFocus}
						$isFocused
						ref={refInput}
						className={CLASSNAME_TEXTAREA_POPUP}
					/>
				</div>
			</Portal>
		</>
	)
}
