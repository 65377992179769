import styled, { css } from 'styled-components'

export const HelpBlock = styled.div<{
	hideWhenNotActive: boolean
	$focused: boolean
	isOnTop: boolean
}>`
	position: absolute;
	left: 14px;
	z-index: 4;

	padding: 5px 10px;

	${(props) => css`
		background: ${props.theme.colors.form.error.background};
		color: ${props.theme.colors.form.error.color};

		&::before {
			content: ' ';
			position: absolute;
			top: -10px;
			left: 10%;
			margin-left: -5px;
			border: 5px solid transparent;
			border-bottom-color: ${props.theme.colors.form.error.background};
		}
	`}

	${(props) =>
		props.hideWhenNotActive &&
		css`
			opacity: 0;
			display: none;
		`}

	${(props) =>
		props.$focused &&
		css`
			opacity: 1;
			display: inherit;
		`}

	${(props) =>
		props.isOnTop &&
		css`
			white-space: normal;
			min-width: 120px;
			bottom: 30px;
			text-align: left;

			&::before {
				bottom: 0;
				transform: rotate(180deg);
				transform-origin: bottom;
			}
		`}
`

export const Value = styled.div<{
	$withActionButton: boolean
	$isReadOnly: boolean
	$hasError: boolean
}>`
	position: relative;

	${(props) =>
		props.$withActionButton &&
		css`
			width: 100%;
			display: flex;

			input,
			select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			button {
				height: 100%;
				padding: 5px;
				width: 23px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-left: 0;
			}
		`}

	${(props) =>
		props.$hasError &&
		css`
			input,
			select {
				border-color: ${props.theme.colors.input.error.border};
				background: ${props.theme.colors.input.error.background};
			}
		`}

	${(props) =>
		props.$isReadOnly &&
		css`
			/* padding: ${props.theme.input.padding}; */
		`}
`

export const Container = styled.div<{
	$isHorizontal: boolean
	$compact: boolean
}>`
	margin-right: ${(props) => (props.$compact ? '-1px' : '6px')};
	margin-bottom: ${(props) => (props.$compact ? '-1px' : '6px')};
	flex: 1;
	${(props) =>
		props.$compact &&
		css`
			width: 0;
		`}

	&:hover {
		z-index: 2;
	}

	&:focus-within {
		z-index: 3;
		${HelpBlock} {
			opacity: 1;
			display: inherit;
		}
	}

	${(props) =>
		props.$isHorizontal &&
		css`
			margin-right: 10px;

			:last-child {
				margin-right: 0;
			}
		`}
`
export const FormWrap = styled.form<{ $isHorizontal: boolean }>`
	${(props) =>
		props.$isHorizontal &&
		css`
			display: flex;
			align-items: center;
		`}
`
