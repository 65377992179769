import React from 'react'
import { TableData } from '@/endpoints/schemas'
import { OpenedTableData, TableColumnFlat } from '@/store/modules/table/types'
import { TableAndProperties } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { UpdateDeepPartial } from '@/store/utils'
import { createEmptyIndex } from '@/store/modules/table/helpers'
import { useIndexProperties } from './useIndexProperties'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { NamingTypeEnum } from '@/types'

type IndexesComponentProps = {
	node: StructureDto
	data: OpenedTableData
	onChange: (v: UpdateDeepPartial<TableData>) => void
	technicalColumns: TableColumnFlat[]
}

const IndexesComponent = ({
	node,
	data,
	onChange,
	technicalColumns,
}: IndexesComponentProps) => {
	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
	)

	const properties = useIndexProperties({
		columns: [...data.form.columns.filter((c) => c.name), ...technicalColumns],
		canGenerateNaming,
	})

	return (
		<TableAndProperties
			idKey="id"
			node={node}
			data={data.form}
			createEmpty={createEmptyIndex}
			itemsKey="indexes"
			idCounterKey="indexesLastId"
			onChange={onChange}
			isRowOrderable
			properties={properties}
			tabKey="index"
			maxWidth={1200}
		/>
	)
}

export const Indexes = React.memo(IndexesComponent)
