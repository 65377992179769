import React from 'react'
import styled from 'styled-components'
import { TreePanel } from './components/TreePanel/TreePanel'
import { NavigationSplitPane } from '../../components/NavigationSplitPane'
import { TabsDetailView } from './components/TabsDetailView/TabsDetailView'

const HomeComponent = () => {
	return (
		<NavigationSplitPane
			navigationPanel={
				<TreeContainer>
					<TreePanel />
				</TreeContainer>
			}
		>
			<ViewContainer>
				<TabsDetailView />
			</ViewContainer>
		</NavigationSplitPane>
	)
}

const TreeContainer = styled.div`
	overflow: auto;
	height: 100%;
	display: flex;
`

const ViewContainer = styled.div`
	flex-direction: column;
	overflow: auto;
	height: 100%;
	display: flex;
`

export const Home = HomeComponent
