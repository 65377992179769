import React from 'react'
import memoizeOne from 'memoize-one'
import cn from 'classnames'
import { getQsValue } from '@/utils/querystring'
import Tooltip from '../Tooltip/Tooltip'
import { withForm } from './FormContext'
import { FormFieldContext } from './FormFieldContext'
import {
	AnyObject,
	FormFieldProps,
	FormFieldRecap,
	FormFieldState,
	FormInput,
} from './types'
import { Label } from './Label'
import { shallowEqual, ConnectedProps, connect } from 'react-redux'
import { EnhancedPureComponentWithContext } from '../EnhancedComponents'
import Button from '../Button/Button'
import { isEqual, isNil } from 'lodash'
import { Container, HelpBlock, Value } from './styles'
import { isEmpty } from './utils'
import { FormValue } from '@/types'
import { RootState } from '@/store'

const mapStateToProps = (state: RootState) => ({
	formHighlights: state.formHighlights,
})

type PropsFromRedux = Partial<ConnectedProps<typeof connector>>

export class FormlessFormField<
	T = AnyObject,
> extends EnhancedPureComponentWithContext<
	FormFieldProps<T> & PropsFromRedux,
	FormFieldState
> {
	static defaultProps = {
		labelCols: 2,
		inputCols: 5,
		hideTitle: false,
		hideTooltip: false,
	}

	state = {
		error: null,
		touched: false,
		dirty: false,
		validating: false,
		isFocused: false,
		value: null as FormValue,
		isFieldHighlighted: false,
	}

	private input!: FormInput<T>
	private lastValidationId = 0

	async componentDidMount() {
		if (this.props.form) {
			this.props.form.register(this)

			if (this.props.form.withQueryString) {
				let value = getQsValue(this.props.name)

				if (value !== undefined) {
					if (value === 'false') {
						value = false
					}

					if (value === 'true') {
						value = true
					}

					if (typeof value === 'number') {
						value = value.toString()
					}

					await this.setValue(value, false)
				}
			}
		} else {
			if (this.props.initialValue) {
				await this.setValue(this.props.initialValue, false, true)
			}
		}

		if (this.props.validateOnMount) {
			await this.validate()
		}
	}

	componentWillUnmount() {
		if (this.props.form) {
			this.props.form.unregister(this)
		}
	}

	handleChange = async (
		updatedValue: FormValue,
		internal = false,
		fireFormChange = true,
	) => {
		const original = this.state.value

		await this.setState({
			value: updatedValue,
			touched: internal ? this.state.touched : true,
		})

		const { value } = this.state
		const { name, form, onChange } = this.props

		if (original !== updatedValue && fireFormChange) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			form && form.onFieldChange(this, value)
			onChange && onChange(value, name)
		}

		this.validate()
	}

	handleFocus = (e: React.FocusEvent) => {
		this.setState({ isFocused: true })

		if (this.props.onFocus) {
			this.props.onFocus(e)
		}
	}

	handleBlur = (e: React.FocusEvent) => {
		const { touched } = this.state

		this.setState({ isFocused: false, dirty: touched })

		if (this.props.onBlur) {
			this.props.onBlur(e)
		}
	}

	handleRegister = (input: FormInput<T>) => {
		this.input = input
	}

	setValue = async (
		value: FormValue,
		fireFormChange = true,
		isDefault = false,
	) => {
		return this.handleChange(
			value,
			true,
			!!(fireFormChange || (isDefault && this.props.fireDefaultValue)),
		)
	}

	validate = async (submitted = false) => {
		const { name, form, onValidChange } = this.props
		const { dirty } = this.state

		// Validation id helps us track concurrent validations and prevent race conditions
		this.lastValidationId++
		const thisValidationId = this.lastValidationId

		const error = await this.doValidate(submitted)

		// Only process the results when this is the last validation
		if (thisValidationId === this.lastValidationId) {
			await this.setState({
				error,
				validating: false,
				dirty: dirty || submitted,
			})

			if ((submitted || dirty) && form) {
				form.onFieldValidated(name, error === null)
			}

			if (onValidChange) {
				onValidChange(error === null, name, error)
			}
		}
	}

	shouldRecap = () => !this.props.skipRecap

	recap = (): FormFieldRecap => {
		const value =
			this.input && this.input.recap
				? this.input.recap(this.state.value)
				: this.state.value

		return {
			title: this.props.title,
			value: value || undefined,
		}
	}

	// @TODO: Optimization: Save context object when mounted or use state
	getContext = memoizeOne(
		(
			props: FormFieldProps<T>,
			value: FormValue,
			isFieldHighlighted: boolean,
		): FormFieldContext<T> => {
			const {
				name,
				form,
				required,
				disabled,
				isReadOnly,
				isCompact,
				fieldIsLoading,
			} = props

			const { id = null, submitting = false } = form || {}
			const inputId = `${id}-${name}`

			let isDisabled = submitting

			if (typeof disabled === 'boolean') {
				isDisabled = disabled
			} else if (typeof disabled === 'function') {
				isDisabled = disabled(form ? form.getValues() : {})
			}

			let loadingStatus: boolean = false

			if (fieldIsLoading) {
				loadingStatus = true
			} else if (form) {
				loadingStatus = form.loading
			}

			return {
				disabled: isReadOnly || (form && form.disabled) || isDisabled,
				id: inputId,
				name,
				onChange: this.handleChange,
				register: this.handleRegister,
				value,
				required,
				loading: loadingStatus,
				onFocus: this.handleFocus,
				onBlur: this.handleBlur,
				withQueryString: form ? form.withQueryString : false,
				isHorizontal: form ? form.isHorizontal : false,
				isReadOnly: isReadOnly,
				isCompact: isCompact,
				isFieldHighlighted,
			}
		},
	)

	handleActionButtonClick = () => {
		const { actionButtonOnClick } = this.props

		if (actionButtonOnClick) {
			actionButtonOnClick(this.state.value)
		}
	}

	componentDidUpdate(prevProps: FormFieldProps<T>) {
		if (
			!shallowEqual(prevProps.initialValue, this.props.initialValue) &&
			!shallowEqual(this.props.initialValue, this.state.value)
		) {
			this.setValue(this.props.initialValue, false, true)
		}

		if (
			this.props.form?.enableFieldHighlight &&
			this.props.formHighlights?.active &&
			this.props.formHighlights.prevVersionValues
		) {
			const originalValue =
				this.props.formHighlights.prevVersionValues[this.props.name]

			if (
				!isEqual(originalValue, this.state.value) &&
				!(isNil(originalValue) && isNil(this.state.value))
			) {
				this.setState({ isFieldHighlighted: true })
			} else {
				this.setState({ isFieldHighlighted: false })
			}
		}

		if (!this.props.formHighlights?.active && this.state.isFieldHighlighted) {
			this.setState({ isFieldHighlighted: false })
		}
	}

	render() {
		const {
			name,
			title,
			hideTitle,
			children,
			required,
			form,
			tooltip,
			hideTooltip,
			inputTooltip,
			showTitlePlaceholder,
			showErrorTop,
			hideErrorOnBlur,
			actionButtonOnClick,
			actionButtonIcon,
			actionButtonDisabled,
			actionButtonTooltip,
			isReadOnly,
			className,
			isCompact,
			customLabel,
		} = this.props

		const { id = null, isHorizontal = false } = form || {}
		const { error, isFocused, dirty, isFieldHighlighted } = this.state
		const inputId = `${id}-${name}`

		let childrenContainer = children

		if (actionButtonOnClick) {
			childrenContainer = (
				<React.Fragment>
					{children}
					<span>
						<Button
							icon={actionButtonIcon}
							disabled={actionButtonDisabled}
							onClick={this.handleActionButtonClick}
							tooltip={actionButtonTooltip}
						/>
					</span>
				</React.Fragment>
			)
		}

		const FFC = FormFieldContext as React.Context<FormFieldContext<T>>

		return (
			<FFC.Provider
				value={this.getContext(
					this.props,
					this.state.value,
					isFieldHighlighted,
				)}
			>
				<Container
					$isHorizontal={isHorizontal}
					className={cn('form-field', className)}
					$compact={!!isCompact}
				>
					<Label
						hideTitle={!!hideTitle}
						hideTooltip={!!hideTooltip}
						inputId={inputId}
						isCompact={isCompact}
						isHorizontal={isHorizontal}
						required={!!required}
						showTitlePlaceholder={!!showTitlePlaceholder}
						title={title}
						tooltip={tooltip}
						customLabel={customLabel}
						isFieldHighlighted={isFieldHighlighted}
					/>

					<Value
						$isReadOnly={!!isReadOnly}
						$withActionButton={!!actionButtonOnClick}
						$hasError={dirty && !!error}
					>
						{inputTooltip ? (
							<Tooltip content={inputTooltip}>{childrenContainer}</Tooltip>
						) : (
							childrenContainer
						)}
						{dirty && error && (
							<HelpBlock
								hideWhenNotActive={!!hideErrorOnBlur}
								$focused={isFocused}
								isOnTop={!!showErrorTop}
							>
								{error}
							</HelpBlock>
						)}
					</Value>
				</Container>
			</FFC.Provider>
		)
	}

	private doValidate = async (submitted: boolean) => {
		const { value, dirty } = this.state
		const { validators, name, required, form } = this.props
		const { locale } = this.context

		const getValues = form ? form.getValues : () => ({})

		const onFieldValidating =
			(submitted || dirty) && form && form.onFieldValidating

		if (required && isEmpty(value)) {
			return locale.translate('VALIDATOR_REQUIRED')
		}

		if (this.input && this.input.validate) {
			const result = this.input.validate(value, getValues())

			if (typeof result === 'string') {
				return result
			}
		}

		if (!validators) {
			return null
		}

		for (const validator of validators) {
			let error: string | null | undefined | Promise<string | null | undefined>

			// Value can be object, right now only in case of NumberRange
			if (value && typeof value === 'object' && !Array.isArray(value)) {
				for (const key of Object.keys(value) as ['from', 'to']) {
					const sub = validator(value[key], getValues(), locale)

					if (sub) {
						error = sub
						break
					}
				}
			} else {
				error = validator(value, getValues(), locale)
			}

			// Normal error occured
			if (typeof error === 'string') {
				return error
			}

			// Async validator
			// @TODO: This stops other validations!
			if (error instanceof Promise) {
				if (onFieldValidating) {
					onFieldValidating(name)
				}

				const delayedError = await error

				if (typeof delayedError === 'string') {
					return delayedError
				} else {
					return null
				}
			}
		}

		return null
	}
}

const connector = connect(mapStateToProps)

export default withForm(
	connector(FormlessFormField as any) as any,
) as typeof FormlessFormField
