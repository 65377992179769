import { EffectCallback, DependencyList, useState, useEffect } from 'react'

export const useEffectWithoutMount = (
	effect: EffectCallback,
	deps?: DependencyList
) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		if (!mounted) {
			setMounted(true)

			return
		}

		return effect()
	}, deps)
}
