import React from 'react'
import { useAppContext } from '@/hooks'
import {
	Button,
	LightTable,
	Loader,
	DialogWrapper,
	Container,
} from '@/components'
import { faPuzzlePiece, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TemplateModal } from './components/TemplateModal'
import { TemplateListDto } from '@/endpoints/models'
import { useApi } from '@/endpoints/hooks'
import { getAllTemplates } from '@/endpoints'
import {
	TitleContainer,
	TitleText,
	TitleIcon,
	TitleActions,
} from '../../styles'
import { useTemplatesColumns } from './hooks/useTemplatesColumns'
import { TemplateFilter } from './components/TemplateFilter'
import { useFilter } from '../../hooks/useFilter'
import { useTemplatesFilteredData } from './hooks/useTemplatesFilteredData'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'
import { TemplateFilterValues } from './components/types'

interface TemplatesProps {
	textAreaHeight?:string
}

export const Templates = ({textAreaHeight}:TemplatesProps) => {
	const { t } = useAppContext()
	const templates = useApi(getAllTemplates())
	const { filter, onChangeFilter } = useFilter<TemplateFilterValues>()

	const handleSave = useSettingsPageSaveHandler(templates)

	const columns = useTemplatesColumns(handleSave)
	const data = useTemplatesFilteredData(filter, templates.data)

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faPuzzlePiece} />
				<TitleText>{t('TEMPLATES')}</TitleText>
				<TitleActions>
					<DialogWrapper
						dialog={(opened, onClose) =>
							opened && <TemplateModal textAreaHeight={textAreaHeight} onSave={handleSave} onClose={onClose} />
						}
					>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<TemplateFilter onChangeFilter={onChangeFilter} />
				<Loader loaded={!templates.loading} $absolute />

				<LightTable<TemplateListDto>
					data={data}
					columns={columns}
					keyField={'id'}
					isLoading={templates.loading}
				/>
			</Container>
		</>
	)
}
