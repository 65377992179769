import { useApiRequest } from '@/endpoints/hooks'
import {
	importStructureCsvIntoNode,
	importStructureFile,
	importStructureFileIntoNode,
} from 'src/endpoints'
import { loadNodeChildren, loadSystemNodes } from '@/store/modules/node/actions'
import { loadNode } from '@/store/modules/node/general-actions'
import { setApiError } from '@/store/modules/apiError/actions'
import { CsvImportRowDto, StructureExportDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import { useEffect, useState } from 'react'

export type ImportStructureProps = {
	namesNormalisation: 'NONE' | 'UPPERCASE_FIRST_CHARS' | 'UPPERCASE_ALL'
	caseSensitive: boolean
	createNewDomains: boolean
	generateCodes: boolean
	importAllColumns: boolean
}

export const useImport = (onImported: () => void, nodeId?: number) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const [file, setFile] = useState(null as File | null)
	const [structure, setStructure] = useState(null as StructureExportDto | null)
	const [csvData, setCsvData] = useState(null as string | null)
	const [csvRows, setCsvRows] = useState<CsvImportRowDto[]>()
	const [importProps, setImportProps] = useState<ImportStructureProps>()
	const [loading, setLoading] = useState(false)
	const [type, setType] = useState(null as string | null)
	const request = useApiRequest()

	useEffect(() => {
		if (!file) {
			setStructure(null)
			setCsvData(null)

			return
		}

		const reader = new FileReader()

		reader.onload = () => {
			if (file.type === 'application/json') {
				try {
					const structure = JSON.parse(
						reader.result as string,
					) as StructureExportDto

					setStructure(structure)
					setCsvData(null)
					setType('json')
				} catch {
					dispatch(
						setApiError({
							title: t('IMPORT_ERROR_TITLE'),
							description: t('IMPORT_ERROR_DESCRIPTION'),
							message: null,
						}),
					)

					setStructure(null)
					setCsvData(null)
					setFile(null)
				}
			} else if (
				file.type === 'application/vnd.ms-excel' ||
				file.type === 'text/csv'
			) {
				setCsvData(reader.result as string)
				setStructure(null)
				setType('csv')
			} else {
				dispatch(
					setApiError({
						title: t('IMPORT_ERROR_TITLE'),
						description: t('IMPORT_ERROR_DESCRIPTION'),
						message: null,
					}),
				)

				setStructure(null)
				setFile(null)
				setCsvData(null)
			}
		}

		reader.readAsText(file)
	}, [file])

	const handleFile = (files: File[]) => {
		setFile(files[0])
	}

	const handleImport = async () => {
		if (structure) {
			setLoading(true)

			if (nodeId) {
				await request(importStructureFileIntoNode(nodeId, structure))
			} else {
				await request(importStructureFile(structure))
			}

			// update nodes after import in tree panel
			if (nodeId) {
				dispatch(loadNodeChildren(nodeId))
				dispatch(loadNode(nodeId))
			} else {
				dispatch(loadSystemNodes())
			}

			onImported()

			setLoading(false)
		} else if (csvData) {
			setLoading(true)

			if (nodeId && csvRows && importProps) {
				await request(importStructureCsvIntoNode(nodeId, csvRows, importProps))
			}

			// update nodes after import in tree panel
			if (nodeId) {
				dispatch(loadNodeChildren(nodeId))
				dispatch(loadNode(nodeId))
			} else {
				dispatch(loadSystemNodes())
			}

			onImported()

			setLoading(false)
		}
	}

	return {
		handleFile,
		handleImport,
		loading,
		structure,
		csvData,
		setCsvRows,
		setImportProps,
		file,
		setFile,
	}
}
