import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import { useCallback, useMemo, useRef, useState } from 'react'

import { PropertiesChangeCallback } from '@/components/Properties/types'
import { StructureDto } from '@/endpoints/models'
import {
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
	useTableColumns,
} from '@/hooks'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { UpdateDeepPartial } from '@/store/utils'
import { arrayMoveIndex, NativeMap } from '@/utils'

import { PanelProperties, TableProperties } from '../Properties'
import { MainTable } from './MainTable'
import { LastEditedCellProps, TableAndPropertiesProps } from './types'
import { findOriginalIndex, updateTableAndPropertiesValues } from './utils'

export const TableAndProperties = <Data extends object, Item extends object>({
	properties,
	data,
	node,
	onChange,
	itemsKey,
	createEmpty,
	tabKey,
	isReadonly,
	idKey = 'id' as any,
	existsKey = 'name',
	idCounterKey,
	errors,
	panelPropertiesMinWidth,
	panelPropertiesDefaultOpen,
	disablePanelProperties,
	technicalColumns,
	maxWidth,
	children,
}: TableAndPropertiesProps<Item>) => {
	const dispatch = useAppDispatch()
	const openedTabs = useAppStore((store) => store.tab.openedTabs)
	const nodeId = useAppStore((store) => store.tab.selectedId)
	const systemsSlice = useAppStore((state) => state.system.systems)
	const nodes = useAppStore((state) => state.node.nodes)
	const systemId = findSystemNodeId(node, nodes as NativeMap<StructureDto>)
	const isCodeLowerCase = systemsSlice[systemId]?.form
		?.letterCaseToggle as boolean
	const tabInfo = openedTabs.find((tab) => tab.nodeId === nodeId)
	const isEditMode = Boolean(tabInfo?.editMode)
	const [refWrapper, setRefWrapper] = useState<HTMLDivElement | undefined>()

	const isDeletingRef = useRef(false)

	const refCallback = useCallback((node: any) => {
		if (node) {
			setRefWrapper(node)
		}
	}, [])

	const [lastEditedCell, setLastEditedCell] = useState<LastEditedCellProps>({
		rowId: -1,
		fieldName: '',
	})

	const lastEditedCellRef = useRef<HTMLTableCellElement>(null)

	const items = useMemo<any>(() => {
		const dataItems = data[itemsKey]
		const isLastItemExists = get(last(dataItems), existsKey)
		const shouldAddNewItem = isEmpty(dataItems) || isLastItemExists
		const newItem = createEmpty(dataItems)

		return isEditMode && shouldAddNewItem ? [...dataItems, newItem] : dataItems
	}, [data, itemsKey, existsKey, createEmpty, isEditMode])

	const columns = useTableColumns({
		tableData: [...items, ...(technicalColumns ?? [])],
		properties,
		refWrapper,
		lastEditedCell,
		lastEditedCellRef,
		node,
	})

	const handleChange: PropertiesChangeCallback = useCallback(
		async ({ rowId, fieldId, fieldName, fieldValue }) => {
			const originalIndex = findOriginalIndex(fieldId, items, idKey)
			const rowIndex = fieldValue?.index
			const isFieldIdInItems = originalIndex < 0

			if (isFieldIdInItems || isDeletingRef.current) {
				isDeletingRef.current = false
				return
			}
			await updateTableAndPropertiesValues({
				createDynamicEmptyRow: createEmpty,
				fieldName,
				data,
				dispatch,
				idCounterKey,
				idKey,
				items,
				itemsKey,
				onChange,
				originalIndex,
				rowIndex,
				setLastEditedCell,
				fieldValue,
				fieldId,
				isCodeLowerCase,
				rowId,
			})
		},
		[onChange, items],
	)

	const onSaveData = useDebounceCallback((newItems: Item[]) => {
		const updatedData = {
			[itemsKey]: newItems,
		} as UpdateDeepPartial<Data>

		onChange(updatedData)
	})

	const handleDelete = useCallback(
		(deletedItem: Item) => {
			isDeletingRef.current = true
			const updatedData = {
				[itemsKey]: items.filter(
					(item: any) => item[idKey] !== deletedItem[idKey],
				),
			} as UpdateDeepPartial<Data>

			onChange(updatedData)
		},
		[idKey, items, itemsKey],
	)

	// only for old table handlers
	const tab = useAppStore((store) =>
		store.tab.openedTabs.find((t) => t.nodeId === node.id),
	)

	const itemsForm = useMemo(
		() => data[itemsKey] || [],
		[data, itemsKey],
	) as any[]

	const resolveIsDeletable = (item: any, itemIndex: number) => {
		if (itemIndex + 1 < items.length) {
			return true
		}

		return !!item[existsKey]
	}

	const selectedItemIndex = useMemo(
		() => ((tab || { properitesItems: {} }).propertiesItems || {})[tabKey],
		[tab, tabKey],
	)

	const handleRowOrderChanged = (oldIndex: number, newIndex: number) => {
		const reordered = arrayMoveIndex(itemsForm, oldIndex, newIndex)

		onChange({
			[itemsKey]: reordered,
		} as any)
	}

	const NEW_TABLE = true

	const renderTable = useCallback(
		(propertiesWidth: number, propertiesHidden: boolean) => {
			return (
				<div ref={refCallback} className="pt-5 h-full overflow-y-auto">
					{NEW_TABLE ? (
						<MainTable
							columns={columns}
							handleChange={handleChange}
							handleDelete={handleDelete}
							items={items}
							lastEditedCell={lastEditedCell}
							onSaveData={onSaveData}
							isEditMode={isEditMode}
							isReadonly={isReadonly}
							properties={properties}
							technicalColumns={technicalColumns}
							maxWidth={maxWidth}
						/>
					) : (
						<TableProperties
							items={items}
							selectedItemIndex={selectedItemIndex}
							readonly={false}
							properties={properties}
							onChange={handleChange as any}
							onDelete={handleDelete}
							isDeletable={resolveIsDeletable}
							isRowOrderable={false}
							isReadonly={isReadonly}
							onRowOrderChanged={handleRowOrderChanged}
							errors={errors}
							propertiesHidden={propertiesHidden}
						>
							{children}
						</TableProperties>
					)}
				</div>
			)
		},
		[lastEditedCell, properties, refCallback, items],
	)

	if (disablePanelProperties) {
		return renderTable(0, true)
	}

	return (
		<div className="relative h-full">
			{renderTable(0, true)}
			<PanelProperties
				node={node}
				items={items}
				properties={properties}
				listOfValues={items}
				onChange={handleChange}
				errors={errors}
				tabKey={tabKey}
				panelPropertiesMinWidth={panelPropertiesMinWidth}
				panelPropertiesDefaultOpen={panelPropertiesDefaultOpen}
			/>
		</div>
	)
}
