import { updateReleaseEnvironment } from 'src/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import {
	ReleaseDto,
	ReleaseEnvironmentDto,
	StructureDto,
} from '@/endpoints/models'
import { ModalForm } from '@/components/Modal/ModalForm'
import { DatePickerFormField } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import React from 'react'

type Props = {
	node: StructureDto
	release: ReleaseDto
	env: ReleaseEnvironmentDto
	onClose: () => void
}

type FormData = {
	releaseDate: Date
}

export const EnvironmentEditModal = ({
	node,
	release,
	env,
	onClose,
}: Props) => {
	const { t } = useAppContext()

	const request = useApiRequest()

	const MODAL_HEIGHT = 400

	const handleSubmit = async (data: FormData) => {
		const releaseDate = format(data.releaseDate, 'yyyy-MM-dd')

		await request(
			updateReleaseEnvironment(node.id, release.id!, env.id!, {
				releaseDate,
			}),
		)

		onClose()
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('SAVE')}
			saveSchema="primary"
			saveIcon={faSave}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px',
			}}
			initialValues={{
				releaseDate: env.releaseDate ? new Date(env.releaseDate) : new Date(),
			}}
			header={`${t('EDIT')} ${env.name}`}
			height={MODAL_HEIGHT}
		>
			<DatePickerFormField
				name="releaseDate"
				required
				title={t('RELEASE_DATE')}
				minDate={new Date()}
			/>
		</ModalForm>
	)
}
