const NAV_WIDTH_KEY = 'NavigationWidth'

export const getInitSizes = () => {
	const navigationWidth = Number(localStorage.getItem(NAV_WIDTH_KEY)) || 200

	return [navigationWidth]
}

export const setNavigationWidth = (width: number) =>
	localStorage.setItem(NAV_WIDTH_KEY, String(width))
