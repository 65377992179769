import React, { useRef, useState } from 'react'
import { TextProps, FormlessText } from '../UberForm/Input/Text/Text'
import { useDebounce, useEffectWithoutMount } from '@/hooks'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface SearchBarProps extends TextProps {
	value: string
	setValue: (value: string) => void
	debounceTime: number
}

export const SearchBar = (props: SearchBarProps) => {
	const { value: propsValue, setValue, debounceTime, ...otherProps } = props

	const wasEnter = useRef(false)
	const [localValue, setLocalValue] = useState<string>(propsValue)
	const changedValue = useDebounce(localValue, debounceTime)

	useEffectWithoutMount(() => {
		if (wasEnter.current) {
			wasEnter.current = false
		} else {
			setValue(changedValue)
		}
	}, [changedValue])

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			wasEnter.current = true
			setValue(localValue)
		} else {
			wasEnter.current = false
		}
	}

	return (
		<FormlessText
			value={localValue}
			onChange={(v) => {
				setLocalValue(v)
			}}
			onKeyPress={onKeyPress}
			inputIcon={faSearch}
			{...otherProps}
		/>
	)
}
