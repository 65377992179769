import React from 'react'
import { useAppDispatch, useAppContext } from '@/hooks'
import {
	faUser,
	faSignOutAlt,
	faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { logout } from '@/store/modules/auth/actions'
import { DropdownMenu } from '@/components'
import { KeycloakProfile } from 'keycloak-js'
import { useKeycloak } from '@react-keycloak/web'
import {
	Container,
	MenuButton,
	MenuButtonIcon,
	MenuButtonText,
	MenuItem,
	Separator,
	UserBackgroundIcon,
	UserMenuButton,
	UserDropIcon,
	UserIcon,
	UserName,
} from './styles'

const userProfile: KeycloakProfile = {}

export const UserMenu = ({ className = '' }: { className?: string }) => {
	const dispatch = useAppDispatch()
	const { keycloak } = useKeycloak()
	const { t } = useAppContext()

	const handleLogoutClick = () => {
		dispatch(logout())
	}

	if (!keycloak.authenticated) {
		return <></>
	}

	keycloak.loadUserProfile().then((value) => {
		userProfile.firstName = value.firstName
		userProfile.lastName = value.lastName
	})

	const trigger = (isOpen: boolean) => (
		<UserMenuButton className={className} $isOpen={isOpen} aria-haspopup="true">
			<UserName>{userProfile.firstName + ' ' + userProfile.lastName}</UserName>
			<UserIcon icon={faUser} />
			<UserDropIcon icon={faCaretDown} />
		</UserMenuButton>
	)

	return (
		<>
			<DropdownMenu
				trigger={trigger}
				contentStyle={{ padding: '0', width: '220px' }}
			>
				<Container role="menu">
					<UserBackgroundIcon icon={faUser} />
					<MenuItem>
						{userProfile.firstName + ' ' + userProfile.lastName}
					</MenuItem>
					<MenuItem>{keycloak.realm}</MenuItem>
					<Separator />
					<MenuButton onClick={handleLogoutClick} role="menuitem">
						<MenuButtonIcon icon={faSignOutAlt} />
						<MenuButtonText>{t('LOGOUT')}</MenuButtonText>
					</MenuButton>
				</Container>
			</DropdownMenu>
		</>
	)
}
