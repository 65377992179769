import { useMemo } from 'react'
import { TableIndex } from '@/endpoints/schemas'
import { useAppContext, useMemoObject } from '@/hooks'
import { TableColumnFlat } from '@/store/modules/table/types'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

type HookOptions = {
	columns: TableColumnFlat[]
	canGenerateNaming: boolean
}

export const useIndexProperties = ({
	columns,
	canGenerateNaming,
}: HookOptions) => {
	const { t } = useAppContext()
	const columnOptions = useMemoObject(columns)

	const properties = useMemo<TableColumnProps<TableIndex>[]>(
		() => [
			{
				field: 'name',
				label: t('TABLE_INDEXES_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				width: 150,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'isUnique',
				label: t('TABLE_INDEXES_UNIQUE'),
				type: ItemPropertyType.CHECKBOX,
				width: 60,
			},
			{
				field: 'physicalOptions',
				label: t('TABLE_INDEXES_PHYSICAL_OPTIONS'),
				type: ItemPropertyType.ACE_EDITOR,
				hideInTable: true,
			},
			{
				field: 'description',
				label: t('TABLE_INDEXES_DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
			{
				field: 'comment',
				label: t('TABLE_INDEXES_COMMENT'),
				type: ItemPropertyType.TEXTAREA,
				width: 150,
			},
			{
				field: 'columns',
				label: t('TABLE_INDEXES_COLUMNS'),
				type: ItemPropertyType.COLUMNS_WITH_ORDER,
				options: columnOptions,
				hideInTable: true,
				width: 150,
			},
		],
		[canGenerateNaming, columnOptions, t],
	)

	return properties
}
