import { ApiError } from '@/endpoints/utils'
import { useAppDispatch, useAppStore } from '@/hooks'
import { useCallback, useMemo } from 'react'
import { useBatchEditContext } from '../context/BatchEditContext'
import { ActionTypeEnum } from '../context/types'
import { v4 } from 'uuid'
import { closeTab, openTab } from '@/store/modules/tab/actions'
import { useTypeData } from './useTypeData'
import { FormDataPushModal } from '../../../../PushModal/PushModal'

export const usePush = () => {
	const dispatch = useAppDispatch()
	const tab = useAppStore((state) => state.tab)

	const {
		state: {
			onClose,
			formChanged,
			submitting,
			selectedStructures,
			errors,
			type,
		},
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const { updateData, saveData } = useTypeData(type)

	const handleSaveError = useCallback(
		async (e: Error) => {
			if (e instanceof ApiError && e.response.status === 423) {
				const data = await e.response.json()

				dispatchContext({
					type: ActionTypeEnum.SET,
					state: {
						errors: {
							...errors,
							[v4()]: `${data.errorDescription}`,
						},
					},
				})
			} else {
				throw e
			}
		},
		[dispatchContext, errors],
	)

	const beforePush = useCallback(async () => {
		if (submitting) {
			return
		}

		dispatchContext({ type: ActionTypeEnum.SET, state: { submitting: true } })

		try {
			selectedStructures.forEach((node) => {
				const update = { name: node.name, ...formChanged }

				return dispatch(updateData(node, update))
			})

			const saves = selectedStructures.map((node) => dispatch(saveData(node)))

			await Promise.allSettled(saves)
		} catch (e: any) {
			handleSaveError(e)
		}
	}, [
		dispatch,
		dispatchContext,
		formChanged,
		handleSaveError,
		saveData,
		selectedStructures,
		submitting,
		updateData,
	])

	const afterPush = useCallback(
		(values: Partial<FormDataPushModal>) => {
			// Refresh form in tab after edit
			if (tab.selectedTab !== undefined) {
				const { nodeId, editMode } = tab.openedTabs[tab.selectedTab]

				const tabNodeEdited = selectedStructures.find(
					(structure) => structure.id === nodeId,
				)

				if (tabNodeEdited) {
					dispatch(closeTab(nodeId))

					dispatch(
						openTab(
							tabNodeEdited,
							false,
							values.exitEditMode ? false : editMode,
						),
					)
				}
			}

			onClose()

			dispatchContext({
				type: ActionTypeEnum.SET,
				state: { submitting: false },
			})
		},
		[
			dispatch,
			dispatchContext,
			onClose,
			selectedStructures,
			tab.openedTabs,
			tab.selectedTab,
		],
	)

	const push = useMemo(
		() => ({ beforePush, afterPush }),
		[beforePush, afterPush],
	)

	return push
}
