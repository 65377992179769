import { useRef, useCallback, useEffect } from 'react'

export const useDebounceCallback = <T, A extends any[]>(
	value: (...args: A) => T,
	delay = 100
): ((...args: A) => void) => {
	const callback = useRef<(...args: A) => T>()
	const callArgs = useRef<A>()
	const timeout = useRef<ReturnType<typeof setTimeout>>()

	useEffect(
		() => () => (timeout.current ? clearTimeout(timeout.current) : undefined),
		[]
	)

	callback.current = value

	return useCallback(
		(...args: A) => {
			callArgs.current = args

			if (timeout.current) {
				clearTimeout(timeout.current)
			}

			timeout.current = setTimeout(() => {
				if (callback.current && callArgs.current) {
					callback.current.apply(null, callArgs.current)
				}
			}, delay)
		},
		[callback, callArgs, delay]
	)
}
