import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	Button,
	Loader,
	DialogWrapper,
	TableAndProperties,
	TabsActionsBar,
} from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { AddModal } from '../../../../components/AddModal'
import { useAppContext } from '@/hooks'
import { useApi } from '@/endpoints/hooks'
import { getMappings } from '@/endpoints'
import { useOpenTab } from '../../../../components/Dependencies/hooks/useOpenTab'
import { useMappingProperties } from './Properties'
import { MainContentWrap } from '@/styles'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { NamingTypeEnum } from '@/types'

type Props = {
	node: StructureDto
}

export const Mappings = ({ node }: Props) => {
	const { t } = useAppContext()
	const { openTabById } = useOpenTab()
	const mappings = useApi(getMappings(node.id))

	const onEdit = useCallback(
		(nodeId: number) => {
			openTabById(nodeId)
		},
		[openTabById],
	)

	const data = useMemo(
		() => ({
			mappings: mappings.data ?? ([] as StructureObjectDto[]),
			counter: mappings.data?.length ?? 0,
		}),
		[mappings.data],
	)

	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
	)

	const properties = useMappingProperties(
		onEdit,
		(removed) => removed && mappings.invalidate(),
		canGenerateNaming,
	)

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				type={StructureObjectDto.TypeEnum.MAPPING}
				onClose={onClose}
				node={node}
			/>
		)

	return (
		<>
			{node.canEdit && (
				<TabsActionsBar>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button
								icon={faPlus}
								onClick={onClick}
								schema="success"
								coloredIcon={true}
							>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActionsBar>
			)}
			<MainContentWrap>
				<LoaderContainer>
					<Loader loaded={!mappings.loading} $absolute />
				</LoaderContainer>

				<TableAndProperties
					node={node}
					data={data}
					itemsKey={'mappings'}
					idCounterKey={'counter'}
					isRowOrderable
					properties={properties}
					tabKey="mapping"
					onChange={() => undefined}
					createEmpty={() => ({}) as StructureObjectDto}
					disablePanelProperties
					maxWidth={1280}
				/>
			</MainContentWrap>
		</>
	)
}

const LoaderContainer = styled.div`
	position: relative;
`
