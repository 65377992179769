import { StructureObjectDto } from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import { GeneralNodeActions } from '@/store/modules/tab/types'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'

import { ensureValidData } from '../helpers'
import { TAB_CLOSE } from '../tab/constants'
import { Actions } from './actions'
import {
	ENVIRONMENT_WAS_BLURRED,
	SYSTEM_INIT,
	SYSTEM_LOAD_MAPPINGS,
	SYSTEM_SAVE,
	SYSTEM_SELECT_TAB,
	SYSTEM_UPDATE,
} from './constants'
import {
	defaultSystemData,
	getInitialSystemData,
	getSystemDataForm,
} from './helpers'
import { OpenedSystemData, SystemTab } from './types'

type State = Readonly<typeof initialState>

const initialState = {
	systems: {} as NativeMap<OpenedSystemData>,
	mappings: {} as NativeMap<StructureObjectDto[]>,
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions,
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const systems = { ...state.systems }
			delete systems[action.nodeId]

			return {
				...state,
				systems,
			}
		}

		case SYSTEM_INIT: {
			const { node, editMode, force } = action

			const previous = state.systems[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: SystemData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultSystemData)
				: getInitialSystemData(node.name)

			const systemDataForm = getSystemDataForm(data)

			return {
				...state,
				systems: {
					...state.systems,
					[node.id]: {
						form: systemDataForm,
						original: systemDataForm,
						tab: previous ? previous.tab : SystemTab.Overview,
						dirty: false,
						parsedEditMode: editMode,
						workflowTechnologyName: node.workflowTechnologyName,
						systemTechnologyName: node.systemTechnologyName,
					} as OpenedSystemData,
				},
			}
		}

		case ENVIRONMENT_WAS_BLURRED: {
			//TD update only one environment
			const { payload } = action
			const { isBlurred, nodeId, onBlurItemId } = payload
			const updatedEnvironment = state?.systems[nodeId]?.form.environments?.map(
				(item) => {
					if (item.id === payload.onBlurItemId) {
						return {
							...item,
							isBlurred,
						}
					}
					return item
				},
			)

			return {
				...state,
				systems: updateTabData(state.systems, nodeId, (node) => ({
					...node,
					form: {
						environments: {
							...node.form.environments,
							...updatedEnvironment,
						},
					},
					dirty: true,
				})),
			}
		}

		case SYSTEM_UPDATE: {
			const { node, update } = action
			return {
				...state,
				systems: updateTabData(state.systems, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...update,
					},
					dirty: true,
				})),
			}
		}

		case SYSTEM_SAVE: {
			const {
				metadata: { node },
			} = action

			return {
				...state,
				systems: updateTabData(state.systems, node.id, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		case SYSTEM_SELECT_TAB: {
			return {
				...state,
				systems: updateTabData(state.systems, action.node.id, {
					tab: action.tab,
				}),
			}
		}

		case SYSTEM_LOAD_MAPPINGS: {
			return {
				...state,
				mappings: {
					...state.mappings,
					[action.metadata.systemId]: action.payload,
				},
			}
		}

		default:
			return state
	}
}
