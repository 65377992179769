import { prePushValidation } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { PrePushValidationDto } from '@/endpoints/models'
import { useEffect, useMemo, useState } from 'react'

export const usePrePushValidation = (nodeId?: number) => {
	const request = useApiRequest()
	const [isLoading, setIsLoading] = useState(true)

	const [validations, setValidations] = useState<PrePushValidationDto[]>([])

	useEffect(() => {
		const call = async () => {
			setIsLoading(true)

			if (nodeId) {
				const validations = await request(prePushValidation(nodeId))
				setValidations(validations.data ?? [])
			}

			setIsLoading(false)
		}

		call()
	}, [nodeId, request])

	const result = useMemo(
		() => ({ isLoading, validations }),
		[isLoading, validations],
	)

	return result
}
