import styled from 'styled-components'
import { PageType } from './types'

export const Wrapper = styled.div`
	height: 3.438rem;
	display: flex;
	align-content: flex-end;
	flex-wrap: wrap;
`
export const CustomAttributesSwitcherWrap = styled.div<{
	$pageType: PageType | undefined
}>`
	margin-bottom: ${({ $pageType }) =>
		$pageType === PageType.OVERVIEW_PAGE ? '0' : '1rem'};
`
