import { Button } from '@/components'
import { FieldSet, Legend } from '@/components/Layout'
import { CheckBox } from '@/components/UberForm'
import styled, { css } from 'styled-components'

export const ObjectTypesFieldSet = styled(FieldSet)`
	margin-bottom: 15px;
	padding-bottom: 13px;
	max-height: 400px;
	overflow-x: auto;
	padding-top: 0;
`

export const StyledCheckbox = styled(CheckBox)`
	margin-left: 18px;
`

export const StyledLegend = styled(Legend)`
	letter-spacing: 0;
`

export const StructureTypeButton = styled(Button)`
	padding-top: 12px;
	font-size: 105%;
	padding-bottom: 6px;
	padding-left: 6px;
`
export const Title = styled.div`
	padding: 8px 12px;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background: ${(props) =>
		props.theme.colors.layout.treePanel.headerBackgroundColor};
	text-transform: uppercase;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
`
export const TitleActions = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
`
export const TitleAction = styled.div`
	cursor: pointer;
	padding: 3px;
	border-radius: 4px;
	width: 30px;
	text-align: center;
	margin-right: 5px;
	font-size: 1rem;

	&:hover {
		background-color: #265560;
		opacity: 0.5;
		color: white;
	}
`
export const ToggleButton = styled.div<{ $isChecked?: boolean }>`
	cursor: pointer;
	padding: 3px;
	border-radius: 4px;
	width: 30px;
	text-align: center;
	margin-right: 5px;
	font-size: 1rem;

	&:hover {
		background-color: #265560;
		opacity: 0.5;
		color: white;
	}

	${(props) => css`
		color: ${props.$isChecked ? 'white' : 'initial'};
		background: ${props.$isChecked ? '#265560' : 'initial'};
		box-shadow: ${props.$isChecked ? '0px 0px 2px 0px #265560' : 'initial'};
	`}
`
