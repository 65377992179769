import React, { useEffect, useMemo, useState } from 'react'
import { useAppContext, useSaveFile } from '@/hooks'
import { useApi } from '@/endpoints/hooks'
import { getTemplateHistory, getTemplateHistoryDetail } from '@/endpoints'
import { Flex } from '@/components/Layout'
import { Modal } from '@/components/Modal/Modal'
import * as jsondiffpatch from 'jsondiffpatch'
import * as htmlFormatter from 'jsondiffpatch/formatters/html'
import { Button } from '@/components'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { TemplateDiffProps } from './types'
import {
	StyledDiffWrap,
	StyledSelectVersion,
} from '@/pages/User/pages/Settings/pages/Templates/styles'

export const TemplateDiff = ({ template, onClose }: TemplateDiffProps) => {
	const { t } = useAppContext()
	const saveFile = useSaveFile()
	const [version, setVersion] = useState(template?.version)

	const versionDiffInit = useMemo(
		() =>
			template?.version && template.version <= 2
				? template?.version
				: template?.version && template?.version - 1,
		[template],
	)

	const [versionDiff, setVersionDiff] = useState(versionDiffInit)

	const templateHistory = useApi(getTemplateHistory(template?.id as number))

	const templateVersion = useApi(
		getTemplateHistoryDetail(template?.id as number, version as number),
	)

	const templateVersionDiff = useApi(
		getTemplateHistoryDetail(template?.id as number, versionDiff as number),
	)

	const onDownload = async () => {
		if (templateVersionDiff.data) {
			saveFile(
				`template_${templateVersionDiff.data?.id}_${templateVersionDiff.data.version}.sql`,
				[templateVersionDiff.data.data ?? ''],
			)
		}
	}

	const versions = useMemo(() => templateHistory.data ?? [], [templateHistory])

	const header = () => (
		<>
			<Flex>
				{t('TEMPLATE_VERSIONS')}

				<StyledSelectVersion
					title={t('VERSION')}
					name="version"
					options={versions}
					fieldIsLoading={templateHistory.reloading}
					valueKey="version"
					labelKey="version"
					required={true}
					isNumeric
					clearable={false}
					onChange={(value: any) => setVersion(value as number)}
					initialValue={template?.version}
					formless
				/>
				<StyledSelectVersion
					title={t('VERSION_TO_COMPARE')}
					name="versionDiff"
					options={versions}
					fieldIsLoading={templateHistory.reloading}
					valueKey="version"
					labelKey="version"
					required={true}
					isNumeric
					clearable={false}
					onChange={(value: any) => setVersionDiff(value as number)}
					initialValue={versionDiffInit}
					formless
				/>
			</Flex>
		</>
	)

	useEffect(() => {
		const left = templateVersion.data
		const right = templateVersionDiff.data
		const delta = jsondiffpatch.diff(left, right)

		function setElementHTML(id: string, content: string) {
			const element = document.getElementById(id)

			if (element) {
				element.innerHTML = content
			} else {
				console.warn(`No element with id "${id}" found in the document.`)
			}
		}

		const formattedHTML = htmlFormatter.format(delta, left)
		setElementHTML('visual', formattedHTML as string)
	}, [templateVersion.data, templateVersionDiff.data])

	return (
		<>
			<Modal
				open={true}
				onClose={onClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				maximizeButtonVisible
				maximized
				headerStyle={{ paddingBottom: 0 }}
				header={header}
			>
				<StyledDiffWrap id="visual" />
				<div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
					<Button onClick={onDownload}>
						{t('DOWNLOAD_VERSION_TO_COMPARE')}
					</Button>
					<Button onClick={onClose} schema="transparent" icon={faTimes}>
						{t('CLOSE')}
					</Button>
				</div>
			</Modal>
		</>
	)
}
