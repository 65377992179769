import React from 'react'
import styled from 'styled-components'
import { faUnlock, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@/components'
import { Modal } from '@/components/Modal/Modal'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppContext, useAppStore, useAppDispatch } from '@/hooks'
import { unlockNode } from '@/store/modules/node/actions'
import { isLockedByMe } from '@/utils/locking'

type Props = {
	node: StructureObjectDto
	onClose: () => void
	onConfirm?: () => void
}

export const UnlockModal = ({ node, onClose, onConfirm }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const user = useAppStore((state) => state.auth.user)

	const handleConfirm = async () => {
		await dispatch(unlockNode(node))
		onClose()
		onConfirm && onConfirm()
	}

	return (
		<Modal
			open={true}
			contentStyle={{
				width: '400px',
			}}
			header={t('UNLOCK_HEADER')}
			footer={(close) => (
				<>
					<Button icon={faUnlock} schema="warn" onClick={handleConfirm}>
						{t('YES')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('NO')}
					</Button>
				</>
			)}
			onClose={onClose}
		>
			<PopupText>
				<span>
					{isLockedByMe(node, user)
						? t('UNLOCK_MESSAGE_LOCKED_BY_YOU')
						: t('UNLOCK_MESSAGE_LOCKED_BY_USER', [node.lockUserName as string])}
				</span>
			</PopupText>
		</Modal>
	)
}

const PopupText = styled.div`
	display: flex;
	align-items: center;
`
