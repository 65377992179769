import { useAppDispatch } from '@/hooks'
import { useEffect } from 'react'
import { useDetailTabContext } from '../components/DetailTab/context/DetailTabContext'
import { useStructureTypeActions } from '@/utils/structureType/useStructureTypeActions'
import { TableMode } from '@/store/modules/table/types'

/** Load node history version or current version */
export const useNodeInit = (mode?: TableMode) => {
	const dispatch = useAppDispatch()

	const {
		state: { node, version, editMode, envId },
	} = useDetailTabContext()

	const { initData } = useStructureTypeActions(node.type)

	useEffect(() => {
		if (version) {
			dispatch(
				initData({
					nodeId: node.id,
					editMode: false,
					mode,
					force: true,
					version,
					envId,
				}),
			)

			return
		}

		dispatch(initData({ nodeId: node.id, editMode, mode }))
	}, [dispatch, editMode, mode, node.id, version, envId, initData])
}
