import { RowProps } from './types'
import { TableRowContent } from './TableRowContent'
import { TableSubRowContent } from './TableSubRowContent'
import { useCallback } from 'react'
import { tableRowSelected } from '@/store'
import { useAppDispatch } from '@/hooks'

export const TableRowNoDnd = <Item extends object>({
	actionCellWidth,
	hasLastRowEdit,
	hasLeftAction,
	isEditMode,
	isLastRow,
	onEdit,
	onDelete,
	row,
	...rowProps
}: RowProps<Item>) => {
	const dispatch = useAppDispatch()

	const handleSelect = useCallback((selectedIndex: number) => {
		return dispatch(tableRowSelected(selectedIndex))
	}, [])

	return (
		<>
			<tr key={row.id} onClick={() => handleSelect(row.index)}>
				<TableRowContent
					actionCellWidth={actionCellWidth}
					hasLeftAction={hasLeftAction}
					hasLastRowEdit={hasLastRowEdit}
					isEditMode={isEditMode}
					isLastRow={isLastRow}
					onEdit={onEdit}
					onDelete={onDelete}
					row={row}
					{...rowProps}
				/>
			</tr>
			{row.getIsExpanded() && (
				<tr className="expandedRow">
					<td colSpan={6}>
						<TableSubRowContent row={row} />
					</td>
				</tr>
			)}
		</>
	)
}
