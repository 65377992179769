import { StructureObjectDto } from '@/endpoints/models'
import { DialogWrapper } from '@/components'
import { DeleteModal } from '../../../../components/DeleteModal'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { useMemo } from 'react'
import { useAppContext } from '@/hooks'
import { TableColumnProps } from '@/types'
import { ItemPropertyType } from '@/enums'

export const useMappingProperties = (
	onEdit: (nodeId: number) => void,
	onDelete: (removed: boolean) => void,
	canGenerateNaming: boolean,
) => {
	const { t } = useAppContext()

	const properties = useMemo<TableColumnProps<StructureObjectDto>[]>(
		() => [
			{
				field: 'name',
				label: t('TABLE_INDEXES_NAME'),
				type: ItemPropertyType.TEXT,
				showWhenPanelOpened: true,
				autoWidth: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				codeSource: 'name',
				showWhenPanelOpened: true,
				autoWidth: true,
				disabled: canGenerateNaming,
				canGenerateCodeFieldName: canGenerateNaming,
			},
			{
				field: 'type',
				label: t('MAPPING_TYPE'),
				type: ItemPropertyType.TEXT,
				width: 150,
			},
			{
				field: 'description',
				label: t('DESCRIPTION'),
				type: ItemPropertyType.TEXTAREA,
				width: 200,
				flex: 2,
			},
			{
				field: 'actions',
				label: t('ACTIONS'),
				type: ItemPropertyType.TEXTAREA,
				width: 70,
				flex: 0,
				sticky: true,
				formatter: ({ item }) => (
					<Wrapper>
						<Action onClick={() => onEdit(item.id)} title={t('EDIT')}>
							<FontAwesomeIcon icon={faEdit} />
						</Action>
						<DialogWrapper
							dialog={(opened, onClose) =>
								opened && (
									<DeleteModal
										node={item}
										onClose={(removed) => {
											onDelete(removed)
											onClose()
										}}
									/>
								)
							}
						>
							{(onClick) => (
								<Action
									onClick={onClick}
									style={{ marginLeft: 'auto' }}
									title={t('DELETE')}
								>
									<FontAwesomeIcon icon={faTimes} color="red" />
								</Action>
							)}
						</DialogWrapper>
					</Wrapper>
				),
			},
		],
		[canGenerateNaming, onDelete, onEdit, t],
	)

	return properties
}

const Wrapper = styled.div`
	position: sticky;
	right: 30px;
	top: 0;
	display: flex;
	margin-right: 5px;
`

const Action = styled.div`
	opacity: 0.5;
	cursor: pointer;
	margin-left: 5px;

	&:hover {
		opacity: 1;
	}
`
