import { SourceEnums } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { useMemo } from 'react'

export const useSourceOptions = (): Record<
	'sourceStereotypes' | 'sourceObjectTypes',
	any
> => {
	const { t } = useAppContext()

	const options = useMemo(
		() => ({
			sourceStereotypes: [
				{
					label: t('MAPPING_SOURCES_STEREOTYPE_FROM'),
					value: SourceEnums.StereotypeEnum.FROM,
				},
				{
					label: t('MAPPING_SOURCES_STEREOTYPE_JOIN'),
					value: SourceEnums.StereotypeEnum.JOIN,
				},
				{
					label: t('MAPPING_SOURCES_STEREOTYPE_LEFT_JOIN'),
					value: SourceEnums.StereotypeEnum.LEFT_JOIN,
				},
				{
					label: t('MAPPING_SOURCES_STEREOTYPE_RIGHT_JOIN'),
					value: SourceEnums.StereotypeEnum.RIGHT_JOIN,
				},
				{
					label: t('MAPPING_SOURCES_STEREOTYPE_FULL_OUTER_JOIN'),
					value: SourceEnums.StereotypeEnum.FULL_OUTER_JOIN,
				},
			],
			sourceObjectTypes: [
				{
					label: t('MAPPING_SOURCES_OBJECT_TYPE_TABLE'),
					value: SourceEnums.ObjectTypeEnum.TABLE,
				},
				{
					label: t('MAPPING_SOURCES_OBJECT_TYPE_VIEW'),
					value: SourceEnums.ObjectTypeEnum.VIEW,
				},
				{
					label: t('MAPPING_SOURCES_OBJECT_TYPE_SUB_SELECT'),
					value: SourceEnums.ObjectTypeEnum.SUB_SELECT,
				},
				{
					label: t('MAPPING_SOURCES_OBJECT_TYPE_DUMMY'),
					value: SourceEnums.ObjectTypeEnum.DUMMY,
				},
			],
		}),
		[t],
	)

	return options
}
