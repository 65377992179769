import React, { useCallback, useMemo, useRef } from 'react'
import { Form, TextFormField, SelectFormField } from '@/components/UberForm'
import { useAppContext, useCustomAttributesInitValues } from '@/hooks'
import { StructureObjectDto, StereotypeDto } from '@/endpoints/models'
import { getSystemUsers, getStereotypes } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { Container, Flex } from '@/components'
import {
	OpenedFolderData,
	FolderData,
	FolderDataForm,
} from '@/store/modules/folder/types'
import { FormlessFormField } from '@/components/UberForm/FormField'
import { CustomAttributes } from '../../../../components/CustomAttributes/CustomAttributes'
import { GenerateCodeEnum } from '@/types'

type OverviewProps = {
	isEditMode: boolean
	data: OpenedFolderData
	systemNodeId: number
	onChange: (v: Partial<FolderData>) => void
	node?: StructureObjectDto
	/** Selected nodes for batch edit */
	selectedNodes?: StructureObjectDto[]
}

export const Overview = ({
	node,
	isEditMode,
	onChange,
	data,
	systemNodeId,
	selectedNodes,
}: OverviewProps) => {
	const { t } = useAppContext()
	const refForm = useRef<Form<FolderDataForm>>(null)

	const [systemUsers, isSystemUserLoading] = useApi(
		getSystemUsers(systemNodeId),
	)

	const stereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.FOLDER }),
	)

	const {
		initialValuesCustomAttributes,
		parseCustomAttribute,
		customAttributes,
		getCustomAttributesReset,
	} = useCustomAttributesInitValues(
		data.form,
		StructureObjectDto.TypeEnum.FOLDER,
	)

	const handleChange = useCallback(
		(
			item: Partial<FolderDataForm>,
			source?: FormlessFormField<FolderDataForm>,
		) => {
			if (source) {
				const name = source.props.name

				if (name === 'ownerId') {
					const owner = systemUsers?.find((u) => u.id === item[name])

					onChange({
						[name]: owner?.id,
						ownerName: owner?.name,
					})

					return
				}

				if (name === 'stereotypeId') {
					const stereotype = stereotypes.data?.find((u) => u.id === item[name])

					const { customAttributesForm, customAttributesRedux } =
						getCustomAttributesReset(stereotype?.id)

					refForm.current?.setValues(customAttributesForm)

					onChange({
						[name]: stereotype?.id,
						stereotypeCode: stereotype?.code,
						customAttributes: customAttributesRedux,
					})

					return
				}

				onChange(
					parseCustomAttribute(
						{
							[name]: item[name],
						},
						data.form.customAttributes,
						customAttributes,
					),
				)
			}
		},
		[
			onChange,
			parseCustomAttribute,
			data.form.customAttributes,
			systemUsers,
			stereotypes,
			getCustomAttributesReset,
		],
	)

	const generateCodeOptions = useMemo(
		() =>
			Object.values(GenerateCodeEnum).map((key) => ({
				value: key,
				label: t(key),
			})),
		[t],
	)

	return (
		<Container className="pt-5 h-full overflow-y-auto">
			<Form<FolderDataForm>
				defaultValues={{
					name: node?.name,
					ownerId: data.form.ownerId,
					stereotypeId: data.form.stereotypeId,
					generateCode: data.form.generateCode,
					generateTableColumnCode: data.form.generateTableColumnCode,
					...initialValuesCustomAttributes,
				}}
				onChange={handleChange}
				disabled={isEditMode}
				enableFieldHighlight
				ref={refForm}
			>
				<Flex>
					<TextFormField
						name="name"
						title={t('NAME')}
						disabled={Boolean(selectedNodes)}
						initialValue={data.form.name}
					/>

					<SelectFormField
						name="ownerId"
						title={t('NODE_OWNER')}
						options={systemUsers || []}
						fieldIsLoading={isSystemUserLoading}
						initialValue={data.form.ownerId}
						valueKey="id"
						labelKey="name"
						isNumeric
						allowEmpty
						clearable
					/>
					<SelectFormField
						name="stereotypeId"
						title={t('MAPPING_STEREOTYPE')}
						fieldIsLoading={stereotypes.loading}
						initialValue={data.form.stereotypeId}
						options={stereotypes.data || []}
						valueKey="id"
						labelKey="name"
						isNumeric
						allowEmpty
						clearable
					/>
				</Flex>
				<Flex>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateCode"
							title={t('GENERATE_ELEMENT_CODE')}
							options={generateCodeOptions}
							initialValue={data.form.generateCode}
							allowEmpty
							clearable
							tooltip={t('UNDEFINED_INHERIT')}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<SelectFormField
							name="generateTableColumnCode"
							title={t('GENERATE_TABLE_COLUMN_CODE')}
							options={generateCodeOptions}
							initialValue={data.form.generateTableColumnCode}
							allowEmpty
							clearable
							tooltip={t('UNDEFINED_INHERIT')}
						/>
					</div>
				</Flex>
				<CustomAttributes customAttributes={customAttributes} />
			</Form>
		</Container>
	)
}
