import { GenerateCodeEnum } from '@/types'

export const parseGenerateTableColumnCode = (
	columnCode: GenerateCodeEnum | undefined,
) => {
	switch (columnCode) {
		case GenerateCodeEnum.YES:
			return true

		case GenerateCodeEnum.NO:
			return false

		default:
			return true
	}
}
