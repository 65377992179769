import React from 'react'

import styled, { css } from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	faCaretUp,
	faCaretDown,
	faEllipsisV,
} from '@fortawesome/free-solid-svg-icons'
import Button from '@/components/Button/Button'
import { ITEM_HEIGHT } from '../tableProperties/constants'

interface Props<T> {
	index: number
	isRowOrderable: { up?: boolean; down?: boolean; enabled: boolean }
	onRowOrderChanged: (oldIndex: number, newIndex: number) => void
	onOrderDragStart: (e: React.DragEvent<HTMLDivElement>) => void
	onOrderDragEnd: (e: React.DragEvent<HTMLDivElement>) => void
}

export const RowOrderer = <T extends object>({
	onOrderDragStart,
	onOrderDragEnd,
	onRowOrderChanged,
	index,
	isRowOrderable,
}: Props<T>) => (
	<ChangeOrderContainer
		disabled={!isRowOrderable.up && !isRowOrderable.down}
		onDragStart={onOrderDragStart}
		onDragEnd={onOrderDragEnd}
		draggable={isRowOrderable.up || isRowOrderable.down}
	>
		<OrderIconDots disabled={!isRowOrderable.up && !isRowOrderable.down}>
			<FontAwesomeIcon icon={faEllipsisV} />
		</OrderIconDots>

		<ArrowsOrderContainer>
			<OrderIconArrow
				schema="transparent"
				disabled={!isRowOrderable.up}
				onClick={() => onRowOrderChanged(index, index - 1)}
				icon={faCaretUp}
			/>
			<OrderIconArrow
				schema="transparent"
				disabled={!isRowOrderable.down}
				onClick={() => onRowOrderChanged(index, index + 1)}
				icon={faCaretDown}
			/>
		</ArrowsOrderContainer>
	</ChangeOrderContainer>
)

const ArrowsOrderContainer = styled.div`
	display: flex;
	width: 12px;
	height: ${ITEM_HEIGHT}px;
	align-items: center;
	flex-direction: column;
`

const OrderIcon = styled(Button)`
	opacity: 0.3;
	transition: 0.2s opacity;
	padding: 0;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
	&:disabled {
		opacity: 0 !important;
		cursor: initial;
	}
`

const OrderIconArrow = styled(OrderIcon)`
	padding-right: 4px;
	&:first-child {
		& > span {
			margin-bottom: -3px;
			padding-top: 2px;
		}
	}
	&:last-child {
		& > span {
			margin-top: -3px;
			padding-bottom: 1px;
		}
	}
`

const OrderIconDots = styled.div<{ disabled: boolean }>`
	height: ${ITEM_HEIGHT}px;
	padding: 0;
	transition: 0.2s opacity;
	display: flex;
	align-items: center;
	flex-direction: row;
	& > svg {
		padding-left: 5px;
		padding-right: 3px;
	}
	&:hover {
		opacity: 1;
	}
	${(props) => css`
		opacity: ${props.disabled ? '0 !important' : 0.3};
		cursor: ${props.disabled ? 'initial' : 'pointer'};
	`}
`

const ChangeOrderContainer = styled.div<{ disabled: boolean }>`
	display: flex;
	width: 26px;
	min-width: 26px;
	align-items: center;
	height: ${ITEM_HEIGHT}px;
	transition: 0.2s background-color;
	border-style: solid;
	border-right-width: 1px;
	border-color: #ddd;
	&:hover {
		${(props) => css`
			background: ${props.disabled ? 'transparent' : '#f0f0f0'};
		`}
		& ${OrderIconDots} {
			opacity: 1;
		}
	}
`
