import { NativeMap } from '@/utils/collections'
import { OpenedWorkflowData, WorkflowTab } from './types'
import { Actions } from './actions'
import { WorkflowData } from '@/endpoints/schemas'
import {
	WORKFLOW_INIT,
	WORKFLOW_SAVE,
	WORKFLOW_UPDATE,
	WORKFLOW_SELECT_TAB,
	WORKFLOW_UPDATE_GRAPH,
} from './constants'
import { updateTabData } from '@/store/utils'
import { getInitialWorkflowData } from './helpers'
import { TAB_CLOSE } from '../tab/constants'
import { GeneralNodeActions } from '@/store/modules/tab/types'

type State = Readonly<typeof initialState>

const initialState = {
	workflows: {} as NativeMap<OpenedWorkflowData>,
}

export default (
	state = initialState,
	action: Actions | GeneralNodeActions,
): State => {
	switch (action.type) {
		case TAB_CLOSE: {
			const workflows = { ...state.workflows }
			delete workflows[action.nodeId]

			return {
				...state,
				workflows,
			}
		}

		case WORKFLOW_INIT: {
			const { node, editMode } = action

			const previous = state.workflows[node.id]

			if (previous && editMode && previous.parsedEditMode) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			const parsed = JSON.parse(serializedData as string) as WorkflowData
			const original = JSON.parse(node.data as string) as WorkflowData

			return {
				...state,
				workflows: {
					...state.workflows,
					[node.id]: {
						form: {
							...getInitialWorkflowData(node.name),
							...parsed,
						},
						tab: previous ? previous.tab : WorkflowTab.Overview,
						stereotypeId: node.stereotypeId,
						dirty: false,
						parsedEditMode: editMode,
						graph: null,
						original: original,
					} as OpenedWorkflowData,
				},
			}
		}

		case WORKFLOW_SAVE: {
			const { node } = action.metadata

			return {
				...state,
				workflows: updateTabData(state.workflows, node.id, {
					dirty: false,
				}),
			}
		}

		case WORKFLOW_UPDATE: {
			const { node, update } = action

			return {
				...state,
				workflows: updateTabData(state.workflows, node.id, {
					form: update,
					dirty: true,
				}),
			}
		}

		case WORKFLOW_SELECT_TAB: {
			const { node, tab } = action

			return {
				...state,
				workflows: updateTabData(state.workflows, node.id, {
					tab,
				}),
			}
		}

		case WORKFLOW_UPDATE_GRAPH: {
			const { node, update } = action

			return {
				...state,
				workflows: updateTabData(state.workflows, node.id, {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					graph: update as any,
				}),
			}
		}

		default:
			return state
	}
}
