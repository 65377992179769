import { StructureObjectDto } from '@/endpoints/models'
import { DialogWrapper } from '@/components'
import { nodeRefreshTree, selectModeChange } from '@/store/modules/node/actions'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useAuthContext,
} from '@/hooks'
import {
	faCheck,
	faFileImport,
	faPlus,
	faSortAlphaDown,
	faSync,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { AddModal } from '../../AddModal'
import { TREE_PANEL_ORDER } from '../utils'
import { ImportStructureModal } from './ImportStructureModal'
import { Title, TitleAction, TitleActions, ToggleButton } from './styles'

type HeaderProps = {
	alphabet: boolean
	setAlphabet: (value: boolean) => void
}

export const Header = ({ alphabet, setAlphabet }: HeaderProps) => {
	const { t } = useAppContext()
	const auth = useAuthContext()

	const dispatch = useAppDispatch()

	const selectMode = useAppStore((store) => store.node.selectMode)

	const [refreshing, setRefreshing] = useState(false)

	const handleRefresh = useCallback(async () => {
		setRefreshing(true)
		await dispatch(nodeRefreshTree())
		setRefreshing(false)
	}, [dispatch])

	const changeSelectMode = (value: boolean) => dispatch(selectModeChange(value))

	const addDialog = (isModalOpen: boolean, onClose: () => void) =>
		isModalOpen && (
			<AddModal type={StructureObjectDto.TypeEnum.SYSTEM} onClose={onClose} />
		)

	const importDialog = (isModalOpen: boolean, onClose: () => void) => {
		return (
			<>
				{isModalOpen && (
					<ImportStructureModal onClose={onClose} title={t('IMPORT_SYSTEM')} />
				)}
			</>
		)
	}

	return (
		<Title role="heading">
			<TitleActions>
				<ToggleButton
					$isChecked={selectMode}
					onClick={() => {
						changeSelectMode(!selectMode)
					}}
				>
					<FontAwesomeIcon icon={faCheck} title="Select mode" />
				</ToggleButton>

				<ToggleButton
					title="Alphabetical order"
					$isChecked={alphabet}
					onClick={() => {
						setAlphabet(!alphabet)
						localStorage.setItem(TREE_PANEL_ORDER, !alphabet ? 'true' : 'false')
					}}
				>
					<FontAwesomeIcon icon={faSortAlphaDown} title="Alphabetical order" />
				</ToggleButton>

				<TitleAction title={t('REFRESH')} onClick={handleRefresh} role="button">
					<FontAwesomeIcon icon={faSync} spin={refreshing} />
				</TitleAction>
				{auth.canCreateSystem() && (
					<>
						<DialogWrapper dialog={addDialog}>
							{(onClick) => (
								<TitleAction
									title={t('CREATE_SYSTEM')}
									onClick={onClick}
									role="button"
								>
									<FontAwesomeIcon icon={faPlus} />
								</TitleAction>
							)}
						</DialogWrapper>
						<DialogWrapper dialog={importDialog}>
							{(onClick) => (
								<TitleAction
									title={t('IMPORT_SYSTEM')}
									onClick={onClick}
									role="button"
								>
									<FontAwesomeIcon icon={faFileImport} />
								</TitleAction>
							)}
						</DialogWrapper>
					</>
				)}
			</TitleActions>
		</Title>
	)
}
