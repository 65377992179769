import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	Body,
	Dialog,
	Footer,
	Header,
	Maximizer,
	Popup,
	PopupBackground,
	Resizer,
} from './styles'
import { useAppStore, useWindowEvent } from '@/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { saveDialogSize } from '@/store/modules/user/actions'
import mainColors from '@/styles/mainColors'
import { ModalProps } from '@/types'
import { Portal } from '@/components'
import { ResizeData } from './types'
import { getResizedData, getResizedStyle } from './utils'

const RELATIVE_MAX_WIDTH = 0.98
const RELATIVE_MAX_HEIGHT = 0.98

export const Modal = ({
	children,
	contentStyle,
	height,
	header,
	footer,
	open,
	onClose,
	disablePortal,
	headerStyle,
	footerStyle,
	bodyStyle,
	stretchFooterButtons = true,
	closeOnEscape = true,
	maximizeButtonVisible,
	resizable,
	dialogId,
	zIndex,
	maximized,
	stickyFooter,
}: ModalProps) => {
	const stopEvent = useCallback((e: React.MouseEvent) => {
		e.nativeEvent.stopImmediatePropagation()
	}, [])

	const refMaximized = useRef(false)
	const [isResizing, setIsResizing] = useState(false)

	const [resizedData, setResizedData] = useState<ResizeData>({
		height:
			typeof contentStyle?.height === 'number'
				? contentStyle.height
				: undefined,
		width:
			typeof contentStyle?.width === 'number' ? contentStyle.width : undefined,
		isMaximized: !!maximized,
	})

	const dialog = useAppStore((state) => state.user.dialog.dimensions)
	const dispatch = useDispatch()

	useEffect(() => {
		if (dialogId) {
			const dialogSize = dialog[dialogId]

			if (dialogSize) {
				setResizedData({
					width: dialogSize.width,
					height: dialogSize.height,
					isMaximized:
						dialogSize.isMaximized ||
						(window.innerWidth * RELATIVE_MAX_WIDTH < dialogSize.width &&
							window.innerHeight * RELATIVE_MAX_HEIGHT < dialogSize.height),
				})
			}
		}
	}, [])

	useWindowEvent('keyup', (e: KeyboardEvent) => {
		if (closeOnEscape && open && onClose && e.key === 'Escape') {
			onClose()
		}
	})

	useWindowEvent('mouseup', () => {
		if (isResizing) {
			setIsResizing(false)
			trySaveCurrentDialogData(resizedData)
		}
	})

	const trySaveCurrentDialogData = useCallback(
		(data: ResizeData) => {
			if (dialogId && data.width && data.height) {
				dispatch(
					saveDialogSize(
						dialogId,
						data.width <= 0 ? 1 : data.width,
						data.height <= 0 ? 1 : data.height,
						data.isMaximized,
					),
				)
			}
		},
		[dialogId, dispatch],
	)

	useWindowEvent('mousemove', (e: MouseEvent) => {
		if (isResizing) {
			e.preventDefault()
			setResizedData(getResizedData(e, RELATIVE_MAX_WIDTH, RELATIVE_MAX_HEIGHT))
		}
	})

	const onResizerMouseDown = () => {
		setIsResizing(true)
	}

	const onMaximizeClick = useCallback(() => {
		if (!maximizeButtonVisible) {
			return
		}

		const data = { ...resizedData, isMaximized: !resizedData.isMaximized }

		if (
			!data.isMaximized &&
			data.width &&
			data.height &&
			window.innerWidth * RELATIVE_MAX_WIDTH < data.width &&
			window.innerHeight * RELATIVE_MAX_HEIGHT < data.height
		) {
			data.width = window.innerWidth / 1.5
			data.height = window.innerHeight / 1.75
		}

		setResizedData(data)
		trySaveCurrentDialogData(data)
	}, [maximizeButtonVisible, resizedData, trySaveCurrentDialogData])

	const getPopupStyle = () => {
		if (resizable) {
			const resizedStyle: CSSProperties = getResizedStyle(
				resizedData,
				isResizing,
				RELATIVE_MAX_WIDTH,
				RELATIVE_MAX_HEIGHT,
			)

			return { minWidth: 400, minHeight: 400, ...contentStyle, ...resizedStyle }
		} else {
			return contentStyle
		}
	}

	useEffect(() => {
		if (maximized && !refMaximized.current) {
			refMaximized.current = true
		}
	}, [maximized])

	const popup = (
		<>
			{open && (
				<PopupBackground zIndex={zIndex}>
					<Popup style={getPopupStyle() as React.CSSProperties}>
						<Dialog $dialogHeight={height} role="dialog" onClick={stopEvent}>
							{header && (
								<Header
									style={headerStyle as React.CSSProperties}
									onDoubleClick={onMaximizeClick}
								>
									{typeof header === 'function'
										? header(onClose || (() => null))
										: header}
									{maximizeButtonVisible && (
										<Maximizer onClick={onMaximizeClick}>
											<FontAwesomeIcon
												icon={faWindowMaximize}
												color={
													resizedData?.isMaximized ? mainColors.primary : 'gray'
												}
											/>
										</Maximizer>
									)}
								</Header>
							)}
							<Body style={bodyStyle as React.CSSProperties}>
								{typeof children === 'function'
									? children(onClose || (() => null))
									: children}
							</Body>
							{footer && (
								<Footer
									style={footerStyle as React.CSSProperties}
									$stretchFooterButtons={stretchFooterButtons}
									stickyFooter={stickyFooter}
								>
									{typeof footer === 'function'
										? footer(onClose || (() => null))
										: footer}
								</Footer>
							)}
						</Dialog>
						{resizable && <Resizer onMouseDown={onResizerMouseDown} />}
					</Popup>
				</PopupBackground>
			)}
		</>
	)

	if (disablePortal) {
		return popup
	} else {
		return <Portal>{popup}</Portal>
	}
}
