import { FieldSet } from '@/components/Layout'
import styled from 'styled-components'

export const FieldSetProject = styled(FieldSet)`
	margin-top: 15px;
`

export const FieldSetGap = styled(FieldSet)`
	margin-top: 20px;
`
