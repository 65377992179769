import { CheckBox } from '@/components/UberForm'
import { useAppContext } from '@/hooks'
import { range } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { useCsvImporterContext } from '../context/CsvImporterContext'
import { ActionTypeEnum } from '../context/types'
import { StepTitle } from './styled'

export const Preview = () => {
	const { t } = useAppContext()

	const {
		state: { inputDataParsed, firstRow, hasHeaders },
		dispatch,
	} = useCsvImporterContext()

	return (
		<>
			<div>
				<StepTitle>{t('PREVIEW')}</StepTitle>
				<CheckBox
					value={hasHeaders}
					onChange={(value) =>
						dispatch({
							type: ActionTypeEnum.HAS_HEADERS_SET,
							hasHeaders: value as boolean,
						})
					}
					title={t('HAS_HEADERS')}
				/>
			</div>
			<Wrapper>
				{firstRow?.map((column, columnIndex) => (
					<Column key={column}>
						<Header hasHeaders={hasHeaders}>{column}</Header>
						{range(1, 5).map((rowIndex) => (
							<Cell key={rowIndex}>
								{inputDataParsed?.data[rowIndex]?.[columnIndex]}
							</Cell>
						))}
					</Column>
				))}
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	overflow: auto;
	margin-top: 10px;
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
`

const Cell = styled.div`
	white-space: nowrap;
	padding-top: 2px;
	padding-right: 8px;
	padding-left: 2px;
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Header = styled(Cell)<{ hasHeaders: boolean }>`
	${(props) =>
		props.hasHeaders &&
		css`
			border-bottom: 1px solid #afafaf;
			padding-bottom: 2px;
			text-align: center;
		`}
`
